import React, { useEffect, useState } from 'react'
import Box from '@material-ui/core/Box'
import useStyles from '../index.style'
import { useDispatch, useSelector } from 'react-redux'
import SidebarHeader from './SidebarHeader'
import {
  getChatUsers,
  onUserSelect,
  readLastMessage
} from '../../../../store/actions/ActionsFiles/Chat'
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
// import ContactList from './ContactList';
import ChatUserList from './ChatUserList'
// import {users} from '../fakeData/chat'

const Sidebar = ({ mainUser }) => {
  // const { users, currentUser, contacts, isSideBarCollapsed } = useSelector(({ chat }) => chat);
  var { currentUser, isSideBarCollapsed, users } = useSelector(({ chat }) => chat)
  // console.log(currentUser, isSideBarCollapsed, users, 'INDEX')
  // const [value, setValue] = useState(1);
  const [searchText, setSearchText] = useState('')
  const dispatch = useDispatch()

  const onContactSelect = (contact) => {
    dispatch(onUserSelect(contact))
    if (contact.sender !== mainUser.email) {
      users = users.map((user) => {

        if (user.email === contact.sender) {
          user.readMessage = true
          return user
        } else {
          return user
        }
      })
      let _obj = {}
      _obj['fromEmail'] = contact.sender
      _obj['toEmail'] = contact.receiver
      _obj['message'] = contact.lastMessage
      _obj['messageType'] = contact.messageType
      dispatch(readLastMessage(_obj))
    }
  }

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  useEffect(() => {
    // if (value === 1) {
    dispatch(getChatUsers())
    // dispatch(getChatUsers({ search: searchText }));
    // } else {
    //   dispatch(getContactUsers({ search: searchText }));
    // }
  }, [dispatch])

  const classes = useStyles({ isCollapsed: isSideBarCollapsed })
  // const classes = useStyles()


  console.log(users, 'users')
  return (
    <Box className={classes.inBuildAppSidebar}>
      <SidebarHeader searchText={searchText} setSearchText={setSearchText} />
      {/* <Tabs
        className={classes.tabContainer}
        value={value}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}>
        <Tab className={classes.tabRoot} label="Chat" value={1} />
        <Tab className={classes.tabRoot} label="Contact" value={2} />
      </Tabs> */}

      <ChatUserList
        mainUser={mainUser}
        currentUser={currentUser}
        users={users.sort(function (x, y) {
          return y.favourite - x.favourite
        })}
        onContactSelect={onContactSelect}
      />
      {/* {value === 1 ? (
        <ChatUserList
          currentUser={currentUser}
          users={users && users.sort(function(x, y) {
            return y.favourite - x.favourite;
          })}
          onContactSelect={onContactSelect}
        />
      ) : (
        <ContactList
          currentUser={currentUser}
          contacts={contacts && contacts.sort(function(x, y) {
            return y.favourite - x.favourite;
          })}
          onContactSelect={onContactSelect}
        />
      )} */}
    </Box>
  )
}

// const mapStateToProps = (state) => {
//   return {
//     isAuthenticated: state.userAuth.isAuthenticated,
//     user: state.userAuth.user,
//     token: state.userAuth.token,
//     chat: state.chat
//   }
// }

export default Sidebar
