// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SideDrawer_SideDrawer__3kXLK {\n    position: fixed;\n    width: 300px;\n    max-width: 100%;\n    min-width: 40%;\n    height: 100%;\n    left: 0;\n    top: 0;\n    z-index: 200;\n    background: white;\n    padding: 3px 2px;\n    box-sizing: border-box;\n    transition: transform 0.3s ease-out;\n    overflow-y: scroll;\n    color: rgb(115, 129, 129);\n}\n\n.SideDrawer_UserInfo__vk7CM {\n    border-radius: 5px;\n    text-align: center;\n    padding: 5px;\n    word-wrap: break-word;\n}\n\n.SideDrawer_UserInfo__vk7CM h3 {\n    color: rgb(77, 71, 71);\n}\n\n.SideDrawer_closeHandler__TL_A3 {\n    margin-left: 5px;\n}\n\n.SideDrawer_CloseHandler__1j3Jv {\n    float: right;\n    background: #edf1f8;\n    cursor: pointer;\n    padding: 5px;\n    border-radius: 3px;\n}\n\n.SideDrawer_CloseHandler__1j3Jv:hover {\n    background: #dfdfdd;\n}\n\n.SideDrawer_Divider__3EGQs {\n    height: 1px;\n    background: rgb(233, 230, 230);\n    margin: 3% 0%;\n}\n\n.SideDrawer_UserInfo__vk7CM img {\n    border-radius: 50%;\n    height: 80px;\n    width: 80px;\n    cursor: pointer;\n    margin-bottom: 10px;\n}\n\n.SideDrawer_FixedSideNav__20m3S {\n    width: auto;\n    position: fixed;\n    z-index: 10;\n    top: 65px;\n    background: white;\n    box-shadow: 0 4px 6px 0 rgba(109, 108, 108, 0.2);\n    display: none;\n    max-height: 100%;\n}\n\n.SideDrawer_FixedSideNav__20m3S a {\n    padding: 7px 17px;\n    text-decoration: none;\n    display: block;\n    margin-left: 1px;\n}\n\n@media (min-width: 930px) {\n    .SideDrawer_SideDrawer__3kXLK {\n        display: none;\n    }\n    .SideDrawer_UserInfo__vk7CM {\n        height: 46%;\n        background: whitesmoke;\n        border-radius: 5px;\n        padding: 5px;\n    }\n    .SideDrawer_FixedSideNav__20m3S {\n        display: flex;\n    }\n}\n\n.SideDrawer_Open__1tCv1 {\n    transform: translateX(0);\n}\n\n.SideDrawer_Close__9j7x- {\n    transform: translateX(-100%);\n}", ""]);
// Exports
exports.locals = {
	"SideDrawer": "SideDrawer_SideDrawer__3kXLK",
	"UserInfo": "SideDrawer_UserInfo__vk7CM",
	"closeHandler": "SideDrawer_closeHandler__TL_A3",
	"CloseHandler": "SideDrawer_CloseHandler__1j3Jv",
	"Divider": "SideDrawer_Divider__3EGQs",
	"FixedSideNav": "SideDrawer_FixedSideNav__20m3S",
	"Open": "SideDrawer_Open__1tCv1",
	"Close": "SideDrawer_Close__9j7x-"
};
module.exports = exports;
