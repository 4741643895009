import * as actionTypes from '../actions/ActionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    files: [],
    loading: false,
    error: false,
    message: null,
    percentage: 0
};

const filesUploadStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        error: false,
        percentage: 0,
        message: null,
    });
}

const filesUploadFail = (state, action) => {
    return updateObject(state, {
        error: true,
        message: action.payload.message,
        loading: false
    });
};

const filesUploadSuccess = (state, action) => {
    return updateObject(state, {
        files: action.payload.files,
        message: action.payload.message,
        error: false,
        percentage: 0,
        loading:false
    });
}

const uploadingPercentage = (state, action) => {
    return updateObject(state, {
        percentage: action.payload.percentage
    });
}

export const FileUploadReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FILE_UPLOAD_START: return filesUploadStart(state, action);
        case actionTypes.FILE_UPLOAD_SUCCESS: return filesUploadSuccess(state, action);
        case actionTypes.FILE_UPLOAD_ERROR: return filesUploadFail(state, action);
        case actionTypes.FILE_UPLOAD_PERCENTAGE: return uploadingPercentage(state, action)
        default: return state;
    }
};