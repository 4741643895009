export const allDomains = [
    { name: "Clothing & Accessories", value: 0 },
    { name: "Foodies & Drinks", value: 0 },
    { name: "Travel & Adventure", value: 0},
    { name: "Beauty & wellness", value: 0 },
    { name: "Fitness & Health", value: 0 },
    { name: "Sport", value: 0 },
    { name: "Music & Video", value: 0},
    { name: "Event", value: 0 },
    { name: "Business & Job", value: 0 },
    { name: "Home & Garden", value: 0},
    { name: "Cinema & Show", value: 0 },
    { name: "All Engine", value: 0},
    { name: "Town planning & Others", value: 0 },
    { name: "Transport & Delivery", value: 0 },
    { name: "Social & Political", value: 0 },
    { name: "Game & Techno", value: 0},
    { name: "Beliefs & Religion", value: 0},
    { name: "Adults", value: 0},
    { name: "Construction", value: 0}
];

export const allTypes = [
    { name: "TV", value: 0 },
    { name: "Radio", value: 0 },
    { name: "Internet", value: 0 },
    { name: "Sports", value: 0 },
    { name: "Print-media", value: 0 },
    { name: "Cinema", value: 0 },
    { name: "Music", value: 0 },
    { name: "Billboard", value: 0 },
    { name: "Social-media", value: 0 },
    { name: "Event", value: 0 },
    { name: "Freestyle", value: 0 }
];

export const allSubMedia = [
    { name: "Generalist", value: 0 },
    { name: "News", value: 0 },
    { name: "Sports", value: 0 },
    { name: "Gastronomy", value: 0 },
    { name: "Humor", value: 0 },
    { name: "Adult", value: 0 },
    { name: "Web", value: 0 },
    { name: "Social Media", value: 0 },
    { name: "Mobile App", value: 0 },
    { name: "Mailing", value: 0 },
    { name: "Connected Objects", value: 0 },
    { name: "Team", value: 0 },
    { name: "Player", value: 0 },
    { name: "Free Print", value: 0 },
    { name: "Paid Print", value: 0 },
    { name: "Clothes", value: 0 },
    { name: "Sticker", value: 0 },
    { name: "Paper", value: 0 },
    { name: "Author", value: 0 },
    { name: "Room", value: 0 },
    { name: "Theater", value: 0 },
    { name: "Actor", value: 0 },
    { name: "Sound", value: 0 },
    { name: "Outdoor", value: 0 },
    { name: "Indoor", value: 0 },
    { name: "Immovable", value: 0 },
    { name: "Mobile", value: 0 },
    { name: "Digital", value: 0 },
    { name: "Printed", value: 0 },
    { name: "Sculpture", value: 0 },
    { name: "Facebook", value: 0 },
    { name: "Youtube", value: 0 },
    { name: "Tiktok", value: 0 },
    { name: "Twitter", value: 0 },
    { name: "Instagram", value: 0 },
    { name: "Snapchat", value: 0 },
    { name: "Pinterest", value: 0 }
]
export const countryData = () => {
    return [
        {
            "code": "+93",
            "name": "Afghanistan",
            "nick": "AF"
        },
        {
            "code": "+355",
            "name": "Albania",
            "nick": "AL"
        },
        {
            "code": "+213",
            "name": "Algeria",
            "nick": "DZ"
        },
        {
            "code": "+1 684",
            "name": "American Samoa",
            "nick": "AS"
        },
        {
            "code": "+376",
            "name": "Andorra"
        },
        {
            "code": "+244",
            "name": "Angola",
            "nick": "AO"
        },
        {
            "code": "+1 264",
            "name": "Anguilla",
            "nick": "AI"
        },
        {
            "code": "+1 268",
            "name": "Antigua and Barbuda",
            "nick": "AG"
        },
        {
            "code": "+54",
            "name": "Argentina",
            "nick": "AR"
        },
        {
            "code": "+374",
            "name": "Armenia",
            "nick": "AM"
        },
        {
            "code": "+297",
            "name": "Aruba",
            "nick": "AW"
        },
        {
            "code": "+247",
            "name": "Ascension",
            "nick": "AC"
        },
        {
            "code": "+61",
            "name": "Australia",
            "nick": "AU"
        },
        {
            "code": "+672",
            "name": "Australian External Territories",
            "nick": "AE"
        },
        {
            "code": "+43",
            "name": "Austria",
            "nick": "AT"
        },
        {
            "code": "+994",
            "name": "Azerbaijan",
            "nick": "AZ"
        },
        {
            "code": "+1 242",
            "name": "Bahamas",
            "nick": "BS"
        },
        {
            "code": "+973",
            "name": "Bahrain",
            "nick": "BH"
        },
        {
            "code": "+880",
            "name": "Bangladesh",
            "nick": "BD"
        },
        {
            "code": "+1 246",
            "name": "Barbados",
            "nick": "BB"
        },
        {
            "code": "+1 268",
            "name": "Barbuda",
            "nick": "BQ"
        },
        {
            "code": "+375",
            "name": "Belarus",
            "nick": "BY"
        },
        {
            "code": "+32",
            "name": "Belgium",
            "nick": "BE"
        },
        {
            "code": "+501",
            "name": "Belize",
            "nick": "BZ"
        },
        {
            "code": "+229",
            "name": "Benin",
            "nick": "BJ"
        },
        {
            "code": "+1 441",
            "name": "Bermuda",
            "nick": "BM"
        },
        {
            "code": "+975",
            "name": "Bhutan",
            "nick": "BT"
        },
        {
            "code": "+591",
            "name": "Bolivia",
            "nick": "BO"
        },
        {
            "code": "+387",
            "name": "Bosnia and Herzegovina",
            "nick": "BA"
        },
        {
            "code": "+267",
            "name": "Botswana",
            "nick": "BW"
        },
        {
            "code": "+55",
            "name": "Brazil",
            "nick": "BR"
        },
        {
            "code": "+246",
            "name": "British Indian Ocean Territory",
            "nick": "IO"
        },
        {
            "code": "+1 284",
            "name": "British Virgin Islands",
            "nick": "VG"
        },
        {
            "code": "+673",
            "name": "Brunei"
        },
        {
            "code": "+359",
            "name": "Bulgaria",
            "nick": "BG"
        },
        {
            "code": "+226",
            "name": "Burkina Faso",
            "nick": "BF"
        },
        {
            "code": "+257",
            "name": "Burundi",
            "nick": "BI"
        },
        {
            "code": "+855",
            "name": "Cambodia",
            "nick": "KH"
        },
        {
            "code": "+237",
            "name": "Cameroon",
            "nick": "CM"
        },
        {
            "code": "+1",
            "name": "Canada",
            "nick": "CA"
        },
        {
            "code": "+238",
            "name": "Cape Verde",
            "nick": "CV"
        },
        {
            "code": "+ 345",
            "name": "Cayman Islands",
            "nick": "KY"
        },
        {
            "code": "+236",
            "name": "Central African Republic",
            "nick": "CF"
        },
        {
            "code": "+235",
            "name": "Chad",
            "nick": "TD"
        },
        {
            "code": "+56",
            "name": "Chile",
            "nick": "CL"
        },
        {
            "code": "+86",
            "name": "China",
            "nick": "CN"
        },
        {
            "code": "+61",
            "name": "Christmas Island",
            "nick": "CX"
        },
        {
            "code": "+61",
            "name": "Cocos-Keeling Islands",
            "nick": "CC"
        },
        {
            "code": "+57",
            "name": "Colombia",
            "nick": "CO"
        },
        {
            "code": "+269",
            "name": "Comoros",
            "nick": "KM"
        },
        {
            "code": "+242",
            "name": "Congo",
            "nick": "CG"
        },
        {
            "code": "+243",
            "name": "Congo, Dem. Rep. of (Zaire)",
            "nick": "CD"
        },
        {
            "code": "+682",
            "name": "Cook Islands",
            "nick": "CK"
        },
        {
            "code": "+506",
            "name": "Costa Rica",
            "nick": "CR"
        },
        {
            "code": "+385",
            "name": "Croatia",
            "nick": "HR"
        },
        {
            "code": "+53",
            "name": "Cuba",
            "nick": "CU"
        },
        {
            "code": "+599",
            "name": "Curacao",
            "nick": "CW"
        },
        {
            "code": "+537",
            "name": "Cyprus",
            "nick": "CY"
        },
        {
            "code": "+420",
            "name": "Czech Republic",
            "nick": "CZ"
        },
        {
            "code": "+45",
            "name": "Denmark",
            "nick": "DK"
        },
        {
            "code": "+246",
            "name": "Diego Garcia",
            "nick": "DG"
        },
        {
            "code": "+253",
            "name": "Djibouti",
            "nick": "DJ"
        },
        {
            "code": "+1 767",
            "name": "Dominica",
            "nick": "DM"
        },
        {
            "code": "+1 809",
            "name": "Dominican Republic",
            "nick": "DO"
        },
        {
            "code": "+670",
            "name": "East Timor",
            "nick": "TP"
        },
        {
            "code": "+56",
            "name": "Easter Island",
            "nick": "EC"
        },
        {
            "code": "+593",
            "name": "Ecuador",
            "nick": "EC"
        },
        {
            "code": "+20",
            "name": "Egypt",
            "nick": "EG"
        },
        {
            "code": "+503",
            "name": "El Salvador",
            "nick": "SV"
        },
        {
            "code": "+240",
            "name": "Equatorial Guinea",
            "nick": "GQ"
        },
        {
            "code": "+291",
            "name": "Eritrea",
            "nick": "ER"
        },
        {
            "code": "+372",
            "name": "Estonia",
            "nick": "EE"
        },
        {
            "code": "+251",
            "name": "Ethiopia",
            "nick": "ET"
        },
        {
            "code": "+500",
            "name": "Falkland Islands",
            "nick": "FK"
        },
        {
            "code": "+298",
            "name": "Faroe Islands",
            "nick": "FO"
        },
        {
            "code": "+679",
            "name": "Fiji",
            "nick": "FJ"
        },
        {
            "code": "+358",
            "name": "Finland",
            "nick": "FI"
        },
        {
            "code": "+33",
            "name": "France",
            "nick": "FR"
        },
        {
            "code": "+596",
            "name": "French Antilles"
        },
        {
            "code": "+594",
            "name": "French Guiana",
            "nick": "GF"
        },
        {
            "code": "+689",
            "name": "French Polynesia",
            "nick": "PF"
        },
        {
            "code": "+241",
            "name": "Gabon",
            "nick": "GA"
        },
        {
            "code": "+220",
            "name": "Gambia",
            "nick": "GM"
        },
        {
            "code": "+995",
            "name": "Georgia",
            "nick": "GE"
        },
        {
            "code": "+49",
            "name": "Germany",
            "nick": "DE"
        },
        {
            "code": "+233",
            "name": "Ghana",
            "nick": "GH"
        },
        {
            "code": "+350",
            "name": "Gibraltar",
            "nick": "GI"
        },
        {
            "code": "+30",
            "name": "Greece",
            "nick": "GR"
        },
        {
            "code": "+299",
            "name": "Greenland",
            "nick": "GL"
        },
        {
            "code": "+1 473",
            "name": "Grenada",
            "nick": "GD"
        },
        {
            "code": "+590",
            "name": "Guadeloupe",
            "nick": "GP"
        },
        {
            "code": "+1 671",
            "name": "Guam",
            "nick": "GU"
        },
        {
            "code": "+502",
            "name": "Guatemala",
            "nick": "GT"
        },
        {
            "code": "+224",
            "name": "Guinea",
            "nick": "GN"
        },
        {
            "code": "+245",
            "name": "Guinea-Bissau",
            "nick": "GW"
        },
        {
            "code": "+595",
            "name": "Guyana",
            "nick": "GY"
        },
        {
            "code": "+509",
            "name": "Haiti",
            "nick": "HT"
        },
        {
            "code": "+504",
            "name": "Honduras",
            "nick": "HN"
        },
        {
            "code": "+852",
            "name": "Hong Kong SAR China",
            "nick": "HK"
        },
        {
            "code": "+36",
            "name": "Hungary",
            "nick": "HU"
        },
        {
            "code": "+354",
            "name": "Iceland",
            "nick": "IS"
        },
        {
            "code": "+91",
            "name": "India",
            "nick": "IN"
        },
        {
            "code": "+62",
            "name": "Indonesia",
            "nick": "ID"
        },
        {
            "code": "+98",
            "name": "Iran",
            "nick": "IR"
        },
        {
            "code": "+964",
            "name": "Iraq",
            "nick": "IQ"
        },
        {
            "code": "+353",
            "name": "Ireland",
            "nick": "IE"
        },
        {
            "code": "+972",
            "name": "Israel",
            "nick": "IL"
        },
        {
            "code": "+39",
            "name": "Italy",
            "nick": "IT"
        },
        {
            "code": "+225",
            "name": "Ivory Coast"
        },
        {
            "code": "+1",
            "name": "Jamaica",
            "nick": "JM"
        },
        {
            "code": "+81",
            "name": "Japan",
            "nick": "JP"
        },
        {
            "code": "+962",
            "name": "Jordan",
            "nick": "JO"
        },
        {
            "code": "+7",
            "name": "Kazakhstan",
            "nick": "KZ"
        },
        {
            "code": "+254",
            "name": "Kenya",
            "nick": "KE"
        },
        {
            "code": "+686",
            "name": "Kiribati",
            "nick": "KI"
        },
        {
            "code": "+965",
            "name": "Kuwait",
            "nick": "KW"
        },
        {
            "code": "+996",
            "name": "Kyrgyzstan",
            "nick": "KG"
        },
        {
            "code": "+856",
            "name": "Laos",
            "nick": "LA"
        },
        {
            "code": "+371",
            "name": "Latvia",
            "nick": "LV"
        },
        {
            "code": "+961",
            "name": "Lebanon",
            "nick": "LB"
        },
        {
            "code": "+266",
            "name": "Lesotho",
            "nick": "LS"
        },
        {
            "code": "+231",
            "name": "Liberia",
            "nick": "LR"
        },
        {
            "code": "+218",
            "name": "Libya",
            "nick": "LY"
        },
        {
            "code": "+423",
            "name": "Liechtenstein",
            "nick": "LI"
        },
        {
            "code": "+370",
            "name": "Lithuania",
            "nick": "LT"
        },
        {
            "code": "+352",
            "name": "Luxembourg",
            "nick": "LU"
        },
        {
            "code": "+853",
            "name": "Macau SAR China",
            "nick": "MO"
        },
        {
            "code": "+389",
            "name": "Macedonia",
            "nick": "MK"
        },
        {
            "code": "+261",
            "name": "Madagascar",
            "nick": "MG"
        },
        {
            "code": "+265",
            "name": "Malawi",
            "nick": "MW"
        },
        {
            "code": "+60",
            "name": "Malaysia",
            "nick": "MY"
        },
        {
            "code": "+960",
            "name": "Maldives",
            "nick": "MV"
        },
        {
            "code": "+223",
            "name": "Mali",
            "nick": "ML"
        },
        {
            "code": "+356",
            "name": "Malta",
            "nick": "MT"
        },
        {
            "code": "+692",
            "name": "Marshall Islands",
            "nick": "MH"
        },
        {
            "code": "+596",
            "name": "Martinique",
            "nick": "MQ"
        },
        {
            "code": "+222",
            "name": "Mauritania",
            "nick": "MR"
        },
        {
            "code": "+230",
            "name": "Mauritius",
            "nick": "MU"
        },
        {
            "code": "+262",
            "name": "Mayotte",
            "nick": "YT"
        },
        {
            "code": "+52",
            "name": "Mexico",
            "nick": "MX"
        },
        {
            "code": "+691",
            "name": "Micronesia",
            "nick": "FM"
        },
        {
            "code": "+1 808",
            "name": "Midway Island"
        },
        {
            "code": "+373",
            "name": "Moldova",
            "nick": "MD"
        },
        {
            "code": "+377",
            "name": "Monaco",
            "nick": "MC"
        },
        {
            "code": "+976",
            "name": "Mongolia",
            "nick": "MN"
        },
        {
            "code": "+382",
            "name": "Montenegro",
            "nick": "ME"
        },
        {
            "code": "+1664",
            "name": "Montserrat",
            "nick": "MS"
        },
        {
            "code": "+212",
            "name": "Morocco",
            "nick": "MA"
        },
        {
            "code": "+95",
            "name": "Myanmar",
            "nick": "MM"
        },
        {
            "code": "+264",
            "name": "Namibia",
            "nick": "NA"
        },
        {
            "code": "+674",
            "name": "Nauru",
            "nick": "NR"
        },
        {
            "code": "+977",
            "name": "Nepal",
            "nick": "NP"
        },
        {
            "code": "+31",
            "name": "Netherlands",
            "nick": "NL"
        },
        {
            "code": "+599",
            "name": "Netherlands Antilles",
            "nick": "AN"
        },
        {
            "code": "+1 869",
            "name": "Nevis",
            "nick": "KN"
        },
        {
            "code": "+687",
            "name": "New Caledonia",
            "nick": "NC"
        },
        {
            "code": "+64",
            "name": "New Zealand",
            "nick": "NZ"
        },
        {
            "code": "+505",
            "name": "Nicaragua",
            "nick": "NI"
        },
        {
            "code": "+227",
            "name": "Niger",
            "nick": "NE"
        },
        {
            "code": "+234",
            "name": "Nigeria",
            "nick": "NG"
        },
        {
            "code": "+683",
            "name": "Niue",
            "nick": "NU"
        },
        {
            "code": "+672",
            "name": "Norfolk Island",
            "nick": "NF"
        },
        {
            "code": "+850",
            "name": "North Korea"
        },
        {
            "code": "+1 670",
            "name": "Northern Mariana Islands",
            "nick": "MP"
        },
        {
            "code": "+47",
            "name": "Norway",
            "nick": "NO"
        },
        {
            "code": "+968",
            "name": "Oman",
            "nick": "OM"
        },
        {
            "code": "+92",
            "name": "Pakistan",
            "nick": "PK"
        },
        {
            "code": "+680",
            "name": "Palau",
            "nick": "PW"
        },
        {
            "code": "+970",
            "name": "Palestinian Territory",
            "nick": "PS"
        },
        {
            "code": "+507",
            "name": "Panama",
            "nick": "PA"
        },
        {
            "code": "+675",
            "name": "Papua New Guinea",
            "nick": "PG"
        },
        {
            "code": "+595",
            "name": "Paraguay",
            "nick": "PY"
        },
        {
            "code": "+51",
            "name": "Peru",
            "nick": "PE"
        },
        {
            "code": "+63",
            "name": "Philippines",
            "nick": "PH"
        },
        {
            "code": "+48",
            "name": "Poland",
            "nick": "PL"
        },
        {
            "code": "+351",
            "name": "Portugal",
            "nick": "PT"
        },
        {
            "code": "+1 787",
            "name": "Puerto Rico",
            "nick": "PR"
        },
        {
            "code": "+974",
            "name": "Qatar",
            "nick": "QA"
        },
        {
            "code": "+262",
            "name": "Reunion",
            "nick": "RE"
        },
        {
            "code": "+40",
            "name": "Romania",
            "nick": "RO"
        },
        {
            "code": "+7",
            "name": "Russia",
            "nick": "RU"
        },
        {
            "code": "+250",
            "name": "Rwanda",
            "nick": "RW"
        },
        {
            "code": "+685",
            "name": "Samoa",
            "nick": "WS"
        },
        {
            "code": "+378",
            "name": "San Marino",
            "nick": "SM"
        },
        {
            "code": "+966",
            "name": "Saudi Arabia",
            "nick": "SA"
        },
        {
            "code": "+221",
            "name": "Senegal",
            "nick": "SN"
        },
        {
            "code": "+381",
            "name": "Serbia",
            "nick": "RS"
        },
        {
            "code": "+248",
            "name": "Seychelles",
            "nick": "SC"
        },
        {
            "code": "+232",
            "name": "Sierra Leone",
            "nick": "SL"
        },
        {
            "code": "+65",
            "name": "Singapore",
            "nick": "SG"
        },
        {
            "code": "+421",
            "name": "Slovakia",
            "nick": "SK"
        },
        {
            "code": "+386",
            "name": "Slovenia",
            "nick": "SI"
        },
        {
            "code": "+677",
            "name": "Solomon Islands",
            "nick": "SB"
        },
        {
            "code": "+27",
            "name": "South Africa",
            "nick": "ZA"
        },
        {
            "code": "+500",
            "name": "South Georgia and the South Sandwich Islands",
            "nick": "GS"
        },
        {
            "code": "+82",
            "name": "South Korea",
            "nick": "KR"
        },
        {
            "code": "+34",
            "name": "Spain",
            "nick": "ES"
        },
        {
            "code": "+94",
            "name": "Sri Lanka",
            "nick": "LK"
        },
        {
            "code": "+249",
            "name": "Sudan",
            "nick": "SD"
        },
        {
            "code": "+597",
            "name": "Suriname",
            "nick": "SR"
        },
        {
            "code": "+268",
            "name": "Swaziland",
            "nick": "SZ"
        },
        {
            "code": "+46",
            "name": "Sweden",
            "nick": "SE"
        },
        {
            "code": "+41",
            "name": "Switzerland",
            "nick": "CH"
        },
        {
            "code": "+963",
            "name": "Syria",
            "nick": "SY"
        },
        {
            "code": "+886",
            "name": "Taiwan",
            "nick": "TW"
        },
        {
            "code": "+992",
            "name": "Tajikistan",
            "nick": "TJ"
        },
        {
            "code": "+255",
            "name": "Tanzania",
            "nick": "TZ"
        },
        {
            "code": "+66",
            "name": "Thailand",
            "nick": "TH"
        },
        {
            "code": "+670",
            "name": "Timor Leste",
            "nick": "TL"
        },
        {
            "code": "+228",
            "name": "Togo",
            "nick": "TG"
        },
        {
            "code": "+690",
            "name": "Tokelau",
            "nick": "TK"
        },
        {
            "code": "+676",
            "name": "Tonga",
            "nick": "TO"
        },
        {
            "code": "+1 868",
            "name": "Trinidad and Tobago",
            "nick": "TT"
        },
        {
            "code": "+216",
            "name": "Tunisia",
            "nick": "TN"
        },
        {
            "code": "+90",
            "name": "Turkey",
            "nick": "TR"
        },
        {
            "code": "+993",
            "name": "Turkmenistan",
            "nick": "TM"
        },
        {
            "code": "+1 649",
            "name": "Turks and Caicos Islands",
            "nick": "TC"
        },
        {
            "code": "+688",
            "name": "Tuvalu",
            "nick": "TV"
        },
        {
            "code": "+1 340",
            "name": "U.S. Virgin Islands",
            "nick": "VI"
        },
        {
            "code": "+256",
            "name": "Uganda",
            "nick": "UG"
        },
        {
            "code": "+380",
            "name": "Ukraine",
            "nick": "UA"
        },
        {
            "code": "+971",
            "name": "United Arab Emirates",
            "nick": "AE"
        },
        {
            "code": "+44",
            "name": "United Kingdom",
            "nick": "GB"
        },
        {
            "code": "+1",
            "name": "United States",
            "nick": "US"
        },
        {
            "code": "+598",
            "name": "Uruguay",
            "nick": "UY"
        },
        {
            "code": "+998",
            "name": "Uzbekistan",
            "nick": "UZ"
        },
        {
            "code": "+678",
            "name": "Vanuatu",
            "nick": "VU"
        },
        {
            "code": "+58",
            "name": "Venezuela",
            "nick": "VE"
        },
        {
            "code": "+84",
            "name": "Vietnam",
            "nick": "VN"
        },
        {
            "code": "+1 808",
            "name": "Wake Island",
            "nick": "WK"
        },
        {
            "code": "+681",
            "name": "Wallis and Futuna",
            "nick": "WF"
        },
        {
            "code": "+967",
            "name": "Yemen",
            "nick": "YE"
        },
        {
            "code": "+260",
            "name": "Zambia",
            "nick": "ZM"
        },
        {
            "code": "+255",
            "name": "Zanzibar",
            "nick": "ZR"
        },
        {
            "code": "+263",
            "name": "Zimbabwe",
            "nick": "ZW"
        }
    ]
}

