import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import classes from '../Dashboard.module.css'
import { Spinner } from '../../../../components/UI/Spinner/Spinner';
import { getDate } from '../../../../shared/utility';

export const OrderMobileView = ({ screenWidth, latestOrders, isLoading, orderManage }) => {
    const { t } = useTranslation(['Dashboard'])
    return <div className={`row ${ screenWidth < 715 ? 'justify-content-center' : null }`} > {isLoading ? <Spinner /> : latestOrders.map((order, index) => (
        <div className={classes.OrdersInfoMobile} key={index}>
            <img src={order.advertiseId.files[0]} className='rounded bm-3' alt='Profile' />
            <div className='col-12 mt-2'>
                <div className='d-flex align-items-center'>
                    <div className={classes.Avatar}>
                        {order[orderManage] && order[orderManage].profile_image ?
                            <img
                                style={{ height: '45px', width: '45px', maxWidth: 'unset' }}
                                src={order[orderManage] && order[orderManage].profile_image}
                                alt='Profile' /> :order[orderManage] &&  order[orderManage].username.charAt(0).toUpperCase()}
                    </div>
                    <h6>{order[orderManage] && order[orderManage].username}</h6>
                </div>
            </div>
            <div className='col-12 p-2 mt-2'>
                <div className='d-flex justify-content-between'>
                    <small className='text-muted'>{t('Dashboard:orders.0')}</small>
                    <small>${order.paymentId.priceId}</small>
                </div>
            </div>
            <div className='col-12 p-2'>
                <div className='d-flex justify-content-between'>
                    <small className='text-muted'>{t('Dashboard:orders.1')}</small>
                    <small className='text-success'>{!order.deliveryDate ? 'Not confirmed' : getDate(order.deliveryDate)}</small>
                </div>
            </div>
            <div className='col-12 p-2'>
                <div className='d-flex justify-content-between'>
                    <small className='text-muted'>{t('Dashboard:orders.2')}</small>
                    <div className={classes.IsActive}>{order.status}</div>
                </div>
            </div>
            <div className='col-12 p-2 m-1'>
                <div className='d-flex justify-content-center'> <small className='text-primary'><Link to={{
                    pathname: `/manage-order/${ order._id }/${ order.advertiseId._id }/${ order.status }`,
                }}>{t('Dashboard:orders.3')}</Link> </small>
                </div>
            </div>
        </div>
    ))}
    </div>
}
