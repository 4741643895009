import * as actionTypes from '../ActionTypes'
import axios from '../../../axios'
import {tokenHeadersNormal} from '../HeaderConfig'

export const uploadPaymentData = (data) => {
  return (dispatch, getState) => {
    axios
      .post('/stripe/saveToDatabase', data, {
        headers: tokenHeadersNormal(getState).headers,
      })
      .then((response) => {
        console.log(response.data,'subscription')
        
        dispatch({
          type: actionTypes.UPLOAD_PAYMENT,
          payload: {
            uploadedData: response.data,
          },
        })
      })
      .catch((error) => {
        if (error.response === undefined) return
        dispatch({
          type: actionTypes.ERROR_PAYMENT,
          payload: {
            error: error.response.data.message,
          },
        })
      })
  }
}

export const getPaymentInfo = () => {
  // console.log('here')
  return (dispatch, getState) => {
    if (!getState().userAuth.token) return;
    const url = '/stripe/check';
    axios
      .get(url, {headers: tokenHeadersNormal(getState).headers})
      .then((response) => {
        // console.log(response.data,'ids')
        dispatch({
          type: actionTypes.LOAD_PAYMENT,
          payload: {
            loadedData: response.data.data,
            ids: response.data.ids
          },
        })
      })
      .catch((error) => {
        if (error.response === undefined) return
        dispatch({
          type: actionTypes.ERROR_PAYMENT,
          payload: {
            error: error.response.data.message,
          },
        })
      })
  }
}
