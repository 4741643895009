import React, { Component } from 'react';
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux';
import classes from './AllOrders.module.css';
import Button from '../../../components/UI/Button/Button';
import Dashboard from '../Dashboard/Dashboard.module.css';
// import { Link } from 'react-router-dom';
import { CountDown } from '../../../components/TimeCountDown/CountDown';
import { getDate, getDateItems } from '../../../shared/utility';
import axios from '../../../axios';
import { CircularProgress } from '@material-ui/core';
import { Spinner } from './../../../components/UI/Spinner/Spinner';
import * as actions from '../../../store/actions';
import SimpleSnackbar from '../../../components/Snackbar/Snackbar';
import { Modal } from '../../../components/UI/Modal';
import { GiveReview } from './GiveReviews';
import styles from '../../NormalUser/Tender/Tender.module.css';
import styles1 from '../../../components/Navigation/Toolbar/Toolbar.module.css';
import { Translate } from 'react-auto-translate';
import Translation from '../../../shared/Translate';

class ManageSpecificOrder extends Component {
    state = {
        days: '0',
        hours: '0',
        minutes: '0',
        seconds: '0',
        loading: false,
        isLoading: false,
        message: '',
        reviewMessage: '',
        selectedOrder: '',
        comment: '',
        comments: [],
        showModal: false,
        error: false,
        params: {
            orderId: this.props.history.location.pathname.split('/')[2],
            advertiseId: this.props.history.location.pathname.split('/')[3]
        }
    }

    getTimeUntil = (deadline) => {
        const time = Date.parse(deadline) - Date.parse(new Date().toDateString());
        if (time < 0) {
            console.log('Date passed');
        } else {
            const seconds = Math.floor((time / 1000) % 60);
            const minutes = Math.floor((time / 1000 / 60) % 60);
            const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
            const days = Math.floor(time / (1000 * 60 * 60 * 24));

            this.setState({
                days, hours, minutes, seconds
            });
        }
    }

    componentDidMount() {
        window.scroll(0.0, 0.0);
        if (this.state.params.orderId && this.state.params.advertiseId) {
            localStorage.setItem('tabStatus', this.props.history.location.pathname.split('/')[4]);

            axios.get(
                `/stripe/getPurchaseOrder?orderId=${ this.state.params.orderId }&advertiseId=${ this.state.params.advertiseId }`,
                { headers: this.tokenHeadersNormal().headers }
            ).then(response => {
                this.setState({ selectedOrder: response.data.order });
                const dateObj = getDateItems(this.state.selectedOrder.deliveryDate);
                const deadline = dateObj.month + ', ' + dateObj.day + ', ' + dateObj.year;
                setInterval(() => this.getTimeUntil(deadline), 1000);
            });
            this.props.getOrderComments(this.state.params.orderId, this.state.params.advertiseId);
        }
    }

    tokenHeadersNormal = () => {
        const headersNormal = {
            headers: {
                'Content-Type': 'application/json',
            },
        }
        const token = this.props.token;
        if (token) headersNormal.headers['x-auth-header'] = token;

        return headersNormal;
    }

    setOrderStatus = async (selectedOrder, status) => {
        const date = new Date();

        const orderObject = {
            orderId: selectedOrder._id,
            advertiseId: selectedOrder.advertiseId._id,
            status: status,
            deliveryDate: date.setDate(date.getDate() + parseInt(selectedOrder.advertiseId.packages[0].delivery_time)),
        }
        this.setState({ loading: true });
        axios.post(
            '/stripe/setActiveStatus',
            orderObject,
            { headers: this.tokenHeadersNormal().headers }
        ).then(res => {
            this.setState({ loading: false, message: res.data.message });
            if (this.props.user && this.props.user.role === 'seller') {
                this.props.history.push('/seller/all-orders');
            } else {
                if (status !== 'completed') {
                    this.props.history.push('/buyer/all-orders');
                }
            }
        });
    }

    onChangeComment = (event) => this.setState({ comment: event.target.value });

    upCommentValue = (event) => {
        event.preventDefault();
        if (this.state.comment.length !== 0) {
            const info = {
                comment: this.state.comment,
                commentBy: {
                    username:
                    this.state.selectedOrder[this.props.user.role] && this.state.selectedOrder[this.props.user.role].username,
                    profile_image: this.state.selectedOrder[this.props.user.role] && this.state.selectedOrder[this.props.user.role].profile_image
                },
                createdAt: new Date()
            };
            this.props.setOrderComment({
                commentInfo: info
            }, this.state.params.orderId, this.state.params.advertiseId);

            const localComments = [...this.state.comments];
            localComments.push(info);

            if (this.props.allComments) {
                this.setState({ comment: '', comments: [...this.props.allComments.comments, ...localComments] });
            } else {
                this.setState({ comment: '', comments: localComments });
            }
        }
    }

    onSubmitReviewRating = (countRating, review) => {
        this.setState({ isLoading: true });
        let prevRating = this.state.selectedOrder.advertiseId.rating;

        if (countRating !== 0) {
            prevRating = (this.state.selectedOrder.advertiseId.rating + countRating) / 2;
        }

        axios.post(`/dashboardAdvertise/review-rating`, {
            adId: this.state.params.advertiseId,
            countRating,
            review,
            rating: prevRating.toFixed(1),
            adOwner: this.state.selectedOrder.seller.username
        },
            { headers: this.tokenHeadersNormal().headers, }
        )
            .then(res => this.setState({
                isLoading: false, showModal: false, reviewMessage: res.data.message,
                error: false
            }))
            .catch(error => this.setState({ isLoading: false, error: true, message: error.message }));
    }
    closeModal = () => this.setState({ showModal: false });
    openModal = () => this.setState({ showModal: true });

    render() {
        const { days, hours, minutes, seconds, selectedOrder } = this.state;
        const { t } = this.props;

        console.log(selectedOrder)
        const orderInfo = selectedOrder ? <React.Fragment>
            <div className={classes.ManageSpecificOrderHeader}>
                <div className={classes.ManageSpecificOrderHeaderInfo}>
                    <div className='row w-75 justify-content-start align-items-center'>
                        {this.props.user && this.props.user.role === 'seller' && selectedOrder.status === 'new' &&
                            <div className='col-lg-5 col-sm-12 align-items-center'>
                                <div className='d-flex align-items-center justify-content-center'>
                                    <i className="fas fa-check-circle"></i>
                                    <small className='m-2' style={{ color: 'green' }}>{t('Dashboard:specificOrder.0')}</small>
                                </div>
                            </div>}
                        {selectedOrder.status === 'active' &&
                            <div className='col-lg-5 col-sm-12'>
                                <div className='d-flex align-items-center justify-content-center'>
                                    <i className="fas fa-map-marker-alt"></i>
                                    <small className='m-2' style={{ color: 'goldenrod' }}>{t('Dashboard:specificOrder.1')}</small>
                                </div>
                            </div>}
                        {selectedOrder.status === 'new' &&
                            <div className='col-lg-5 col-sm-12'>
                                <div className='d-flex align-items-center justify-content-center'>
                                    <i className="fas fa-map-marker-alt"></i>
                                    <small className='m-2' style={{ color: 'goldenrod' }}>{t('Dashboard:specificOrder.2')}</small>
                                </div>
                            </div>}
                    </div>
                    <div className='row'>
                        {this.state.loading ? <CircularProgress /> :
                            (
                                selectedOrder.status === 'new' && this.props.user.role === 'seller' &&
                                <Button buttonType="Submit" clicked={(e) => this.setOrderStatus(selectedOrder, 'active')}>{t('Dashboard:specificOrder.3')}</Button>
                            )
                        }
                        {selectedOrder.status === 'active' && this.props.user.role === 'seller' &&
                            <Button
                                buttonType="Submit"
                                clicked={(e) => this.setOrderStatus(selectedOrder, 'delivered')}>
                                {t('Dashboard:specificOrder.4')}
                            </Button>}
                        {selectedOrder.status === 'delivered' && this.props.user.role === 'buyer' &&
                            <Button
                                buttonType="Submit"
                                clicked={(e) => { this.openModal(); this.setOrderStatus(selectedOrder, 'completed') }}>{t('Dashboard:specificOrder.5')}
                            </Button>}
                        {selectedOrder.status === 'completed' && (<Button buttonType="Google">{t('Dashboard:specificOrder.6')}</Button>)}
                    </div>
                </div>
            </div>
            <div className={classes.ManageSpecificOrderMain}>
                <div className={classes.ManageOrdersMainInfo}>
                    <div className={classes.ManageSpecificOrderMainHeader}>
                        <div className='align-items-center'>
                            <div className='d-flex align-items-center'>
                                <h5>Order #{selectedOrder._id.toUpperCase().substr(0, 10)}</h5>
                                {/* <sub className='text-muted'>view ad</sub> */}
                            </div>
                            <small>
                                {this.props.user.role === 'seller' ? 'Buyer : ' : 'Seller : '}
                                <span className="text-primary">
                                    {this.props.user.role === 'seller' ?
                                        (selectedOrder.buyer.full_name
                                            ? selectedOrder.buyer.full_name : selectedOrder.buyer.username) :
                                        (selectedOrder.seller.full_name
                                            ? selectedOrder.seller.full_name : selectedOrder.seller.username
                                        )
                                    }
                                </span>
                                {/* ( <span><Link class="link-warning">{t('Dashboard:specificOrder.7')}</Link></span>) */}
                                <span> | </span>
                                {getDate(selectedOrder.createdAt)}</small>
                        </div>
                        <div className='d-flex align-items-center'>
                            <h2>${selectedOrder.advertiseId.packages[0].price}.00</h2>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between m-2">
                        <div className={Dashboard.IsActive} style={{ background: 'green' }}>
                            <h6> {selectedOrder.packageType}</h6>
                        </div>
                        <div className={Dashboard.IsActive}>{selectedOrder.status.toUpperCase()}</div>
                    </div>
                    <div className={Dashboard.Divider}></div>
                    <div className='ms-2'>
                        <label className='py-2 text-secondary text-bold'>
                            <Translation
                                from={'en'}
                                text="Media"
                                input={<Translate>Media</Translate>}
                            />
                        </label>
                    </div>
                    <div className='ms-2'>
                        <div className={styles.MediaItem}>
                            {selectedOrder.advertiseId.types.map((type, index) => (
                                <span key={index}>
                                    {type}
                                </span>
                            ))}
                        </div>
                    </div>
                    <div className='ms-2'>
                        <p className='py-2 text-secondary text-bold'>
                            <Translation
                                from={'en'}
                                text="Sub Media"
                                input={<Translate>Sub Media</Translate>}
                            />
                        </p>
                    </div>
                    <div className='ms-2'>
                        <div className={styles.MediaItem}>
                            {selectedOrder.advertiseId.subtypes.map((subtype, index) => (
                                <span key={index}>
                                    {subtype}
                                </span>
                            ))}
                        </div>
                    </div>
                    <div className={classes.OrderDescription}>
                        <p className='text-dark' dangerouslySetInnerHTML={{ __html: selectedOrder.advertiseId.description }} />
                    </div>
                    {this.props.screenWidth > 840 ?
                        <div className={classes.OrderDescription}>
                            <div className="row justify-content-between align-items-center bg-light p-3">
                                <div className='col-5'>
                                    <h6 className='text-dark'>{t('Dashboard:specificOrder.8')}</h6>
                                </div>
                                <div className='col-2'>
                                    <h6 className='text-dark'>{t('Dashboard:specificOrder.9')}</h6>
                                </div>
                                <div className='col-2'>
                                    <h6 className='text-dark'>{t('Dashboard:specificOrder.10')}</h6>
                                </div>
                                <div className='col-2'>
                                    <h6 className='text-dark'>{t('Dashboard:specificOrder.11')}</h6>
                                </div>
                            </div>
                            <div className="row justify-content-between align-items-center mt-3">
                                <div className='col-5'>
                                    <div className={classes.OrderTask}>
                                        {selectedOrder.advertiseId.packages[0].tasks.map((task, index) => (
                                            <span key={index}> <i class="fas fa-check"></i>{task}</span>
                                        ))}
                                    </div>
                                </div>
                                <div className='col-2'>
                                    <div className={Dashboard.OrderInfo}>
                                        <small className='text-muted'>1</small>
                                    </div>
                                </div>
                                <div className='col-2'>
                                    <div className={Dashboard.OrderInfo}>
                                        <small className='text-muted'>{selectedOrder.advertiseId.packages[0].delivery_time} days</small>
                                    </div>
                                </div>
                                <div className='col-2'>
                                    <div className={Dashboard.OrderInfo}>
                                        <small className='text-muted'>${selectedOrder.advertiseId.packages[0].price}.00</small>
                                    </div>
                                </div>
                            </div>
                            <div className={Dashboard.Divider}></div>
                            <h6 className='text-dark text-end'>{t('Dashboard:specificOrder.12')} : ${selectedOrder.advertiseId.packages[0].price}.00</h6>
                        </div>
                        :
                        <div className={classes.OrderDescription}>
                            <div className="p-2">
                                <div className='col-12'>
                                    <h6 className='text-dark'>{t('Dashboard:specificOrder.8')}</h6>
                                    <div className={classes.OrderTask}>
                                        {selectedOrder.advertiseId.packages[0].tasks.map((task, index) => (
                                            <span key={index}> <i class="fas fa-check"></i>{task}</span>
                                        ))}
                                    </div>
                                </div>
                                <div className="row justify-content-between align-items-center mt-3">
                                    <div className='col-9'>
                                        <h6 className='text-dark'>{t('Dashboard:specificOrder.9')}</h6>
                                    </div>
                                    <div className='col-3'>
                                        <div className={Dashboard.OrderInfo}>
                                            <small className='text-muted'>1</small>
                                        </div>
                                    </div>
                                </div>
                                <div className="row justify-content-between align-items-center mt-3">
                                    <div className='col-9'>
                                        <h6 className='text-dark'>{t('Dashboard:specificOrder.10')}</h6>
                                    </div>
                                    <div className='col-3'>
                                        <div className={Dashboard.OrderInfo}>
                                            <small className='text-muted'>{selectedOrder.advertiseId.packages[0].delivery_time} days</small>
                                        </div>
                                    </div>
                                </div>
                                <div className="row justify-content-between align-items-center mt-3">
                                    <div className='col-9'>
                                        <h6 className='text-dark'>{t('Dashboard:specificOrder.11')}</h6>
                                    </div>
                                    <div className='col-3'>
                                        <div className={Dashboard.OrderInfo}>
                                            <small className='text-muted'>${selectedOrder.advertiseId.packages[0].price}.00</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={Dashboard.Divider}></div>
                            <h6 className='text-dark text-end'>{t('Dashboard:specificOrder.12')}: ${selectedOrder.advertiseId.packages[0].price}.00</h6>
                        </div>
                    }
                </div>
                {selectedOrder.status === 'active' && (<div className={classes.DraftCountdown}>
                    <CountDown
                        hours={hours}
                        days={days}
                        minutes={minutes}
                        seconds={seconds}
                    />
                </div>)}
            </div>
        </React.Fragment> : <Spinner />

        let comments = this.props.allComments ? this.props.allComments.comments : [];
        if (this.state.comments.length !== 0) comments = this.state.comments;

        return <React.Fragment>
            <Modal type='lightbox' show={this.state.showModal}
                modalClosed={this.closeModal}>
                <GiveReview
                    loading={this.state.isLoading}
                    onSubmitReviewRating={this.onSubmitReviewRating}
                />
            </Modal>
            {/* reviewMessage */}
            {this.state.reviewMessage && !this.state.error &&
                <SimpleSnackbar open={true} message={this.state.message} type='success' />}
            {this.props.message && !this.props.error &&
                <SimpleSnackbar open={true} message={this.props.message} type='success' />}
            <div className={classes.ManageSpecificOrder}>
                {orderInfo}
                {selectedOrder ? <div className={classes.ManageSpecificOrderMessage}>
                    <div className={classes.ConversationHeader}>
                        <i className="far fa-comments"></i>
                        <span>{t('Dashboard:specificOrder.13')}</span>
                        <span>{t('Dashboard:specificOrder.14')} <span className='text-danger'>{this.props.user.role === 'seller' ?
                            selectedOrder.buyer.username :
                            selectedOrder.seller.username
                        }
                        </span>
                            <span>
                                {/* <Link className="link-warning"> {t('Dashboard:specificOrder.15')}</Link> */}
                            </span></span>
                    </div>
                    <div className={classes.Conversation}>
                        <div className={classes.ConversationHeader} style={{ alignItems: 'start' }}>
                            {comments.length > 0 ? comments.map((commentInfo, index) => (
                                <React.Fragment>
                                    <div className='col-12' key={index}>
                                        <div className='d-flex' >
                                            <div className=''>
                                                <div className={Dashboard.Avatar}>
                                                    <img src={commentInfo.commentBy && commentInfo.commentBy.profile_image} alt='My Img' />
                                                </div>
                                            </div>
                                            <div className='col-10'>
                                                <h6>{this.props.user && this.props.user.username === commentInfo.commentBy && commentInfo.commentBy.username ? 'Me' :commentInfo.commentBy && commentInfo.commentBy.username}</h6>
                                                <small>{commentInfo.comment}</small>
                                                <br />
                                                <br />
                                                <small className='text-secondary mt-5'>{getDate(commentInfo.createdAt)}</small>
                                            </div>
                                            {/* <div className=''>
                                                <i className="fas fa-ellipsis-v text-secondary" style={{fontSize:'17px'}}></i>
                                            </div> */}
                                        </div>
                                    </div>
                                    {index < comments.length - 1 ?
                                        <div className={Dashboard.Divider}></div> : null}
                                </React.Fragment>
                            )) : <p className='px-2'>{t('Dashboard:specificOrder.16')}</p>}
                        </div>
                    </div>
                    {/* <div className={classes.DeliverButton}>
                        <Button buttonType="Submit">Deliver Now</Button>
                    </div> */}
                    <div className={classes.MessageForm}>
                        <div className="form-group">
                            <textarea class="form-control" placeholder={t('Dashboard:specificOrder.18')} id="exampleFormControlTextarea1" rows="4" onChange={this.onChangeComment} value={this.state.comment}></textarea>
                            <div className='align-items-end'>
                                <button
                                    style={{ width: '130px' }}
                                    onClick={this.upCommentValue}
                                    className={styles1.SwitchButton}>
                                    {t('Dashboard:specificOrder.17')}
                                    <i className="far fa-paper-plane mx-1"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div> : null}
            </div>
        </React.Fragment>
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.userAuth.user,
        purchases: state.buy.data,
        token: state.userAuth.token,
        screenWidth: state.responsiveness.screenWidth,
        allComments: state.orderComments.commentsInfo,
        message: state.orderComments.message,
        error: state.orderComments.error
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOrderComment: (comment, orderId, advertiseId) => dispatch(actions.setOrderComment(comment, orderId, advertiseId)),
        getOrderComments: (orderId, advertiseId) => dispatch(actions.getOrderComments(orderId, advertiseId))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(['Dashboard'])(ManageSpecificOrder));

