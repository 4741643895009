import React, { Component } from 'react'
import AllAds from '../MyAdds/AllAds';
import Tabs from '../../../components/Tabs/Tabs';
import * as actions from '../../../store/actions';
import { connect } from 'react-redux';
import { Fade } from 'react-reveal';
import { AdsComponent } from './components/AdsComponent';
import classes from './components/Profile.module.css';
import { withTranslation } from 'react-i18next';
import AD_LOGO from '../../../assets/images/New_Logo.jpg';

class MyAllAds extends Component {
    componentDidMount() {
        window.scroll(0.0, 0.0);
        this.props.fetchUserAdvertises();
    }

    render() {
        const { t } = this.props;
        let activeAdvertises = [];
        // let unPublished = [];
        let drafts = [];

        if (this.props.advertisesData) {
            activeAdvertises = this.props.advertisesData.filter(advertise => advertise.is_published === true && advertise.is_drafted === false).map((advertise, index) =>
                <Fade delay={200 * (index + 1)}>
                    <AdsComponent
                        index={index}
                        user={this.props.user}
                        advertisesData={advertise}
                    />
                </Fade>
            );

            // unPublished = this.props.advertisesData.filter(advertise => advertise.is_published === false).map((advertise, index) => {
            //     return <Fade delay={200 * (index + 1)}>
            //         <AdsComponent
            //             index={index}
            //             user={this.props.user}
            //             advertisesData={advertise}
            //         ><button className='btn btn-primary w-100'>Publish the Add</button></AdsComponent>
            //     </Fade>;
            // });

            drafts = this.props.advertisesData.filter(advertise => advertise.is_published === false && advertise.is_drafted === true).map((advertise, index) => {
                let advertiseInfo = { ...advertise, files: [AD_LOGO] };
                return <Fade delay={200 * (index + 1)}>
                    <AdsComponent
                        index={index}
                        user={this.props.user}
                        advertisesData={advertiseInfo}
                    ><button
                            onClick={() => this.props.history.push(`/edit-advertise?adId=${advertise._id}`)}
                        className='btn btn-primary w-100'
                    >Continue the Ad</button></AdsComponent>
                </Fade>;
            });
        };

        return <div className={classes.Profile}>
            <div className={[classes.PartRight, classes.AddShow].join(' ')}>
                <Tabs>
                    <div label={t('allAds')}>
                        <AllAds />
                    </div>
                    <div label={t('activeAds')}>
                        <div className="d-flex flex-wrap">
                            {activeAdvertises.length === 0 ? <p>No ads available on active.</p> : activeAdvertises}
                        </div>
                    </div>
                    <div label={t('drafts')} className="d-flex flex-wrap">
                        <div className="d-flex flex-wrap">
                            {drafts.length === 0 ? <p>No ads available on draft.</p> : drafts}
                        </div>
                    </div>
                    {/* <div label={t('unpublished')} className="d-flex flex-wrap">
                        <div className="d-flex flex-wrap">
                            {unPublished.length === 0 ? <p>No unpublished ads available.</p> : unPublished}
                        </div>
                    </div> */}
                </Tabs>
            </div>
        </div>
    }
}


const mapStateToProps = (state) => {
    return {
        user: state.userAuth.user,
        advertisesData: state.dashboardAdvertise.userCreatedAdvertises,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchUserAdvertises: () => dispatch(actions.fetchUserAdvertises())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(['translation'])(MyAllAds));

