import * as actionTypes from '../ActionTypes';
import axios from '../../../axios';
import { tokenHeadersNormal } from '../HeaderConfig';

const commentFetchStart = () => {
    return {
        type: actionTypes.START_FETCH_ORDER_COMMENTS
    }
}

const commentFetchSuccess = (orderComments) => {
    return {
        type: actionTypes.SUCCESS_FETCH_ORDER_COMMENTS,
        payload: { orderComments }
    }
}

const newCommentAdd = (message) => {
    return {
        type: actionTypes.ADD_ORDER_COMMENTS,
        payload: { message }
    }
}
const commentFetchError = (message) => {
    return {
        type: actionTypes.ERROR_FETCH_ORDER_COMMENTS,
        payload: { message }
    }
}


export const getOrderComments = (orderId, advertiseId) => {
    return (dispatch, getState) => {
        commentFetchStart();
        const url = `/stripe/getOrderComments?orderId=${orderId}&advertiseId=${advertiseId}`;
        axios.get(url, { headers: tokenHeadersNormal(getState).headers })
            .then(response => dispatch(commentFetchSuccess(response.data.orderComments, null)))
            .catch(error => {
                if (error.response === undefined) return;
                dispatch(commentFetchError(error.response.data.message))
            });
    }
}

export const setOrderComment = (OrderComment, orderId, advertiseId) => {
    return (dispatch, getState) => {

        const url = `/stripe/setOrderComment?orderId=${orderId}&advertiseId=${advertiseId}`;
        axios.post(url, OrderComment, { headers: tokenHeadersNormal(getState).headers })
            .then(response => {
                dispatch(newCommentAdd(response.data.message))
            })
            .catch(error => {
                if (error.response === undefined) return;
                dispatch(commentFetchError(error.response.data.message))
            });
    }
}