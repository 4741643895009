// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Modal_Modal__1-5dN {\n    position: fixed;\n    z-index: 500;\n    background-color: white;\n    left: 0;\n    right: 0;\n    margin: 0 10px;\n    transition: all 0.6s ease-out;\n    border-radius: 5px;\n    box-shadow: 0px 3px 3px -3px rgba(48, 46, 46, 0.2), 0px 3px 3px 0px rgba(44, 42, 42, 0.14), 0px 3px 5px 0px rgba(49, 47, 47, 0.12);\n    top: 20px;\n    position: fixed;\n    /* transform: translate(-50%, -50%); */\n}\n\n.Modal_ConfirmModal__1VGQu {\n    z-index: 500;\n    position: fixed;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    top: 30%;\n    right: 0;\n    left: 0;\n}\n\n@media (min-width: 800px) {\n    .Modal_Modal__1-5dN {\n        width: 700px;\n        margin: auto;\n    }\n}", ""]);
// Exports
exports.locals = {
	"Modal": "Modal_Modal__1-5dN",
	"ConfirmModal": "Modal_ConfirmModal__1VGQu"
};
module.exports = exports;
