import React from 'react';
// import { useTranslation } from 'react-i18next'
import classes from './Middle.module.css';
import MiddleItem from './MiddleItem';
import { video_1, video_2, video_3, video_4 } from '../../../assets/videos';
import thumbnail from '../../../assets/images/Thumbnail.png';
import { NavigateBefore, NavigateNext } from '@material-ui/icons';
import { Translate } from 'react-auto-translate';
import Translation from '../../../shared/Translate';
const videos = [video_1, video_2, video_3, video_4];

const Middle = () => {
    const [showMore, setShowMore] = React.useState(false);
    const [selectedVideo, setSelectedVideo] = React.useState(0);

    // const { t } = useTranslation();
    const privileges = [];
    const privilegesMore = [];
    const privilegesTexts = {
        "0": "Umediad is an online marketing platform, which allows users to buy and sell their audience, directly to local and international media & influencers.",
        "1": "The platform is optimized and secured with tools and companies among the most reliable on the market.",
        "2": "Our unique intelligent search engine, since (since January 28, 2021) opens all the doors to you in this field.",
        "3": "Buy your perfect audience, according to your criteria and your budget or publish a free call for tenders to target your customers.",
        "4": "Sell your audience (visibility, listening, presence ...) at your price. Your ads are available to buyers anywhere during your subscription. Focus on calls for tenders to obtain more contracts.",
        "5": "Partners manage their collaborations, sell their customers' audience, give discounts on our subscriptions, get a commission on the sale of our subscriptions and residual income with their subscribers.",
        "6": "Welcome to all types of hearings and legal areas. You are responsible for everything that your posts involve."
    }
    for (let i = 0; i < 5; i++) {
        privileges.push(<MiddleItem text={
            // {t(`middle.privilegesList.${ i }`)}
            <Translation
                from={'en'}
                text={privilegesTexts[i]}
                input={<Translate>{privilegesTexts[i]}</Translate>}
            />
        }
        />)
    }
    for (let i = 5; i < 7; i++) {
        privilegesMore.push(<MiddleItem text={
            <Translation
                from={'en'}
                text={privilegesTexts[i]}
                input={<Translate>{privilegesTexts[i]}</Translate>}
            />
        }
        />)
    }

    const goPrevVideo = () => {
        if (selectedVideo === 0) setSelectedVideo(0);
        else setSelectedVideo(selectedVideo - 1);
    }

    const goNextVideo = () => {
        if (selectedVideo === 3) setSelectedVideo(3);
        else setSelectedVideo(selectedVideo + 1);
    }

    return <div className={classes.Middle}>
        <div className={classes.Sub}>
            {privileges}
            {showMore && privilegesMore}
            <div className={classes.ShowMore}>
                <small onClick={_ => setShowMore(!showMore)}>{showMore ?
                    <Translation
                        from={'en'}
                        text='See Less..'
                        input={<Translate>See Less..</Translate>}
                    /> :
                    <Translation
                        from={'en'}
                        text='See More..'
                        input={<Translate>See More..</Translate>}
                    />
                }</small>
            </div>
        </div>
        <div className={classes.Sub}>
            <div className={classes.AppSlide}>
                {selectedVideo === 0 &&
                    <video controls autoplay loop playsinline poster={thumbnail}>
                        <source src={videos[selectedVideo]}></source>
                    </video>
                }
                {selectedVideo === 1 &&
                    <video controls autoplay loop playsinline>
                        <source src={videos[selectedVideo]}></source>
                    </video>
                }
                {selectedVideo === 2 &&
                    <video controls autoplay loop playsinline>
                        <source src={videos[selectedVideo]}></source>
                    </video>
                }
                {selectedVideo === 3 &&
                    <video controls autoplay loop playsinline>
                        <source src={videos[selectedVideo]}></source>
                    </video>
                }
                {selectedVideo !== 0 && <button title='Go previous video' className={[classes.VideoButton, classes.PrevButton].join(' ')} onClick={goPrevVideo}><NavigateBefore color='primary' /></button>}

                {selectedVideo !== 3 && <button title='Go next video' className={[classes.VideoButton, classes.NextButton].join(' ')} onClick={goNextVideo}><NavigateNext color='primary' /></button>}
            </div>
            <div className={classes.VideoItems}>
                {videos.map((_, index) => (
                    <video className={selectedVideo === index && classes.BorderVideo} poster={index === 0 && thumbnail} controls={false} onClick={_ => setSelectedVideo(index)}>
                        <source src={videos[index]}></source>
                    </video>
                ))}
            </div>
        </div>
    </div>
};

export default Middle;