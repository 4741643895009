import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withTranslation} from 'react-i18next'
import AppImage from '../../../assets/images/app-image.png'
import Button from '../../../components/UI/Button/Button'
import {Spinner} from '../../../components/UI/Spinner/Spinner'
import classes from './Auth.module.css'
import {Link} from 'react-router-dom'
// import FacebookButton from './SocialLoginsButtons/FacebookButton';
import GoogleButton from './SocialLoginsButtons/GoogleButton'
import * as actions from '../../../store/actions/index'
import MyAppleSigninButton from './AppleSignin'
import SimpleSnackbar from '../../../components/Snackbar/Snackbar'

const APP_ID_GOOGLE =
  '994763187052-2qkrod9ro6hknrf5k9icd30m0qurufu2.apps.googleusercontent.com'
// const APP_ID_FACEBOOK = '630218267646419';

class Login extends Component {
  state = {
    formData: {
      email: {
        value: '',
        valid: false,
        touched: false,
      },
      password: {
        value: '',
        valid: false,
        touched: false,
      },
    },
    isSignup: false,
    showPassword: false,
    isLoginError: false,
    isAdminLogin: false,
  }

  componentDidMount() {
    window.scroll(0, 0)
  }

  handleInput = (elementType, event) => {
    const userData = {...this.state.formData}
    userData[elementType].value = event.target.value
    userData[elementType].touched = true
    this.setState({formData: userData})
    this.validate(elementType, event.target.value)
  }

  validate = (elementType, value) => {
    const userData = {...this.state.formData}
    if (elementType === 'email') {
      const regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
      userData[elementType].valid = regex.test(value)
      this.setState({formData: userData})
    }
  }

  handlePasswordVisibility = () =>
    this.setState({showPassword: !this.state.showPassword})

  handleSocialLogin = (user) => {
    const socialId = user._profile.id
    const loginType = 'social'
    const body = JSON.stringify({socialId, loginType})
    this.props.onAuth(body, this.state.isSignUp, this.props.history)
  }

  handleSocialLoginFailure = (err) => {
    // this.setState({ isLoginError: true })
    console.log(err)
  }

  handlerSubmit = (event) => {
    event.preventDefault()
    const userData = {...this.state.formData}
    const email = userData['email'].value
    const password = userData['password'].value
    if (
      userData['email'].valid &&
      userData['password'].value &&
      userData['email'].value !== 'admin@umediad.com'
    ) {
      userData['email'].value = ''
      userData['password'].value = ''
      this.setState({formData: userData, isLoginError: false})
      const loginType = 'normal'
      const body = JSON.stringify({email, password, loginType})
      this.props.onAuth(body, this.state.isSignUp, this.props.history)
    } else {
      if (userData['email'].value === 'admin@umediad.com') {
        this.setState({
          isLoginError: false,
          isAdminLogin: true,
        })
      } else {
        this.setState({
          isLoginError: true,
          isAdminLogin: false,
        })
      }
    }
  }
  render() {
    let formData = this.state.formData
    const {t} = this.props
    return (
      <React.Fragment>
        {this.props.message && !this.props.error && (
          <SimpleSnackbar
            open={true}
            message={this.props.message}
            type="success"
          />
        )}
        {this.props.message && this.props.error && (
          <SimpleSnackbar
            open="true"
            message={this.props.message}
            type="error"
          />
        )}
        {this.props.loading ? (
          <Spinner />
        ) : (
          <div className={classes.AuthMain}>
            <div className={classes.Auth}>
              <img src={AppImage} alt="AppImage" />
              <h3>{t('SigninUp:signin.name')}</h3>
              <form onSubmit={(event) => this.handlerSubmit(event)}>
                <div className={classes.Divider}></div>
                <input
                  type="email"
                  value={formData['email'].value}
                  placeholder={t('SigninUp:signin.fields.0')}
                  onChange={(event) => this.handleInput('email', event)}
                />
                {formData['email'].touched && !formData['email'].valid ? (
                  <strong className="mb-3 mt-1" style={{color: 'red'}}>
                    Provide a valid email
                  </strong>
                ) : null}
                <div className={classes.Divider}></div>
                <div className={classes.Divider}></div>
                <div className={classes.PasswordWrapper}>
                  <input
                    value={formData['password'].value}
                    type={this.state.showPassword ? 'text' : 'password'}
                    min="8"
                    max="16"
                    placeholder={t('SigninUp:signin.fields.1')}
                    onChange={(event) => this.handleInput('password', event)}
                  />
                  {this.state.showPassword ? (
                    <i
                      className="fas fa-eye"
                      onClick={this.handlePasswordVisibility}></i>
                  ) : (
                    <i
                      className="fas fa-eye-slash"
                      onClick={this.handlePasswordVisibility}></i>
                  )}
                </div>
                <div className={classes.Divider}></div>
                <div className={classes.SignUpButton}>
                  <Button buttonType="Submit" clicked={this.handlerSubmit}>
                    <i
                      className="fa fa-paper-plane mx-1"
                      aria-hidden="true"></i>
                    {t('SigninUp:signin.0')}
                  </Button>
                </div>
                {this.state.isLoginError ? (
                  <strong className="m-3" style={{color: 'red', width: '100%'}}>
                    {t('SigninUp:signin.error')}
                  </strong>
                ) : null}
                {this.state.isAdminLogin ? (
                  <strong className="m-3" style={{color: 'red', width: '100%'}}>
                    {t('SigninUp:signin.admin')}
                    <a
                      style={{textDecoration: 'none'}}
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://admin.umediad.com">
                      {t('SigninUp:signin.admin_panel')}
                    </a>
                  </strong>
                ) : null}

                <div className={classes.ModalFooter}>
                  <Link to="/reset-password">{t('SigninUp:signin.1')}</Link>
                </div>
              </form>
              <h6>{t('SigninUp:common.3')}</h6>
              <p style={{fontSize: '10pt', paddingBottom: '10px'}}>
                {t('SigninUp:common.4')}
              </p>
              <GoogleButton
                provider="google"
                appId={APP_ID_GOOGLE}
                onLoginSuccess={this.handleSocialLogin}
                onLoginFailure={this.handleSocialLoginFailure}
                redirect="https://umediad.com/my_profile">
                <div className={classes.SocialButton}>
                  <i className="fab fa-google" aria-hidden="true"></i>
                  <div className={classes.SocialText}>
                    {t('SigninUp:common.0')}
                  </div>
                </div>
              </GoogleButton>
              {/* <FacebookButton
							provider="facebook"
							appId={APP_ID_FACEBOOK}
							onLoginSuccess={this.handleSocialLogin}
							onLoginFailure={this.handleSocialLoginFailure}
							redirect='https://umediad.com/my_profile'
						>
							<div className={classes.SocialButton}>
								<i className="fab fa-facebook-f" aria-hidden="true"></i>
								<div className={classes.SocialText}>
									{t('SigninUp:common.1')}
								</div>
							</div>
						</FacebookButton> */}
              <MyAppleSigninButton url="https://api.umediad.com/user/apple/login" />
            </div>
          </div>
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    error: state.userAuth.error,
    message: state.userAuth.message,
    loading: state.userAuth.loading,
    isAuthenticated: state.userAuth.token !== null,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (body, isSignUp, history) =>
      dispatch(actions.auth(body, isSignUp, history)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation(['translation', 'SigninUp'])(Login))
