import React, { Component } from 'react';
import classes from '../CreateAdd.module.css';
import Button from '../../../../components/UI/Button/Button';
import { AddAPhoto, VideoCall } from '@material-ui/icons';
import StepProgress from './StepProgress';
import { Divider } from '../../../../components/UI/Spacing';
import { CoverPhotoModal } from './PhotoModal';

export class Step5 extends Component {
  componentDidMount() {
    window.scroll(0.0, 0.0)
  }
  state = {
    isClicked: false,
    showModal: false,
    file: null,
    index: null
  };

  continue = (event, isFieldOk) => {
    event.preventDefault();
    if (isFieldOk) this.props.nextStep();
    this.setState({ isClicked: true });
    setTimeout(() => {
      this.setState({ isClicked: false });
    }, 4000)
  };

  back = e => {
    e.preventDefault();
    this.props.prevStep();
  };


  closeModal = () => { this.setState({ showModal: false, file: null, index: null }) };

  openModal = (index, file) => {
    this.setState({ index, showModal: true, file })
  }

  handleCoverFile = (image) => {
    this.props.handleCroppedFiles(this.state.index, image);

    this.setState({
      showModal: false,
      index: null,
      file: null
    });
  };

  render() {
    const { values, advertiseSpecificationData, handleAdvertiseFiles, handleRemoveFile, t } = this.props;
    let isFieldOk = values.filesPreviews.images.length > 0;

    return <React.Fragment>
      {this.state.file && this.state.index !== null && <CoverPhotoModal
        showModal={this.state.showModal}
        closeModal={this.closeModal}
        file={this.state.file}
        handleCoverFile={image => this.handleCoverFile(image)}
      />}

      <Divider orientation='v' space='2' />
      <StepProgress step={this.props.step} />
      <div className={classes.NB}><small>{t('MyAds:CreateAdd.NB')}</small></div>
      <h5>{t('MyAds:CreateAdd.Step4.firstSection.6')}</h5>
      <div className={classes.Divider}></div>

      <div className={classes.Photos}>
        {values.filesPreviews.images.length > 0 ?
          values.filesPreviews.images.map((image, index) => (
            <div className={classes.PhotosItems} key={index}>
              <img className={classes.PhotosItem} src={image.url} alt='ImagePhoto' />
              <div className={classes.ButtonControl}>
                <button
                  title='Remove'
                  className={classes.Button}
                  onClick={_ => handleRemoveFile(image.url, 'images', index)}>
                  <i className="fas fa-times"></i>
                </button>
                <button
                  title='Crop'
                  className={classes.Button}
                  onClick={_ => this.openModal(index, image.file)}>
                  <i className="fas fa-crop"></i>
                </button>
              </div>
            </div>
          )) : <div className={classes.FileInput}>
            <input
              type='file'
              accept='.png,.jpg,.jpeg,.gif'
              multiple
              onChange={(event) => handleAdvertiseFiles(event, 'images')}
              id='image' />
            <label htmlFor='image'>
              <AddAPhoto />
            </label>
          </div>}
        {values.filesPreviews.images.length > 0 && <div className={classes.FileInput}>
          <input type='file'
            accept='.png,.jpg,.jpeg,.gif'
            multiple
            onChange={(event) => handleAdvertiseFiles(event, 'images')}
            id='image' />
          <label htmlFor='image' style={{ background: 'goldenrod' }}>
            <i className="fas fa-plus"></i>
          </label>
        </div>}
      </div>
      <div className={classes.Info}>
        <strong>(max-size: 10MB)</strong>
      </div>
      {
        this.state.isClicked && !isFieldOk ? <div className={classes.Error}>
          <strong>{t('MyAds:CreateAdd.Step4.errors.0')}</strong>
        </div> : null
      }
      <div className={classes.Divider}></div>
      <div className={classes.Photos}>
        {values.filesPreviews.video.length > 0 ?
          values.filesPreviews.video.map((video, index) => (
            <div className={classes.PhotosItems} key={index}>
              <video height='400' width='300' className={classes.PhotosItem} controls>
                <source src={video.url} type="video/mp4"></source>
              </video>
              <button
                title='Remove'
                className={classes.Button}
                onClick={_ => handleRemoveFile(video.url, 'video', index)}>
                <i className="fas fa-times"></i>
              </button>
            </div>
          )) : <div className={classes.FileInput}>
            <input
              type='file'
              accept='.mp4, .mov, .webm'
              multiple
              onChange={(event) => handleAdvertiseFiles(event, 'video')}
              id='video' />
            <label htmlFor='video'>
              <VideoCall />
            </label>
          </div>}
      </div>
      <div className={classes.Info}>
        <strong>(max-size: 100MB)</strong>
      </div>
      {
        values.filesSizeError &&
        <div className={classes.Error}>
          <strong>{t('MyAds:CreateAdd.Step4.errors.1')}
          </strong>
        </div>
      }
      <div className={classes.Divider}></div>

      {/* <h5>{t('MyAds:CreateAdd.Step3.2')}</h5> */}
      <h5>Terms and Conditions</h5>
      <br />
      <div className={classes.Info}>
        <strong>{t('MyAds:CreateAdd.Step3.3')}</strong>
      </div>
      <div className={classes.DividerWithoutStyle}></div>
      <div className={classes.AdvertiseSpecification}>
        {values.advertiseSpecification.map((specification, index) => (
          <div className={classes.AdvertiseSpecificationItem} key={index}>
            <div className={classes.AdvertiseSpecificationSingleItem}>
              <div className={classes.AdvertiseSpecificationSingleItemIndex}>{index + 1}</div>
              <div className={classes.AdvertiseSpecificationTextArea}>
                <textarea
                  className="form-control"
                  type='text'
                  maxLength='400'
                  value={specification.rule}
                  onChange={(event) => advertiseSpecificationData(event, index)}
                ></textarea>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className={classes.Divider}></div>
      <div className={classes.StepsButton}>
        <Button buttonType='AppleDark' clicked={this.back} >  <i className='fas fa-chevron-left '></i>{t('MyAds:CreateAdd.button2')}</Button>
        <Button
          buttonType='Submit'
          clicked={(e) => this.continue(e, isFieldOk)}
        >{t('MyAds:CreateAdd.button1')}  <i className='fas fa-chevron-right'></i></Button>
      </div>
    </React.Fragment >
  }
}

export default (Step5);
