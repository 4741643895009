import * as actionTypes from '../actions/ActionTypes'

const INIT_STATE = {
  initialURL: '/',
  error: '',
  loading: false,
};

export const CommonReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionTypes.FETCH_START: {
      return { ...state, error: '', loading: true };
    }
    case actionTypes.FETCH_SUCCESS: {
      return { ...state, error: '', loading: false };
    }
    case actionTypes.FETCH_ERROR: {
      return { ...state, loading: false, error: action.payload };
    }
    default:
      return state;
  }
};
