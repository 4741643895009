import React from 'react'
import classes from '../Tender.module.css';
import Tabs from '../../../../components/Tabs/Tabs';
import * as actions from '../../../../store/actions';
import { connect } from 'react-redux';
import { Spinner } from '../../../../components/UI/Spinner/Spinner';
import { TenderItem, BidItem } from './BidsTenderItems';
import SimpleSnackbar from '../../../../components/Snackbar/Snackbar';
import { ConfirmModal } from '../../../../components/UI/Modal';
import styles from '../../../ProfileDashBoard/MyAdds/MyAdds.module.css';

class BuyerTenders extends React.Component {
    state = {
        showModal: false,
        tenderId: null
    }
    componentDidMount() {
        this.props.onGetBuyerTenders();
        this.props.onGetBidsTenders();
    }

    onChangeTenderMode = (modeType, tenderId) => {
        this.props.onChangeTenderMode(modeType, tenderId);
        setTimeout(() => {
            window.location.reload();
        }, 2000);
    }

    onDeleteTender = (tenderId) => this.setState({ showModal: true, tenderId });
    closeModal = () => this.setState({ showModal: false });

    deleteTender = () => {
        if (this.state.tenderId)
            this.props.onDeleteTender(this.state.tenderId);
        setTimeout(() => {
            window.location.reload();
        }, 2000);
        this.setState({ showModal: false });
    }

    onAcceptBid = (tenderId, bidId) => {
        if (tenderId && bidId)
            this.props.onAcceptBid(tenderId, bidId);
        setTimeout(() => {
            window.location.reload();
        }, 2000);
    }
    
    render() {
        const closedTender = this.props.tenders.filter(tender => tender.isClosed && !tender.isActive)
            .map((tender, index) => <TenderItem
                tender={tender}
                key={index}
                type='closed'
                onDeleteTender={this.onDeleteTender}
            />);

        const draftTender = this.props.tenders.filter(tender => !tender.isActive && !tender.isClosed)
            .map((tender, index) => <TenderItem
                tender={tender}
                key={index}
                type='draft'
                onChangeTenderMode={this.onChangeTenderMode}
            />);

        const activeTender = this.props.loading ?
            <Spinner /> :
            this.props.tenders.filter(tender => tender.isActive === true)
                .map((tender, index) =>
                    <TenderItem
                    tender={tender}
                    key={index}
                    type='active'
                    onChangeTenderMode={this.onChangeTenderMode}
                />);

        const requestedBids = this.props.bitTenders.filter(bitTenders => bitTenders.isAccepted === false).map((bitTenders, index) =>
            <BidItem
            bitTenders={bitTenders}
            onAcceptBid={this.onAcceptBid}
            key={index}
        />);

        const acceptedBids = this.props.bitTenders.filter(bitTenders => bitTenders.isAccepted === true).map((bitTenders, index) =>
            <BidItem
            bitTenders={bitTenders}
            key={index}
        />);

        return <div className={classes.Tender}>
            <ConfirmModal
                show={this.state.showModal}
                modalClosed={this.closeModal}>
                <div className={styles.ConfirmWindow}>
                    <h2 className='my-2'>Are you sure?</h2>
                    <span className='my-2'>You want to delete this tender? If you delete the tender, the related resources also would be deleted.</span>
                    <div className='py-4'></div>
                    <div className='d-flex justify-content-center'>
                        <button
                            onClick={this.closeModal}
                            type='button'
                            className='btn btn-success mx-1 my-2 w-50'
                        >No</button>
                        <button
                            onClick={this.deleteTender}
                            type='button'
                            className='btn btn-danger mx-1 my-2 w-50'
                        >Yes, Delete the Tender</button>
                    </div>
                </div>
            </ConfirmModal>
            {this.props.message && !this.props.error &&
                <SimpleSnackbar open={true} message={this.props.message} type='success' />}
            {this.props.message && this.props.error &&
                <SimpleSnackbar open='true' message={this.props.message} type='error' />}
            <div>
                <div className={classes.PartRight}>
                    <h5>ALL TENDERS</h5>
                    <Tabs>
                        <div label="ACTIVE">
                            {activeTender.length === 0 ?
                                <div className={classes.PartRightItem}>
                                    You have no active tender yet.
                                </div> :
                                activeTender
                            }
                        </div>
                        <div label="DRAFT">
                            {draftTender.length === 0 ?
                                <div className={classes.PartRightItem}>
                                    You have no draft tender yet.
                                </div> :
                                draftTender
                            }
                        </div>
                        <div label="CLOSED">
                            {closedTender.length === 0 ?
                                <div className={classes.PartRightItem}>
                                    You have no closed tender yet.  </div> :
                                closedTender
                            }
                        </div>
                    </Tabs>
                </div>
                <div className={classes.PartRight}>
                    <h5 className='mt-5'>ALL BIDS</h5>
                    <Tabs>
                        <div label="REQUESTED">
                            {requestedBids.length === 0 ?
                                <div className={classes.PartRightItem}>
                                    You have no requested bids yet.  </div> :
                                requestedBids
                            }
                        </div>
                        <div label="ACCEPTED">
                            {acceptedBids.length === 0 ?
                                <div className={classes.PartRightItem}>
                                    You have no accepted bids yet.  </div> :
                                acceptedBids
                            }
                        </div>
                    </Tabs>
                </div>
            </div>
        </div>
    }
}


const mapStateToProps = (state) => {
    return {
        message: state.tender.modeChangeMessage,
        error: state.tender.error,
        loading: state.tender.loading,
        tenders: state.tender.tenders,
        bitTenders: state.tender.bids,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onGetBuyerTenders: () => dispatch(actions.getBuyerTenders()),
        onGetBidsTenders: () => dispatch(actions.getBidsTenders()),
        onChangeTenderMode: (modeType, tenderId) => dispatch(actions.onChangeTenderMode(modeType, tenderId)),
        onDeleteTender: (tenderId) => dispatch(actions.deleteTender(tenderId)),
        onAcceptBid: (tenderId, bidId) => dispatch(actions.acceptBid(tenderId, bidId)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BuyerTenders)