import React from 'react'
import {Box} from '@material-ui/core'
// import {useSelector} from 'react-redux'
import {connect} from 'react-redux'
import Sidebar from './Sidebar'
import useStyles from './index.style'
import ChatContainer from './ChatContainer'
import socket from '../../../config/socket'
// import axios from '../../../axios'
// import {requestFirebaseNotificationPermission} from './firebaseinit'

class TaskListClass extends React.Component {
  componentDidMount() {
    if (this.props.isAuthenticated) {
      const sessionID = localStorage.getItem('sessionID')
      const {full_name, email} = this.props.user
      if (sessionID) {
        socket.auth = {sessionID}
        socket.connect()
      } else {
        socket.auth = {username: full_name, userid: email}
        socket.connect()
      }
      socket.on('session', ({sessionID, userID}) => {
        socket.auth = {sessionID}
        localStorage.setItem('sessionID', sessionID)
        socket.userID = userID
      })
      socket.on('connect_error', (err) => {
        if (err.message === 'invalid username') {
          localStorage.removeItem('sessionID')
          console.log('Not registered yet')
        }
      })
    }
  }

  render() {
    const {classes} = this.props
    return (
      <Box className={classes.inBuildAppContainer}>
        <Sidebar mainUser={this.props.user}/>
        <ChatContainer />
      </Box>
    )
  }
}

const TaskList = (props) => {
  const classes = useStyles()
  return <TaskListClass {...props} classes={classes} />
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.userAuth.isAuthenticated,
    user: state.userAuth.user,
  }
}

export default connect(mapStateToProps, null)(TaskList)

// const classes = useStyles()
// const {user, token} = useSelector(({userAuth}) => userAuth)

// const headersNormal = {
//   headers: {
//     'Content-Type': 'application/json',
//   },
// }
// const tokenHeadersNormal = () => {
//   if (token) {
//     headersNormal.headers['x-auth-header'] = token
//   }
//   return headersNormal
// }

// requestFirebaseNotificationPermission()
//   .then((firebaseToken) => {
//     const url = '/chat/saveBrowserToken'
//     axios.post(
//       url,
//       {token: firebaseToken, email: user.email},
//       {headers: tokenHeadersNormal().headers},
//     )
//     .then((_) => {})
//     .catch((_) => {
//       console.log('ALREADY EXISTS')
//     })
//     // console.log(firebaseToken)
//   })
//   .catch((err) => {
//     console.log('CHAT-E', err)
//     return err
//   })

// const [messages, setMessages] = React.useState([])

// const {user} = useSelector(({userAuth}) => userAuth)
// const ENDPOINT = 'localhost:8888'
// useEffect(() => {
//   socket = io(ENDPOINT)
//   const payload = {
//     username: user.id,
//     message: 'start',
//   }
//   socket.emit('join', payload, () => {})
//   return () => {
//     socket.emit('disconnet', payload)
//     socket.off()
//   }
// }, [ENDPOINT, user.id])

// useEffect(() => {
//   console.log('send message')
//   socket.on('message', (payload) => {
//     console.log(payload,'payload')
//     setMessages(payload)
//   })
// }, [messages])
