import * as actionTypes from '../ActionTypes';

const screenSizeValue = (screenWidth) => {
    return {
        type: actionTypes.HANDLE_SCREEN_SIZE,
        payload: { screenWidth }
    }
}

export const onChangeScreenSize = (screenWidth) => {
    return (dispatch) => dispatch(screenSizeValue(screenWidth))
}

