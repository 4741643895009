// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".CollapsiblePanel_CollapsiblePanel__3iMSx {\n  width: 90%;\n  align-items: center;\n  margin: 10px auto;\n  background-color: #fff;\n}\n\n.CollapsiblePanel_Question__21imd {\n  display: flex;\n  justify-content: space-between;\n}\n.CollapsiblePanel_Text__1rJX8 {\n  font-size: 1.2em;\n  text-align: start;\n  width: 90%;\n  padding: 0.5em 0.5em;\n  padding-top: 0.1em;\n}\n.CollapsiblePanel_CollapsiblePanel__button__24Byk {\n  outline: none;\n  text-align: start;\n  padding: 1em 0.5em;\n  width: 100%;\n  font-weight: bold;\n  box-shadow: none;\n  border: none;\n}\n", ""]);
// Exports
exports.locals = {
	"CollapsiblePanel": "CollapsiblePanel_CollapsiblePanel__3iMSx",
	"Question": "CollapsiblePanel_Question__21imd",
	"Text": "CollapsiblePanel_Text__1rJX8",
	"CollapsiblePanel__button": "CollapsiblePanel_CollapsiblePanel__button__24Byk"
};
module.exports = exports;
