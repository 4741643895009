import React from 'react'
import styles from '../../Tender/Tender.module.css'
import classes from '../Partner.module.css'
import {Line} from '../../../../components/UI/Spacing'
import {getDate} from '../../../../shared/utility'
import {Link} from 'react-router-dom'
import {withTranslation} from 'react-i18next'
import {Translate} from 'react-auto-translate'
import Translation from '../../../../shared/Translate'

const SingleRequest = (props) => {
  const {
    name,
    email,
    experience,
    status,
    userStatus,
    specialty,
    restrictions,
    contact,
    location,
    language,
    media,
    subMedia,
    domains,
    cv,
    createdAt,
    slogan,
    cost,
    partnership,
    about,
    condemned,
    whereMedia,
    websiteURL,
    isClosed,
    _id,
    user,
  } = props.requestPartner

  const {t} = props

  return (
    <div className={styles.Tender} style={{margin: '20px auto', width: '100%'}}>
      <div className={styles.PartRight}>
        <div
          className={styles.PartRightItem}
          style={{borderTop: `3px solid ${props.color}`}}>
          <div className="mx-0 mx-lg-2">
            <div className={classes.RequestInfo}>
              <div className={classes.RequestInfoLeft}>
                <img src={user && user.profile_image} alt="Find" />
                <div className="d-flex-text-secondary mb-2  w-100">
                  <span>
                    <Translation
                      text={createdAt && getDate(createdAt)}
                      input={
                        <Translate>{createdAt && getDate(createdAt)}</Translate>
                      }
                    />
                  </span>
                  <div className={styles.MediaItem}>
                    <span>
                      {isClosed ? (
                        <Translation
                          from={'en'}
                          text={'Closed'}
                          input={<Translate>Closed</Translate>}
                        />
                      ) : (
                        <Translation
                          from={'en'}
                          text={'Open'}
                          input={<Translate>Open</Translate>}
                        />
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div className={classes.RequestInfoRight}>
                <div className={classes.HeaderINfo}>
                  <big>{name}</big>
                  <p>
                    <Translation
                      text={userStatus}
                      input={<Translate>{userStatus}</Translate>}
                    />
                  </p>
                </div>

                <div className="py-1 text-secondary col-8">
                  <span>
                    <i className="fas fa-map-marker-alt"></i>{' '}
                    <span className="mx-2">
                      <Translation
                        text={
                          location.city +
                          ', ' +
                          location.state +
                          ', ' +
                          location.country
                        }
                        input={
                          <Translate>
                            {location.city +
                              ', ' +
                              location.state +
                              ', ' +
                              location.country}
                          </Translate>
                        }
                      />
                    </span>
                  </span>
                </div>
                <div className="py-1 text-primary">
                  <span>
                    <i className="fas fa-envelope"></i>
                    <span className="mx-2">{email}</span>
                  </span>
                </div>
                <div className="py-1 text-primary">
                  <span>
                    <i className="fas fa-link"></i>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={websiteURL}>
                      <span className="mx-2">{websiteURL}</span>
                    </a>
                  </span>
                </div>
                <div className="py-1 text-primary">
                  <span>
                    <i className="fas fa-phone-alt"></i>
                    <span className="mx-1">{contact}</span>
                  </span>
                </div>
                {props.isUser ? (
                  <div className="py-2 mt-2 d-flex justify-content-between  align-items-center">
                    <div>
                      <button type="submit" className="btn btn-sm btn-warning">
                        <Translation
                          from={'en'}
                          text="Delete"
                          input={<Translate>Delete</Translate>}
                        />
                      </button>
                      <button
                        type="submit"
                        className="btn btn-sm btn-primary mx-2">
                        <Link to={`/edit-partner-request?info_id=${_id}`}>
                          <Translation
                            from={'en'}
                            text="Edit"
                            input={<Translate>Edit</Translate>}
                          />
                        </Link>
                      </button>
                    </div>
                    <div className={styles.MediaItem}>
                      <span>
                        <Translation
                          text={status}
                          input={<Translate>{status}</Translate>}
                        />
                      </span>
                    </div>
                  </div>
                ) : (
                  <React.Fragment>
                    <div className="py-2 mt-2 d-flex justify-content-center justify-content-lg-start">
                      <button
                        onClick={(_) => props.onInterest(_id)}
                        type="submit"
                        style={{background: props.color}}
                        className="btn btn-sm text-light">
                        <Translation
                          from={'en'}
                          text="Choose"
                          input={<Translate>Choose</Translate>}
                        />
                      </button>
                    </div>
                    {props.message && (
                      <span className="my-2 text-primary">
                        <Translation
                          from={'en'}
                          text={props.message}
                          input={<Translate>{props.message}</Translate>}
                        />
                      </span>
                    )}
                  </React.Fragment>
                )}
              </div>
            </div>
            <Line
              space="2"
              orientation="h"
              width="100"
              height="1"
              backgroundColor={undefined}
            />
            <div className="mt-2">
              <div className={classes.RequestType}>
                <h6>
                  <Translation
                    text={t('description_title')}
                    input={<Translate>{t('description_title')}</Translate>}
                  />
                </h6>
              </div>
              <li className="py-1">
                <Translation
                  text={experience}
                  input={<Translate>{experience}</Translate>}
                />
              </li>
            </div>
            <div className="mt-2">
              <div className={classes.RequestType}>
                <h6>
                  {' '}
                  <Translation
                    from={'en'}
                    text="Specialty"
                    input={<Translate>Specialty</Translate>}
                  />
                </h6>
              </div>
              <li className="py-1">
                <Translation
                  from={'en'}
                  text={specialty ? specialty : '.....'}
                  input={
                    <Translate>{specialty ? specialty : '.....'}</Translate>
                  }
                />
              </li>
            </div>
            <div className="mt-3">
              <div className={classes.RequestType}>
                <h6>
                  <Translation
                    from={'en'}
                    text="About Me"
                    input={<Translate>About Me</Translate>}
                  />
                </h6>
              </div>
              <li className="py-1">
                <Translation
                  text={about ? about : '.....'}
                  input={<Translate>{about ? about : '.....'}</Translate>}
                />
              </li>
            </div>
            <div className="mt-3">
              <div className={classes.RequestType}>
                <h6>
                  <Translation
                    from={'en'}
                    text="Slogan"
                    input={<Translate>Slogan</Translate>}
                  />
                </h6>
              </div>
              <li className="py-1">
                <Translation
                  text={slogan ? slogan : '.....'}
                  input={<Translate>{slogan ? slogan : '.....'}</Translate>}
                />
              </li>
            </div>
            <div className="row mt-3 col-12">
              <div className="col-12  mt-2 mt-lg-0 col-lg-6">
                <div className={classes.RequestType}>
                  <h6>
                    <Translation
                      from={'en'}
                      text="Where are my media?"
                      input={<Translate>Where are my media?</Translate>}
                    />
                  </h6>
                </div>
                <li className="py-1">
                  <Translation
                    text={whereMedia ? whereMedia : '.....'}
                    input={
                      <Translate>{whereMedia ? whereMedia : '.....'}</Translate>
                    }
                  />
                </li>
              </div>
              <div className="col-12 col-lg-6">
                <div className={classes.RequestType}>
                  <h6>
                    <Translation
                      from={'en'}
                      text="Condemned"
                      input={<Translate>Condemned</Translate>}
                    />
                  </h6>
                </div>
                <li className="py-1">
                  <Translation
                    from={'en'}
                    text={condemned ? condemned : '.....'}
                    input={
                      <Translate>{condemned ? condemned : '.....'}</Translate>
                    }
                  />
                </li>
              </div>
            </div>
            <div className="row mt-3 col-12">
              <div className="col-12  mt-2 mt-lg-0 col-lg-6">
                <div className={classes.RequestType}>
                  <h6>
                    <Translation
                      from={'en'}
                      text="Media"
                      input={<Translate>Media</Translate>}
                    />
                  </h6>
                </div>
                <div className={styles.MediaItem}>
                  {media.map((media, index) => (
                    <span key={index}>
                      <Translation
                        text={media}
                        input={<Translate>{media}</Translate>}
                      />
                    </span>
                  ))}
                  {media.length === 0 && '.....'}
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className={classes.RequestType}>
                  <h6>
                    <Translation
                      from={'en'}
                      text="Sub Media"
                      input={<Translate>Sub Media</Translate>}
                    />
                  </h6>
                </div>
                <div className={styles.MediaItem}>
                  {subMedia.map((media, index) => (
                    <span key={index}>
                      <Translation
                        text={media}
                        input={<Translate>{media}</Translate>}
                      />
                    </span>
                  ))}
                  {subMedia.length === 0 && '.....'}
                </div>
              </div>
            </div>
            <div className="mt-3">
              <div className={classes.RequestType}>
                <h6>
                  <Translation
                    from={'en'}
                    text="Working Domains"
                    input={<Translate>Working Domains</Translate>}
                  />
                </h6>
              </div>
              <div className={styles.MediaItem}>
                {domains.map((_domain, index) => (
                  <span key={index}>
                    <Translation
                      text={_domain.domain}
                      input={<Translate>{_domain.domain}</Translate>}
                    />
                  </span>
                ))}
                {domains.length === 0 && '.....'}
              </div>
            </div>
            <div className="row mt-3 col-12">
              <div className="col-12 col-lg-6">
                <div className={classes.RequestType}>
                  <h6>
                    <Translation
                      from={'en'}
                      text="Partnership Reason"
                      input={<Translate>Partnership Reason</Translate>}
                    />
                  </h6>
                </div>
                <div className="mt-2">
                  <li>
                    <Translation
                      text={partnership ? partnership : '....'}
                      input={
                        <Translate>
                          {partnership ? partnership : '....'}
                        </Translate>
                      }
                    />
                  </li>
                </div>
              </div>
              <div className="col-12 mt-2 mt-lg-0 col-lg-6">
                <div className={classes.RequestType}>
                  <h6>
                    <Translation
                      from={'en'}
                      text="Restrictions"
                      input={<Translate>Restrictions</Translate>}
                    />
                  </h6>
                </div>
                <div className="mt-2">
                  <li>
                    <Translation
                      text={restrictions ? restrictions : '....'}
                      input={
                        <Translate>
                          {restrictions ? restrictions : '....'}
                        </Translate>
                      }
                    />
                  </li>
                </div>
              </div>
            </div>

            <div className="row mt-3 col-12">
              <div className="col-12 col-lg-6">
                <div className={classes.RequestType}>
                  <h6>
                    <Translation
                      from={'en'}
                      text="Language"
                      input={<Translate>Language</Translate>}
                    />
                  </h6>
                </div>
                <div className="py-1">
                  <li>
                    <Translation
                      text={language ? language : '....'}
                      input={
                        <Translate>{language ? language : '....'}</Translate>
                      }
                    />
                  </li>
                </div>
              </div>
              <div className="col-12 mt-2 mt-lg-0 col-lg-6">
                <div className={classes.RequestType}>
                  <h6>
                    <Translation
                      from={'en'}
                      text="Proof of Identity"
                      input={<Translate>Proof of Identity</Translate>}
                    />
                  </h6>
                </div>
                <li>
                  {cv && (
                    <Link
                      target="_blank"
                      rel="noopener noreferrer"
                      to={{pathname: cv}}
                      download="file"
                      className="text-primary text-bold"
                      style={{textDecoration: 'underline'}}>
                      <Translation
                      from={'en'}
                      text="View"
                      input={<Translate>View</Translate>}
                    />
                    </Link>
                  )}
                </li>
              </div>
            </div>
            <div className="mt-3">
              <div className={classes.RequestType}>
                <h6>
                  <Translation
                    from={'en'}
                    text="Photo"
                    input={<Translate>Photo</Translate>}
                  />{' '}
                </h6>
              </div>
            </div>
            <div className="mt-3">
              <div className={classes.RequestType}>
                <h6>
                  <Translation
                    from={'en'}
                    text="Costs"
                    input={<Translate>Costs</Translate>}
                  />{' '}
                </h6>
              </div>
              <li className="py-2">
                {cost ? (
                  cost
                ) : (
                  <Translation
                    from={'en'}
                    text="Price: Payment after results To be discussed"
                    input={
                      <Translate>
                        Price: Payment after results To be discussed
                      </Translate>
                    }
                  />
                )}{' '}
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withTranslation(['Partner'])(SingleRequest)
