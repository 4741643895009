import React from 'react'
import {connect} from 'react-redux'
import * as actions from '../../store/actions/index'
import HomeSlide from './HomeSlide/HomeSlide'
import ServicesDetails from './ServicesDetails/ServicesDetails'
import ImageSlider from './ImageSlider/ImageSlider'
import Middle from './Middle/Middle'
import SimpleSnackbar from '../../components/Snackbar/Snackbar'
// @ts-ignore
import MetaTags from 'react-meta-tags'
import {withTranslation} from 'react-i18next'
import axios from '../../axios'

class index extends React.Component {
  state = {
    appleUser: '',
    message: '',
    placeholder1: [],
  }
  componentDidMount() {
    if (this.props.location.search) {
      const search = this.props.location.search // could be '?foo=bar'
      // console.log(search,'search')
      // const params = new URLSearchParams(search)
      const completeUrl = 'https://umediad.com' + search
      const params = new URL(completeUrl).searchParams
      let ok = params.get('ok')
      // console.log(ok, 'ok')
      if (ok) {
        let user = params.get('user')
        if (user) {
          this.setState({
            appleUser: user,
            message: ok,
          })
        } else {
          const authStatus = params.get('authStatus')
          const token = params.get('token')
          const expiresIn = params.get('expiresIn')
          const user = JSON.parse(params.get('info'))
          const body = JSON.stringify({
            authStatus,
            token,
            expiresIn,
            ok,
          })
          this.setState({
            appleUser: user.email,
            message: ok,
          })
          this.props.onAuth(body, user, this.props.history)
        }
      }
    }
    axios
      .post('/translate/translatedText', {
        text: ['Find Your Audience'],
        targetLanguage: localStorage.getItem('translateTo'),
      })
      .then((response) => {
        console.log(response.data, 'response.data')
        this.setState({
          placeholder1: response.data.data,
        })
      })
      .catch((error) => console.log(error))
  }
  render() {
    const {t} = this.props
    let {appleUser, message} = this.state
    let messageShouldShow = ''
    if (appleUser && message) {
      messageShouldShow = 'Welcome to umediad ' + appleUser + ', ' + message
    }
    return (
      <React.Fragment>
        {this.state.appleUser.length !== 0 && (
          <SimpleSnackbar
            open={true}
            message={messageShouldShow}
            type="success"
            height="70"
          />
        )}
        <MetaTags>
          <title>Umediad : {t('metaTitle')}</title>
        </MetaTags>
        <HomeSlide
          searchText={
            this.state.placeholder1.length === 0
              ? 'Find Your Audience'
              : this.state.placeholder1[0]
          }
        />
        <Middle />
        <ImageSlider />
        <ServicesDetails />
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.userAuth.loading,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (body, user, history) =>
      dispatch(actions.appleAuth(body, user, history)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation(['translation'])(index))
