import React from 'react';
import classes from './Modal.module.css';
import Backdrop from '../Backdrop/Backdrop';
import {  Bounce } from 'react-reveal';

export const Modal = (props) =>
    <React.Fragment>
        <Backdrop
            show={props.show}
            clicked={props.modalClosed} />
        {props.show && props.type === 'lightbox' &&
            <Bounce>
                <div className={classes.Modal}>
                    {props.children}
                </div>
            </Bounce>}
        {props.show && props.type === 'normal' &&
            <Bounce>
                <div className={classes.Modal}>
                    {props.children}
                </div>
            </Bounce>}
    </React.Fragment>

export const ConfirmModal = (props) =>
    <React.Fragment>
        {props.show && <Bounce>
            <div className={classes.ConfirmModal}>
                {props.children}
            </div>
        </Bounce>}
    </React.Fragment>
