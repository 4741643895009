import React, {Component} from 'react'
import {withTranslation} from 'react-i18next'
import styles from './ImageSlider.module.css'
import {HorizontalSlider} from '../../../components/HorizontalSlider'
import SliderItems from './SliderItem'
import {
  Adults,
  Travel_Adventure1,
  Travel_Adventure2,
  Belief_Religion1,
  Belief_Religion2,
  Game_Techno1,
  Game_Techno2,
  Social_Political1,
  Social_Political2,
  Transport_Delivery1,
  Transport_Delivery2,
  Lifestyle_Others1,
  Lifestyle_Others2,
  All_Engine1,
  All_Engine2,
  Cinema_Show1,
  Cinema_Show2,
  Home_Garden1,
  Home_Garden2,
  Business_Job1,
  Business_Job2,
  Event1,
  Event2,
  Sport1,
  Sport2,
  Fitness_Health1,
  Fitness_Health2,
  Music_Video1,
  Music_Video2,
  Beauty_wellness1,
  Beauty_wellness2,
  Foodies_Drinks1,
  Foodies_Drinks2,
  Clothing_Accessories1,
  Clothing_Accessories2,
} from '../../../assets/images/domains'
import {connect} from 'react-redux'
import Translation from '../../../shared/Translate'
import {Translate} from 'react-auto-translate'

class ImageSlider extends Component {
  checkWhitespace = (str) => {
    let hasWhitespace = /\s/g.test(str)
    return hasWhitespace ? str.split(' ')[0] + '&' + str.split(' ')[2] : str
  }
  render() {
    const {t} = this.props
    const domains = [
      {
        images: [Clothing_Accessories1, Clothing_Accessories2],
        text: t('translation:domainSlider.list.0'),
        link: 'Clothing & Accessories',
      },
      {
        images: [Foodies_Drinks1, Foodies_Drinks2],
        text: t('translation:domainSlider.list.1'),
        link: 'Foodies & Drinks',
      },
      {
        images: [Travel_Adventure1, Travel_Adventure2],
        text: t('translation:domainSlider.list.2'),
        link: 'Travel & Adventure',
      },
      {
        images: [Beauty_wellness1, Beauty_wellness2],
        text: t('translation:domainSlider.list.3'),
        link: 'Beauty & wellness',
      },
      {
        images: [Fitness_Health1, Fitness_Health2],
        text: t('translation:domainSlider.list.4'),
        link: 'Fitness & Health',
      },
      {
        images: [Sport1, Sport2],
        text: t('translation:domainSlider.list.5'),
        link: 'Sport',
      },
      {
        images: [Music_Video1, Music_Video2],
        text: t('translation:domainSlider.list.6'),
        link: 'Music & Video',
      },
      {
        images: [Event1, Event2],
        text: t('translation:domainSlider.list.7'),
        link: 'Event',
      },
      {
        images: [Business_Job1, Business_Job2],
        text: t('translation:domainSlider.list.8'),
        link: 'Business & Job',
      },
      {
        images: [Home_Garden1, Home_Garden2],
        text: t('translation:domainSlider.list.9'),
        link: 'Home & Garden',
      },
      {
        images: [Cinema_Show1, Cinema_Show2],
        text: t('translation:domainSlider.list.10'),
        link: 'Cinema & Show',
      },
      {
        images: [All_Engine1, All_Engine2],
        text: t('translation:domainSlider.list.11'),
        link: 'All-Engine',
      },
      {
        images: [Lifestyle_Others1, Lifestyle_Others2],
        text: t('translation:domainSlider.list.12'),
        link: 'Town-planning & Others',
      },
      {
        images: [Transport_Delivery1, Transport_Delivery2],
        text: t('translation:domainSlider.list.13'),
        link: 'Transport & Delivery',
      },
      {
        images: [Social_Political1, Social_Political2],
        text: t('translation:domainSlider.list.14'),
        link: 'Social & Political',
      },
      {
        images: [Game_Techno1, Game_Techno2],
        text: t('translation:domainSlider.list.15'),
        link: 'Game & Techno',
      },
      {
        images: [Belief_Religion1, Belief_Religion2],
        text: t('translation:domainSlider.list.16'),
        link: 'Belief & Religion',
      },
      {
        images: [Adults, Adults],
        text: t('translation:domainSlider.list.17'),
        link: 'Adults',
      },
    ]

    let list = []
    domains.map((item) => {
      return list.push(
        <SliderItems
          image={this.props.screenWidth < 600 ? item.images[0] : item.images[1]}
          text={item.text}
          link={this.checkWhitespace(item.link.toLowerCase())}
        />,
      )
    })
    return (
      <div className={styles.Slider}>
        <h3 className={styles.Head}>
          <Translation
          from={'en'}
            text={'Popular Domains'}
            input={<Translate>Popular Domains</Translate>}
          />
        </h3>
        <HorizontalSlider items={list} />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    screenWidth: state.responsiveness.screenWidth,
  }
}

export default connect(
  mapStateToProps,
  null,
)(withTranslation(['translation'])(ImageSlider))
