import React, { Component } from 'react';
import classes from '../CreateAdd.module.css';
import Button from '../../../../components/UI/Button/Button';
import StepProgress from './StepProgress';
import { Divider } from '../../../../components/UI/Spacing';
import styles from '../../../../containers/NormalUser/Tender/Tender.module.css';

const backgroundColors = ['#4dabf7', 'rgb(170, 235, 73)', 'tomato'];

class Step7 extends Component {
  componentDidMount() {
    window.scroll(0.0, 0.0)
  }
  continue = e => {
    e.preventDefault();
    this.props.nextStep();
  };

  back = e => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    const { title, description, domains, audience, visibility,location, types, sub_types, packagesData, filesPreviews, advertiseSpecification } = this.props.values;
    const { t } = this.props;
    
    return <React.Fragment>
      <Divider orientation='v' space='2' />
      <StepProgress step={this.props.step} />
      <div className={classes.Divider}></div>
      <div className={classes.ConfirmAdvertise}>
        <div className={classes.ConfirmAdvertiseItems}>
          <div className={classes.ConfirmAdvertiseItemRow}>
            <div className={classes.ConfirmAdvertiseItemColumn1}>
              <label>{t('MyAds:CreateAdd.Step4.firstSection.0')}</label>
            </div>
            <div className={classes.ConfirmAdvertiseItemColumn2}>
              <span>{title}</span>
            </div>
          </div>
          <div className={classes.DividerWithoutStyle}></div>
          <div className={classes.ConfirmAdvertiseItemRow}>
            <div className={classes.ConfirmAdvertiseItemColumn1}>
              <label>{t('MyAds:CreateAdd.Step4.firstSection.1')}</label>
            </div>
            <div className={classes.ConfirmAdvertiseItemColumn2}>
              <div dangerouslySetInnerHTML={{ __html: description}}></div>
            </div>
          </div>
          <div className={classes.DividerWithoutStyle}></div>
          <div className={classes.ConfirmAdvertiseItemRow}>
            <div className={classes.ConfirmAdvertiseItemColumn1}>
              <label>{t('MyAds:CreateAdd.Step4.firstSection.2')}</label>
            </div>
            <div className={classes.ConfirmAdvertiseItemColumn2}>
              <div className={styles.MediaItem}>
              {types.map((type, index) => (
                <span key={index}>
                  {type}
                </span>
              ))}
            </div>
            </div>
          </div>
          <div className={classes.DividerWithoutStyle}></div>
          <div className={classes.ConfirmAdvertiseItemRow}>
            <div className={classes.ConfirmAdvertiseItemColumn1}>
              <label>{t('MyAds:CreateAdd.Step4.firstSection.3')}</label>
            </div>
            <div className={classes.ConfirmAdvertiseItemColumn2}>
              <div className={styles.MediaItem}>
              {sub_types.map((sub_type, index) => (
                <span key={index}>
                  {sub_type}
                </span>
              ))}
            </div>
            </div>
          </div>
          <div className={classes.DividerWithoutStyle}></div>
          <div className={classes.ConfirmAdvertiseItemRow}>
            <div className={classes.ConfirmAdvertiseItemColumn1}>
              <label>Ad Domains</label>
            </div>
            <div className={classes.ConfirmAdvertiseItemColumn2}>
              {domains.map((domainValue, index) => (
                <span className='_7ahQImyV4dj0EpcNOjnwA'>{domainValue.domain}</span>
              ))}
            </div>
          </div>
          <div className={classes.DividerWithoutStyle}></div>
          <div className={classes.ConfirmAdvertiseItemRow}>
            <div className={classes.ConfirmAdvertiseItemColumn1}>
              <label>Audience</label>
            </div>
            <div className={classes.ConfirmAdvertiseItemColumn2}>
              <span>{audience.value}</span>
            </div>
          </div>
          <div className={classes.DividerWithoutStyle}></div>
          <div className={classes.ConfirmAdvertiseItemRow}>
            <div className={classes.ConfirmAdvertiseItemColumn1}>
              <label>Visibility</label>
            </div>
            <div className={classes.ConfirmAdvertiseItemColumn2}>
              <span>{visibility.value}</span>
            </div>
          </div>
          <div className={classes.DividerWithoutStyle}></div>
          <div className={classes.ConfirmAdvertiseItemRow}>
            <div className={classes.ConfirmAdvertiseItemColumn1}>
              <label>Location</label>
            </div>
            <div className={classes.ConfirmAdvertiseItemColumn2}>
              <span>{location.address}</span>
            </div>
          </div>
          <div className={classes.DividerWithoutStyle}></div>
          <div className={classes.ConfirmAdvertiseItemRow}>
            <div className={classes.ConfirmAdvertiseItemColumn1}>
              <label>{t('MyAds:CreateAdd.Step4.firstSection.4')}</label>
            </div>
            <div className={classes.ConfirmAdvertiseItemColumn2}>
              {advertiseSpecification.map((specification, index) => (
                specification.rule !== '' &&
                  <React.Fragment>
                    <div className='d-flex my-1 align-items-center'>
                      <div className={classes.AdvertiseSpecificationSingleItemIndex}>{index + 1}</div>
                      <div className={classes.PackageTaskItemValue}><span>{specification.rule}</span></div>
                    </div>
                  </React.Fragment>
              ))}
            </div>
          </div>
        </div>
        <div className={classes.DividerH}></div>
        <div className={classes.MobileOnly}></div>
        <div className={classes.ConfirmAdvertiseItems}>
          <div className={classes.ConfirmAdvertiseItemColumn1}>
            <label>{t('MyAds:CreateAdd.Step4.firstSection.5')}</label>
          </div>
          <div className={classes.ConfirmPhotos}>
            {filesPreviews.images.map(image => (
              <img src={image.url} alt='ImagePhoto' />
            ))}
          </div>
          <div className={classes.ConfirmPhotos}>
            {filesPreviews.video.map(video => (
              <video width="320" height="240" controls>
                <source src={video.url} type="video/mp4"></source>
              </video>
            ))}
          </div>
        </div>
      </div>
      <div className={classes.Divider}></div>
      <div className={classes.PackagesConfirmHeader}>
        <label>{t('MyAds:CreateAdd.Step4.secondSection.0')}</label>
      </div>
      <div className={classes.DividerWithoutStyle}></div>
      <div className={classes.ConfirmAdvertise}>
        {packagesData.map((packageValue, index) => (
          packageValue.name.length !== 0 &&
            <React.Fragment>
              <div className={classes.ConfirmAdvertiseItems}>
                <div className={classes.ConfirmAdvertiseItemRow}>
                  <div className={classes.ConfirmAdvertiseItemColumn2}>
                    <div className={classes.PackageTypeHeader}
                      style={{ background: backgroundColors[index] }}>{packageValue.type}
                    </div>
                  </div>
                </div>
                <div className={classes.DividerWithoutStyle}></div>
                <div className={classes.ConfirmAdvertiseItemRow}>
                  <div className={classes.ConfirmAdvertiseItemColumn1}>
                    <label>{t('MyAds:CreateAdd.Step4.secondSection.1')}</label>
                  </div>
                  <div className={classes.ConfirmAdvertiseItemColumn2}>
                    <span>{packageValue.name}</span>
                  </div>
                </div>
                <div className={classes.DividerWithoutStyle}></div>
                <div className={classes.ConfirmAdvertiseItemRow}>
                  <div className={classes.ConfirmAdvertiseItemColumn1}>
                    <label>{t('MyAds:CreateAdd.Step4.secondSection.2')}</label>
                  </div>
                  <div className={classes.ConfirmAdvertiseItemColumn2}>
                    <span>{packageValue.description}</span>
                  </div>
                </div>
                <div className={classes.DividerWithoutStyle}></div>
                <div className={classes.ConfirmAdvertiseItemRow}>
                  <div className={classes.ConfirmAdvertiseItemColumn1}>
                    <small>{t('MyAds:CreateAdd.Step4.secondSection.3')}</small>
                  </div>
                  <div className={classes.ConfirmAdvertiseItemColumn2}>
                    {packageValue.tasks.map((task, index) => (
                      <React.Fragment>
                        <div className='d-flex my-1 align-items-center'>
                          <div className={classes.PackageTaskItemIndex}>{index + 1}</div>
                          <div className={classes.PackageTaskItemValue}>
                            <span>{task}</span>
                          </div>
                        </div>
                      </React.Fragment>
                    ))}
                  </div>
                </div>
                <div className={classes.DividerWithoutStyle}></div>
                <div className={classes.ConfirmAdvertiseItemRow}>
                  <div className={classes.ConfirmAdvertiseItemColumn1}>
                    <label>{t('MyAds:CreateAdd.Step4.secondSection.4')}</label>
                  </div>
                  <div className={classes.ConfirmAdvertiseItemColumn2}>
                    <span>{packageValue.delivery_time} days</span>
                  </div>
                </div>
                <div className={classes.DividerWithoutStyle}></div>
                <div className={classes.ConfirmAdvertiseItemRow}>
                  <div className={classes.ConfirmAdvertiseItemColumn1}>
                    <label>{t('MyAds:CreateAdd.Step4.secondSection.5')}</label>
                  </div>
                  <div className={classes.ConfirmAdvertiseItemColumn2}>
                    <span>${packageValue.price}</span>
                  </div>
                </div>
              </div>
              <div className={classes.DividerWithoutStyleH}></div>
              <div className={classes.MobileOnly}></div>
            </React.Fragment>
        ))}
      </div>
      <div className={classes.Divider}></div>
      <div className={classes.StepsButton}>
        <Button buttonType='AppleDark' clicked={this.back} >  <i className='fas fa-chevron-left '></i>{t('MyAds:CreateAdd.button2')}</Button>
        <Button
          buttonType='Submit'
          clicked={(event) => { this.continue(event); this.props.handleFormSubmit() }}
        >{t('MyAds:CreateAdd.button3')}  <i className='fas fa-chevron-right'></i></Button>
      </div>
    </React.Fragment>
  }
}

export default Step7;
