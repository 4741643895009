import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import classes from '../Dashboard.module.css'
import ProfileImage from '../../../../assets/images/profileImage.png'
import { getCurrentDate } from '../../../../shared/utility';


export const ChatList = ({ chats, screenWidth }) => {

  const { t } = useTranslation(['Dashboard'])

  const renderChatUsers = () => {
    return (
      <div>
        {chats.map((single) => {
          return (
            <Link to="/inbox" style={{ textDecoration: 'none', color: '#000' }}>
              <div className={classes.ChatItem}>
                <div className="d-flex align-items-center">
                  <div className="col-10">
                    <div className="d-flex align-items-center my-2">
                      <div className={classes.Avatar}>
                        <img
                          src={single ? single.profile_pic : ProfileImage}
                          alt="Profile"
                        />
                      </div>
                      <div
                        className={classes.TextInfo}
                        style={{ alignItems: 'flex-start' }}>
                        {screenWidth > 1200 ?
                          <p>
                            {single.name.length > 13 ? single.name.substring(0, 12) + '...' : single.name}
                          </p> : <p>
                            {screenWidth < 415 ? single.name.substring(0, 10) + '...' : single.name}
                          </p>}
                        <small className="text-secondary">
                          {single.lastMessage ? single.messageType === 'file' ? 'You sent files' : single.lastMessage : ''}
                        </small>
                      </div>
                    </div>
                  </div>
                  <div className="text-secondary col-2">
                    <small>
                      {getCurrentDate(single.mood ? single.mood : Date.now())}
                    </small>
                  </div>
                </div>
              </div>
            </Link>
          )
        })}
      </div>
    )
  }
  return (
    <div className={classes.ProfileInfo}>
      <div className={classes.ProfileInfoDesc}>
        <div className={classes.ProfileInfoDesc1}>
          <small>{t('Dashboard:profileInfo.1')}</small>
        </div>
        <Link to="/inbox" style={{ textDecoration: 'none' }}>
          <small className="text-primary">{t('Dashboard:profileInfo.2')}</small>
        </Link>
      </div>
      <div className={classes.Divider}></div>
      <div className={classes.InboxMessages}>{chats && renderChatUsers()}</div>
    </div>
  )
}
