import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withTranslation} from 'react-i18next'
import Button from '../../../components/UI/Button/Button'
import {Spinner} from '../../../components/UI/Spinner/Spinner'
import classes from './Auth.module.css'
import Captcha from 'react-recaptcha'
// import FacebookButton from './SocialLoginsButtons/FacebookButton';
import GoogleButton from './SocialLoginsButtons/GoogleButton'
import * as actions from '../../../store/actions'
import AppImage from '../../../assets/images/app-image.png'
import PropTypes from 'prop-types'
import MyAppleSigninButton from './AppleSignin'
import SimpleSnackbar from '../../../components/Snackbar/Snackbar'
const reCaptchaApiKey = '6LfqitoZAAAAAHjqdjAEv5WAdCScQ6s-wyFfYJbX'
const APP_ID_GOOGLE =
  '994763187052-2qkrod9ro6hknrf5k9icd30m0qurufu2.apps.googleusercontent.com'
// const APP_ID_FACEBOOK = '630218267646419';

class SignUp extends Component {
  state = {
    formData: {
      email: {
        value: '',
        valid: false,
        touched: false,
      },
      password: {
        value: '',
        valid: false,
        touched: false,
      },
      confirm_password: {
        value: '',
        valid: false,
        touched: false,
      },
    },
    isSignupError: false,
    isCaptchaVerified: false,
    showPassword: false,
    showConfirmPassword: false,
    isSignUp: true,
    codeToRefer: null,
  }
  componentDidMount() {
    window.scroll(0, 0)
    if (this.props.location.search) {
      const search = this.props.location.search
      const params = new URLSearchParams(search)
      if (params.get('code')) {
        this.setState({codeToRefer: params.get('code')})
      }
    }
  }

  handleSocialLogin = (user) => {
    const socialId = user._profile.id
    const full_name = user._profile.name
    const profile_image = user._profile.profilePicURL
    let email = ''
    if (user._profile.email) {
      email = user._profile.email
    }
    const registrationType = 'social'
    const body = JSON.stringify({
      socialId,
      email,
      full_name,
      profile_image,
      registrationType,
      codeToRefer: this.state.codeToRefer,
    })
    this.props.onAuth(body, this.state.isSignUp, this.props.history)
  }

  handleSocialLoginFailure = (err) => {
    // this.setState({ isSignupError: true });
  }

  handlerSubmit = (event) => {
    event.preventDefault()
    const userData = {...this.state.formData}
    const email = userData['email'].value
    const password = userData['password'].value

    if (
      userData['email'].valid &&
      userData['password'].valid &&
      userData['confirm_password'].valid &&
      this.state.isCaptchaVerified
    ) {
      userData['email'].value = ''
      userData['password'].value = ''
      userData['confirm_password'].value = ''
      this.setState({formData: userData, isSignupError: false})
      const registrationType = 'normal'
      const body = JSON.stringify({
        email,
        password,
        registrationType,
        codeToRefer: this.state.codeToRefer,
      })

      this.props.onAuth(body, this.state.isSignUp, this.props.history)
    } else {
      this.setState({isSignupError: true})
    }
  }
  reCaptchaLoaded = () => console.log('Captcha loaded successfully')

  verifyCaptcha = (response) => {
    if (response) this.setState({isCaptchaVerified: true})
  }
  handleInput = (elementType, event) => {
    const userData = {...this.state.formData}
    userData[elementType].value = event.target.value
    userData[elementType].touched = true
    this.setState({formData: userData})
    this.validate(elementType, event.target.value)
  }

  validate = (elementType, value) => {
    const userData = {...this.state.formData}
    if (elementType === 'email') {
      const regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
      userData[elementType].valid = regex.test(value)
      this.setState({formData: userData})
    }
    if (elementType === 'password') {
      const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,16}$/
      userData[elementType].valid = regex.test(value)
      this.setState({formData: userData})
    }
    if (elementType === 'confirm_password') {
      let password = userData['password'].value
      if (password === value) {
        userData[elementType].valid = true
      } else {
        userData[elementType].valid = false
      }
      this.setState({formData: userData})
    }
  }

  handlePasswordVisibility = (eventName) => {
    if (eventName === 'password')
      this.setState({showPassword: !this.state.showPassword})
    if (eventName === 'confirm_password')
      this.setState({showConfirmPassword: !this.state.showConfirmPassword})
  }

  render() {
    let formData = this.state.formData
    const {t} = this.props
    return (
      <React.Fragment>
        {this.props.message && !this.props.error ? (
          <SimpleSnackbar
            open={true}
            message={this.props.message}
            type="success"
          />
        ) : null}
        {this.props.message && this.props.error ? (
          <SimpleSnackbar
            open="true"
            message={this.props.message}
            type="error"
          />
        ) : null}
        {this.props.loading ? (
          <Spinner />
        ) : (
          <div className={classes.AuthMain}>
            <div className={classes.Auth}>
              <img src={AppImage} alt="AppImage" />
              <h3>{t('SigninUp:signup.name')}</h3>
              <form onSubmit={(event) => this.handlerSubmit(event)}>
                <input
                  type="email"
                  value={formData['email'].value}
                  placeholder={t('SigninUp:signup.fields.0')}
                  onChange={(event) => this.handleInput('email', event)}
                />
                {formData['email'].touched && !formData['email'].valid ? (
                  <strong>{t('SigninUp:signup.fields.01')}</strong>
                ) : null}
                <div className={classes.Divider}></div>
                <div className={classes.PasswordWrapper}>
                  <input
                    type={this.state.showPassword ? 'text' : 'password'}
                    min="8"
                    max="16"
                    placeholder={t('SigninUp:signup.fields.1')}
                    value={formData['password'].value}
                    onChange={(event) => this.handleInput('password', event)}
                  />
                  {this.state.showPassword ? (
                    <i
                      className="fas fa-eye"
                      onClick={(e) =>
                        this.handlePasswordVisibility('password')
                      }></i>
                  ) : (
                    <i
                      className="fas fa-eye-slash"
                      onClick={(e) =>
                        this.handlePasswordVisibility('password')
                      }></i>
                  )}
                </div>
                {formData['password'].touched && !formData['password'].valid ? (
                    <small style={{color: 'red'}}>
                      {t('SigninUp:signup.fields.3')}
                    </small>
                ) : null}
                <div className={classes.Divider}></div>
                <div className={classes.PasswordWrapper}>
                  <input
                    type={this.state.showConfirmPassword ? 'text' : 'password'}
                    min="8"
                    max="16"
                    placeholder={t('SigninUp:signup.fields.2')}
                    value={formData['confirm_password'].value}
                    onChange={(event) =>
                      this.handleInput('confirm_password', event)
                    }
                  />
                  {this.state.showConfirmPassword ? (
                    <i
                      className="fas fa-eye"
                      onClick={(e) =>
                        this.handlePasswordVisibility('confirm_password')
                      }></i>
                  ) : (
                    <i
                      className="fas fa-eye-slash"
                      onClick={(e) =>
                        this.handlePasswordVisibility('confirm_password')
                      }></i>
                  )}
                </div>
                {formData['confirm_password'].touched &&
                !formData['confirm_password'].valid ? (
                  <small style={{color: 'red'}}>
                    {t('SigninUp:signup.fields.21')}
                  </small>
                ) : null}
                <div className={classes.Divider}></div>
                <small>{t('SigninUp:signup.fields.4')}</small>
                <div className={classes.Divider}></div>
                <div className={classes.Captcha}>
                  <Captcha
                    sitekey={reCaptchaApiKey}
                    render="explicit"
                    onloadCallback={this.reCaptchaLoaded}
                    verifyCallback={this.verifyCaptcha}
                  />
                </div>
                <div className={classes.Divider}></div>
                <div className={classes.SignUpButton}>
                  <Button buttonType="Submit" clicked={this.handlerSubmit}>
                    <i
                      className="fa fa-paper-plane mx-1"
                      aria-hidden="true"></i>
                    {t('SigninUp:signup.fields.5')}
                  </Button>
                </div>
                {this.state.isSignupError ? (
                  <strong>{t('SigninUp:signup.error')}</strong>
                ) : null}
              </form>
              <h6>{t('SigninUp:common.3')}</h6>
              <p style={{fontSize: '10pt', paddingBottom: '10px'}}>
                {t('SigninUp:common.5')}
              </p>
              <GoogleButton
                provider="google"
                appId={APP_ID_GOOGLE}
                onLoginSuccess={this.handleSocialLogin}
                onLoginFailure={this.handleSocialLoginFailure}
                redirect="https://umediad.com/my_profile">
                <div className={classes.SocialButton}>
                  <i className="fab fa-google" aria-hidden="true"></i>{' '}
                  <div className={classes.SocialText}>
                    {t('SigninUp:common.0')}
                  </div>
                </div>
              </GoogleButton>
              {/* <FacebookButton
                            provider='facebook'
                            appId={APP_ID_FACEBOOK}
                            onLoginSuccess={this.handleSocialLogin}
                            onLoginFailure={this.handleSocialLoginFailure}
                            redirect='https://umediad.com/my_profile'
                        >
                            <div className={classes.SocialButton}>
                                <i className="fab fa-facebook-f" aria-hidden="true"></i>
                                <div className={classes.SocialText}>  {t('SigninUp:common.1')}</div>
                            </div>
                        </FacebookButton> */}
              <MyAppleSigninButton url="https://api.umediad.com/user/apple/register" />
            </div>
          </div>
        )}
      </React.Fragment>
    )
  }
}

SignUp.propTypes = {
  loading: PropTypes.bool,
}

const mapStateToProps = (state) => {
  return {
    loading: state.userAuth.loading,
    error: state.userAuth.error,
    message: state.userAuth.message,
    isAuthenticated: state.userAuth.token !== null,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (body, isSignUp, history) =>
      dispatch(actions.auth(body, isSignUp, history)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation(['translation', 'SigninUp'])(SignUp))
