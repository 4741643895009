import React from 'react'
import {useTranslation} from 'react-i18next'
import classes from '../Dashboard.module.css'

export const ProfileInfo = ({user}) => {
  const {t} = useTranslation(['Dashboard'])
  return (
    <div className={classes.ProfileInfo}>
      <div className={classes.ProfileInfoDesc}>
        <div className={classes.ProfileInfoDesc1}>
          <img
            src={user && user.profile_image}
            className={classes.ProfileInfoImage}
            alt="Profile"
          />
          <h6>{user && user.full_name ? user.full_name : ''}</h6>
        </div>
        {/* <span>
          <i className="fa fa-star"></i>
        </span>{' '}
        5 */}
      </div>
      <div className={classes.Divider}></div>
      {/* <div className='row justify-content-around mt-3'>
            <div className='col-8'>
                <p>Earned in April</p>
            </div>
            <div className='text-secondary col-3'>
                <h6>$10</h6>
            </div>
        </div> */}
      <div className="row justify-content-around mt-3">
        <div className="col-8">
          <p>{t('Dashboard:profileInfo.0')}</p>
        </div>
        <div className="text-success col-3">
          <h6>1Hrs</h6>
        </div>
      </div>
    </div>
  )
}
