import React, { Component } from 'react';
import classes from '../CreateAdd.module.css';
import Button from '../../../../components/UI/Button/Button';
import StepProgress from './StepProgress';
import { Divider } from '../../../../components/UI/Spacing';

const visibilityOptions = [
    {
        name: '24 hour',
        type: 24
    },
    {
        name: '7 days',
        type: 7
    },
    {
        name: '28 days',
        type: 28
    },
    {
        name: '90 days',
        type: 90
    }
]
const audienceOptions = [
    {
        type: 'Viewers'
    },
    {
        type: 'Listeners'
    },
    {
        type: 'Readers'
    },
]

export class Step2 extends Component {
    state = {
        isClicked: false,
        other: ''
    };

    componentDidMount() {
        window.scroll(0.0, 0.0)
    }

    continue = (event, isFieldOk) => {
        event.preventDefault();
        if (isFieldOk) this.props.nextStep();
        this.setState({ isClicked: true });
        setTimeout(() => {
            this.setState({ isClicked: false });
        }, 4000)
    };
    handleOtherAudienceInputChange = (e) => this.setState({ other: e.target.value });
    back = e => {
        e.preventDefault();
        this.props.prevStep();
    };

    render() {

        const { values, handleAudienceAndVisibilityInputChange, onChangeVisibility,
            onAddVisibility, onAddAudience, addOtherAudience, removeOtherAudience, t } = this.props;
        const { audience, visibility } = values;
        let isFieldOk = true;
        //     audience.value.length > 0;
        // isFieldOk = isFieldOk && visibility.value.length > 0;


        const selectedVisibility = visibility.selectedVisibility;
        const audienceTypes = audience.types;

        return <React.Fragment>
            <Divider orientation='v' space='2' />
            <StepProgress step={this.props.step} />
            <h5>{t('MyAds:CreateAdd.Step2.extra.0')}</h5>
            <div className={classes.Divider}></div>
            <div className={classes.AudienceAndVisibility}>
                <div className={classes.AudienceAndVisibilityItems}>
                    <label className='form-label'>Total audience</label>
                    <input
                        className="form-control"
                        type='text'
                        maxLength='50'
                        placeholder='Audience'
                        value={audience.value}
                        name='audience'
                        onChange={handleAudienceAndVisibilityInputChange}
                    ></input>
                    <div className={classes.ErrorInfo}>
                        {values.audience.length <= 0 && this.state.isClicked && <strong className={classes.Error}>Audience must be not be empty</strong>}
                    </div>
                    <Divider orientation='v' space='2' />
                    <label className='form-label'>Audience's Gender</label>
                    <div className={classes.AudienceAndVisibilityRow}>
                        <input
                            type='text'
                            maxLength='10'
                            placeholder='Man(%)'
                            value={audience.gender.man}
                            name='man'
                            onChange={handleAudienceAndVisibilityInputChange}
                            className="form-control"
                        ></input>
                        <input
                            type='text'
                            maxLength='10'
                            placeholder='Women(%)'
                            value={audience.gender.women}
                            name='women'
                            onChange={handleAudienceAndVisibilityInputChange}
                            className="form-control"
                        ></input>
                    </div>
                    <Divider orientation='v' space='2' />
                    <div className={classes.AudienceAndVisibilityRow}>
                        <label className='form-label'>Language</label>
                        <label className='form-label'>Audience age on average</label>
                    </div>
                    <div className={classes.AudienceAndVisibilityRow}>
                        <input
                            type='text'
                            maxLength='30'
                            placeholder='Language'
                            value={audience.language}
                            name='language'
                            onChange={handleAudienceAndVisibilityInputChange}
                            className="form-control"
                        ></input>
                        <input
                            type='text'
                            maxLength='10'
                            placeholder='Age'
                            value={audience.averageAge}
                            name='averageAge'
                            onChange={handleAudienceAndVisibilityInputChange}
                            className="form-control"
                        ></input>
                    </div>
                    <Divider orientation='v' space='2' />
                    <label className='form-label'>Audience Types</label>
                    <div className='d-flex flex-wrap justify-content-between'>
                        {audienceOptions.map((audience, index) => (
                            <div className='col-6' key={index}>
                                <div className="form-check" key={index}>
                                    <input
                                        checked={audienceTypes.findIndex(audienceType => audienceType.type === audience.type) !== -1}
                                        className="form-check-input"
                                        type="checkbox"
                                        name='visibility'
                                        id='visibility'
                                        onChange={(e) => onAddAudience(audience)}
                                    />
                                    <label
                                        className="form-check-label mt-1"
                                        htmlFor='visibility'>
                                        {audience.type}
                                    </label>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className='d-flex flex-wrap my-2'>
                        {audienceTypes.length > 0 && audienceTypes.map((audience, index) => (
                            <span key={index} className='rounded-pill bg-primary text-light p-2 m-1'>
                                {audience.type} <i className="fas fa-times mx-1" onClick={_ => removeOtherAudience(audience.type)}>
                                </i>
                            </span>
                        ))}
                    </div>
                    <form onSubmit={(e) => addOtherAudience(e, this.state.other)}>
                        <label className='form-label'>Add other audience types</label>
                        <input
                            type='text'
                            maxLength='50'
                            placeholder='Other'
                            name='otherAudience'
                            value={this.state.other}
                            onChange={this.handleOtherAudienceInputChange}
                            className="form-control"
                        ></input>
                        <button
                            onClick={(e) => addOtherAudience(e, this.state.other)}
                            className='btn btn-sm btn-outline-primary mt-3'><i className="fas fa-plus mx-1"></i>Add New</button>
                    </form>
                </div>
                <div className={classes.DividerH}></div>
                <div className={classes.AudienceAndVisibilityItems}>
                    <label className='form-label mt-3 mt-lg-0'>Average visibility</label>
                    <input
                        type='text'
                        maxLength='50'
                        placeholder='Visibility'
                        value={visibility.value}
                        name='visibility'
                        onChange={handleAudienceAndVisibilityInputChange}
                        className="form-control"
                    ></input>
                    <div className={classes.ErrorInfo}>
                        {visibility.length <= 0 && this.state.isClicked && <strong className={classes.Error}>Visibility must be not be empty</strong>}
                    </div>
                    <Divider orientation='v' space='2' />
                    <label className='form-label'>Last Average visibility for</label>
                    <div className='d-flex flex-wrap justify-content-between'>
                        {visibilityOptions.map((visible, index) => (
                            <div className='col-6' key={index}>
                                <div className="form-check" key={index}>
                                    <input
                                        checked={selectedVisibility.findIndex(visibility => visibility.type === visible.type) !== -1}
                                        className="form-check-input"
                                        type="checkbox"
                                        name='visibility'
                                        id='visibility'
                                        onChange={(e) => onAddVisibility(visible)}
                                    />
                                    <label
                                        className="form-check-label mt-1"
                                        htmlFor='visibility'>
                                        {visible.name}
                                    </label>
                                    {selectedVisibility.findIndex(visibility => visibility.type === visible.type) !== -1 && <input
                                        className="form-control my-1 w-100"
                                        type="text"
                                        value={selectedVisibility[selectedVisibility.findIndex(visibility => visibility.type === visible.type)].value}
                                        name='visibility'
                                        onChange={(e) => onChangeVisibility(e, visible.type)}
                                    />}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className={classes.Divider}></div>
            <div className={classes.StepsButton}>
                <Button buttonType='AppleDark' clicked={this.back} >  <i className='fas fa-chevron-left'></i>{t('MyAds:CreateAdd.button2')}</Button>
                <Button
                    buttonType='Submit'
                    clicked={(e) => this.continue(e, isFieldOk)}
                >{t('MyAds:CreateAdd.button1')}
                    <i className='fas fa-chevron-right mx-1'></i></Button>
            </div>
        </React.Fragment >
    }
}

export default (Step2);
