import React from 'react'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import classes from './AllOrders.module.css'
import SellerAllOrders from './UsersAllOrders/SellerAllOrders';
import BuyerAllOrders from './UsersAllOrders/BuyerAllOrders';
import { connect } from 'react-redux'
import * as actions from '../../../store/actions'


class AllOrders extends React.Component {
  state = {
    statusType: localStorage.getItem('tabStatus') ? localStorage.getItem('tabStatus') : 'active',
    orders: [],
    allStatus: [
      {
        text: '',
        name: 'active',
        count: 0
      },
      {
        text: '',
        name: 'new',
        count: 0
      },
      {
        text: '',
        name: 'late',
        count: 0
      },
      {
        text: '',
        name: 'delivered',
        count: 0
      },
      {
        text: '',
        name: 'completed',
        count: 0
      }
    ]
  };

  componentWillUnmount() {
    localStorage.removeItem('tabStatus');
  }

  componentDidMount() {
    window.scroll(0.0, 0.0);
    if (this.props.user.role === 'seller') {
      this.props.getSellInfo();
    } else if (this.props.user.role === 'buyer') {
      this.props.getBuyInfo();
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.buy.data !== nextProps.buy.data) {
      this.setState({ orders: nextProps.buy.data })
    }
  }

  setStatusType = (type) => this.setState({ statusType: type })

  getOrderSpecificCount = (statusType, index) => {
    const filteredOrders = this.state.orders.filter((order, index) => statusType === order.status);
    const status = [...this.state.allStatus];
    status[index].count = filteredOrders.length;
    return status[index].count;
  }

  withTranslationOrders = (statusType) => {
    const { t } = this.props;

    if (statusType === "active") {
      return t('Dashboard:orders.list.0')
    } else if (statusType === "new") {
      return t('Dashboard:orders.list.1')
    } else if (statusType === "late") {
      return t('Dashboard:orders.list.2')
    } else if (statusType === "delivered") {
      return t('Dashboard:orders.list.3')
    } else if (statusType === "completed") {
      return t('Dashboard:orders.list.4')
    }
  }

  render() {
    const { statusType } = this.state;
    const { t } = this.props;

    return <div className={classes.ManageOrders}>
      <div className={classes.ManageOrdersMain}>
        <big>{t('Dashboard:manageOrders.0')}</big>
        <div className={classes.Tab}>
          <ul className="nav nav-tabs">
            {this.state.allStatus.map((status, index) => (
              <li className="nav-item">
                <Link
                  className={`nav-link ${ statusType === status.name && ('active') }`}
                  onClick={(e) => this.setStatusType(status.name)}
                  aria-current="page" to={this.props.history.location.pathname}>
                  {this.withTranslationOrders(status.name)} ({this.getOrderSpecificCount(status.name, index)})
                </Link>
              </li>
            ))}
          </ul>
        </div>
        {this.props.user.role === 'buyer' ?
          <BuyerAllOrders
            t={t}
            orders={this.props.buy.data}
            statusType={statusType}
            history={this.props.history}
            location={this.props.location} /> :
          <SellerAllOrders
            orders={this.props.buy.data}
            statusType={statusType}
            history={this.props.history}
            location={this.props.location}
            t={t}
          />}
      </div>
    </div>
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userAuth.user,
    buy: state.buy,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getSellInfo: () => dispatch(actions.getSellInfo()),
    getBuyInfo: () => dispatch(actions.getBuyInfo()),
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(['Dashboard'])(AllOrders))
