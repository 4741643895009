export const TermsData = [
  'TERMS AND CONDITIONS',
  'PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE USING THIS WEBSITE.',
  'The following Terms and Conditions govern and apply to your use of or reliance upon this website maintained by ',
  'Your access or use of the Website indicates that you have read, understand and agree to be bound by these Terms and Conditions and any other applicable laws, statutes and/or regulations. We may change these Terms and Conditions at any time without notice, effective upon its posting to the Website. Your continued use of the Website will be considered your acceptance to the revised Terms and Conditions.',
  '1. AGE RESTRICTION',
  'You must be at least sixteen (16) years of age to use this Website or any services contained herein. Your access or use of this Website indicates your representation that you are at least sixteen (16) years of age. We assume no responsibility or liability for any misrepresentation of your age.',
  '2. INTELLECTUAL PROPERTY',
  'All intellectual property on the Website (except for User Generated Content, as defined below) is owned by us or our licence, which includes materials protected by copyright, trademark, or patent laws. All trademarks, service marks and trade names are owned, registered and/or licensed by us. All content on the Website (except for User Generated Content, as defined below), including but not limited to text, software, code, designs, graphics, photos, sounds, music, videos, applications, interactive features and all other content is a collective work under Canadian and other copyright laws and is the proprietary property of the Company; All rights reserved.',
  '3. USE OF COMPANY MATERIALS',
  'We may provide you with certain information as a result of your use of the Website including, but not limited to, documentation, data, or information developed by us, and other materials which may assist in the use of the Website or Services (Company Materials). The Company Materials may not be used for any other purpose than the use of this Website and the services offered on the Website. Nothing in these Terms of Use may be interpreted as granting any licence of intellectual property rights to you.',
  '4. USER GENERATED CONTENT',
  "'User Generated Content' is communications, materials, information, data, opinions, photos, profiles, messages, notes, website links, text information, music, videos, designs, graphics, sounds, and any other content that you and/or other Website users post or otherwise make available on or through the Website, except to the extent the content is owned by us.",
  '5. ACCOUNT AND ACCOUNT USE',
  "If your use of the Website requires an account identifying you as a user of the Website (an 'Account')",
  'a) you are solely responsible for your Account and the maintenance, confidentiality and security of your Account and all passwords related to your Account, and any and all activities that occur under your Account, including all activities of any persons who gain access to your Account with or without your permission;',
  'b) you agree to immediately notify us of any unauthorized use of your Account, any service provided through your Account or any password related to your Account, or any other breach of security with respect to your Account or any service provided through it, and you agree to provide assistance to us, as requested, to stop or remedy any breach of security related to your Account, and',
  'c) you agree to provide true, current, accurate and complete customer information as requested by us from time to time and you agree to promptly notify us of any changes to this information as required to keep such information held by us current, complete and accurate.',
  '6. PAYMENT',
  'When you make a purchase on the Website, you agree to provide a valid instrument to make a payment. Pay attention to the details of the transaction, as your total price may include taxes, fees, and shipping costs, all of which you are responsible for.',
  'When you provide a payment instrument to us, you confirm that you are permitted to use that payment instrument. When you make a payment, you authorize us (and our designated payment processor) to charge the full amount of the payment instrument you designate for the transaction. You also authorize us to collect and store that funding instrument, along with other related transaction information.If you pay by credit or debit card, we may obtain a pre-approval from the issuer of the card for an amount as high as the full price. If you cancel a transaction before completion, this pre-approval may result in those funds not otherwise being immediately available to you',
  'We may cancel any transaction if we believe the transaction violates these Terms, or if we believe in doing so may prevent financial loss.',
  'In order to prevent financial loss to you or to us, we may contact your funding instrument issuer, law enforcement, or affected third parties (including other users) and share details of any payments you are associated with, if we believe doing so may prevent financial loss or a violation of law.',
  'Payment for any ongoing services is billed automatically until notification that you would like to terminate your access to the services.',
  '7. SALE OF GOODS AND SERVICES',
  'We may sell goods or services or allow third parties to sell goods or services on the Website. We undertake to be as accurate as possible with all information regarding the goods and services, including product descriptions and images. However, we do not guarantee the accuracy or reliability of any product information and you acknowledge and agree that you purchase such products at your own risk. For goods or services sold by others, we assume no liability for any product and cannot make any warranties about the merchantability, fitness, quality, safety or legality of these products. For any claim you may have against the manufacturer or seller of the product, you agree to pursue that claim directly with the manufacturer or seller and not with us. You agree to release us from any claims related to goods or services manufactured or sold by third parties, including any and all warranties or product liability claims.',
  '8. SHIPPING, DELIVERY AND RETURN POLICY',
  'You agree to ensure payment for any items you may purchase from us and you acknowledge and affirm that prices are subject to change. When purchasing a physical good, you agree to provide us with a valid email and shipping address, as well as valid billing information. We reserve the right to reject or cancel an order for any reason, including errors or omissions in the information you provide to us. If we do so after payment has been processed, we will issue a refund to you in the amount of the purchase price. ',
  'We may also request additional information from you prior to confirming a sale and we reserve the right to place any additional restrictions on the sale of any of our products. For the sale of physical products, we may preauthorize your credit or debit card at the time you place the order or we may simply charge your card upon shipment. You agree to monitor your method of payment. Shipment costs and dates are subject to change from the costs and dates you are quoted due to unforeseen circumstances.',
  'For any questions, concerns, or disputes, you agree to contact us in a timely manner at the following:',
  'If you are unhappy with anything you have purchased on our Website, you may do the following:',
  '9. AFFILIATE MARKETING & ADVERTISING',
  'We, through the Website and its services, may engage in affiliate marketing whereby we receive a commission on or percentage of the sale of goods or services on or through the Website. We may also accept advertising and sponsorships from commercial businesses or receive other forms of advertising compensation.',
  '10. ACCEPTABLE USE',
  'You agree not to use the Website for any unlawful purpose, or any purpose prohibited under this clause. You agree not to use the Website in any way that could damage the Website, the services or the general business of ',
  'You further agree not to use and/or access the Website:',
  " To harass, abuse, or threaten others or otherwise violate any person's legal rights;",
  ' To violate any intellectual property rights of us or any third party;',
  ' To upload or otherwise disseminate any computer viruses or other software that may damage the property of another;',
  ' To perpetrate any fraud;',
  ' To engage in or create any unlawful gambling, sweepstakes, or pyramid scheme;',
  ' To publish or distribute any obscene or defamatory material;',
  ' To publish or distribute any material that incites violence, hate or discrimination towards any group;',
  ' To unlawfully gather information about others.',
  '11. PROTECTION OF PRIVACY',
  'Through your use of the Website, you may provide us with certain information. By using the Website, you authorize us to use your information in Canada and any other country where We may operate.',
  'When you register for an account, you provide us with a valid email address and may provide us with additional information, such as your name and/or billing information. Depending on how you use our Website, we may also receive information from external applications you use to access our Website, or we may receive information through various web technologies, such as cookies, log files, clear gifs, web beacons or others.',
  'We use the information gathered from you to ensure your continued good experience on our website, including through email communication. We may also track certain of the passive information received to improve our marketing and analytics, and for this, we may work with third-party providers.',
  'If you would like to disable our access to any passive information we receive from the use of various technologies, you may choose to disable cookies in your web browser. Please be aware that we will still receive information about you that you have provided, such as your email address.',
  'If you choose to terminate your account, we will store information about you for the following period of time: 6 Months. After that time, all information about you will be deleted.',
  '12. ASSUMPTION OF RISK',
  'The Website is provided for communication purposes only. You acknowledge and agree that any information posted on our Website is not intended to be legal advice, medical advice, or financial advice, and no fiduciary relationship has been created between you and Jean Kindar Bury. You further agree that your purchase of any of the products on the Website is at your own risk. We do not assume responsibility or liability for any advice or other information given on the Website.',
  '13. REVERSE ENGINEERING & SECURITY',
  'You may not undertake any of the following actions:',
  ' Reverse engineer, or attempt to reverse engineer or disassemble any code or software from or on the Website;',
  ' Violate the security of the Website through any unauthorized access, circumvention of encryption or other security tools, data mining or interference to any host, user or network.',
  '14. DATA LOSS',
  'We are not responsible for the security of your Account or Content. Your use of the Website is at your own risk.',
  '15. INDEMNIFICATION',
  " and any of its affiliates and hold us harmless against any and all legal claims and demands, including reasonable attorney's fees, which may arise from or relate to your use or misuse of the Website, your breach of these Terms and Conditions, or your conduct or actions. We will select our own legal counsel and may participate in our own defense, if we wish to so.",
  '16. SPAM POLICY',
  'You are strictly prohibited from using the Website or any of our services for illegal spam activities, including gathering email addresses and personal information from others or sending any mass commercial emails.',
  '17. THIRD-PARTY LINKS & CONTENT',
  'We may occasionally post links to third party websites or other services. We are not responsible or liable for any loss or damage caused as a result of your use of any third party services linked to from our Website.',
  '18. VARIATION',
  'To the extent any part or sub-part of these Terms and Conditions is held ineffective or invalid by any court of law, the prior, effective version of these Terms and Conditions be considered enforceable and valid to the fullest extent.',
  '19. SERVICE INTERRUPTIONS',
  'We may need to interrupt your access to the Website to perform maintenance or emergency services on a scheduled or unscheduled basis. You agree that your access to the Website may be affected by unanticipated or unscheduled downtime, for any reason, but that the we will have no liability for any damage or loss caused as a result of such downtime.',
  '20. TERMINATION OF ACCOUNT',
  "We may, in our sole discretion, suspend, restrict or terminate your Account and your use of the Website, effective at any time, without notice to you, for any reason, including because the operation or efficiency of the Website or our or any third party's equipment or network is impaired by your use of the Website, any amount is past due from you to us, we have received a third party complaint which relates to your use or misuse of the Website, or you have been or are in breach of any term or condition of these Terms and Conditions. We will have no responsibility to notify any third party, including any third-party providers of services, merchandise or information, of any suspension, restriction or termination of your access to the Website.",
  '21. NO WARRANTIES',
  "Your use of the Website is at your sole and exclusive risk and any services provided by us are on an 'as is' basis. We disclaim any and all express or implied warranties of any kind, including, but not limited to the implied warranty of fitness for a particular purpose and the implied warranty of merchantability. We make no warranties that the Website will meet your needs or that the Website will be uninterrupted, error-free, or secure. We also make no warranties as to the reliability or accuracy of any information on the Website or obtained through the Services. Any damage that may occur to you, through your computer system, or as a result of loss of your data from your use of the Website is your sole responsibility and we are not liable for any such damage or loss.",
  '22. PRIVACY',
  'In addition to these Terms and Conditions, this Website has a Privacy Policy that describes how your personal data is collected and how cookies are used on the Website. For more information, you may refer to our Privacy Policy, which you may find at the following address: ',
  'By using or browsing this Website, you also acknowledge that you have read our Privacy Policy.',
  '23. LIMITATION ON LIABILITY',
  'We are not liable for any damages that may occur to you as a result of your use of the Website, to the fullest extent permitted by law. The maximum liability of Jean Kindar Bury arising from your use of the Website is limited to the greater of one hundred ($100) Canadian Dollars or the amount you paid to Jean Kindar Bury in the last six (6) months. This applies to any and all claims by you, including, but not limited to, lost profits or revenues, consequential or punitive damages, negligence, strict liability, fraud, or torts of any kind.',
]

//4. er niche ekta word ase
export const PolicyData = [
  'The respect of your private life is of the utmost importance for Universal Media Advertising Inc., who is responsible for this website.',
  'This Privacy Policy aims to lay out :',
  "the way your personal information is collected and processed. 'Personal information' means any information that could identify you, such as your name, your mailing address, your email address, your location and your IP address. 'Personal information' is a synonym for 'personal data' within the meaning of the Regulation 2016/679 of the European Union (General Data Protection Regulation);",
  'your rights regarding your personal information;',
  'who is responsible for the processing of the collected and processed information;',
  'to whom the information is transmitted;',
  "if applicable, the website's policy regarding cookies.",
  'This Privacy Policy complement the Terms and Conditions that you may find at the following address:',
  '1. COLLECTION OF PERSONAL INFORMATION',
  'We collect the following personal information:',
  '2. FORMS AND METHODS OF COLLECTION',
  'Your personal information is collected through the following methods:',
  'We use the collected data for the following purposes:',
  '3. INTERACTIVITY',
  'Your personal information is also collected through the interactivity between you and the website. This personal information is collected through the following methods:',
  'We use the personal information thus collected for the following purposes:',
  '4. COOKIES AND LOG FILES',
  'We collect information through log files and cookies. These allow us to process statistics and information on traffic on the Website, to ease navigation and improve your experience for your comfort.',
  'Under the General Data Protection Regulation (European Regulation 2016/679), the use of cookies and log files that involves personal information saving and analysis requires your consen',
  'Your consent is considered to be valid for a maximum period of thirteen (13) months. At the end of that period, we will ask again for your consent to save cookies and log files on your hard disk.',
  ' used by the Website',
  'The cookie files used on the Website are the following:',
  'The use of such files allows us to achieve the following purposes:',
  'Improvement of the service and personalized welcome',
  'Personalized consumption profiles',
  'Order tracking',
  'Statistical surveys',
  'Other',
  'Promotional purposes',
  'Managing the website and apps',
  'By using the Website, cookies from the following third parties may be saved on your hard disk:',
  'The website also embeds sharing and social media networks buttons that allow you to share your activity on the Website. Cookies and log files from the social media networks may be saved on your computer when you use these features.',
  'These websites have their own privacy policy and terms and condition that may be different than ours. We invite to look at the privacy policies and terms and conditions from these websites.',
  'Objection to the use of cookies and log files by the Website',
  'You have the right to object to the recording of these cookies and log files by configuring your web browser.',
  'Once you have deactivated cookies and log files, you may continue your use of the Website. However, any malfunction resulting from this deactivation may not be considered of our making.',
  '5. SHARING PERSONAL INFORMATION',
  'We are committed to not selling to third parties or otherwise commercialize the personal information we collect. However, we may share this information with third parties for the following reasons:',
  '6. STORAGE PERIOD OF PERSONAL INFORMATION',
  'The controller will keep in its computer systems, in reasonable security conditions, the entirety of the personal information collected for the following duration: 6 Months',
  '7. HOSTING OF PERSONAL INFORMATION',
  'The host may be contacted at the following address:',
  'The host may be contacted at the following phone number: ',
  'Personal information we collect and process is exclusively hosted in Canada.',
  'This transfer of personal information outside of the EU is justified by the fact that our headquarters are located in Canada.',
  '8. CONTROLLER',
  'Controller',
  "The 'Controller' is: Md.Rahat mia. The Controller may be contacted as follows:",
  'he Controller is in charge of determining the purposes for which personal information is processed and the means at the service of such processing',
  'Obligations of the Controller',
  'The Controller is committed to protecting the personal information collected, not to transmit it to third parties without informing you, and to respect the purposes for which personal information was collected.',
  'In the event that the integrity, confidentiality or security of your personal information is compromised, the Controller is committed to notify you.',
  '9. RIGHT OF OBJECTION AND OF WITHDRAWAL',
  "You have the right to object to the processing of your personal information by the Website ('right to object'). You also have the right to request that your personal information does not appear, for example, on a mailing list ('right to withdraw').",
  'If you wish to exercise the right to object or the right to withdraw, you must follow the procedure described hereinafter:',
  '10. RIGHT OF ACCESS, OF RECTIFICATION AND OF REMOVAL',
  'You have the right to consult, update, modify or request the removal of information about you by following the procedure described hereinafter:',
  'If you have a personal account, you may request its removal by following the procedure described hereinafter:',
  '11. GENERAL PRINCIPLES RELATING TO THE COLLECTION AND PROCESSING OF PERSONAL DATA UNDER EUROPEAN REGULATION 2016/679',
  'In accordance with the provisions of Article 5 of European Regulation 2016/679, the collection and processing of your personal data comply with the following principles:',
  'Lawfulness, fairness and transparency: your personal data may only be collected and processed with your consent. Every time your personal data is collected, you will be informed that your personal data is collected and for which reasons your personal data is collected;',
  'Limits purposes: the data is collected and processed for one or more purposes set out in this Privacy Policy;',
  'Data minimization: only personal data necessary for the purpose to which it is necessary is collected;',
  'Storage limited in time: personal data is stored for a limited time, of which you are notified;',
  'Integrity and confidentiality of collected and processed personal data: the Controller is committed to guarantee the integrity and confidentiality of the collected personal data',
  'In order to be lawful and to comply with Article 6 or European Regulation 2016/679, collection and processing will only occur if one of the following applies:',
  'You have given your express consent;',
  'Processing is necessary for the performance of a contract;',
  'Processing is necessary for compliance with a legal obligation;',
  'Processing is necessary in order to protect your vital interests or those of another physical person;',
  'Processing is necessary for the performance of a task carried out in the public interest or in the exercise of official authority;',
  'Processing is necessary for the purposes of the legitimate interests pursued by the Controller or a third party.',
  '12. DATA PROTECTION OFFICER PURSUANT TO EUROPEAN REGULATION 2016/679',
  'The following person has been appointed as the Data Protection Officer (also referred to as DPO): ',
  'The purpose of the Data Protection Officer is to ensure the successful implementation of the applicable European legislative provisions relating to the collection and processing of personal data.The Data Protection Officer may be reached as follows:',
  '13. ADDITIONAL RIGHTS PURSUANT TO EUROPEAN REGULATION 2016/679',
  'In accordance with European regulation relating to the processing of personal data, you also have the rights listed below',
  'In order for the Controller to grant your request, you must provide your first and last name, your email address, and if relevant, your personal account or membership number.',
  'The Controller must answer your request within a period of thirty (30) days.',
  'Right to portability of personal data',
  'You have the right to request the portability of your personal data held by the Website to another site by following the procedure described below:',
  'Right of not being the object of a decision based only on automated processing',
  'In accordance with the provisions of the European Regulation 2016/679, you have the right of not being the subject of decision based solely on automated processing if the decision produces legal affecting concerning you or significantly affects you.',
  'Right to submit a complaint to the competent authority',
  'In the event that the Controller does not answer your request, you wish to challenge his or her decision or you believe one of your rights has been infringed upon, you have the right to submit a complaint to the competent authority.',
  '14. SECURITY',
  'Personal information we collect is stored in a secured environment. People working for us are obligated to respect the confidentiality of your personal information.',
  'To ensure the security of your personal information, we use the following methods:',
  'We are committed to maintaining a high degree of confidentiality by integrating the latest technological innovations that allow us to ensure the confidentiality of your transactions. Nevertheless, no mechanism can ensure a complete security and transmitting personal information on the Internet always entail a part of risk.',
  '15. PERSONAL DATA OF MINORS UNDER EUROPEAN REGULATION 2016/679',
  'In accordance with the provisions of Article 8 of Regulation 2016/679, only minors over 15 years of age may consent to the processing of their personal data.',
  'If you are a minor under the age of 15, the consent of a legal representative is required in order for your personal data to be collected and processed.',
  'We reserve the right to verify by any means that you are over 15 years of age or that you have obtained the consent of a legal representative before using our Website.',
  '16. CHANGES TO OUR PRIVACY POLICY',
  'Our Privacy Policy may be viewed at all times at the following address:',
  'We reserve the right to modify our Privacy Policy in order to guarantee its compliance with the applicable law.',
  'You are therefore invited to regularly consult our Privacy Policy to be informed of the latest changes.',
  '17. ACCEPTANCE OF OUR PRIVACY POLICY',
  'By using our Website, you certify that you have read and understood this Privacy Policy and accept its conditions, more specifically conditions relating to the collection and processing of personal information, and the use of cookies.',
  '18. APPLICABLE LAW',
  'We are committed to respect the legislative provisions as specified in:',
]

export default {
  TermsData,
  PolicyData,
}
