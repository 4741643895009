import * as actionTypes from '../ActionTypes';
import axios from '../../../axios';
import { tokenHeadersMultipart } from '../HeaderConfig';


const filesUploadStart = () => {
    return {
        type: actionTypes.FILE_UPLOAD_START
    }
}

const filesUploadFail = (message) => {
    return {
        type: actionTypes.FILE_UPLOAD_ERROR,
        payload: { message }
    }
}

const filesUploadSuccess = (files, message) => {
    return {
        type: actionTypes.FILE_UPLOAD_SUCCESS,
        payload: { files, message }
    }
}

const uploadingPercentage = (percentage) => {
    return {
        type: actionTypes.FILE_UPLOAD_PERCENTAGE,
        payload: { percentage }
    }
}

export const uploadFiles = (files) => {
    return (dispatch, getState) => {
        dispatch(filesUploadStart());
        const url = '/upload/user-files';
        axios.post(url, files, {
            headers: tokenHeadersMultipart(getState).headers,
            onUploadProgress: (progressEvent) => {
                dispatch(uploadingPercentage(parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total).toString())));
            }
        }).then(response => {
            dispatch(filesUploadSuccess(response.data.files, response.data.message))
        }).catch(error => {
            if (error.response === undefined) return;
            dispatch(filesUploadFail(error.response.data.message));
        });
    }
}

export const uploadChatFiles = (files) => {
    return (dispatch, getState) => {
        dispatch(filesUploadStart());
        const url = '/upload/chat-files';
        axios.post(url, files, {
            headers: tokenHeadersMultipart(getState).headers,
            onUploadProgress: (progressEvent) => {
                dispatch(uploadingPercentage(parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total).toString())));
            }
        }).then(response => {
            dispatch(filesUploadSuccess(response.data.files, response.data.message))
        }).catch(error => {
            if (error.response === undefined) return;
            dispatch(filesUploadFail(error.response.data.message));
        });
    }
}