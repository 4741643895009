// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Contact_Contact__rrrnr {\n    margin-top: 75px;\n    margin-bottom: 30%;\n    display: flex;\n    justify-content: center;\n}\n\n.Contact_ContactMain__fI8yq {\n    width: 97%;\n    background-color: white;\n    border-radius: 5px;\n    box-shadow: 0px 2px 2px -3px rgba(48, 46, 46, 0.2), 0px 2px 2px 0px rgba(44, 42, 42, 0.14), 0px 2px 2px 0px rgba(49, 47, 47, 0.12);\n}\n\n.Contact_ContactMainHeader__3sUEs {\n    background-color: rgb(248, 248, 248);\n    padding: 10px 20px;\n    margin-bottom: 15px;\n\n}\n\n.Contact_ContactMainForm__12m03 {\n    margin: 15px 0px;\n}\n\n.Contact_ContactMainForm__12m03 form {\n    display: flex;\n    flex-flow: column;\n    width: 90%;\n    margin: 0 auto;\n}\n\n.Contact_ContactMainForm__12m03 form>label {\n    margin: 10px 0px;\n}\n\n@media only screen and (min-width: 501px) and (max-width: 800px) {\n    .Contact_ContactMain__fI8yq {\n        width: 90%;\n    }\n    .Contact_ContactMainForm__12m03 form {\n        width: 90%;\n    }\n    .Contact_ContactMainHeader__3sUEs {\n        padding: 15px 30px;\n    }\n}\n\n@media only screen and (min-width: 801px) and (max-width: 1025px) {\n    .Contact_ContactMain__fI8yq {\n        width: 80%;\n    }\n    .Contact_ContactMainForm__12m03 form {\n        width: 90%;\n    }\n    .Contact_ContactMainHeader__3sUEs {\n        padding: 15px 40px;\n    }\n}\n\n@media (min-width: 1025px) {\n    .Contact_ContactMain__fI8yq {\n        width: 700px;\n    }\n    .Contact_ContactMainForm__12m03 form {\n        width: 90%;\n    }\n    .Contact_ContactMainHeader__3sUEs {\n        padding: 15px 30px;\n    }\n}", ""]);
// Exports
exports.locals = {
	"Contact": "Contact_Contact__rrrnr",
	"ContactMain": "Contact_ContactMain__fI8yq",
	"ContactMainHeader": "Contact_ContactMainHeader__3sUEs",
	"ContactMainForm": "Contact_ContactMainForm__12m03"
};
module.exports = exports;
