// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FAQPage_FAQPage__1aiKY {\n  font-family: sans-serif;\n  margin: 13vh auto;\n  width: 85%;\n}\n.FAQPage_FAQPage__1aiKY h2{\n  text-align: center;\n  margin-bottom: 30px;\n}\n.FAQPage_bottomInfo__3mutI {\n  font-family: sans-serif;\n  margin: 30px 5%;\n  width: 85%;\n}\n.FAQPage_bottomInfo__3mutI p {\n  line-height: 2em;\n  font-size: 1em;\n}\n.FAQPage_bottomInfo__3mutI h3 {\n  font-size: 1.5em;\n  margin: 10px 0;\n}\n.FAQPage_FAQPage__1aiKY button {\n  padding: 10px;\n  text-align: left;\n  color: rgb(45, 56, 81);\n}\n.FAQPage_FAQItem__3gJ71 {\n  color: rgb(8, 8, 8);\n  line-height: 1.5em;\n}\n", ""]);
// Exports
exports.locals = {
	"FAQPage": "FAQPage_FAQPage__1aiKY",
	"bottomInfo": "FAQPage_bottomInfo__3mutI",
	"FAQItem": "FAQPage_FAQItem__3gJ71"
};
module.exports = exports;
