import React from 'react'
import classes from './Settings.module.css';
import { NormalNavigationItem } from '../../../components/Navigation/Toolbar/NavigationItems/NavigationItem/NavigationItem';
import { withTranslation } from 'react-i18next';

const SettingsNavigation = (props) => {
    const { t } = props;
    return <div className={classes.SettingsItems}>
        {/* <NormalNavigationItem link={`/settings/${props.user.username}/account`}>Account</NormalNavigationItem> */}
        <NormalNavigationItem link={`/settings/${props.user.username}/security`}>{t('security')}</NormalNavigationItem>
        <NormalNavigationItem link={`/settings/${props.user.username}/notifications`}>{t('notification')}</NormalNavigationItem>
        {/* <NormalNavigationItem link={`/settings/${props.user.username}/billing-info`}>Billing Information</NormalNavigationItem> */}
        {/* <NormalNavigationItem link={`/settings/${props.user.username}/payment-methods`}>Balance</NormalNavigationItem> */}
    </div>
}
export default withTranslation(['translation'])(SettingsNavigation);

