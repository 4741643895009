import React from 'react'
import {Translator} from 'react-auto-translate'
import {LangObj} from '../assets/langs'
const LanguageDetect = require('languagedetect')

const Translation = ({input, text, from}) => {
  const lngDetector = new LanguageDetect()
  var detected = from
    ? from
    : lngDetector.detect(text)[0]
    ? LangObj[lngDetector.detect(text)[0][0]] ?? 'fr'
    : 'fr'
  var dt =
    localStorage.getItem('translateTo') === detected ? (
      input
    ) : (
      <Translator
        from={detected}
        to={localStorage.getItem('translateTo')}
        googleApiKey={process.env.REACT_APP_GOOGLE_TRANSLATE_KEY}>
        {input}
      </Translator>
    )
  return dt
}

export default Translation
