import React from 'react';
import { useTranslation } from 'react-i18next'
import classes from '../CreateAdd.module.css';
import Button from '../../../../components/UI/Button/Button';
const backgroundColors = ['#4dabf7', 'rgb(170, 235, 73)', 'tomato'];

export const SinglePackageItem = ({
    isClicked,
    packageType,
    selectedPackage,
    values,
    handlePackagesData,
    handleAddTasksFields,
    handleTasksInputChange, handleRemoveTasksFields }) => {
    const style = {
        background: backgroundColors[selectedPackage - 1]
    }
    const packageData = values.packagesData[selectedPackage - 1];

    const { t } = useTranslation(['translation', 'MyAds'])
    return <div className={classes.PackageDescriptionItem}>
        <div className={classes.PackageTypeHeader} style={style}>{packageType}</div>
        <div className={classes.DividerWithoutStyle}></div>
        <div className={classes.DividerWithoutStyle}></div>
        <div className={classes.PackageDescriptionItemName}>
            <textarea
                className="form-control"
                type='text'
                minLength='10'
                maxLength='50'
                value={packageData.name}
                placeholder={t('MyAds:CreateAdd.Step2.SinglePackageItem.0')}
                onChange={(event) => handlePackagesData(event, selectedPackage, 'name')}
            ></textarea>
            <div className={classes.ErrorInfo}>
                <strong className={classes.Info}>{packageData.name.length}/50 (max-50,min-10)</strong>
                {packageData.name.length < 10 && isClicked ? <strong className={classes.Error}>{t('MyAds:CreateAdd.Step2.errors.0')}</strong> : null}
            </div>
        </div>
        <div className={classes.DividerWithoutStyle}></div>
        <textarea
            className="form-control"
            maxLength='500'
            value={packageData.description}
            type='text'
            placeholder={t('MyAds:CreateAdd.Step2.SinglePackageItem.1')}
            onChange={(event) => handlePackagesData(event, selectedPackage, 'description')}
        ></textarea>
        <div className={classes.ErrorInfo}>
            {packageData.description.length === 0 && isClicked ? <strong className={classes.Error}>{t('MyAds:CreateAdd.Step2.errors.1')}</strong> : null}
        </div>
        <div className={classes.DividerWithoutStyle}></div>
        <label>{t('MyAds:CreateAdd.Step2.SinglePackageItem.2')}</label>
        <div className={classes.DividerWithoutStyle}></div>
        <div className={classes.PackageTask}>
            <input
                placeholder={t('MyAds:CreateAdd.Step2.SinglePackageItem.8')}
                maxLength='100'
                type='text'
                value={values.packageTask[selectedPackage - 1].task}
                name='confirmed_tasks'
                onChange={event => handleTasksInputChange(event, selectedPackage)}
                className="form-control"
            />
        </div>
        <div className={classes.AddOrRemoveButton}>
            <button
                onClick={_ => handleAddTasksFields(selectedPackage)}
                className='btn btn-sm btn-outline-primary'>
                <i className="fas fa-plus mx-1"></i>{t('MyAds:CreateAdd.Step2.SinglePackageItem.9')}
            </button>
        </div>
        {packageData.tasks.length !== 0 ?
            <React.Fragment>
                <div className={classes.TaskDivider}></div>
                <label>{t('MyAds:CreateAdd.Step2.SinglePackageItem.3')}</label>
                <div className={classes.TaskDivider}></div>
                {packageData.tasks.map((task, index) =>
                    <div className={classes.PackageTaskItem} key={index}>
                        <div>
                            <div className={classes.PackageTaskItemIndex}>{index + 1}</div>
                        </div>
                        <div className={classes.PackageTaskItemValue}>
                            <span>{task}</span>
                        </div>
                        <div className={classes.PackageTaskRemove}>
                            <Button
                                buttonType='Google'
                                clicked={(e) => handleRemoveTasksFields(selectedPackage, index)}>
                                <i className="fas fa-times"></i>
                            </Button>
                        </div>
                    </div>
                )}
            </React.Fragment> :
            null}
        <div className={classes.ErrorInfo}>
            {packageData.tasks.length === 0 && isClicked ? <strong className={classes.Error}>{t('MyAds:CreateAdd.Step2.errors.2')}</strong> : null}
        </div>
        <div className={classes.DividerWithoutStyle}></div>
        <label>{t('MyAds:CreateAdd.Step2.SinglePackageItem.4')}</label>
        <div className={classes.DividerWithoutStyle}></div>
        <input
            type='text'
            name='delivery_time'
            placeholder='Days'
            value={packageData.delivery_time}
            onChange={(event) => handlePackagesData(event, selectedPackage, 'delivery_time')}
            className="form-control"
        />
        <div className={classes.ErrorInfo}>
            {packageData.delivery_time.length === 0 && isClicked && <strong className={classes.Error}>{t('MyAds:CreateAdd.Step2.errors.3')}</strong>}
        </div>
        <div className={classes.DividerWithoutStyle}></div>
        <label>{t('MyAds:CreateAdd.Step4.secondSection.5')}</label>
        <div className={classes.DividerWithoutStyle}></div>
        <input
            type='text'
            name='price'
            placeholder={t('MyAds:CreateAdd.Step2.SinglePackageItem.7')}
            value={packageData.price}
            onChange={(event) => handlePackagesData(event, selectedPackage, 'price')}
            className="form-control"
        />
        <div className={classes.ErrorInfo}>
            {packageData.price.length === 0 && isClicked && <strong className={classes.Error}>{t('MyAds:CreateAdd.Step2.errors.5')}</strong>}
        </div>
    </div>
}