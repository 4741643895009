import React from 'react';
import classes from './CountDown.module.css';

export const CountDown = ({ seconds, hours, days, minutes }) => {

    const addZeroToDays = (str) => {
        if (str.toString().length === 2) {
            return '0' + str;
        }
        else if (str.toString().length === 1) {
            return '00' + str;
        }
        else {
            return str
        }
    }
    const second1 = seconds > 9 ? seconds.toString().charAt(0) : 0;
    const second2 = seconds > 9 ? seconds.toString().charAt(1) : seconds.toString().charAt(0);
    const hours1 = hours > 9 ? hours.toString().charAt(0) : 0;
    const hours2 = hours > 9 ? hours.toString().charAt(1) : hours.toString().charAt(0) ;
    const minutes1 = minutes > 9 ? minutes.toString().charAt(0) : 0;
    const minutes2 = minutes > 9 ? minutes.toString().charAt(1) : minutes.toString().charAt(0);
    const daysValue = addZeroToDays(days);
   
    return <div className={classes.CountDownContainer}>
        <div className={classes.CountDownItemMain}>
            <div><h5>Days</h5></div>
            <div className={classes.CountDownItem}>
                <div className={classes.CountDownBox}>{daysValue.toString().charAt(0)}</div>
                <div className={classes.CountDownBox}>{daysValue.toString().charAt(1)}</div>
                <div className={classes.CountDownBox}>{daysValue.toString().charAt(2)}</div>
            </div>
        </div>
        <div className={classes.CountDownItemMain}>
            <div><h5>Hours</h5></div>
            <div className={classes.CountDownItem}>
                <div className={classes.CountDownBox}>{hours1}</div>
                <div className={classes.CountDownBox}>{hours2}</div>
            </div>
        </div>
        <div className={classes.CountDownItemMain}>
            <div><h5>Minutes</h5></div>
            <div className={classes.CountDownItem}>
                <div className={classes.CountDownBox}>{minutes1}</div>
                <div className={classes.CountDownBox}>{minutes2}</div>
            </div>
        </div>
        <div className={classes.CountDownItemMain}>
            <div><h5>Seconds</h5></div>
            <div className={classes.CountDownItem}>
                <div className={classes.CountDownBox}>{second1}</div>
                <div className={classes.CountDownBox}>{second2}</div>
            </div>
        </div>
    </div>
}
