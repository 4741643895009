import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Step1, Step2, Step3, Step4, Step5, Step6, Step7, Step8 } from './AddFormSteps';
import classes from './CreateAdd.module.css';
import * as actions from '../../../store/actions';
import { connect } from 'react-redux';

export class CreateAddForm extends Component {
  constructor(props) {
    super(props)
    const { t } = this.props
    this.state = {
      step: 1,
      packageTask: [
        { task: '' },
        { task: '' },
        { task: '' },
      ],
      title: t('MyAds:CreateAdd.Step1.secondSection.fields.default'),
      description: '',
      select: [],
      location: '',
      types: [],
      sub_types: [],
      values: '',
      visibility: {
        value: '',
        selectedVisibility: []
      },
      audience: {
        value: '',
        averageAge: '',
        gender: {
          man: '',
          women: ''
        },
        language: '',
        types: []
      },
      domains: [],
      selectedIndex: null,
      numberOfPackage: 1,
      packagesData: [
        {
          type: 'BASIC',
          id: 0,
          name: '',
          description: '',
          delivery_time: '',
          price: '',
          tasks: [],
        },
        {
          type: 'STANDARD',
          id: 1,
          name: '',
          description: '',
          delivery_time: '',
          price: '',
          tasks: [],
        },
        {
          type: 'PREMIUM',
          id: 2,
          name: '',
          description: '',
          delivery_time: '',
          price: '',
          tasks: [],
        },
      ],
      selectedPackage: 1,
      advertiseSpecification: [
        { rule: '' },
        { rule: '' },
        { rule: '' },
        { rule: '' },
        { rule: '' },
      ],
      whatWant: '',
      preference: '',
      whatAccept: '',
      extra: '',
      filesPreviews: { images: [], video: [] },
      removedFiles: [],
      existingFiles: [],
      files: { images: [], video: [] },
      filesSizeError: false,
      _filesPreviews: { images: [], video: [] },
      _removedFiles: [],
      _existingFiles: [],
      _files: { images: [], video: [] },
      _isSizeLarge: false,
      advertiseId: null,
      modeUpdated: false,
      is_drafted: true
    }
  }

  componentDidMount() {
    this.props.onFetchTypeAndSubTypes();

    if (this.props.location.search) {
      const search = this.props.location.search;
      const params = new URLSearchParams(search);

      if (params.get('adId')) {
        this.props.onfetchSingleAdvertise(params.get('adId'), true);
        this.setState({ advertiseId: params.get('adId') });
      }
    }

    // if (localStorage.getItem('adsData')) {
    //   const adsData = JSON.parse(localStorage.getItem('adsData'));
    //   const drafts = JSON.parse(localStorage.getItem('drafts')) ? JSON.parse(localStorage.getItem('drafts')) : [];

    //   if (drafts.findIndex(draft => draft.title === adsData.title) === -1) {
    //     localStorage.setItem('drafts', JSON.stringify([...drafts, adsData]));
    //   }
    // }
  }

  getFileType = (file) => {
    let splitArray = file.split('.')
    return file.split('.')[splitArray.length - 1].toLowerCase();
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    const adsData = JSON.parse(localStorage.getItem('adsData'));

    if (adsData && !this.props.location.search) {
      const { title, description, types, sub_types, audience, visibility, location, packagesData, domains, advertiseSpecification, whatWant, preference, whatAccept, extra } = adsData;

      this.setState({
        title, description, types, sub_types, audience, visibility, location, packagesData, domains, advertiseSpecification, whatWant, preference, whatAccept, extra
      });
    }
  }

  nextStep = () => {
    const { step } = this.state;
    this.setState({ step: step + 1 });
  }

  prevStep = () => {
    const { step } = this.state;
    this.setState({ step: step - 1 });
  }

  onReturnOnlyDigit = (value) => value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');

  handleTextInputChange = (event, name) => {
    if (name === 'title') {
      this.setState({ title: event.target.value })
    } else if (name === 'description') {
      this.setState({ description: event })
    } else if (name === 'whatWant') {
      this.setState({ whatWant: event.target.value })
    } else if (name === 'preference') {
      this.setState({ preference: event.target.value })
    } else if (name === 'whatAccept') {
      this.setState({ whatAccept: event.target.value })
    } else if (name === 'extra') {
      this.setState({ extra: event.target.value })
    }
  }

  onAddVisibilityAndAudience = (data) => this.setState({ visibility: data.visibility, audience: data.audience });

  handleAudienceAndVisibilityInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const visibility = { ...this.state.visibility };
    const audience = { ...this.state.audience };
    if (name === 'audience') {
      audience.value = this.onReturnOnlyDigit(value);
    } else if (name === 'visibility') {
      visibility.value = this.onReturnOnlyDigit(value);
    } else if (name === 'man') {
      audience.gender.man = this.onReturnOnlyDigit(value);
    } else if (name === 'women') {
      audience.gender.women = this.onReturnOnlyDigit(value);
    } else if (name === 'language') {
      audience.language = value;
    } else if (name === 'averageAge') {
      audience.averageAge = this.onReturnOnlyDigit(value);
    }
    this.setState({ visibility, audience })
  }

  addOtherAudience = (e, other) => {
    e.preventDefault();

    const audience = { ...this.state.audience };
    audience.types.push({ type: other });
    this.setState({ audience });
  }

  removeOtherAudience = (audienceType) => {
    const audience = { ...this.state.audience };
    audience.types = audience.types.filter(audType => audType.type !== audienceType);
    this.setState({ audience })
  }

  onChangeVisibility = (event, type) => {
    const visibility = { ...this.state.visibility };
    const index = visibility.selectedVisibility.findIndex(visibility => visibility.type === type)
    visibility.selectedVisibility[index].value = this.onReturnOnlyDigit(event.target.value);

    this.setState({ visibility });
  }

  onAddAudience = (audienceType) => {
    const audience = { ...this.state.audience };
    const index = audience.types.findIndex(audience => audience.type === audienceType.type);
    if (index === -1) {
      audience.types.push(audienceType);
    } else if (index !== -1) {
      audience.types.splice(index, 1);
    }
    this.setState({ audience });
  }

  onAddVisibility = (visible) => {
    const visibility = { ...this.state.visibility };
    const index = visibility.selectedVisibility.findIndex(visibility => visibility.name === visible.name);
    if (index === -1) {
      visibility.selectedVisibility.push(visible);
    } else if (index !== -1) {
      visibility.selectedVisibility.splice(index, 1);
    }
    this.setState({ visibility });
  }


  getSelectedOption = (event, name) => {
    const index = event.target.value;
    if (name === 'type') {
      if (this.state.types.findIndex(type => this.state.select[index].type === type) === -1) {
        this.state.types.push(this.state.select[index].type);
      }
      this.setState({
        selectedIndex: index,
        types: this.state.types
      })
    } else if (name === 'sub_type') {
      const subTypes = [...this.state.select[this.state.selectedIndex].sub_type]
      if (this.state.sub_types.findIndex(subType => subTypes[index] === subType) === -1) {
        this.state.sub_types.push(subTypes[index]);
      }
      this.setState({ sub_types: this.state.sub_types })
    }
  }

  onRemoveType = (type) => this.setState({ types: this.state.types.filter(_type => type !== _type) });

  onRemoveSubType = (sub_type) => this.setState({ sub_types: this.state.sub_types.filter(_sub_type => sub_type !== _sub_type) });


  addTypeOrSubType = (event, value, name) => {
    event.preventDefault();
    if (name === 'type' && value) {
      if (this.state.types.findIndex(type => value === type) === -1) {
        this.state.types.push(value);
      }
      this.setState({
        types: this.state.types
      })
    } else if (name === 'subType' && value) {
      if (this.state.sub_types.findIndex(subType => value === subType) === -1) {
        this.state.sub_types.push(value);
      }
      this.setState({ sub_types: this.state.sub_types });
    }
  }


  getSelectedNumberOfPackage = (event) => {
    this.setState({
      numberOfPackage: parseInt(event.target.value)
    })
  }

  handlePackagesData = (event, packageType, text) => {
    const packageData = [...this.state.packagesData];
    let selectedPackage = this.state.selectedPackage;
    if (text === 'delivery_time' || text === 'price') {
      packageData[packageType - 1][text] = this.onReturnOnlyDigit(event.target.value);
    } else {
      packageData[packageType - 1][text] = event.target.value;
    }

    this.setState({
      packagesData: packageData,
      selectedPackage: selectedPackage,
    });
  }

  handleTasksInputChange = (event, selectedPackage) => {
    const packageTask = [...this.state.packageTask];
    packageTask[selectedPackage - 1].task = event.target.value;
    this.setState({ packageTask });
  }

  handleAddTasksFields = (packageType) => {
    const values = [...this.state.packagesData];
    const packageTask = [...this.state.packageTask];
    if (packageTask[packageType - 1].task.length !== 0) {
      values[packageType - 1].tasks.push(packageTask[packageType - 1].task);
    }
    packageTask[packageType - 1].task = '';

    this.setState({ packagesData: values, packageTask })
  }

  handleRemoveTasksFields = (packageType, index) => {
    const values = [...this.state.packagesData];
    values[packageType - 1].tasks.splice(index, 1);

    this.setState({ packagesData: values })
  }

  advertiseSpecificationData = (event, index) => {
    const advertiseSpecification = [...this.state.advertiseSpecification];
    advertiseSpecification[index].rule = event.target.value;
    this.setState({
      advertiseSpecification: advertiseSpecification,
    })
  }

  handleAdvertiseFiles = (event, type) => {
    if (
      event.target.files.length + this.state.filesPreviews.images.length > 5 &&
      type === 'images'
    )
      return alert('Your max files limit is five');

    const previews = { ...this.state.filesPreviews };
    const files = { ...this.state.files };

    let isSizeLarge = false;

    for (let i = 0; i < event.target.files.length; i++) {
      const size = (event.target.files[i].size / (1024 * 1024)).toFixed(2);
      if ((parseInt(size) > 10 && type === 'images') || (parseInt(size) > 100 && type === 'video')) {
        return alert('Max file size limit error.');
      }
      files[type].push({ file: event.target.files[i] });

      // const reader = new FileReader();
      // reader.addEventListener('load', () => localStorage.setItem(`image${i}`, reader.result), false);
      // reader.readAsDataURL(event.target.files[i]);

      previews[type].push({ url: URL.createObjectURL(event.target.files[i]), file: event.target.files[i] });
    }
    this.setState({
      filesSizeError: isSizeLarge,
      filesPreviews: previews,
      files: files,
    })
  }

  handleRemoveFile = (url, type, index) => {
    const previews = { ...this.state.filesPreviews };
    const files = { ...this.state.files };
    const removedFiles = [...this.state.removedFiles];
    const existingFiles = [...this.state.existingFiles];

    const filteredPreviews = previews[type].filter(previewFile => previewFile.url !== url);

    const removedIndex = existingFiles.findIndex(_url => _url === url);
    if (removedIndex !== -1) {
      removedFiles.push(url);
      // @ts-ignore
      existingFiles.shift(removedIndex, 1);
    }

    files[type].shift(index, 1);
    previews[type] = filteredPreviews;
    this.setState({ filesPreviews: previews, files, removedFiles, existingFiles });
  }

  handlePreferencesFiles = (event, type) => {
    if (
      event.target.files.length + this.state._filesPreviews.images.length > 3 &&
      type === 'images'
    )
      return alert('Your max files limit is three');
    if (
      event.target.files.length + this.state._filesPreviews.video.length > 2 &&
      type === 'video'
    )
      return alert('Your max files limit is two');

    const _filesPreviews = { ...this.state._filesPreviews }
    const _files = { ...this.state._files }

    let _isSizeLarge = false;

    for (let i = 0; i < event.target.files.length; i++) {
      const size = (event.target.files[i].size / (1024 * 1024)).toFixed(2);
      if (parseInt(size) > 10) _isSizeLarge = true;
      _files[type].push({ file: event.target.files[i] });
      _filesPreviews[type].push({ url: URL.createObjectURL(event.target.files[i]) });
    }
    this.setState({ _isSizeLarge, _filesPreviews, _files });
  }

  preferencesRemoveFile = (url, type, index) => {
    const _filesPreviews = { ...this.state._filesPreviews };
    const _files = { ...this.state._files };
    const _removedFiles = [...this.state._removedFiles];
    const _existingFiles = [...this.state._existingFiles];

    const filteredPreviews = _filesPreviews[type].filter(previewFile => previewFile.url !== url);

    const removedIndex = _existingFiles.findIndex(_url => _url === url);
    if (removedIndex !== -1) {
      _removedFiles.push(url);
      // @ts-ignore
      _existingFiles.shift(removedIndex, 1);
    }

    _files[type].shift(index, 1);
    _filesPreviews[type] = filteredPreviews;
    this.setState({ _filesPreviews, _files, _removedFiles, _existingFiles });
  }
  onSelectDomains = (selectedList, selectedItem) => this.setState({ domains: selectedList });

  handleFormSubmit = (advertiseId) => {
    const advertiseForm = new FormData();

    advertiseForm.append('title', this.state.title);
    advertiseForm.append('description', this.state.description);
    advertiseForm.append('types', JSON.stringify(this.state.types));
    advertiseForm.append('sub_types', JSON.stringify(this.state.sub_types));
    advertiseForm.append('audience', JSON.stringify(this.state.audience));
    advertiseForm.append('visibility', JSON.stringify(this.state.visibility));
    advertiseForm.append('location', JSON.stringify(this.state.location));
    advertiseForm.append('is_subscribed', this.props.user.is_subscribed);
    advertiseForm.append('whatWant', this.state.whatWant);
    advertiseForm.append('domains', JSON.stringify(this.state.domains));
    advertiseForm.append('preference', this.state.preference);
    advertiseForm.append('whatAccept', this.state.whatAccept);
    advertiseForm.append('extra', this.state.extra);
    advertiseForm.append('is_drafted', JSON.stringify(this.state.step === 7 ? false : true));

    const filteredPackages = this.state.packagesData.filter(packageData => packageData.tasks.length !== 0);
    advertiseForm.append('packagesData', JSON.stringify(filteredPackages));

    for (let i = 0; i < this.state.files.images.length; i++) {
      advertiseForm.append('advertiseFiles', this.state.files.images[i].file)
    }

    if (this.state.files.video[0]) {
      advertiseForm.append('advertiseFiles', this.state.files.video[0].file);
    }
    // const filteredAdvertiseSpecification = this.state.advertiseSpecification.filter(
    //   (specification) => specification.rule.length !== 0);
    advertiseForm.append(
      'advertiseSpecification',
      JSON.stringify(this.state.advertiseSpecification),
    )

    console.log(this.state.modeUpdated)
    console.log(advertiseId);


    if (this.state.modeUpdated || advertiseId) {
      advertiseForm.append('removedFiles', JSON.stringify(this.state.removedFiles));
      advertiseForm.append('existingFiles', JSON.stringify(this.state.existingFiles));
      this.props.onUpdateAdvertiseForm(advertiseForm, advertiseId ? advertiseId : this.state.advertiseId);
      console.log('called 1');
    } else {
      console.log('called 2');
      this.props.onSubmitAdvertiseForm(advertiseForm);
    }
  }

  getAddress = (location) => this.setState({ location });

  componentDidUpdate(prevProps, prevState) {
    const { title, description, types, sub_types, audience, visibility, location, packagesData, domains, advertiseSpecification, whatWant, preference, whatAccept, extra } = prevState;

    if (prevProps.advertiseId !== this.props.advertiseId)
      this.setState({ advertiseId: this.props.advertiseId });


    if (prevState.step !== this.state.step && !this.props.location.search) {

      let id = localStorage.getItem('adsData') ? JSON.parse(localStorage.getItem('adsData')).advertiseId : null;

      this.handleFormSubmit(this.props.advertiseId ? this.props.advertiseId : id);

      localStorage.setItem('adsData', JSON.stringify({
        advertiseId: id ? id : this.props.advertiseId,
        title, description, types, sub_types, audience, visibility, location, packagesData, domains, advertiseSpecification, whatWant, preference, whatAccept, extra
      }));

    }

    if (prevProps.singleAdvertiseData !== this.props.singleAdvertiseData && this.props.location.search) {

      const { title, description, types, subtypes, audience, visibility, location, packages, domains, specification, whatWant, preference, whatAccept, extra, files, areaCoverage } = this.props.singleAdvertiseData.advertise;
      const images = [];
      const video = [];
      const packagesData = [...this.state.packagesData];

      files.forEach(url => {
        if (this.getFileType(url) === 'mp4')
          video.push({ url });
        else
          images.push({ url });
      });

      const allFiles = { images, video };
      packages.forEach((_, index) => packagesData[index] = packages[index]);
      // if (this.props.advertiseData.length > 0) {
      const markerPosition = {
        lng: areaCoverage.coordinates[0],
        lat: areaCoverage.coordinates[1]
      }
      location.coordinates = markerPosition;

      this.setState({
        title, description, types, sub_types: subtypes, audience, visibility, location, packagesData, domains, advertiseSpecification: specification, whatWant, preference, whatAccept, extra, filesPreviews: allFiles, existingFiles: files, modeUpdated: true
      });
    }
  }

  handleCroppedFiles = (index, file) => {
    const filesPreviews = { ...this.state.filesPreviews };

    filesPreviews.images[index] = { file: file, url: URL.createObjectURL(file) };
    this.setState({ filesPreviews });
  }
  // check for back click
  // window.onpopstate = event => {
  //   }

  resetLocation = () => this.setState({ location: '' });
  componentWillUnmount() {
    localStorage.removeItem('adsData');
  }

  headerTitle = (modeUpdated, t) => modeUpdated ? <h4>{t('MyAds:CreateAdd.title2')}</h4> : <h4>{t('MyAds:CreateAdd.title1')}</h4>;


  render() {
    const { step } = this.state;
    if (this.props.advertiseData.length > 0 && this.state.select.length === 0) {
      this.setState({ select: this.props.advertiseData })
    }
    const { t, screenWidth } = this.props;
    const {
      numberOfPackage,
      packageTask,
      title,
      description,
      domains,
      audience,
      visibility,
      location,
      select,
      types,
      sub_types,
      selectedIndex,
      packagesData,
      selectedPackage,
      advertiseSpecification,
      filesPreviews,
      filesSizeError,
      files,
      whatWant,
      preference,
      whatAccept,
      extra,
      _filesPreviews,
      _files,
      _isSizeLarge,
      modeUpdated
    } = this.state;

    const values = {
      numberOfPackage,
      packageTask,
      title,
      description,
      domains,
      location,
      audience,
      visibility,
      select,
      types,
      sub_types,
      selectedIndex,
      packagesData,
      selectedPackage,
      advertiseSpecification,
      filesPreviews,
      filesSizeError,
      files,
      screenWidth,
      whatWant,
      preference,
      whatAccept,
      extra,
      _filesPreviews,
      _files,
      _isSizeLarge,
      modeUpdated
    }
    switch (step) {
      case 1:
        return <div className={classes.CreateAdd}>
          <div className={classes.CreateAddItems}>
            <div className={classes.CreateAddItemsStyle}>
              {this.headerTitle(modeUpdated, t)}
              <br />
              <Step1
                nextStep={this.nextStep}
                onSelectDomains={this.onSelectDomains}
                handleTextInputChange={this.handleTextInputChange}
                getSelectedOption={this.getSelectedOption}
                onRemoveType={this.onRemoveType}
                onRemoveSubType={this.onRemoveSubType}
                addTypeOrSubType={this.addTypeOrSubType}
                values={values}
                error={this.props.error}
                message={this.props.message}
                step={this.state.step}
                t={t}
              />
            </div>
          </div>
        </div>
      case 2:
        return <div className={classes.CreateAdd}>
          <div className={classes.CreateAddItems}>
            <div className={classes.CreateAddItemsStyle}>
              {this.headerTitle(modeUpdated, t)}
              <br />
              <Step2
                nextStep={this.nextStep}
                prevStep={this.prevStep}
                addOtherAudience={this.addOtherAudience}
                removeOtherAudience={this.removeOtherAudience}
                handleAudienceAndVisibilityInputChange={this.handleAudienceAndVisibilityInputChange}
                onChangeVisibility={this.onChangeVisibility}
                onAddVisibility={this.onAddVisibility}
                onAddAudience={this.onAddAudience}
                values={values}
                step={this.state.step}
                t={t}
              />
            </div>
          </div>
        </div>
      case 3:
        return <div className={classes.CreateAdd}>
          <div className={classes.CreateAddItems}>
            <div className={classes.CreateAddItemsStyle}>
              {this.headerTitle(modeUpdated, t)}
              <br />
              <Step3
                nextStep={this.nextStep}
                prevStep={this.prevStep}
                getSelectedNumberOfPackage={this.getSelectedNumberOfPackage}
                handlePackagesData={this.handlePackagesData}
                handleAddTasksFields={this.handleAddTasksFields}
                handleTasksInputChange={this.handleTasksInputChange}
                handleRemoveTasksFields={this.handleRemoveTasksFields}
                values={values}
                step={this.state.step}
                t={t}
              />
            </div>
          </div>
        </div>
      case 4:
        return <div className={classes.CreateAdd}>
          <div className={classes.CreateAddItems}>
            <div className={classes.CreateAddItemsStyle}>
              {this.headerTitle(modeUpdated, t)}
              <br />
              <Step4
                nextStep={this.nextStep}
                prevStep={this.prevStep}
                handleTextInputChange={this.handleTextInputChange}
                handlePreferencesFiles={this.handlePreferencesFiles}
                preferencesRemoveFile={this.preferencesRemoveFile}
                values={values}
                step={this.state.step}
                t={t}
              />
            </div>
          </div>
        </div>
      case 5:
        return <div className={classes.CreateAdd}>
          <div className={classes.CreateAddItems}>
            <div className={classes.CreateAddItemsStyle}>
              {this.headerTitle(modeUpdated, t)}
              <br />
              <Step5
                nextStep={this.nextStep}
                prevStep={this.prevStep}
                advertiseSpecificationData={this.advertiseSpecificationData}
                handleAdvertiseFiles={this.handleAdvertiseFiles}
                handleRemoveFile={this.handleRemoveFile}
                handleCroppedFiles={this.handleCroppedFiles}
                values={values}
                step={this.state.step}
                t={t}
              />
            </div>
          </div>
        </div>
      case 6:
        return <div className={classes.CreateAdd}>
          <div className={classes.CreateAddItems}>
            <div className={classes.CreateAddItemsStyle}>
              {this.headerTitle(modeUpdated, t)}
              <br />
              <Step6
                nextStep={this.nextStep}
                prevStep={this.prevStep}
                values={values}
                getAddress={this.getAddress}
                resetLocation={this.resetLocation}
                step={this.state.step}
                t={t}
              />
            </div>
          </div>
        </div>
      case 7:
        return <div className={classes.CreateAdd}>
          <div className={classes.CreateAddItems}>
            <div className={classes.CreateAddItemsStyle}>
              {this.headerTitle(modeUpdated, t)}
              <br />
              <Step7
                nextStep={this.nextStep}
                prevStep={this.prevStep}
                values={values}
                handleFormSubmit={this.handleFormSubmit}
                step={this.state.step}
                t={t}
              />
            </div>
          </div>
        </div>
      case 8:
        return <div className={classes.CreateAdd}>
          <div className={classes.CreateAddItems}>
            <div className={classes.CreateAddItemsStyle}>
              {this.headerTitle(modeUpdated, t)}
              <br />
              <Step8
                nextStep={this.nextStep}
                prevStep={this.prevStep}
                history={this.props.history}
                step={this.state.step}
                loading={this.props.loading}
                error={this.props.error}
                message={this.props.message}
                loading_percentage={this.props.loading_percentage}
                t={t}
                shortId={this.props.shortId}
              />
            </div>
          </div>
        </div>
      default:
        console.log('This is a multi-step form built with React.')
    }
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userAuth.user,
    loading: state.dashboardAdvertise.loading,
    error: state.dashboardAdvertise.error,
    message: state.dashboardAdvertise.message,
    advertiseData: state.dashboardAdvertise.advertiseData,
    advertiseId: state.dashboardAdvertise.advertiseId,
    shortId: state.dashboardAdvertise.shortId,
    loading_percentage: state.dashboardAdvertise.loading_percentage,
    screenWidth: state.responsiveness.screenWidth,
    singleAdvertiseData: state.normalAdvertise.singleAdvertiseData,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchTypeAndSubTypes: () => dispatch(actions.fetchTypeAndSubtypes()),
    onSubmitTypeAndSubtype: (typeAndSubtypes) =>
      dispatch(actions.updateTypeAndSubtypes(typeAndSubtypes)),
    onSubmitAdvertiseForm: (body) =>
      dispatch(actions.onSubmitAdvertiseForm(body)),
    onUpdateAdvertiseForm: (body, advertiseId) =>
      dispatch(actions.onUpdateAdvertiseForm(body, advertiseId)),
    onfetchSingleAdvertise: (advertiseId, modeToUpdated) => dispatch(actions.onfetchSingleAdvertise(advertiseId, modeToUpdated))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation(['translation', 'MyAds'])(CreateAddForm))
