import React from 'react';
import { Box } from '@material-ui/core';
import clsx from 'clsx';
import moment from 'moment';
import useStyles from '../index.style';
import CmtImage from '../../coremat/CmtImage';
// import MediaViewer from '../../MediaViewer';
import { linkify } from '../../coremat/commonHelper';
import styles from '../../Chat.module.css';
// import { filetypes } from '../../../../../assets/file_types';
import { getFileType } from '../../../../../shared/utility';

const SentMessageCell = ({ conversation }) => {
  // const [position, setPosition] = useState(-1);
  // const handleClose = () => {
  //   setPosition(-1);
  // };

  const classes = useStyles();

  const images = ['jpg', 'png', 'jpeg', 'gif'];
  const docs = ['csv', 'xlsx', 'xls', 'pdf', 'doc', 'zip'];

  return <Box className={clsx(classes.chatMsgItem, classes.sentMsgItem)}>
    <Box className={classes.chatMsgContent}>
      <Box className={[classes.chatBubble, styles.Message].join(' ')}>
        {conversation.messageType === 'text' ?
          <Box
            component="p"
            dangerouslySetInnerHTML={{
              __html: linkify(conversation.message.replace(/(?:\r\n|\r|\n)/g, '<br />')),
            }}
          />
          :
          <Box className={classes.chatBubbleImg}>
            <Box className={classes.chatBubbleImgRow}>
              {JSON.parse(conversation.message).map((file, index) => (
                <Box key={index} className={classes.chatBubbleImgItem} onClick={() => window.open(
                  file.url, "_blank")}>
                  <Box className={classes.chatBubbleImgItemInner}>
                    {images.findIndex(imageFile => imageFile === getFileType(file.name)) !== -1 ?
                      <CmtImage
                        key={index}
                        src={file.url}
                        alt='img'
                        title={file.name}
                        height={100}
                        width={100}
                      />
                      : docs.findIndex(docFile => docFile === getFileType(file.name)) !== -1 ?
                        <CmtImage
                          key={index}
                          title={file.name}
                          // src={filetypes[getFileType(file.name)]}
                          alt='img'
                          height={100}
                          width={100}
                        />
                        :
                        <iframe key={index} src={file.url} title={file.name} height={100} width={100} />
                    }
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        }
      </Box>
      <Box className={classes.chatTime}>{moment(conversation.sentAt).format('hh:mm:ss')}</Box>
    </Box>
    {/* <MediaViewer position={position} medias={conversation.media} handleClose={handleClose} /> */}
  </Box>
};

export default SentMessageCell;
