import React from 'react'
import { Link } from 'react-router-dom';
import classes from '../Tender.module.css';
import { getDate } from '../../../../shared/utility';

export const TenderItem = ({ tender, type, onChangeTenderMode, onDeleteTender }) => {
    return <div className={classes.PartRightItem}>
        <div className={classes.PartRightItems}>
            <div className='col-lg-10 col-12'>
                <Link to={`/tender-description?info_id=${tender._id}`}>
                    <h6>{tender.title}</h6>
                </Link>
                <div className='my-2'>
                    <span>{tender.description.length > 300 ? tender.description.trim().substr(0, 300) + '...' : tender.description}</span>
                </div>
            </div>
            <div className='col-lg-2'>
                <h6>{'$(' + tender.budget.budgetMin + ' -' + tender.budget.budgetMax + ')/' + tender.budget.fixed + ' cad'}</h6>
                <div className='my-2'>
                    <span className='badge bg-primary mx-1'>
                        {tender.bids}</span>
                    <span>bids</span>
                </div>
            </div>
        </div>
        <div className={classes.MediaItem}>
            <span>Media :</span>
            {tender.media.map((media, index) => (
                <span key={index}>{media}</span>
            ))}
        </div>
        <div className={classes.MediaItem}>
            <span>SubMedia :</span>
            {tender.subMedia.map((subMedia, index) => (
                <span key={index}>{subMedia}</span>
            ))}
        </div>
        <div className={classes.DomainItem}>
            <span>Domains :</span>
            {tender.domains.map((domainData, index) => (
                <span key={index}>{domainData.domain}</span>
            ))}
        </div>
        <div className='d-flex justify-content-between mt-3 align-items-center'>
            <div>
                <span>{getDate(tender.createdAt)}</span>
            </div>

            {type === 'active' &&
                <div className='d-flex justify-content-end'>
                    <button
                        onClick={_ => onChangeTenderMode('draft', tender._id)}
                        className='btn btn-sm btn-outline-primary mx-1'>
                        Add to Draft
                    </button>
                    <button
                        onClick={_ => onChangeTenderMode('closed', tender._id)}
                        className='btn btn-sm btn-primary'>
                        Close Tender
                    </button>
                </div>}
            {type === 'draft' &&
                <div className='d-flex justify-content-end'>
                    <button
                        onClick={_ => onChangeTenderMode('active', tender._id)}
                        className='btn btn-sm btn-outline-primary mx-1'>
                        Add to Active
                    </button>
                    <button
                        className='btn btn-sm btn-primary'>
                        <Link to={`/edit-tender?info_id=${tender._id}`}>Edit Tender</Link>
                    </button>
                </div>}
            {type === 'closed' &&
                <div className='d-flex justify-content-end'>
                    <button
                        onClick={_ => onDeleteTender(tender._id)}
                        className='btn btn-sm btn-outline-danger'>
                        Delete Tender
                    </button>
                </div>}
        </div>
    </div>
}

export const BidItem = ({ bitTenders, onAcceptBid }) => {
    return <div className={classes.PartRightItem}>
        <div className={classes.PartRightItems}>
            <div className='col-12'>
                <Link title='Tender title' to={`/tender-description?info_id=${bitTenders.tender._id}`}>
                    <h6>{bitTenders.tender.title}</h6>
                </Link>
            </div>
        </div>
        <div className={classes.BitTenderInfo}>
            <p>Bid By</p>
            <div className='row py-2'>
                <div className='col-lg-8 col-12'>
                    <div className='d-flex mb-2'>
                        <div>
                            <img src={bitTenders && bitTenders.user && bitTenders.user.profile_image} alt='user img' />
                        </div>
                        <div className={`${classes.CreatorInfoItem}`}>
                            <Link
                                to={`/profile/seller/${bitTenders && bitTenders.user && bitTenders.user.username}`}
                                target='_blank'>
                                <h5>{bitTenders && bitTenders.user && bitTenders.user.full_name}</h5>
                            </Link>
                            <p>{bitTenders.description}</p>
                        </div>
                    </div>
                </div>
                <div className='col-lg-4 col-12'>
                    <h5>$({bitTenders.budget.budgetMin ? bitTenders.budget.budgetMin : ''} - {bitTenders.budget.budgetMax ? bitTenders.budget.budgetMax : ''}) cad <span><p>in {bitTenders.deliveryDays} days</p></span></h5>
                </div>
            </div>
            <div className='py-2'>
                <span className='text-secondary'>{getDate(bitTenders.createdAt)}</span>
            </div>
        </div>
        <div className='d-flex mt-3 justify-content-between'>
            <div className={classes.MediaItem}>
                <span>STATUS : {bitTenders.isAccepted ? 'ACCEPTED' : "PENDING"}</span>
            </div>
            {!bitTenders.isAccepted && <div>
                <button
                    onClick={_ => onAcceptBid(bitTenders.tender._id, bitTenders._id)}
                    className='btn btn-sm btn-primary'>
                    Accept Bid
                </button>
            </div>}
        </div>
    </div>
}
