import React from 'react';
import classes from './NavigationItem.module.css';

import { NavLink } from 'react-router-dom';
// style = { props.scrolled ? { color: 'rgb(86, 110, 110)' } : { color: 'white' } }

export const AppStartNavigationItem = (props) => {
    return (
        <span className={props.scrolled ? classes.NavigationItem : classes.OnScrollNavigationItem}>
            <NavLink
                to={props.link}
                exact={props.exact}
                activeClassName={classes.active}
            >{props.children}</NavLink>
        </span >
    )
}
export const NormalNavigationItem = (props) => {
    return (
        <span className={classes.NavigationItem} >
            <NavLink
                to={props.link}
                exact={props.exact}
                activeClassName={classes.active}
            >{props.children}</NavLink>
        </span>
    )
}