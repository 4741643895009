import React, { useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import CloudUpload from '@material-ui/icons/CloudUpload';
// import { useDropzone } from 'react-dropzone'
// import io from 'socket.io-client'
import AppTextInput from './coremat/formElements/AppTextInput';
import {
  // sendMediaMessage,
  sendTextMessage,
} from '../../../store/actions/ActionsFiles/Chat';
import socket from '../../../config/socket';
import styles from './Chat.module.css';
import * as actions from '../../../store/actions';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
// import { filetypes } from '../../../assets/file_types';
import { getFileType } from '../../../shared/utility';

function CircularProgressWithLabel(props) {
  return <Box position="relative" display="inline-flex">
    <CircularProgress variant="static" {...props} color='secondary' />
    <Box
      top={0}
      left={0}
      bottom={0}
      right={0}
      position="absolute"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Typography variant="caption" component="div" color="primary">{
        props.value}%</Typography>
    </Box>
  </Box>
}

const useStyles = makeStyles((theme) => ({
  chatFooterRoot: {
    // borderTop: `1px solid ${theme.palette.borderColor.main}`,
    backgroundColor: theme.palette.common.dark,
    padding: '14px 20px 15px',
    display: 'flex',
    alignItems: 'center',
    marginTop: 'auto',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 30,
      paddingRight: 30,
    },
  },
  textFieldRoot: {
    flex: 1,
    marginLeft: 5,
    marginRight: 5,
    '& .MuiOutlinedInput-multiline': {
      padding: '10px 16px',
    },
    '& .MuiInputBase-root': {
      backgroundColor: theme.palette.background.paper,
    },
  },
  iconBtnRoot: {
    padding: 8,
  },
}))

const ChatFooter = (props) => {
  const { user, currentUser, addToConversation } = props

  const [message, setMessage] = useState('');
  const [files, setFiles] = useState([]);
  // const [messages, setMessages] = useState([]) // here is problem
  const dispatch = useDispatch()
  const classes = useStyles()

  // const ENDPOINT = 'localhost:8888'
  // const ENDPOINT = 'https://api.umediad.com'

  // useEffect(() => {
  //   setMessages(conversation)
  // })

  // useEffect(() => {
  //   // var connectionOptions = {
  //   //   'force new connection': true,
  //   //   reconnectionAttempts: 'Infinity',
  //   //   timeout: 10000,
  //   //   transports: ['polling'],
  //   //   // withCredentials: true,
  //   // }
  //   // socket = io(ENDPOINT, connectionOptions)
  //   socket = io(ENDPOINT)
  //   const payload = {
  //     username: currentUser.id,
  //     message: 'start',
  //   }
  //   socket.emit('join', payload, () => {})
  //   return () => {
  //     socket.emit('disconnet')
  //     socket.off()
  //   }
  // }, [ENDPOINT, currentUser.id])

  useEffect(() => {
    // incoming message here only!
    socket.on('private message', ({ payload, from }) => {
      if (currentUser.id === payload.username) {
        let _obj = {}
        _obj['messageType'] = payload.messageType;
        _obj['message'] = payload.message;
        _obj['sentAt'] = payload.createdAt;
        if (payload.fromEmail === user.email) {
          _obj['type'] = 'sent'
        } else {
          _obj['type'] = 'received'
        }
        addToConversation(_obj)
        // setMessages([...conversation, _obj])
      }
    });

    return () => {
      socket.off('private message')
    }
  }, [user.email, currentUser.id, addToConversation])
  // }, [messages])

  // onMessageListener()
  //   .then((payload) => {
  //     const {title, body} = payload.data
  //     console.log(title, body, 'works')
  //   })
  //   .catch((err) => {
  //     console.log(err, 'not working !')
  //   })

  const onSendMessage = (message, type) => {
    if (message) {
      dispatch(sendTextMessage(message, user, currentUser, type))
      const payload = {
        username: currentUser.id,
        message: message.trim(),
        messageType: type,
        createdAt: Date.now(),
        fromEmail: user.email,
        toEmail: currentUser.email,
      }
      socket.emit('private message', {
        payload,
        to: currentUser.email,
      })
      // socket.emit('sendMessage', payload, () => {})

      let _obj = {}
      _obj['messageType'] = type;
      _obj['message'] = message.trim()
      _obj['sentAt'] = Date.now()
      _obj['type'] = 'sent'
      addToConversation(_obj)
      // setMessages([...conversation, _obj])

      setMessage('')
    }
  }

  // const { getRootProps, getInputProps } = useDropzone({
  //   accept: ".jpg, .png, .jpeg, .gif, .csv, .xlsx, .xls, .pdf, .doc, .zip, .mp4, .mov",
  //   multiple: true,
  //   onDrop: (acceptedFiles) => {
  //     const files = acceptedFiles.map((file) => {
  //       return {
  //         preview: URL.createObjectURL(file),
  //         name: file.name,
  //         ...file,
  //         metaData: { type: file.type, size: file.size },
  //       }
  //     })
  //     dispatch(sendMediaMessage(files))
  //   },
  // });

  const handleKeyPress = (event) => {
    const message = event.target.value.trim()
    if (event.key === 'Enter' && !event.shiftKey && message) {
      const payload = {
        username: currentUser.id,
        message: message.trim(),
        createdAt: Date.now(),
        fromEmail: user.email,
        toEmail: currentUser.email,
        messageType: 'text',
      }
      socket.emit('private message', {
        payload,
        to: currentUser.email,
      });

      let _obj = {};
      _obj['messageType'] = 'text'
      _obj['message'] = message.trim()
      _obj['sentAt'] = Date.now()
      _obj['type'] = 'sent'
      addToConversation(_obj)
      // socket.emit('sendMessage', payload, () => {})
      dispatch(sendTextMessage(message, user, currentUser, 'text'))
      event.preventDefault()
      setMessage('')
    }
  }

  const handleFiles = (event) => {
    let filesArray = [...files];
   
    for (let i = 0; i < event.target.files.length; i++) {
      const size = (event.target.files[i].size / (1024 * 1024)).toFixed(2);
      if (parseInt(size) > 10) return alert('Your max files limit is 10Mb');
      filesArray.push(event.target.files[i]);
    }
    setFiles(filesArray);
  }

  const uploadFiles = (e) => {
    const filesData = new FormData();
    for (let i = 0; i < files.length; i++) {
      filesData.append('files', files[i]);
    }

    props.onUploadFiles(filesData);
  }

  const removeFile = (e, index) => {
    const filteredFiles = files.filter((_, fileIndex) => index !== fileIndex);
    setFiles(filteredFiles);
  }

  const images = ['jpg', 'png', 'jpeg', 'gif'];
  const docs = ['csv', 'xlsx', 'xls', 'pdf', 'doc', 'zip'];

  useEffect(() => {
    if (props.files.length !== 0) {
      let messageFiles = JSON.stringify(props.files);
      // props.files.forEach((file, i) => {
      //   if (i === props.files.length - 1) {
      //     messageFiles += file;
      //     return
      //   }
      //   messageFiles += file + ' ';
      // })
      onSendMessage(messageFiles, 'file');
      setFiles([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.files]);

  // preferencesRemoveFile = (url, type, index) => {
  //   const filesPreviews = { ...this.state._filesPreviews };
  //   const _files = { ...this.state._files };
  //   const _removedFiles = [...this.state._removedFiles];
  //   const _existingFiles = [...this.state._existingFiles];

  //   const filteredPreviews = _filesPreviews[type].filter(previewFile => previewFile.url !== url);

  //   const removedIndex = _existingFiles.findIndex(_url => _url === url);
  //   if (removedIndex !== -1) {
  //     _removedFiles.push(url);
  //     _existingFiles.shift(removedIndex, 1);
  //   }

  //   _files[type].shift(index, 1);
  //   _filesPreviews[type] = filteredPreviews;
  //   this.setState({ _filesPreviews, _files, _removedFiles, _existingFiles });
  // }

  // console.log(files)



  return (
    <React.Fragment>
      <div className={styles.FileDisplay}>
        {files.map((fileInfo, index) => (
          <div className={styles.File} key={index}>
            {images.findIndex(imageFile => imageFile === getFileType(fileInfo.name)) !== -1 ?
              <img src={URL.createObjectURL(fileInfo)} alt="Image_photo" />
              :
              docs.findIndex(docFile => docFile === getFileType(fileInfo.name)) !== -1
                ?
                // <img src={filetypes[getFileType(fileInfo.name)]} alt="Image_photo" />
                <img  alt="Image_photo" />
                :
                <video controls disablePictureInPicture autoPlay={false} key={index} src={URL.createObjectURL(fileInfo)} title='video' height={100} width={100} />
            }
            <i className='fa fa-times' onClick={e => removeFile(e, index)}></i>
            <small>{fileInfo.name.toString().length > 9 ?
              fileInfo.name.substr(0, props.screenWidth > 800 ? 16 : 10) + '...' + getFileType(fileInfo.name) : fileInfo.name}</small>
          </div>
        ))}
      </div>
      <Box className={classes.chatFooterRoot}>
        {props.fileUploadLoading ? <CircularProgressWithLabel value={props.fileUploadPercentage} /> : files.length !== 0 && <div className={styles.FileInput}>
          <input
            title='Upload'
            type='submit'
            id='upload'
            onClick={uploadFiles}
          />
          <label htmlFor='upload' title='Upload and Sent' style={{ background: 'goldenrod', color: 'white', marginRight: '5px' }}>
            <CloudUpload />
          </label>
        </div>}
        <div className={styles.FileInput}>
          <input
            type='file'
            accept='.jpg, .png, .jpeg, .gif, .csv, .xlsx, .xls, .pdf, .doc, .zip, .mp4, .mov'
            multiple
            onChange={handleFiles}
            id='file' />

          <label htmlFor='file' title='Upload Files'>
            <AttachFileIcon />
          </label>
        </div>

        <AppTextInput
          id="chat-textarea"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder="Type message..."
          variant="outlined"
          multiline
          className={classes.textFieldRoot}
        />
        <IconButton className={classes.iconBtnRoot} onClick={_ => onSendMessage(message, 'text')}>
          <SendIcon />
        </IconButton>
      </Box>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    files: state.fileUpload.files,
    fileUploadLoading: state.fileUpload.loading,
    fileUploadError: state.fileUpload.error,
    fileUploadMessage: state.fileUpload.message,
    fileUploadPercentage: state.fileUpload.percentage,
    screenWidth: state.responsiveness.screenWidth,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onUploadFiles: (files) => dispatch(actions.uploadChatFiles(files)),
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(ChatFooter)
