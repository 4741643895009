import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
// import { createStore, applyMiddleware, combineReducers } from 'redux'
import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import thunk from 'redux-thunk';
import App from './App';
import './config/i18n';
import './index.css';
import 'animate.css';
import { MainSpinner } from '../src/components/UI/Spinner/Spinner'

// import {registerServiceWorker} from './serviceWorker'

import {
  UserAuthReducer,
  ProfileReducer,
  DashboardAdvertisesReducer,
  NormalAdvertisesReducer,
  SubscriptionPackagesReducers,
  HandleResponsivenessReducer,
  PaymentReducer,
  FileUploadReducer,
  BuyReducer,
  ChatReducer,
  CommonReducer,
  OrderCommentReducer,
  TenderReducer,
  PartnerReducer
} from './store/reducers'

const rootReducer = combineReducers({
  userAuth: UserAuthReducer,
  profile: ProfileReducer,
  dashboardAdvertise: DashboardAdvertisesReducer,
  normalAdvertise: NormalAdvertisesReducer,
  subscriptionPackages: SubscriptionPackagesReducers,
  responsiveness: HandleResponsivenessReducer,
  payment: PaymentReducer,
  buy: BuyReducer,
  fileUpload: FileUploadReducer,
  chat: ChatReducer,
  common: CommonReducer,
  orderComments: OrderCommentReducer,
  tender: TenderReducer,
  partner: PartnerReducer
})

const composeEnhancers = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null || compose;

// const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
const store = createStore(rootReducer, applyMiddleware(thunk))

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <Suspense fallback={<MainSpinner />}>
        <App />
      </Suspense>
    </BrowserRouter>
  </Provider>
)

ReactDOM.render(app, document.getElementById('root'))

// registerServiceWorker()