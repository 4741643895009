import React, { Component } from 'react';
import classes from '../CreateAdd.module.css';
import Button from '../../../../components/UI/Button/Button';
import StepProgress from './StepProgress';
import { SinglePackageItem } from './SinglePackageItem';
import { Divider } from '../../../../components/UI/Spacing';

class Step3 extends Component {
  componentDidMount() {
    window.scroll(0.0, 0.0)
  }
  state = { isClicked: false };

  continue = (event, isFieldOk) => {
    event.preventDefault();
    if (isFieldOk)
      this.props.nextStep();
    this.setState({ isClicked: true });
    setTimeout(() => {
      this.setState({ isClicked: false });
    }, 4000)
  };
  back = e => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    const { values, getSelectedNumberOfPackage, handlePackagesData, handleAddTasksFields, handleTasksInputChange, handleRemoveTasksFields } = this.props;
    const { t } = this.props;
    let packageData = values.packagesData[values.numberOfPackage - 1];

    // let isFieldOk = /^.{ 10, 50}$/.test(packageData.name);
    // isFieldOk = packageData.description.length > 0;
    let isFieldOk = packageData.tasks.length > 0;
    // isFieldOk = packageData.delivery_time.toString().length > 0;
    // isFieldOk = packageData.price.toString().length > 0;

    const packages = [];
    for (let i = 1; i <= values.numberOfPackage; i++) {
      packages.push(<SinglePackageItem
        key={i}
        isClicked={this.state.isClicked}
        packageType={t(`MyAds:CreateAdd.Step2.packageTypes.${i - 1}`)}
        selectedPackage={i}
        values={values}
        handlePackagesData={handlePackagesData}
        handleAddTasksFields={handleAddTasksFields}
        handleRemoveTasksFields={handleRemoveTasksFields}
        handleTasksInputChange={handleTasksInputChange}
      />);
    }

    return <React.Fragment>
      <Divider orientation='v' space='2' />
      <StepProgress step={this.props.step} />
      <div className={classes.NB}><small>{t('MyAds:CreateAdd.NB')}</small></div>
      <div className={classes.NumberOfPackage}>
        <div><h6>{t('MyAds:CreateAdd.Step2.title')}</h6></div>
        <select
          className='form-select'
          onChange={(event) => getSelectedNumberOfPackage(event)}>
          <option value='1' selected={values.numberOfPackage === 1}>1</option>
          <option value='2' selected={values.numberOfPackage === 2}>2</option>
          <option value='3' selected={values.numberOfPackage === 3}>3</option>
        </select>
      </div>
      <div className={classes.Divider}></div>
      <h5>{t('MyAds:CreateAdd.Step2.name')}</h5>
      <div className={classes.DividerWithoutStyle}></div>
      <div className={classes.PackageDescription}>
        {packages.map((packageValue, index) => (
          <React.Fragment key={index}>{packageValue}</React.Fragment>
        ))}
      </div>
      <div className={classes.Divider}></div>
      <div className={classes.StepsButton}>
        <Button buttonType='AppleDark' clicked={this.back} >  <i className='fas fa-chevron-left'></i>{t('MyAds:CreateAdd.button2')}</Button>
        <Button
          buttonType='Submit'
          clicked={(e) => this.continue(e, isFieldOk)}
        >{t('MyAds:CreateAdd.button1')} <i className='fas fa-chevron-right'></i></Button>
      </div>
    </React.Fragment >
  }
}

export default Step3;
