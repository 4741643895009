import * as actionTypes from '../ActionTypes'

export const fetchSuccess = () => {
  return dispatch => {
    dispatch({
      type: actionTypes.FETCH_SUCCESS,
    });
  };
};
export const fetchError = error => {
  return dispatch => {
    dispatch({
      type: actionTypes.FETCH_ERROR,
      payload: error,
    });
  };
};

export const fetchStart = () => {
  return dispatch => {
    dispatch({
      type: actionTypes.FETCH_START,
    });
  };
};
