import React from 'react'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import axios from '../../../../axios'
import * as actions from '../../../../store/actions'
import classes from '../AllOrders.module.css'
import Dashboard from '../../Dashboard/Dashboard.module.css'
import {getDate} from '../../../../shared/utility'
import SimpleSnackbar from '../../../../components/Snackbar/Snackbar'

class AllOrdersForBuyer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      canceled: false,
      success: false,
      error: '',
      isLoading: false,
      isOrderLoading: false,
      latestOrders: [],
    }
  }

  componentDidMount() {
    console.log('called')
    if (this.props.location.search) {
      const search = this.props.location.search
      // const params = new URLSearchParams(search)

      const completeUrl = 'https://umediad.com' + search
      const params = new URL(completeUrl).searchParams

      let canceled = params.get('canceled')
      let mm = params.get('mm')

      if (canceled && mm === 'cancel') {
        this.setState({canceled: true})
        this.setState({isLoading: false})
      } else {
        let session_id = params.get('session_id')
        let advertiseId = params.get('advertiseId')
        let type = params.get('type')
        let sellerId = params.get('sellerId')
        let customOrderId = params.get('customOrderId')
          ? params.get('customOrderId')
          : null

        // let advertiseInfo = params.get('advertiseInfo')
        let adType = params.get('adType')
        let adSubtype = params.get('adSubtype')
        let adTitle = params.get('adTitle')
        let advertiseObj = {}
        advertiseObj['type'] = adType
        advertiseObj['subtype'] = adSubtype
        advertiseObj['title'] = adTitle
        let adPrice = params.get('adPrice')

        let advertiseInfo = JSON.stringify(advertiseObj)

        console.log(session_id)
        this.saveToDatabase(
          session_id,
          advertiseId,
          customOrderId,
          type,
          sellerId,
          adPrice,
          advertiseInfo,
        )
        this.setState({isLoading: false, success: true})
        if (this.props.history) this.props.history.replace('/buyer/all-orders')
      }
      // setTimeout(() => {
      //   window.location.reload()
      // }, 3000)
    }
    this.setState({isLoading: false})
  }

  tokenHeadersNormal = () => {
    const headersNormal = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    const token = this.props.token
    if (token) {
      headersNormal.headers['x-auth-header'] = token
    }
    return headersNormal
  }

  fetchSuccessData = async (sessionId) => {
    const res = await axios({
      method: 'GET',
      url: '/stripe/payment-session?sessionId=' + sessionId,
      headers: this.tokenHeadersNormal().headers,
    })
    return res.data.session
  }

  saveToDatabase = async (
    sessionId,
    advertiseId,
    customOrderId,
    type,
    sellerId,
    adPrice,
    advertiseInfo,
  ) => {
    let cc
    try {
      cc = await this.fetchSuccessData(sessionId)
    } catch (e) {
      console.log(e, 'save-to-database')
    }
    if (cc) {
      const {
        customer,
        currency,
        customer_details,
        id,
        payment_status,
        // subscription,
        total_details,
        shipping,
        amount_subtotal,
      } = cc
      const {email} = customer_details
      const {amount_discount, amount_tax} = total_details
      this.props.uploadBuyData({
        packageId: advertiseId,
        customOrderId,
        priceId: amount_subtotal / 100,
        customer: customer,
        currency: currency,
        payment_status: payment_status,
        setup_intent: type,
        shipping: shipping,
        subscription: 'order_price',
        amount_discount: amount_discount,
        amount_tax: amount_tax,
        customer_email: email,
        sessionId: id,
        sellerId: sellerId,
        adPrice,
        advertiseInfo: advertiseInfo,
      })
    }
  }

  renderOrders = () => {
    const OrdersData = this.props.orders
    const filteredOrders = OrdersData.filter(
      (order, index) => this.props.statusType === order.status,
    )
    const t = this.props.t

    if (filteredOrders.length > 0) {
      return filteredOrders.map((order, index) => {
        return (
          <div key={order._id} className="mt-1 mb-2">
            <Link
              to={{
                pathname: `/manage-order/${order._id}/${order.advertiseId._id}/${this.props.statusType}`,
              }}
              style={{textDecoration: 'none'}}>
              <div className="mt-1 mb-1">
                {this.props.screenWidth > 840 ? (
                  <React.Fragment>
                    <div className={classes.ManageOrdersInfoItem}>
                      <div
                        className="row justify-content-around align-items-center"
                        style={{color: '#000'}}>
                        <div className="col-2" style={{width: '200px'}}>
                          <div className="d-flex align-items-center">
                            <div className={Dashboard.Avatar}>
                              {order.seller.profile_image ? (
                                <img
                                  src={order.seller.profile_image}
                                  alt="user img"
                                />
                              ) : order.seller.username ? (
                                order.seller.username[0]
                              ) : (
                                ''
                              )}
                            </div>
                            <h6>
                              {order.seller.username
                                ? order.seller.username
                                : ''}
                            </h6>
                          </div>
                        </div>
                        <div className="col-4">
                          <p className="">
                            <span className="text-dark text-bold">
                              {order.advertiseId.title
                                ? order.customOrderId ? order.customOrderId.adInfo.title : order.advertiseId.title
                                : ''}
                            </span>
                          </p>
                        </div>
                        <div className="col-2">
                          <div className={Dashboard.OrderInfo}>
                            <small className="text-primary">
                              {/* {order.advertiseId.packages && (
                                <div>
                                  {order.advertiseId.packages.map((ss) => {
                                    if (
                                      ss.type === order.paymentId.setup_intent
                                    ) {
                                      return order.deliveryDate
                                        ? getDate(order.deliveryDate)
                                        : ss.delivery_time + ' days'
                                    } else {
                                      return 
                                    }
                                  })}
                                </div>
                              )} */}
                              {order.deliveryDate
                                ? getDate(order.deliveryDate)
                                : 'Not confirmed'}
                            </small>
                          </div>
                        </div>
                        <div className="col-1">
                          <div className={Dashboard.OrderInfo}>
                            <small className="text-success">
                              $
                              {order.paymentId.priceId
                                ? order.paymentId.priceId
                                : ''}
                            </small>
                          </div>
                        </div>
                        <div className="col-2">
                          <div className={Dashboard.OrderInfo}>
                            <div className={Dashboard.IsActive}>
                              {order.status ? order.status : ''}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {index < OrdersData.length - 1 ? (
                      <div className={Dashboard.Divider}></div>
                    ) : null}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div className={classes.ManageOrdersInfoItem}>
                      <div className="row  align-items-center">
                        <div className="col-3">
                          <h6 className="text-muted">
                            {t('Dashboard:AllOrders.1')}
                          </h6>
                        </div>
                        <div className="col-8 text-dark">
                          <div className="d-flex align-items-center">
                            <div className={Dashboard.Avatar}>
                              {order.seller.profile_image ? (
                                <img
                                  src={order.seller.profile_image}
                                  alt="user img"
                                />
                              ) : order.seller.username ? (
                                order.seller.username[0]
                              ) : (
                                ''
                              )}
                            </div>
                            <h6>
                              {order.seller.username
                                ? order.seller.username
                                : ''}
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div className="row align-items-center mt-3">
                        <div className="col-3">
                          <h6 className="text-muted">
                            {t('Dashboard:AllOrders.2')}
                          </h6>
                        </div>
                        <div className="col-8">
                          <div className={Dashboard.OrderInfo}>
                            <p className="pb-1">
                              <span className='text-dark text-bold'>
                                  {order.advertiseId.title
                                    ? order.customOrderId ? order.customOrderId.adInfo.title : order.advertiseId.title
                                    : ''}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="row align-items-center mt-3">
                        <div className="col-3">
                          <h6 className="text-muted">
                            {t('Dashboard:AllOrders.3')}
                          </h6>
                        </div>
                        <div className="col-8 text-dark">
                          <div className={Dashboard.OrderInfo}>
                              <small className='text-primary'>
                              {/* {order.advertiseId.packages && (
                                <div>
                                  {order.advertiseId.packages.map((ss) => {
                                    if (
                                      ss.type === order.paymentId.setup_intent
                                    ) {
                                      return order.deliveryDate
                                          ? getDate(order.deliveryDate)
                                          : 'Not confirmed'
                                      
                                    } else {
                                      return null
                                    }
                                  })}
                                </div>
                              )} */}
                                {order.deliveryDate
                                  ? getDate(order.deliveryDate)
                                  : 'Not confirmed'}
                            </small>
                          </div>
                        </div>
                      </div>
                      <div className="row align-items-center mt-3">
                        <div className="col-3">
                          <h6 className="text-muted">
                            {t('Dashboard:AllOrders.4')}
                          </h6>
                        </div>
                        <div className="col-8 text-dark">
                          <div className={Dashboard.OrderInfo}>
                            <small className="text-success">
                              $
                              {order.paymentId.priceId
                                ? order.paymentId.priceId
                                : ''}
                            </small>
                          </div>
                        </div>
                      </div>
                      <div className="row align-items-center mt-3">
                        <div className="col-3">
                          <h6 className="text-muted">
                            {t('Dashboard:AllOrders.5')}
                          </h6>
                        </div>
                        <div className="col-8">
                          <div className={Dashboard.OrderInfo}>
                            <div className={Dashboard.IsActive}>
                              {order.status ? order.status : ''}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {index < OrdersData.length - 1 ? (
                      <div className={Dashboard.Divider}></div>
                    ) : null}
                  </React.Fragment>
                )}
              </div>
            </Link>
          </div>
        )
      })
    } else
      return (
        <div className="mt-2 mb-2">
          {t('Dashboard:orders.errors.0')} {this.withTranslationOrders()}{' '}
          {t('Dashboard:orders.8')}.
        </div>
      )
  }

  withTranslationOrders = () => {
    const {statusType} = this.props
    const t = this.props.t
    if (statusType === 'active') {
      return t('Dashboard:orders.list.0')
    } else if (statusType === 'new') {
      return t('Dashboard:orders.list.1')
    } else if (statusType === 'late') {
      return t('Dashboard:orders.list.2')
    } else if (statusType === 'delivered') {
      return t('Dashboard:orders.list.3')
    } else if (statusType === 'completed') {
      return t('Dashboard:orders.list.4')
    }
  }

  render() {
    const t = this.props.t
    let {canceled, success} = this.state
    let messageShouldShow = ''
    if (canceled) {
      messageShouldShow =
        'Order payment page gets closed by clicking Cancel or internet connection.'
    } else if (success) {
      messageShouldShow =
        'Your order has been successfully placed. You will receive a confirmation email shortly.'
    }
    return (
      <div className={classes.ManageOrdersMainInfo}>
        {canceled && (
          <SimpleSnackbar
            open={true}
            message={messageShouldShow}
            type="error"
          />
        )}
        {success && (
          <SimpleSnackbar
            open={true}
            message={messageShouldShow}
            type="success"
          />
        )}
        <h6>
          {' '}
          {this.withTranslationOrders()} {t('Dashboard:orders.8')}
        </h6>
        <div className={Dashboard.Divider}></div>
        <div className={classes.ManageOrdersInfo}>
          {this.props.screenWidth > 840 ? (
            <React.Fragment>
              <div className="row justify-content-around align-items-center mt-3 ">
                <div className="col-2">
                  <h6 className="text-muted">{t('Dashboard:AllOrders.1')}</h6>
                </div>
                <div className="col-3">
                  <h6 className="text-muted">{t('Dashboard:AllOrders.2')}</h6>
                </div>
                <div className="col-2">
                  <h6 className="text-muted">{t('Dashboard:AllOrders.3')}</h6>
                </div>
                <div className="col-2">
                  <h6 className="text-muted">{t('Dashboard:AllOrders.4')}</h6>
                </div>
                <div className="col-2">
                  <h6 className="text-muted">{t('Dashboard:AllOrders.5')}</h6>
                </div>
              </div>
              <div className={Dashboard.Divider}></div>
            </React.Fragment>
          ) : null}
          {this.renderOrders()}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.userAuth.isAuthenticated,
    token: state.userAuth.token,
    screenWidth: state.responsiveness.screenWidth,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    uploadBuyData: (data) => dispatch(actions.uploadBuyData(data)),
  }
}

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(AllOrdersForBuyer)
