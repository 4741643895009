import React from 'react'
import {useHistory} from 'react-router-dom'
import classes from './Search.module.css'

// const { search } = window.location;
// const query = new URLSearchParams(search).get('s');

const services = [
  {id: '6', value: 'Automotive', type: 'd'},
  {id: '7', value: 'Business', type: 'd'},
  {id: '8', value: 'Cinema', type: 'd'},
  {id: '9', value: 'Delivered', type: 'd'},
  {id: '10', value: 'Fashion', type: 'd'},
  {id: '11', value: 'Food', type: 'd'},
  {id: '12', value: 'Health', type: 'd'},
  {id: '13', value: 'Humor', type: 'd'},
  {id: '14', value: 'Immovable', type: 'd'},
  {id: '15', value: 'Lifestyle', type: 'd'},
  {id: '16', value: 'Music', type: 'd'},
  {id: '17', value: 'Politics', type: 'd'},
  {id: '18', value: 'Sport', type: 'd'},
  {id: '19', value: 'Technology', type: 'd'},
  {id: '20', value: 'VideoGames', type: 'd'},
  {id: '22', value: 'Visual-arts', type: 'd'},
  {id: '23', value: 'Beauty', type: 'd'},
  {id: '24', value: 'Event', type: 'd'},
  {id: '25', value: 'Social-movement', type: 'd'},
  {id: '26', value: 'Business-job', type: 'd'},
  {id: '27', value: 'Belief-religion', type: 'd'},
  {id: '28', value: 'Well-being', type: 'd'},
  {id: '29', value: 'Trip', type: 'd'},
]

const filterServices = (searchQuery, services) => {
  if (!searchQuery) return services
  return services.filter((service) =>
    service.value.toLowerCase().includes(searchQuery.toLowerCase()),
  )
}

export const Search = ({t, text}) => {
  const [value, setSearchValue] = React.useState('')
  const [focused, setFocused] = React.useState(false)
  const history = useHistory()
  const ref = React.createRef(null)

  const setValue = (searchQuery, type) => {
    setFocused(false)
    setSearchValue(searchQuery)
    setTimeout(() => {
      history.push(
        `/find-audience?search=${true}&query=${searchQuery.toLowerCase()}&type=${type}`,
      )
    }, 1000)
  }
  const getSearchValue = (event) => setSearchValue(event.target.value)

  const onFocus = () => setFocused(true)

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) setFocused(false)
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [ref])

  const goSearch = (event) => {
    event.preventDefault()
    if (value)
      history.push(
        `/find-audience?search=${true}&query=${value.toLowerCase()}&type=td`,
      )
  }

  const filteredServices = filterServices(value, services)

  return (
    <React.Fragment>
      <form className="d-flex" onSubmit={goSearch}>
        <input
          value={value}
          onChange={getSearchValue}
          className="form-control form-control-sm"
          type="search"
          onFocus={onFocus}
          placeholder={text}
        />
        <button onClick={goSearch} className="btn btn-sm btn-primary">
          <i className="fas fa-search"></i>
        </button>
      </form>
      {value && focused && (
        <div className={classes.SearchResults} ref={ref}>
          <span>
            <i className="fab fa-adversal"></i>
            <small className="text-success mx-2">Services</small>
          </span>
          {filteredServices.map((service) => (
            <li
              key={service.id}
              onClick={(_) => setValue(service.value, service.type)}>
              {service.value}
            </li>
          ))}
        </div>
      )}
    </React.Fragment>
  )
}
