// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".DrawerToggle_DrawerToggle__m405X {\n    width: 33px;\n    height: 60%;\n    display: flex;\n    flex-flow: column;\n    justify-content: space-around;\n    align-items: center;\n    padding: 7px 0;\n    box-sizing: border-box;\n    cursor: pointer;\n    margin-left: 10px;\n}\n\n.DrawerToggle_DrawerToggle__m405X div {\n    width: 70%;\n    height: 3px;\n    /* box-shadow: 0px 1px 1px -1px rgba(48, 46, 46, 0.2), 0px 1px 1px 0px rgba(44, 42, 42, 0.14), 0px 1px 1px 0px rgba(49, 47, 47, 0.12); */\n    border-radius: 5px;\n    background: rgb(0, 127, 255);\n}\n\n.DrawerToggle_DrawerToggle__m405X:hover>div {\n    background: rgb(8, 112, 216);\n}\n\n@media (min-width: 930px) {\n    .DrawerToggle_DrawerToggle__m405X {\n        display: none;\n    }\n}", ""]);
// Exports
exports.locals = {
	"DrawerToggle": "DrawerToggle_DrawerToggle__m405X"
};
module.exports = exports;
