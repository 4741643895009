import React, {useState, useEffect} from 'react'
// import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
import classes from './Toolbar.module.css'
import Logo from '../../Logo/Logo'
import NavigationItems from './NavigationItems/AppStartNavigation'
import DrawerToggle from '../SideDrawer/DrawerToggle/AppStartDrawerToggle'
import {Translate} from 'react-auto-translate'
import Translation from '../../../shared/Translate'

const Toolbar = (props) => {
  const [scrolled, setScrolled] = useState(false)
  const [subHeaderScrolled, setSubHeaderScrolled] = useState(false)
  // const {t} = useTranslation()

  const handleScroll = () => {
    const offset = window.scrollY
    if (offset > 50) {
      setScrolled(true)
    } else {
      setScrolled(false)
    }

    if (offset > 150) {
      setSubHeaderScrolled(true)
    } else {
      setSubHeaderScrolled(false)
    }
  }
  useEffect(() => window.addEventListener('scroll', handleScroll))

  let sticky = [classes.StickyToolbar]
  if (scrolled) {
    sticky.push(classes.Scrolled)
  }
  const categories = [
    {
      name: 'TV',
      path: '/tv',
    },
    {
      name: 'Radio',
      path: '/radio',
    },
    {
      name: 'Internet',
      path: '/internet',
      lang: 'fr',
    },
    {
      name: 'Sports',
      path: '/sports',
      lang: 'fr',
    },
    {
      name: 'Cinema',
      path: '/cinema',
    },
    {
      name: "Print-Media",
      path: '/print-media',
    },
    {
      name: "Music",
      path: '/music',
    },
    // {
    //     name: t('appStartToolbar.7'),
    //     path: '/poster'
    // },
    {
      name: "Event",
      path: '/event',
    },
    {
      name: "Freestyle",
      path: '/freestyle',
    },
  ]
  return (
    <header className={sticky.join(' ')}>
      <DrawerToggle clicked={props.drawerToggleClicked} scrolled={scrolled} />
      <div
        className={classes.AppHeaderName2}
        style={{color: scrolled ? ' rgb(60, 60, 60' : 'white'}}>
        Umediad
      </div>
      <div className={classes.Logo}>
        <Logo path="/" />
        <div
          className={classes.AppHeaderName}
          style={{color: scrolled ? ' rgb(60, 60, 60' : 'white'}}>
          Umediad
        </div>
      </div>
      <nav className={classes.DesktopOnly}>
        <NavigationItems scrolled={scrolled} />
      </nav>
      {subHeaderScrolled && (
        <div className={classes.SubHeader}>
          <div className="animate__animated animate__flipInX">
            <div className={classes.SubHeaderMenu}>
              {categories.map((item, index) => (
                <span key={index} className="animate__animated animate__zoomIn">
                  <Link to={`/find/advertises${item.path}?searchType=media`}>
                    <Translation
                      from={item.lang ?? 'en'}
                      text={item.name}
                      input={<Translate>{item.name}</Translate>}
                    />
                  </Link>
                </span>
              ))}
            </div>
          </div>
        </div>
      )}
    </header>
  )
}

export default Toolbar
