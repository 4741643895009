import { Link } from 'react-router-dom';
import React from 'react';
import classes from './Tender.module.css';
import * as actions from '../../../store/actions';
import { connect } from 'react-redux';
import { Line } from '../../../components/UI/Spacing';
import { Spinner } from '../../../components/UI/Spinner/Spinner';
import { withTranslation } from 'react-i18next';
import { Multiselect } from 'multiselect-react-dropdown';
import { getDate } from '../../../shared/utility';
class FindTenders extends React.Component {
    state = {
        selectedTypeIndex: 0,
        tenderData: {
            sellerType: '',
            budget: {
                budgetMin: '',
                budgetMax: ''
            },
            deliveryDays: null,
            languages: [],
            media: [],
            subMedia: [],
            domains: [],
            sortBy: 'bids'
        },
        searchQuery: '',
        tenders: []
    }

    onChangeSort = (event) => {
        const tenderData = { ...this.state.tenderData };

        tenderData.sortBy = event.target.value;
        this.props.onGetTenders(tenderData);
        this.setState({ tenderData });
    }

    onReturnOnlyDigit = value => value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');

    componentDidMount() {
        window.scrollTo(0.0, 0.0);
        this.props.onGetTenders(this.state.tenderData);
        this.props.onFetchTypeAndSubTypes();
    }

    onSelectAdvertiseDomain = (selectedList, selectedItem) => {
        const tenderData = { ...this.state.tenderData };
        tenderData.domains.push(selectedItem);
        this.setState({ tenderData });
    }

    onRemoveAdvertiseDomain = (selectedList, removedItem) => {
        const tenderData = { ...this.state.tenderData };
        tenderData.domains = selectedList;
        this.setState({ tenderData });
    }

    onInputChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        const tenderData = { ...this.state.tenderData };

        if (name === 'media') {
            // tenderData[name] = value;
            if (tenderData.media.findIndex(media => value === media) === -1) {
                tenderData.media.push(value);
            }
            this.setState({
                selectedTypeIndex: this.props.advertiseData.findIndex(data => value === data.type)
            });
        } else if (name === 'subMedia') {
            if (tenderData.subMedia.findIndex(subMedia => value === subMedia) === -1) {
                tenderData.subMedia.push(value);
            }
        }
        else if (name === 'budgetMin' || name === 'budgetMax')
            tenderData['budget'][name] = this.onReturnOnlyDigit(value);
        else if (name === 'language') {
            const index = tenderData.languages.findIndex(language => language === value);
            if (index === -1)
                tenderData.languages.push(value);
            else if (index !== -1)
                tenderData.languages.splice(index, 1);
        }
        else
            tenderData[name] = value;

        this.setState({ tenderData });
    }

    filterTenders = (searchQuery, tenders) => {
        if (!searchQuery) return tenders;
        return tenders.filter(tenders => tenders.title.toLowerCase().includes(searchQuery.toLowerCase()) || tenders.description.toLowerCase().includes(searchQuery.toLowerCase()));
    }

    onChangeSearch = (event) => {
        this.setState({ searchQuery: event.target.value, tenders: this.filterTenders(event.target.value, this.props.tenders) });
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.tenders !== nextProps.tenders) {
            this.setState({ tenders: nextProps.tenders });
        }
    }

    onApplyFilter = () => this.props.onGetTenders(this.state.tenderData);

    onClearFilter = () => {
        const tenderData = { ...this.state.tenderData };

        tenderData.media = [];
        tenderData.subMedia = [];
        tenderData.languages = [];
        tenderData.sellerType = '';
        tenderData.budget.budgetMax = '';
        tenderData.budget.budgetMin = '';
        tenderData.deliveryDays = null;
        tenderData.domains = [];

        this.setState({ tenderData });
        this.props.onGetTenders(tenderData);

    }

    onRemoveMedia = (media) => {
        const tenderData = { ...this.state.tenderData };
        tenderData.media = tenderData.media.filter(_media => media !== _media);
        this.setState({ tenderData });
    }

    onRemoveSubMedia = (subMedia) => {
        const tenderData = { ...this.state.tenderData };
        tenderData.subMedia = tenderData.subMedia.filter(_subMedia => subMedia !== _subMedia);
        this.setState({ tenderData });
    }
    getDomains = (domains) => {
        const allDomains = [];
        const length = domains.length < 6 ? domains.length : 6;
        for (let i = 0; i < length; i++) {
            allDomains.push(<span key={i}>{domains[i].domain}</span>)
        }
        if (domains.length > 6)
            allDomains.push(<span>...</span>);

        return allDomains;
    }

    render() {
        const { deliveryDays, sellerType, sortBy, languages, domains, media, subMedia, budget: { budgetMax, budgetMin } } = this.state.tenderData;

        const { t } = this.props;

        const businessDomain = [
            { id: 0, domain: t('translation:domainSlider.list.0') },
            { id: 1, domain: t('translation:domainSlider.list.1') },
            { id: 2, domain: t('translation:domainSlider.list.2') },
            { id: 3, domain: t('translation:domainSlider.list.3') },
            { id: 4, domain: t('translation:domainSlider.list.4') },
            { id: 5, domain: t('translation:domainSlider.list.5') },
            { id: 6, domain: t('translation:domainSlider.list.6') },
            { id: 7, domain: t('translation:domainSlider.list.7') },
            { id: 8, domain: t('translation:domainSlider.list.8') },
            { id: 9, domain: t('translation:domainSlider.list.9') },
            { id: 10, domain: t('translation:domainSlider.list.10') },
            { id: 11, domain: t('translation:domainSlider.list.11') },
            { id: 12, domain: t('translation:domainSlider.list.12') },
            { id: 13, domain: t('translation:domainSlider.list.13') },
            { id: 14, domain: t('translation:domainSlider.list.14') },
            { id: 15, domain: t('translation:domainSlider.list.15') },
            { id: 16, domain: t('translation:domainSlider.list.16') },
            { id: 17, domain: t('translation:domainSlider.list.17') },
            { id: 18, domain: t('translation:domainSlider.list.18') },
        ];

        const sellerTypes = ['Event-City-Social movement', 'Company', 'Media', 'Influencer-Team-Entrepreneur'];

        const days = [
            {
                value: '1',
                text: t('media:points.fifth.2')
            },
            {
                value: '3',
                text: t('media:points.fifth.3')
            },
            {
                value: '7',
                text: t('media:points.fifth.4')
            },
            {
                value: '8',
                text: t('media:points.fifth.5')
            }
        ]

        return <div className={classes.Tender}>
            <div className={`${ classes.PartLeft } mt-2`}>
                <div className={classes.PartLeftItem}>
                    <div className="form-group has-search my-2">
                        <span className="fa fa-search form-control-feedback"></span>
                        <input
                            onChange={this.onChangeSearch}
                            type="text"
                            value={this.state.searchQuery}
                            className="form-control"
                            placeholder="Search by title or description" />
                    </div>
                    <h6>Recent searches</h6>
                    <div className='my-2'>
                        <span>......</span>
                    </div>
                    <Line space='3' orientation='h' width='100' height='1' />
                    <h6 className='my-1'>Filter By :</h6>
                    <div className='row py-2'>
                        <div className="col-6">
                            <button
                                className="btn btn-outline-primary w-100"
                                onClick={this.onClearFilter}
                            >Clear Filter</button>
                        </div>
                        <div className="col-6">
                            <button
                                className="btn btn-primary w-100"
                                onClick={this.onApplyFilter}
                            >Apply Filter</button>
                        </div>
                    </div>
                    <label className='form-label mt-2'>Budget</label>
                    <div className='row justify-content-between'>
                        <div className="form-group col">
                            <input
                                onChange={this.onInputChange}
                                name='budgetMin'
                                type="number"
                                value={budgetMin}
                                className="form-control"
                                placeholder='min($)' />
                        </div>
                        <div className="form-group col">
                            <input
                                onChange={this.onInputChange}
                                name='budgetMax'
                                type="number"
                                value={budgetMax}
                                className="form-control"
                                placeholder='max($)' />
                        </div>
                    </div>
                    <label className='form-label mt-3'>Media</label>
                    <select className="form-select"
                        name='media'
                        onChange={this.onInputChange}>
                        <option disabled selected>Select</option>
                        {this.props.advertiseData && this.props.advertiseData.map((data, index) => (
                            <option
                                selected={media === data.type}
                                key={index}
                                value={data.type}
                            >{data.type}</option>
                        ))}
                    </select>
                    <div className={classes.MediaItem}>
                        {media.map((media, index) => (
                            <span key={index}>{media} <i onClick={_ => this.onRemoveMedia(media)} className="fas fa-times mx-1"></i></span>
                        ))}
                    </div>
                    <label className='form-label mt-3'>Sub Media</label>
                    <select className="form-select"
                        name='subMedia'
                        onChange={this.onInputChange}>
                        <option disabled selected>Select SubMedia for {this.state.selectedTypeIndex && this.props.advertiseData.length > 0 ? this.props.advertiseData[this.state.selectedTypeIndex].type : ''}</option>
                        {this.props.advertiseData.length > 0 &&
                            this.props.advertiseData[this.state.selectedTypeIndex].sub_type.map((sub_type, index) => (
                                <option
                                    selected={subMedia === sub_type}
                                    key={index}
                                    value={sub_type}
                                >{sub_type}</option>
                            ))}
                    </select>
                    <div className={classes.MediaItem}>
                        {subMedia.map((subMedia, index) => (
                            <span key={index}>{subMedia} <i onClick={_ => this.onRemoveSubMedia(subMedia)} className="fas fa-times mx-1"></i></span>
                        ))}
                    </div>
                    <label className='form-label mt-3'>Select Domains</label>
                    <Multiselect
                        placeholder={t(
                            'MyAds:CreateAdd.Step1.firstSection.typeOptions.4',
                        )}
                        showArrow
                        showCheckbox
                        closeIcon="cancel"
                        options={businessDomain}
                        selectedValues={domains}
                        onSelect={this.onSelectAdvertiseDomain}
                        onRemove={this.onRemoveAdvertiseDomain}
                        displayValue="domain"
                    />

                    <label className='form-label mt-3'>Sellers Type</label>
                    {sellerTypes.map((_sellerType, index) => (
                        <div className="form-check" key={index}>
                            <input
                                className="form-check-input"
                                type="radio"
                                name='sellerType'
                                value={_sellerType}
                                onChange={this.onInputChange}
                                checked={sellerType === _sellerType}
                            />
                            <label
                                className="form-check-label mt-1">
                                {_sellerType}
                            </label>
                        </div>
                    ))}
                    <label className='form-label mt-3'>Delivery Days</label>
                    <div>
                        <select
                            className='form-select'
                            name='deliveryDays'
                            onChange={this.onInputChange}>
                            <option selected={deliveryDays === null} disabled>Select</option>
                            {days.map((day, index) => (
                                <option
                                    selected={deliveryDays === day.value}
                                    key={index}
                                    value={day.value}
                                >{day.text}</option>
                            ))}
                        </select>
                    </div>
                    <label className='form-label mt-3'>Languages</label>
                    <div className="form-check">
                        <input
                            onChange={this.onInputChange}
                            className="form-check-input"
                            type="checkbox"
                            name='language'
                            value='English'
                            checked={languages.findIndex(language => language === 'English') !== -1}
                        />
                        <label
                            htmlFor='english'
                            className="form-check-label mt-1">
                            English
                        </label>
                    </div>
                    <div className="form-check">
                        <input
                            onChange={this.onInputChange}
                            className="form-check-input"
                            type="checkbox"
                            name='language'
                            value='French'
                            checked={languages.findIndex(language => language === 'French') !== -1}
                        />
                        <label
                            htmlFor='french'
                            class="form-check-label mt-1">
                            French
                        </label>
                    </div>
                </div>
            </div>
            <div className={classes.PartRight}>
                <div className={classes.PartRightItem}>
                    <div className='row'>
                        <div className='col-lg-6 col-12'>
                            <div>
                                <select className='form-select w-100 form-select' onChange={this.onChangeSort}>
                                    <option selected={sortBy === 'createdAt'} value='createdAt'>Newest tender</option>
                                    <option value='bids' selected={sortBy === 'bids'} >Highest bids</option>
                                </select>
                            </div>
                            <div className='mt-2'>
                                <span className='btn btn-sm bg-primary text-light fw-bold'><h6>{this.state.tenders.length}</h6> </span><span className='mx-1'>tenders found, pricing in CAD </span>
                            </div>
                        </div>
                        <div className='col-lg-6 col-12 d-flex justify-content-center'>
                            <div className={classes.PartRightPagination}>
                                <nav aria-label="Page navigation example">
                                    <ul className="pagination">
                                        <li className="page-item">
                                            <Link className="page-link" to="#" aria-label="Previous">
                                                <i className="fas fa-chevron-left"></i>
                                            </Link>
                                        </li>
                                        <li className="page-item"><Link className="page-link" to="#">1</Link></li>
                                        <li className="page-item"><Link className="page-link" to="#">2</Link></li>
                                        <li className="page-item"><Link className="page-link" to="#">3</Link></li>
                                        <li className="page-item">
                                            <Link className="page-link" to="#" aria-label="Next">
                                                <i className="fas fa-chevron-right"></i>
                                            </Link>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                {this.props.loading ? <Spinner /> :
                    this.state.tenders.map((tender, index) => (
                        <Link to={`/tender-description?info_id=${ tender._id }`} key={index}>
                            <div className={classes.PartRightItem}>
                                <div className={classes.PartRightItems}>
                                    <div className='col-lg-9 col-12'>
                                        <h6>{tender.title}</h6>
                                        {this.props.isAuthenticated &&
                                            <div className='my-2'>
                                                <span>{tender.description.length > 300 ? tender.description.trim().substr(0, 300) + '...' : tender.description}</span>
                                            </div>}
                                    </div>
                                    <div className='col-lg-3 col-12 mx-lg-1 mx-0'>
                                        <h6>$({tender.budget.budgetMin ? tender.budget.budgetMin : ''} - {tender.budget.budgetMax ? tender.budget.budgetMax : ''})/ {tender.budget.fixed} cad</h6>
                                        <div className='my-2'>
                                            <span className='badge bg-primary mx-1'>
                                                {tender.bids}</span>
                                            <span>bids</span>
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.MediaItem}>
                                    <span>Media :</span>
                                    {tender.media.map((media, index) => (
                                        <span key={index}>{media}</span>
                                    ))}
                                </div>
                                <div className={classes.MediaItem}>
                                    <span>SubMedia :</span>
                                    {tender.subMedia.map((subMedia, index) => (
                                        <span key={index}>{subMedia}</span>
                                    ))}
                                </div>
                                <div className={classes.DomainItem}>
                                    <span>Domains :</span>
                                    {tender.domains && this.getDomains(tender.domains)}
                                </div>

                                <div className='d-flex justify-content-between mt-3 align-items-center'>
                                    <div>
                                        <span>{getDate(tender.createdAt)}</span>
                                    </div>
                                    {this.props.isAuthenticated &&
                                        <div>
                                            <Link to={`/tender-description?info_id=${ tender._id }`}>
                                                <button className='btn btn-sm btn-outline-primary'>Offer Now</button>
                                            </Link>
                                        </div>}
                                </div>
                            </div>
                        </Link>
                    ))}
                {this.props.tenders.length === 0 && !this.props.loading && <p>No, Tender created yet.</p>}
            </div>
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        advertiseData: state.dashboardAdvertise.advertiseData,
        isAuthenticated: state.userAuth.isAuthenticated,
        message: state.tender.message,
        loading: state.tender.loading,
        tenders: state.tender.tenders,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchTypeAndSubTypes: () => dispatch(actions.fetchTypeAndSubtypes()),
        onGetTenders: (filters) => dispatch(actions.onGetTenders(filters)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation(['translation', 'MyAds', 'media'])(FindTenders));

