import React, { Component } from 'react'
import { SimpleSpinner } from '../../../components/UI/Spinner/Spinner'
import { Link } from 'react-router-dom'
import classes from './Auth.module.css';
import axios from "../../../axios";

import SimpleSnackbar from '../../../components/Snackbar/Snackbar'

class ForgotPassword extends Component {
    state = {
        email: '',
        error: false,
        message: '',
        loading: false
    }

    componentDidMount() {
        window.scroll(0, 0);
    }

    onSubmitForm = (e) => {
        e.preventDefault();
        this.setState({ loading: true });

        axios.post('/user/passwordResetEmail', { email: this.state.email })
            .then((response) => {
                this.setState({ message: response.data.message, email: '' });
                this.setState({ loading: false });
                setTimeout(() => {
                    this.setState({ message: '' });
                }, 10000);
            })
            .catch(error => {
                console.log(error)
            });
    }

    render() {
        return <React.Fragment>
            {this.props.message && !this.props.error &&
                <SimpleSnackbar open={true} message={this.props.message} type='success' />
            }
            {this.props.message && this.props.error &&
                <SimpleSnackbar open='true' message={this.props.message} type='error' />
            }

            <div className={classes.ForgotMain}>
                <div className={`m-md-5 m-2 p-4 shadow-sm bg-white text-center rounded ${ classes.ForgotForm }`}>
                    {this.state.message && <div className="rounded bg-primary text-white my-2 p-2">{this.state.message}</div>}
                    <h5>Reset Password</h5>
                    <form onSubmit={this.onSubmitForm}>
                        <label className="text-muted mt-2">Please enter your email address and we'll send you a link to reset your password.</label>
                        <div className="form-group mt-3">
                            <input
                                type="email"
                                value={this.state.email}
                                onChange={e => this.setState({ email: e.target.value })}
                                required
                                className="form-control"
                                placeholder="Email" />
                        </div>
                        {this.state.loading ? <div className='my-2'>
                            <SimpleSpinner />
                        </div> : <input type="submit" value='Submit' className="btn btn-primary w-100 mt-4"></input>}
                    </form>
                    <div className={classes.ModalFooter}>
                        <Link to="/login">Back to login</Link>
                    </div>
                </div>
            </div>
        </React.Fragment>
    }
}

export default ForgotPassword;
