import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import Aux from '../Fragment/Fragment'
import Toolbar from '../../components/Navigation/Toolbar/ProfileDashboardToolbar';
import SideDrawer from '../../components/Navigation/SideDrawer/ProfileDashboardSideDrawer';


class DashboardLayout extends Component {
    state = {
        showSideDrawer: false
    }
    componentDidMount() {
        window.scroll(0, 0);
    }
    sideDrawerClosedHandler = () => {
        this.setState({ showSideDrawer: false });
    }

    sideDrawerToggleHandler = () => {
        this.setState((prevState) => {
            return { showSideDrawer: !prevState.showSideDrawer };
        });
    }

    render() {
        return (
            <Aux>
                <Toolbar
                    drawerToggleClicked={this.sideDrawerToggleHandler} />
                <SideDrawer
                    open={this.state.showSideDrawer}
                    closed={this.sideDrawerClosedHandler} />
                <main >
                    {this.props.children}
                </main>
            </Aux>
        )
    }
}

const DashboardLayoutRoute = ({ component: Component, ...rest }) => {
    return  <Route exact {...rest} render={matchProps => (
            <DashboardLayout>
                <Component {...matchProps} />
            </DashboardLayout>
        )} />
};

export default DashboardLayoutRoute;