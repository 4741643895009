import React from "react";
import classes from '../ChatHome.module.css';
import "./chatList.css";
import ChatListItems from "./ChatListItems";
import { Avatar } from "./Avatar";
import socket from '../../../../config/socket';

function ChatList(props) {
    const [allUsers, setUsers] = React.useState([]);

    React.useEffect(() => {
        socket.on('connect', () => {
            var users = [...allUsers];

            users = users.map((user) => {
                if (user.self) {
                    user.connected = true;
                    return user;
                } else {
                    return user;
                }
            });

            setUsers(users);
        })

        socket.on('disconnect', () => {
            var users = [...allUsers];
            users = users.map((user) => {
                if (user.self) {
                    user.connected = false;
                    return user;
                } else {
                    return user;
                }
            })
            setUsers(users);
        })

        socket.on('users', (users) => {
            users.forEach((user) => {
                var allUsers = [...users]
                allUsers = allUsers.map((existingUser) => {
                    if (existingUser.userID === user.userID) {
                        existingUser.connected = user.connected
                        existingUser.messages = user.messages
                        return existingUser;
                    } else {
                        return existingUser;
                    }
                });
                setUsers(allUsers);

                user.self = user.userID === socket.userID;
                setUsers([...users, user]);
            });

            // put the current user first, and sort by username
            var ggUsers = users.sort((a, b) => {
                if (a.self) return -1
                if (b.self) return 1
                if (a.username < b.username) return -1
                return a.username > b.username ? 1 : 0
            })
            setUsers(ggUsers);
        });

        socket.on('user connected', (user) => {
            var connectUsers = [...this.state.users]
            connectUsers.map((existingUser) => {
                if (existingUser.userID === user.userID) {
                    existingUser.connected = true
                    return existingUser
                } else {
                    return existingUser
                }
            })
            setUsers(connectUsers);
        });

        socket.on('user disconnected', (id) => {
            var ggUsers = [...this.state.users]
            ggUsers = ggUsers.map((user) => {
                if (user.userID === id) {
                    user.connected = false
                    return user;
                } else {
                    return user;
                }
            })
            setUsers(ggUsers);
        });

        socket.on('private message', ({ payload, from }) => {
            var nnUsers = [allUsers];

            nnUsers = nnUsers.map((user) => {
                if (user.userID === from) {
                    user.lastMessage = payload.message;
                    user.readMessage = false;
                    if (props.currentUser) {
                        if (user.userID !== props.currentUser.email) {
                            user.readMessage = false;
                        }
                    } else {
                        user.readMessage = false;
                    }
                    return user;
                } else {
                    return user;
                }
            })
            setUsers(nnUsers);
        });
    });

    const generateList = () => {
        const { users } = props;
        const stateUsers = allUsers;

        if (users.length === 0) {
            return []
        }

        let userList = users;
        if (stateUsers.length > 0 && users.length > 0) {
            for (let i = 0; i < stateUsers.length; i++) {
                for (let j = 0; j < users.length; j++) {
                    if (stateUsers[i].userID === users[j].email) {
                        if (stateUsers[i].connected === true) {
                            users[j].status = 'online'
                            if (stateUsers[i].lastMessage) {
                                users[j].lastMessage = stateUsers[i].lastMessage;
                                users[j].files = stateUsers[i].files;
                            }
                            userList[j] = users[j];
                        }
                        continue;
                    }
                }
            }
            return userList;
        } else {
            return users;
        }
    }

    const [index, setIndex] = React.useState(0);

    const onCurrentContactSelect = (contact, index) => {
        props.onContactSelect(contact);
        setIndex(index);
    }

    const [search, setSearch] = React.useState('');
    if (generateList().length > 0 && index === 0 && !search) {
        props.onContactSelect(generateList()[0]);
    } else if (generateList().length > 0 && index > 0 && !search) {
        props.onContactSelect(generateList()[index]);
    }

    let usersList = generateList();
   
    if (props.latestMessage) {
        usersList[index].lastMessage = props.latestMessage.message;
        usersList[index].files = props.latestMessage.files;
        usersList[index].mood = props.latestMessage.date;
    }

    const onChange = (e, searchQuery) => {
        e.preventDefault();
        setSearch(searchQuery);
    }

    const renderList = () => {
        if (search) {
            usersList = usersList.filter(service => service.name.toLowerCase().includes(search.toLowerCase()));
        }
        return usersList.length > 0 ? usersList.map((item, index) => {
            return <ChatListItems
                key={item.id}
                animationDelay={index + 1}
                isOnline={item.status === 'online' ? "active" : ""}
                data={item}
                onContactSelect={(contact) => onCurrentContactSelect(contact, index)}
                onOpenOrHide={props.onOpenOrHide}
                mainUser={props.mainUser && props.mainUser}
                currentUser={props.currentUser}
                screenWidth={props.screenWidth}
            />

        })
            : <span className="text-muted">No users have...!</span>
    }

    return <div className={classes.ChatList} style={props.screenWidth < 800 && props.show ? { display: 'none', visibility: 'hidden' } : {}}>
        <div className="main__chatlist">
            <div className="blocks">
                <div className="current-chatting-user">
                    <Avatar
                        isOnline="active"
                        image={props.mainUser && props.mainUser.profile_image}
                    />
                    <div>
                        <p>{props.mainUser && props.mainUser.full_name}</p>
                        <small>{props.mainUser && props.mainUser.username}</small>
                    </div>
                </div>
            </div>
            <div className="chatlist__heading mt-3">
                <h5>Chats</h5>
                {/* <button className="btn-nobg">
                    <i className="fa fa-ellipsis-h"></i>
                </button> */}
            </div>
            <div className="chatList__search">
                <div className="search_wrap">
                    <input type="text" value={search} placeholder="Search Here" required onChange={e => (onChange(e, e.target.value))} />
                    <button className="search-btn">
                        <i className="fa fa-search"></i>
                    </button>
                </div>
            </div>
            <div className="chatlist__items">
                {renderList()}
            </div>
        </div>
    </div>
}

export default ChatList;


