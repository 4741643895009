import React from 'react'
import classes from '../Settings.module.css'
import SettingsNavigation from '../SettingsNavigation'
import {connect} from 'react-redux'

const Balance = (props) => {
  return (
    <div className={classes.Settings}>
      <div className={classes.PartLeft}>
        <SettingsNavigation user={props.user} />
      </div>
      <div className={classes.PartRight}>
        <p>Balance</p>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.userAuth.user,
  }
}

export default connect(mapStateToProps, null)(Balance)
