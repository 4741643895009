import React, { Component } from 'react';
import classes from '../CreateAdd.module.css';
import Button from '../../../../components/UI/Button/Button';
import StepProgress from './StepProgress';
import { Divider } from '../../../../components/UI/Spacing';
// import { AddAPhoto, VideoCall } from '@material-ui/icons';

export class Step4 extends Component {
    componentDidMount() {
        window.scroll(0.0, 0.0)
    }
    state = { isClicked: false };

    continue = (event, isFieldOk) => {
        event.preventDefault();
        if (isFieldOk) this.props.nextStep();
        this.setState({ isClicked: true });
        setTimeout(() => {
            this.setState({ isClicked: false });
        }, 4000)
    };

    back = e => {
        e.preventDefault();
        this.props.prevStep();
    };

    render() {
        const { values, handleTextInputChange, t,
            // handlePreferencesFiles,
            // preferencesRemoveFile
        } = this.props;
        let isFieldOk = true;

        return <React.Fragment>
            <Divider orientation='v' space='2' />
            <StepProgress step={this.props.step} />
            <div className={classes.NB}><small>{t('MyAds:CreateAdd.NB')}</small></div>
            <h5>Ad Preferences</h5>
            <div className={classes.Divider}></div>
            <div className='row col-12'>
                <div className='col-lg-6 col-12'>
                    <label className='form-label'>What types of ads/video/poster/channel/banner to publish?</label>
                    <textarea
                        className="form-control"
                        type='text'
                        maxLength='100'
                        value={values.preference}
                        onChange={(event) => handleTextInputChange(event, 'preference')}
                    ></textarea>
                </div>
                <div className='col-lg-6 col-12 mt-3 mt-lg-0'>
                    <label className='form-label'>Which types of ads or order to accept?</label>
                    <textarea
                        className="form-control"
                        type='text'
                        maxLength='100'
                        value={values.whatAccept}
                        onChange={(event) => handleTextInputChange(event, 'whatAccept')}
                    ></textarea>
                </div>
            </div>
            <div className='row col-12 mt-4'>
                <div className='col-lg-6 col-12'>
                    <label className='form-label'>Poster media(size, quality, weight, time, long,distance, presence etc)</label>
                    <textarea
                        className="form-control"
                        type='text'
                        maxLength='100'
                        value={values.extra}
                        onChange={(event) => handleTextInputChange(event, 'extra')}
                    ></textarea>
                </div>
                <div className='col-lg-6 col-12 mt-3 mt-lg-0'>
                    <label className='form-label'>Which kind of customers you wants? Or not want?</label>
                    <textarea
                        className="form-control"
                        type='text'
                        maxLength='100'
                        value={values.whatWant}
                        onChange={(event) => handleTextInputChange(event, 'whatWant')}
                    ></textarea>
                </div>
            </div>
            {/* <Divider orientation='v' space='4' />
            <label className='form-label'>Have any files?</label>
            <div className={classes.Photos}>
                {values._filesPreviews.images.length > 0 ?
                    values._filesPreviews.images.map((image, index) => (
                        <div className={classes.PhotosItems} key={index}>
                            <img src={image.url} alt='ImagePhoto' />
                            <Button
                                clicked={(event) => preferencesRemoveFile(image.url, 'images', index)}>
                                <i className="fas fa-times"></i>
                            </Button>
                        </div>
                    )) : <div className={classes.FileInput}>
                        <input
                            type='file'
                            accept='.png,.jpg,.jpeg, .gif'
                            multiple
                            onChange={(event) => handlePreferencesFiles(event, 'images')}
                            id='image' />
                        <label htmlFor='image'>
                            <AddAPhoto />
                        </label>
                    </div>}
                {values._filesPreviews.images.length > 0 && <div className={classes.FileInput}>
                    <input type='file'
                        accept='.png,.jpg,.jpeg, .gif'
                        multiple
                        onChange={(event) => handlePreferencesFiles(event, 'images')}
                        id='image' />
                    <label htmlFor='image' style={{ background: 'gray' }}>
                        <i className="fas fa-plus"></i>
                    </label>
                </div>}
            </div>
            <div className={classes.Info}>
                <strong>(max-size:10MB)</strong>
            </div>
            {this.state.isClicked && !isFieldOk ? <div className={classes.Error}>
                <strong>{t('MyAds:CreateAdd.Step4.errors.0')}</strong>
            </div> : null}
            <div className={classes.Divider}></div>
            <div className={classes.Photos}>
                {values._filesPreviews.video.length > 0 ?
                    values._filesPreviews.video.map((video, index) => (
                        <div className={classes.PhotosItems} key={index}>
                            <video width="330" height="250" controls>
                                <source src={video.url} type="video/mp4"></source>
                            </video>
                            <Button
                                clicked={(event) => preferencesRemoveFile(video.url, 'video', index)}
                            >
                                <i className="fas fa-times"></i>
                            </Button>
                        </div>
                    )) : <div className={classes.FileInput}>
                        <input
                            type='file'
                            accept='.mp4'
                            multiple
                            onChange={(event) => handlePreferencesFiles(event, 'video')}
                            id='video' />
                        <label htmlFor='video'>
                            <VideoCall />
                        </label>
                    </div>}
                {values._filesPreviews.video.length > 0 && <div className={classes.FileInput}>
                    <input
                        type='file'
                        accept='.mp4'
                        multiple
                        onChange={(event) => handlePreferencesFiles(event, 'video')}
                        id='video' />
                    <label htmlFor='video' style={{ background: 'gray' }}>
                        <i className="fas fa-plus"></i>
                    </label>
                </div>}
            </div> */}
            <Divider orientation='v' space='4' />
            <div className={classes.Divider}></div>
            <div className={classes.StepsButton}>
                <Button buttonType='AppleDark' clicked={this.back} >  <i className='fas fa-chevron-left'></i>{t('MyAds:CreateAdd.button2')}</Button>
                <Button
                    buttonType='Submit'
                    clicked={(e) => this.continue(e, isFieldOk)}
                >{t('MyAds:CreateAdd.button1')}  <i className='fas fa-chevron-right'></i></Button>
            </div>
        </React.Fragment>
    }
}

export default (Step4);
