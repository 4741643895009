import React from 'react'
import classes from './components/Profile.module.css'
import { connect } from 'react-redux'
import { Line, Divider } from '../../../components/UI/Spacing'
import '../../../components/Tabs/Tabs.css'
import * as actions from '../../../store/actions'
import { Spinner } from '../../../components/UI/Spinner/Spinner'
import { getDate } from '../../../shared/utility'
import { RowItem, Label, LabelValue, ProfileRowItem } from './components/Items'
import { Zoom } from 'react-reveal'
import { AdsComponent } from './components/AdsComponent'
import Statistics from './components/Statistics'
import { withTranslation } from 'react-i18next'
import axios from '../../../axios'
import { AllReviewsRatings } from '../../NormalUser/Advertises/SingleAdvertise/AllComponents/AllReviewsRatings'

class PublicProfile extends React.Component {
  state = { role: '', reviewsRating: [] }

  componentDidMount() {
    const history = this.props.history.location.pathname.split('/')
    if (history[3]) {
      this.setState({ role: history[2] })
      this.props.onGetPublicProfile({
        username: history[3],
      })
      this.props.fetchPublicUserAdvertises({
        username: history[3],
      })
      this.getUsersReviewsRating(history[3])
    }
  }

  specificItemsCount = (itemsArray) => {
    const allItems = []
    const counts = {}
    itemsArray.forEach(
      (element) => (counts[element] = (counts[element] || 0) + 1),
    )
    for (let item in counts) {
      const _item = {}
      _item.name = item
      _item.count = counts[item]
      allItems.push(_item)
    }
    return allItems
  }

  getUsersReviewsRating = (username) => {
    axios
      .get(`/dashboardAdvertise/users-review-rating?username=${ username }`)
      .then((res) => {
        this.setState({ reviewsRating: res.data.reviewsRating })
      })
      .catch((error) => console.log(error))
  }

  render() {
    const { t } = this.props;

    const workingDomains = [];
    let workingMedia = [];
    let workingSubMedia = [];

    let advertisesItemsList = <Spinner />
    let filteredAdvertise = [];

    if (this.props.advertisesData) {
      filteredAdvertise = this.props.advertisesData.filter((advertise) => {
        workingMedia = [...workingMedia, ...advertise.types];
        workingSubMedia = [...workingSubMedia, ...advertise.subtypes];

        advertise.domains.forEach((data) => workingDomains.push(data.domain));

        return advertise.is_published === true
      })
    }

    if (this.props.advertisesData && this.props.profile) {
      advertisesItemsList = (
        <React.Fragment>
          {filteredAdvertise.map((advertise, index) => (
            <Zoom delay={200 * (index + 1)}>
              <AdsComponent
                index={index}
                user={this.props.profile.user}
                advertisesData={advertise}
              />
            </Zoom>
          ))}
        </React.Fragment>
      )
    }
    let profileData = <Spinner />
    if (this.props.profile) {
      const { gender, cover_photo, description, country, state, city, user } =
        this.props.profile
      const { full_name, username, profile_image, createdAt, accountType } = user
      profileData = (
        <React.Fragment>
          <div className={classes.CoverPhoto}>
            <img src={cover_photo} alt="cover img" />
            <div className={classes.UserImage}>
              <img src={profile_image} alt="profile" />
              <div className={classes.Level}>LEVEL ONE</div>
            </div>
          </div>
          <div
            className={classes.PartLeftItem}
            style={{
              borderTopLeftRadius: '0px',
              borderTopRightRadius: '0px',
              borderTop: 'none',
            }}>
            <h5 className="fw-bold mx-2">{username} </h5>
            {/* <div className='d-flex justify-content-start align-items-center mx-2 mt-1'>
                        <div className='text-primary'>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                        </div>
                        <div><small className='text-dark fw-bold mx-1'>5.0</small></div>
                        <div><small className='text-secondary fw-lighter'>(50 reviews)</small>
                        </div>
                    </div> */}
            <Line orientation="h" space="3" height="1" width="100" />
            <ProfileRowItem
              icon="fas fa-user-tag"
              label={t('roleAs')}
              value={
                this.state.role.charAt(0).toUpperCase() +
                this.state.role.slice(1)
              }
            />
            <ProfileRowItem
              icon="fas fa-user-cog"
              label={t('accType')}
              value={accountType}
            />
            <ProfileRowItem
              icon="fas fa-map-marker-alt"
              label={t('country')}
              value={country}
            />
            <ProfileRowItem
              icon="fas fa-user"
              label={t('memberSince')}
              value={getDate(createdAt)}
            />
            <ProfileRowItem
              icon="far fa-clock"
              label={t('avgResponse')}
              value="2H"
            />
            <ProfileRowItem
              icon="fas fa-paper-plane"
              label={t('lastDelivary')}
              value={"1h " + t('ago')}
            />
          </div>
          <div className={classes.PartLeftItem}>
            <Label label={t('companyName')} />
            <LabelValue value={full_name} />
            <Divider orientation="v" space="3" />
            <Label label={t('descripton')} />
            <LabelValue value={description} />
            <Line orientation="h" space="2" height="1" width="100" />
            <Label label={t('languages')} />
            <div className="col-12 my-2">
              <div className="row justify-content-center align-items-center my-2">
                <p className="text-secondary">
                  {t('english')}<small className="text-danger mx-1"> {t('fluent')}</small>
                </p>
              </div>
            </div>
            <Line orientation="h" space="2" height="1" width="100" />
            <Label label={t('address')} />
            <RowItem label={t('state')} value={state} />
            <RowItem label={t('city')} value={city} />
            <Line orientation="h" space="2" height="1" width="100" />
            <Label label={t('gender')} />
            <LabelValue value={gender} />
          </div>
          <div className={classes.PartLeftItem}>
            <Label label={t('workingMediaAndSubMedia')} />
            <Statistics
              workingDomains={this.specificItemsCount(workingDomains)}
              workingMedia={this.specificItemsCount(workingMedia)}
              workingSubMedia={this.specificItemsCount(workingSubMedia)}
            />
          </div>
          {this.state.role === 'seller' && (
            <div className={classes.PartLeftItem}>
              <Label label={t('reviewRating')} />
              <div className="mt-3"></div>
              <AllReviewsRatings reviewsRating={this.state.reviewsRating} />
            </div>
          )}
          {this.state.role === 'seller' && (
            <div className={classes.PartLeftItem}>
              <Label label={t('activeAds')} />
              <div className="d-flex flex-wrap justify-content-around">
                {advertisesItemsList}
              </div>
            </div>
          )}
        </React.Fragment>
      )
    }
    return (
      <div className={classes.Profile}>
        <div className={classes.PublicProfile}>{profileData}</div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.profile.profile,
    advertisesData: state.dashboardAdvertise.userCreatedAdvertises,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onGetPublicProfile: (userInfo) =>
      dispatch(actions.getPublicProfile(userInfo)),
    fetchPublicUserAdvertises: (userInfo) =>
      dispatch(actions.fetchPublicUserAdvertises(userInfo)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation(['translation'])(PublicProfile))
