// @ts-nocheck
import React from 'react'
import { withTranslation } from 'react-i18next'
import i18next from 'i18next'
import classes from './NavigationItems.module.css'
import {
  AppStartNavigationItem,
  NormalNavigationItem,
} from './NavigationItem/NavigationItem'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import ProfileInfo from './ProfileInfo'
import { getAllUnreadMessages } from '../../../../store/actions/ActionsFiles/Chat'
import SwitchUser from '../../SwitchUser'

class NavigationItems extends React.Component {
  constructor() {
    super()
    let lang = localStorage.getItem('i18nextLng')
    this.state = {
      selectedValue: lang,
    }
  }

  componentDidMount() {
    this.props.allUnreadMessages()
  }

  handleChange = (e) => {
    this.setState({
      selectedValue: e.target.value,
    })
    i18next.changeLanguage(e.target.value);

    localStorage.setItem('translateTo', e.target.value === 'en-US' ? 'en' : 'fr');
    window.location.reload();
  }

  render() {
    const { t } = this.props;

    return (
      <React.Fragment>
        <ul className={classes.NavigationItems}>
          <div className={classes.dropdownForUser}>
            {this.props.isAuthenticated ? (
              this.props.user.role === 'seller' ? (
                <AppStartNavigationItem
                  scrolled={this.props.scrolled}
                  exact={true}
                  link="/find-tenders">
                  {/* Find Tenders */}
                  {t('navigations.3')}
                </AppStartNavigationItem>
              ) : (
                <AppStartNavigationItem
                  scrolled={this.props.scrolled}
                  exact={true}
                  link="/find-audience">
                  {/* Find Audience */}
                  {t('navigations.2')}
                </AppStartNavigationItem>
              )
            ) : (
              <React.Fragment>
                <span
                  className={[classes.dropdownMenu, this.props.scrolled && classes.dropdownMenuOnScroll].join(' ')}>
                  {t('navigations.1')}<i className='fa fa-caret-down mx-1'></i>
                </span>
                <div className={classes.dropdownContentForUser}>
                  <NormalNavigationItem
                    exact={true}
                    link="/find-audience">
                    <div className={classes.DropMenuStyle}>
                      <span>
                         {t('navigations.2')}
                      </span>
                    </div>
                  </NormalNavigationItem>
                  <NormalNavigationItem
                    exact={true}
                    link="/find-tenders">
                    <div className={classes.DropMenuStyle}>
                      <span>
                        {t('navigations.3')}
                      </span>
                    </div>
                  </NormalNavigationItem>
                </div>
              </React.Fragment>
            )}
          </div>

          <div className={classes.dropdownForUser}>
            <React.Fragment>
              <span className={[classes.dropdownMenu, this.props.scrolled && classes.dropdownMenuOnScroll].join(' ')}>
                {t("partnership")}<i className='fa fa-caret-down mx-1'></i>
              </span>
              <div className={classes.dropdownContentForUser}>
                <NormalNavigationItem
                  exact={true}
                  link="/new-partner-request">
                  <div className={classes.DropMenuStyle}>
                    <span>
                  
                    {t("be_partner")}
                    </span>
                  </div>
                </NormalNavigationItem>
                {this.props.isAuthenticated && this.props.user.role === 'seller' &&
                  <NormalNavigationItem
                    exact={true}
                    link="/find-partner">
                    <div className={classes.DropMenuStyle}>
                      <span>
                         {t("partner_request")}
                      </span>
                    </div>
                  </NormalNavigationItem>}
                {!this.props.isAuthenticated && <NormalNavigationItem
                  exact={true}
                  link="/find-partner">
                  <div className={classes.DropMenuStyle}>
                    <span>
                   {t("partner_request")}
                    </span>
                  </div>
                </NormalNavigationItem>}
                {this.props.isAuthenticated && <React.Fragment>
                  <NormalNavigationItem
                    exact={true}
                    link="/my-partner-request">
                    <div className={classes.DropMenuStyle}>
                      <span>
                        {t("my_partnership")}
                      </span>
                    </div>
                  </NormalNavigationItem>
                   {/* <NormalNavigationItem
                    exact={true}
                     link="/get-paid">
                     <div className={classes.DropMenuStyle}>
                       <span>
                         <i className='fa fa-angle-right mx-1'></i>  Get paid
                       </span>
                     </div>
                   </NormalNavigationItem> */}
                </React.Fragment>}
              </div>
            </React.Fragment>
          </div>
          {this.props.isAuthenticated ? (
            this.props.user.role === 'seller' ? (
              <AppStartNavigationItem
                scrolled={this.props.scrolled}
                exact={true}
                link="/start-selling">
                {t('appStartNavigation.0')}
              </AppStartNavigationItem>
            ) : (
              <AppStartNavigationItem
                scrolled={this.props.scrolled}
                exact={true}
                link="/launch-tender">
                {/* Launch a Tender */}
                {t('navigations.0')}
              </AppStartNavigationItem>
            )
          ) : (
            <React.Fragment>
              <AppStartNavigationItem
                scrolled={this.props.scrolled}
                exact={true}
                link="/launch-tender">
                {/* Launch a Tender */}
                {t('navigations.0')}
              </AppStartNavigationItem>
              <AppStartNavigationItem
                scrolled={this.props.scrolled}
                exact={true}
                link="/start-selling">
                {t('appStartNavigation.0')}
              </AppStartNavigationItem>
            </React.Fragment>
          )}
          {this.props.isAuthenticated && (
            <div className={classes.dropdownForUser}>
              <AppStartNavigationItem
                scrolled={this.props.scrolled}
                link={`/${this.props.user.role}/dashboard`}>
                {t('appStartNavigation.1')}<i className='fa fa-caret-down mx-1'></i>
              </AppStartNavigationItem>
              <div className={classes.dropdownContentForUser}>
                {this.props.user.role === 'seller' ?
                  <React.Fragment>
                    <NormalNavigationItem
                      exact={true}
                      link="/all-audiences">
                      {t('navigations.6')}
                    </NormalNavigationItem>
                    <NormalNavigationItem
                      exact={true}
                      link="/my-all-bids">
                      {t('navigations.7')}
                    </NormalNavigationItem>
                  </React.Fragment>
                  : <React.Fragment>
                    <NormalNavigationItem
                      link={`/my-all-tenders`}>
                      {t('navigations.8')}
                    </NormalNavigationItem>
                  </React.Fragment>}
                <NormalNavigationItem
                  link={`/${this.props.user.role}/all-orders`}>
                  {t('navigations.9')}
                </NormalNavigationItem>
                <NormalNavigationItem link="/inbox">
                  Messages
                  {this.props.unreadmessages !== 0 && (
                    <span
                      style={{
                        background: 'red',
                        borderRadius: '50%',
                        display: 'inline-block',
                        fontSize: 12,
                        padding: '4px',
                        margin: '2px',
                        color: 'white',
                      }}></span>
                  )}
                </NormalNavigationItem>
              </div>
            </div>
          )}
          {this.props.isAuthenticated && (
            <React.Fragment>
              <SwitchUser
                user={this.props.user}
                t={t}
                token={this.props.token}
              />
              <Link to="/profile">
                <ProfileInfo user={this.props.user} />
              </Link>
            </React.Fragment>
          )}
          {!this.props.isAuthenticated && (
            <React.Fragment>
              <AppStartNavigationItem
                scrolled={this.props.scrolled}
                link="/join">
                {t('appStartNavigation.5')}
              </AppStartNavigationItem>
              <AppStartNavigationItem
                scrolled={this.props.scrolled}
                link="/login">
                {t('appStartNavigation.4')}
              </AppStartNavigationItem>
            </React.Fragment>
          )}
          <div className="mx-3">
            <select
              className="form-select "
              value={this.state.selectedValue}
              onChange={this.handleChange}>
              <option value="en-US">{t('appStartNavigation.6')}</option>
              <option value="fr">{t('appStartNavigation.7')}</option>
            </select>
          </div>
        </ul>
      </React.Fragment>
    )
  }
}
// export default navigationItems;

const mapStateToProps = (state) => {
  return {
    user: state.userAuth.user,
    token: state.userAuth.token,
    isAuthenticated: state.userAuth.token !== null,
    unreadmessages: state.chat.unreadmessages,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    allUnreadMessages: () => dispatch(getAllUnreadMessages()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(NavigationItems))
