import React from 'react';
import classes from './Toolbar.module.css';
import Logo from '../../Logo/Logo';
import NavigationItems from './NavigationItems/ProfiledashboardNavigationItems';
import DrawerToggle from '../SideDrawer/DrawerToggle/DrawerToggle';

const toolbar = (props) =>
    <header className={[classes.Toolbar, classes.DashboardToolbar].join(' ')}>
        <DrawerToggle clicked={props.drawerToggleClicked} />
        <div className={classes.AppHeaderName2}>Umediad</div>
        <div className={classes.Logo}>
            <Logo path='/' />
            <div className={classes.AppHeaderName}>Umediad</div>
        </div>
        <nav className={classes.DesktopOnly}>
            <NavigationItems />
        </nav>
    </header>

export default toolbar;