import * as actionTypes from '../actions/ActionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    partnersRequests: [],
    interestedPartners: [],
    requestPartner: null,
    loading: false,
    message: null,
    error: false,
    hasPartner: false,
    confirmPartner: '',
    adsUnderPartner:[]
};

const partnerStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        error: false,
        message: null,
    });
}


const sellerHasPartner = (state, action) => {
    return updateObject(state, {
        hasPartner: action.payload.hasPartner,
    });
}

const partnersSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: false,
        partnersRequests: action.payload.requests,
        message: action.payload.message,
    });
}

const partnerSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: false,
        requestPartner: action.payload.requestPartner,
    });
}

const partnerFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: true,
        message: action.payload.message,
    });
}

const confirmOrDeletePartner = (state, action) => {
    return updateObject(state, {
        confirmPartner: action.payload.message,
    });
}


const partnersInterested = (state, action) => {
    return updateObject(state, {
        error: true,
        interestedPartners: action.payload.interested,
    });
}

const getAdsUnderPartner = (state, action) => {
    return updateObject(state, {
        adsUnderPartner: action.payload.adsUnderPartner,
    });
}

export const PartnerReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.START_PARTNER_REQUEST: return partnerStart(state, action);
        case actionTypes.PARTNER_FETCH_SUCCESS: return partnersSuccess(state, action);
        case actionTypes.REQUEST_FETCH_SUCCESS: return partnerSuccess(state, action);
        case actionTypes.PARTNER_FAIL: return partnerFail(state, action);
        case actionTypes.PARTNER_INTERESTED_SUCCESS: return partnersInterested(state, action);
        case actionTypes.SELLER_HAS_PARTNER: return sellerHasPartner(state, action);
        case actionTypes.PARTNER_CONFIRM_SUCCESS: return confirmOrDeletePartner(state, action);
        case actionTypes.ADS_UNDER_PARTNER: return getAdsUnderPartner(state, action);
        default: return state;
    }
};

