import React from 'react';
import classes from '../Settings.module.css';
import SettingsNavigation from '../SettingsNavigation';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Line } from '../../../../components/UI/Spacing';

const Account = (props) => {
    return <div className={classes.Settings}>
        <div className={classes.PartLeft}>
            <SettingsNavigation user={props.user} />
        </div>
        <div className={classes.PartRight}>
            <div className={classes.PartRightHeader}>
                <span className='mt-2 float-right text-secondary'>Need To Update Your Public Profile? Go to <Link to='/profile' className="link-success"> My Profile</Link>
                </span>
            </div>
            <Line orientation='h' space='4' height='1' width='100' backgroundColor={undefined} />
            <div className='row justify-content-between align-items-center my-2'>
                <div className='col-lg-4 col-md-4 col-sm-12'>
                    <small className='text-secondary fw-bold '>EMAIL</small>
                </div>
                <div className='col-lg-8 col-md-8 col-sm-12 mt-2'>
                    <div className="form-group">
                        <input
                            name='email'
                            type="text"
                            className="form-control"
                            placeholder='m********************gmail.com' />
                    </div>
                </div>
            </div>
            <div className='row justify-content-between align-items-center py-3'>
                <div className='col-lg-4 col-md-4 col-sm-12'>
                        <small className='text-secondary fw-bold'>ONLINE STATUS</small><br />
                        <small className='text-muted' style={{ fontSize: '13px' }}>
                            When online, your Ads are visible under the Online search filter.
                    </small>
                </div>
                <div className='col-lg-8 col-md-8 col-sm-12 mt-2'>
                    <div className="form-group col-lg-6 col-sm-12 col-md-10">
                        <select className="form-select h-100 text-secondary" style={{fontSize:'13px'}}>
                            <option selected disabled>GO OFFLINE FOR</option>
                            <option>1 HOUR</option>
                            <option>1 DAY</option>
                            <option>3 DAYS</option>
                            <option>FOREVER</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className="col-12 py-3 mt-2">
                <div className='d-flex justify-content-end'>
                    <button
                        type="button"
                        className="btn btn-success fw-bold"
                    >Save Changes</button></div>
            </div>
            <Line orientation='h' space='4' height='1' width='100' backgroundColor={undefined} />
            <div className='row justify-content-between align-items-center py-3'>
                <div className='col-lg-4 col-md-4 col-sm-12'>
                    <small className='text-secondary fw-bold'>ACCOUNT DEACTIVATION</small>
                </div>
                <div className='col-lg-8 col-md-8 col-sm-12 mt-2'>
                    <small className='text-secondary fw-bold'>What happens when you deactivate your account?</small><br /><br />
                    <small className='text-muted' style={{ fontSize: '13px' }}>
                        <i className="fas fa-location-arrow mx-1"></i>Your profile and Ads won't be shown on Umediad anymore.<i className="fas fa-question-circle"></i>
                    </small><br/>
                    <small className='text-muted' style={{ fontSize: '13px' }}>
                        <i className="fas fa-location-arrow mx-1"></i>Active orders will be cancelled.<i className="fas fa-question-circle"></i>
                    </small><br />
                    <small className='text-muted' style={{ fontSize: '13px' }}>
                        <i className="fas fa-location-arrow mx-1"></i>You won't be able to re-activate your Ads.<i className="fas fa-question-circle"></i>
                    </small>
                </div>
            </div>
            <div className='row justify-content-between align-items-center py-3'>
                <div className='col-lg-4 col-md-4 col-sm-12'>
                    <small className='text-secondary fw-bold'>I'm leaving because..</small>
                </div>
                <div className='col-lg-8 col-md-8 col-sm-12 mt-2'>
                    <div className="form-group">
                        <select className="form-select h-75 text-secondary" style={{ fontSize: '14px' }}>
                            <option selected disabled>Choose a reason</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className="col-12 py-3 mt-3">
                <div className='d-flex justify-content-end'>
                    <button
                        type="button"
                        className="btn btn-danger fw-bold"
                    >Deactivate Account</button></div>
            </div>
        </div>
    </div>
}


const mapStateToProps = (state) => {
    return {
        user: state.userAuth.user,
    }
}


export default connect(mapStateToProps, null)(Account);