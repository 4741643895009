import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import axios from '../../../../axios';
import * as actions from '../../../../store/actions'
import classes from '../AllOrders.module.css'
import Dashboard from '../../Dashboard/Dashboard.module.css';
import { getDate } from '../../../../shared/utility';

class RelatedAllOrders extends React.Component {

    // componentDidUpdate(prevProps, prevState) {
    //     if (this.props.statusType !== prevProps.statusType) {
    //         if (this.props.statusType) this.props.getSellInfo(this.props.statusType);
    //     }
    // }
    tokenHeadersNormal = () => {
        const headersNormal = {
            headers: {
                'Content-Type': 'application/json',
            },
        }
        const token = this.props.token
        if (token) {
            headersNormal.headers['x-auth-header'] = token
        }
        return headersNormal;
    }

    fetchSuccessData = async (sessionId) => {
        const res = await axios({
            method: 'GET',
            url: '/stripe/payment-session?sessionId=' + sessionId,
            headers: this.tokenHeadersNormal().headers,
        })
        return res.data.session;
    }

    saveToDatabase = async (
        sessionId,
        advertiseId,
        type,
        sellerId,
        advertiseInfo,
    ) => {
        let cc
        try {
            cc = await this.fetchSuccessData(sessionId)
        } catch (e) {
            console.log(e, 'save-to-database')
        }
        if (cc) {
            // setup_intent is "package_type" here
            const {
                customer,
                currency,
                customer_details,
                id,
                payment_status,
                // subscription,
                total_details,
                shipping,
                amount_subtotal,
            } = cc
            const { email } = customer_details
            const { amount_discount, amount_tax } = total_details
            this.props.uploadBuyData({
                packageId: advertiseId,
                priceId: amount_subtotal / 100,
                customer: customer,
                currency: currency,
                payment_status: payment_status,
                setup_intent: type,
                shipping: shipping,
                subscription: 'subscription',
                amount_discount: amount_discount,
                amount_tax: amount_tax,
                customer_email: email,
                sessionId: id,
                sellerId: sellerId,
                advertiseInfo: advertiseInfo,
            })
        }
    }

    renderOrders = () => {
        const OrdersData = this.props.orders;
        const filteredOrders = OrdersData.filter((order, index) => this.props.statusType === order.status);
        const t = this.props.t;

        if (filteredOrders.length > 0) {
            return filteredOrders.map((order, index) => {
                return <div key={order._id} className="mt-1 mb-2">
                    <Link
                        to={{
                            pathname: `/manage-order/${ order._id }/${ order.advertiseId._id }/${ this.props.statusType }`,
                        }}
                        style={{ textDecoration: 'none' }}>
                        <div className="mt-1 mb-1">
                            {this.props.screenWidth > 840 ?
                                <React.Fragment> <div className={classes.ManageOrdersInfoItem}>
                                    <div className="row justify-content-around align-items-center" style={{ color: '#000' }}>
                                        <div className="col-3">
                                            <div className="d-flex align-items-center">
                                                <div className={Dashboard.Avatar}>{order.buyer.profile_image ? <img src={order.buyer.profile_image} alt='user img' /> : order.buyer.username.charAt(0)}
                                                </div>
                                                <h6>{order.buyer ? order.buyer.username : ''}</h6>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <p className="">
                                                <span className="text-dark text-bold">
                                                    {order.advertiseId.title
                                                        ? order.customOrderId ? order.customOrderId.adInfo.title : order.advertiseId.title
                                                        : ''}
                                                </span>
                                            </p>
                                        </div>
                                        <div className="col-2">
                                            <div className={Dashboard.OrderInfo}>
                                                {/* <small>
                                                    {order.advertiseId.packages && (
                                                        <div>
                                                            {order.advertiseId.packages.map((ss) => {
                                                                if (ss.type === order.paymentId.setup_intent) {
                                                                    return order.deliveryDate ? getDate(order.deliveryDate) : ss.delivery_time + ' days'

                                                                } else {
                                                                    return null
                                                                }
                                                            })}
                                                        </div>
                                                    )}
                                                </small> */}
                                                <small className='text-primary'>
                                                    {order.deliveryDate
                                                        ? getDate(order.deliveryDate)
                                                        : 'Not confirmed'}
                                                </small>
                                            </div>
                                        </div>
                                        <div className="col-1">
                                            <div className={Dashboard.OrderInfo}>
                                                <h6 className="text-success">
                                                    ${order.paymentId.priceId
                                                        ? order.paymentId.priceId
                                                        : ''}
                                                </h6>
                                            </div>
                                        </div>
                                        <div className="col-2">
                                            <div className={Dashboard.OrderInfo}>
                                                <div className={Dashboard.IsActive}>
                                                    {order.status ? order.status : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                    {index < OrdersData.length - 1 ?
                                        <div className={Dashboard.Divider}></div> : null}
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <div className={classes.ManageOrdersInfoItem}>
                                        <div className="row justify-content-between align-items-center" >
                                            <div className="col-3">
                                                <h6 className="text-muted">{t('Dashboard:AllOrders.0')}</h6>
                                            </div>
                                            <div className="col-8 text-dark">
                                                <div className="d-flex align-items-center">
                                                    <div className={Dashboard.Avatar}>{order.buyer.profile_image ? <img src={order.buyer.profile_image} alt='user img' /> : order.buyer.username.charAt(0)}</div>
                                                    <h6>{order.buyer ? order.buyer.username : ''}</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row justify-content-between align-items-center mt-3">
                                            <div className="col-3">
                                                <h6 className="text-muted">{t('Dashboard:AllOrders.2')}</h6>
                                            </div>
                                            <div className="col-8">
                                                <div className={Dashboard.OrderInfo}>
                                                    <p className="pb-1">
                                                        <span className="text-dark text-bold">
                                                            {order.advertiseId.title
                                                                ? order.customOrderId ? order.customOrderId.adInfo.title : order.advertiseId.title
                                                                : ''}
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row justify-content-between align-items-center mt-3">
                                            <div className="col-3">
                                                <h6 className="text-muted">{t('Dashboard:AllOrders.3')}</h6>
                                            </div>
                                            <div className="col-8 text-dark">
                                                <div className={Dashboard.OrderInfo}>
                                                    {/* <small>
                                                        {order.advertiseId.packages && (
                                                            <div>
                                                                {order.advertiseId.packages.map((ss) => {
                                                                    if (ss.type === order.paymentId.setup_intent) {
                                                                        return order.deliveryDate ? getDate(order.deliveryDate) : ss.delivery_time + ' days'

                                                                    } else {
                                                                        return null
                                                                    }
                                                                })}
                                                            </div>
                                                        )}
                                                    </small> */}
                                                    <small className='text-primary'>
                                                        {order.deliveryDate
                                                            ? getDate(order.deliveryDate)
                                                            : 'Not confirmed'}
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row justify-content-between align-items-center mt-3">
                                            <div className="col-3">
                                                <h6 className="text-muted">{t('Dashboard:AllOrders.4')}</h6>
                                            </div>
                                            <div className="col-8 text-dark">
                                                <div className={Dashboard.OrderInfo}>
                                                    <small className="text-success">
                                                        ${order.paymentId.priceId
                                                            ? order.paymentId.priceId
                                                            : ''}
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row justify-content-between align-items-center mt-3">
                                            <div className="col-3">
                                                <h6 className="text-muted">{t('Dashboard:AllOrders.5')}</h6>
                                            </div>
                                            <div className="col-8">
                                                <div className={Dashboard.OrderInfo}>
                                                    <div className={Dashboard.IsActive}>
                                                        {order.status ? order.status : ''}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {index < OrdersData.length - 1 ?
                                        <div className={Dashboard.Divider}></div> : null}
                                </React.Fragment>
                            }
                        </div>
                    </Link>
                </div >
            })
        } else
            return <div className="mt-2 mb-2">{t('Dashboard:orders.errors.0')} {this.withTranslationOrders()} {t('Dashboard:orders.8')}.</div>
    }

    withTranslationOrders = () => {
        const { statusType } = this.props
        const t = this.props.t
        if (statusType === "active") {
            return t('Dashboard:orders.list.0')
        } else if (statusType === "new") {
            return t('Dashboard:orders.list.1')
        } else if (statusType === "late") {
            return t('Dashboard:orders.list.2')
        } else if (statusType === "delivered") {
            return t('Dashboard:orders.list.3')
        } else if (statusType === "completed") {
            return t('Dashboard:orders.list.4')
        }
    }

    render() {
        const t = this.props.t;

        return <div className={classes.ManageOrdersMainInfo}>
            <h6>{this.withTranslationOrders()} {t('Dashboard:orders.8')}</h6>
            <div className={Dashboard.Divider}></div>
            <div className={classes.ManageOrdersInfo}>
                {this.props.screenWidth > 840 &&
                    <React.Fragment>
                        <div className="row justify-content-around align-items-center mt-3 ">
                            <div className="col-3">
                                <h6 className="text-muted">{t('Dashboard:AllOrders.0')}</h6>
                            </div>
                            <div className="col-3">
                                <h6 className="text-muted">{t('Dashboard:AllOrders.2')}</h6>
                            </div>
                            <div className="col-2">
                                <h6 className="text-muted">{t('Dashboard:AllOrders.3')}</h6>
                            </div>
                            <div className="col-2">
                                <h6 className="text-muted">{t('Dashboard:AllOrders.4')}</h6>
                            </div>
                            <div className="col-2">
                                <h6 className="text-muted">{t('Dashboard:AllOrders.5')}</h6>
                            </div>
                        </div>
                        <div className={Dashboard.Divider}></div>
                    </React.Fragment>}
                {this.renderOrders()}
            </div>
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.userAuth.isAuthenticated,
        token: state.userAuth.token,
        screenWidth: state.responsiveness.screenWidth,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        uploadBuyData: (data) => dispatch(actions.uploadBuyData(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RelatedAllOrders)
