// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Settings_Settings__gvHiv {\n    margin: 80px auto;\n    display: flex;\n    width: 97%;\n    justify-content: center;\n    flex-direction: column;\n    align-items: center;\n}\n\n.Settings_SettingsItems__LWuBG {\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n    justify-content: center;\n}\n\n.Settings_PartLeft__1Va14 {\n    width: 100%;\n    padding: 10px 10px;\n}\n\n.Settings_PartRight__uKCbS {\n    width: 100%;\n    border: 1px solid rgb(230, 230, 230);\n    border-radius: 5px;\n    background: white;\n    padding: 15px 15px;\n}\n\n.Settings_PartRightHeader__2pKml {\n    display: flex;\n    justify-content: flex-end;\n}\n\n@media only screen and (min-width: 501px) and (max-width: 900px) {\n    .Settings_Settings__gvHiv {\n        width: 95%;\n    }\n    .Settings_PartLeft__1Va14 {\n        padding: 10px 10px;\n    }\n    .Settings_PartRight__uKCbS {\n        padding: 15px 25px;\n    }\n}\n\n@media only screen and (min-width: 900px) {\n    .Settings_Settings__gvHiv {\n        flex-direction: row;\n        margin: 100px auto;\n        align-items: unset;\n    }\n    .Settings_PartLeft__1Va14 {\n        width: 25%;\n        padding: 15px 15px;\n    }\n    .Settings_PartRight__uKCbS {\n        width: 60%;\n        padding: 20px 30px;\n    }\n    .Settings_SettingsItems__LWuBG {\n        flex-direction: column;\n        margin-left: 20px;\n    }\n}", ""]);
// Exports
exports.locals = {
	"Settings": "Settings_Settings__gvHiv",
	"SettingsItems": "Settings_SettingsItems__LWuBG",
	"PartLeft": "Settings_PartLeft__1Va14",
	"PartRight": "Settings_PartRight__uKCbS",
	"PartRightHeader": "Settings_PartRightHeader__2pKml"
};
module.exports = exports;
