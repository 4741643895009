// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ImageSlider_Slider__1M39k {\n    padding: 20px 0px;\n    z-index: 0;\n    margin: 0% 2%;\n}\n\n.ImageSlider_AppSlide__Z6AMR {\n    position: relative;\n    text-align: center;\n    color: white;\n    justify-content: center;\n    margin: 0px 7px;\n    cursor: pointer;\n}\n\n.ImageSlider_AppSlide__Z6AMR img {\n    border-radius: 15px;\n    height: 200px;\n    width: 150px;\n    box-shadow: 0px 2px 2px -3px rgba(48, 46, 46, 0.2), 0px 2px 2px 0px rgba(44, 42, 42, 0.14), 0px 2px 1px 0px rgba(49, 47, 47, 0.12);\n}\n\n.ImageSlider_AppSlide__Z6AMR img:hover {\n    opacity: 0.6;\n}\n\n.ImageSlider_Head__3bnju {\n    margin: 20px 0px;\n    text-align: center;\n}\n\n.ImageSlider_CenteredItem__Vs2Zp {\n    background: rgba(44, 42, 42, 0.3);\n    width: 100%;\n    box-sizing: border-box;\n    position: absolute;\n    bottom: 0%;\n    left: 0%;\n    align-items: center;\n    display: flex;\n    justify-content: center;\n    height: 40px;\n    border-bottom-left-radius: 15px;\n    border-bottom-right-radius: 15px;\n}\n\n@media (min-width: 600px) {\n    .ImageSlider_AppSlide__Z6AMR img {\n        height: 300px;\n        width: 200px;\n    }\n    .ImageSlider_Head__3bnju {\n        margin: 25px 0px;\n    }\n    .ImageSlider_CenteredItem__Vs2Zp {\n        height: 50px;\n    }\n}", ""]);
// Exports
exports.locals = {
	"Slider": "ImageSlider_Slider__1M39k",
	"AppSlide": "ImageSlider_AppSlide__Z6AMR",
	"Head": "ImageSlider_Head__3bnju",
	"CenteredItem": "ImageSlider_CenteredItem__Vs2Zp"
};
module.exports = exports;
