import React from 'react';
// import { Link } from 'react-router-dom';
import './404Page.css';
// import { SimpleSpinner } from '../../components/UI/Spinner/Spinner';
import { MainSpinner } from '../../components/UI/Spinner/Spinner'

export const NotFoundPage = () => {
    return <MainSpinner />
    // <section className="page_404">
    //     <div className="container">
    //         <div className="row">
    //             <div className="col-sm-12 d-flex justify-content-center w-100">
    //                 <div className="col-sm-10 col-sm-offset-1 text-center">
    //                     <div className="four_zero_four_bg">
    //                         <h1 className='py-3'>
    //                             404
    //                         </h1>
    //                     </div>
    //                     <div className='py-5 py-md-0'>
    //                         <h3 className='py-3'>
    //                             Look like you are lost!
    //                         </h3>
    //                         <p className='py-2'>The page you are looking for not available!</p>
    //                         <Link to="/" className="mt-2">Go to Home</Link>
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    // </section>
}
