import React from 'react'
import classes from "./Footer.module.css";

export const SocialMedia = () => {
    return <div className={classes.SocialMedia}>
        <li><a href="https://www.facebook.com/umediad" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></a></li>
        <li><a href="https://www.youtube.com/channel/UCrLjXTFDTXc9m3RM44mK2ag" target="_blank" rel="noopener noreferrer"><i className="fab fa-youtube"></i></a></li>
        <li><a href="https://www.instagram.com/umediad" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a></li>
        <li><a href="https://mobile.twitter.com/umediad?" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a></li>
        <li><a href="https://www.linkedin.com/showcase/umediad" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin"></i></a></li>
        <li><a href="https://www.tiktok.com/@umediad" target="_blank" rel="noopener noreferrer"><i className="fab fa-tiktok"></i></a></li>
    </div>
}
