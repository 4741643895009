import React from 'react';
import classes from './Partner.module.css';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';
import SingleRequest from './components/SingleRequest';
import Tabs from '../../../components/Tabs/Tabs';
import styles from '../Tender/Tender.module.css';
import { getDate } from '../../../shared/utility';
import { Link } from 'react-router-dom';
import SimpleSnackbar from '../../../components/Snackbar/Snackbar';
import { withTranslation } from 'react-i18next';
import {
    tokenHeaders
} from '../../../store/actions/HeaderConfig';
import axios from '../../../axios';


const UserPartnerRequest = (props) => {
    const [error, setError] = React.useState(null);

    React.useEffect(() => {
        window.scrollTo(0.0, 0.0);
        props.onGetUserPartnerRequest();
        props.onGetSellerPartnerRequest();
        props.onGetInterestedPartnerRequest();
        props.onGetAdsUnderPartner();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const { t } = props;

    let info = <div className='py-3 d-flex justify-content-center'>
        <div className={classes.RequestLocation}>
            <li>{t("no_created_request")}</li>
        </div>
    </div>

    if (!props.loading && props.requestPartner)
        info = <SingleRequest
            requestPartner={props.requestPartner}
            isUser={true} />

    const onConfirmPartner = (requestedId) => {
        props.onConfirmPartner(requestedId);
        setTimeout(() => {
            window.location.reload();
        }, 2000);
    }

    const createSingleChatroom = async (data) => {
        const res = await axios({
            method: 'POST',
            data: data,
            url: '/chat/create',
            headers: tokenHeaders(props.token).headers,
        })
        return res.data;
    }

    const createChat = async (userEmail) => {
        try {
            await createSingleChatroom({ toEmail: userEmail, fromEmail: props.user.email });
            props.history.push('/inbox');
        } catch (e) {
            if (e.response.status === 400) {
                props.history.push('/inbox');
                return;
            }
            setError(e.response.data.message);
            setTimeout((() => {
                setError(null);
            }), 3000);
        }
    }

    const activeAdsUnderPartner = props.adsUnderPartner.filter(partner => partner.status === 'active');
    const newAdsUnderPartner = props.adsUnderPartner.filter(partner => partner.status === 'new');
    const closedAdsUnderPartner = props.adsUnderPartner.filter(partner => partner.status === 'completed');

    return <div className={styles.Tender}>
        {props.confirmPartner &&
            <SimpleSnackbar open={true} message={props.confirmPartner} type='success' />}
        <div className={[styles.PartRight, classes.PartnerShow].join(' ')}>
            <React.Fragment>
                {error && <div className="alert alert-warning" role="alert">
                    {error}
                </div>}
                <div className={[classes.HeaderINfoStyle, classes.HeaderINfoStyle1].join(' ')}>
                    <h5>{t("my_partnership")}</h5>
                </div>
                <br />
                <br />
                <Tabs>
                    <div label={t("accepted_sellers")}>
                        <div className={classes.SellerRequests}>
                            {props.sellerRequests.map((sellerRequest, index) => (
                                <div className={classes.SellerRequest}>
                                    <div className='d-flex align-items-center mb-2'>
                                        <div>
                                            <img src={sellerRequest.user && sellerRequest.user.profile_image} alt='user' />
                                        </div>
                                        <div className='mx-2'>
                                            <Link to={`/profile/seller/${sellerRequest.user &&  sellerRequest.user.username }`}><h5>{sellerRequest.user && sellerRequest.user.full_name}</h5></Link>
                                            <p className='text-muted'>{sellerRequest.createdAt && getDate(sellerRequest.createdAt)}</p>
                                        </div>
                                        <div>
                                            {sellerRequest.isAccepted &&
                                                <i className="fas text-primary fa-2x fa-user-check"></i>}
                                        </div>
                                    </div>
                                    <div className='d-flex'>
                                        {!sellerRequest.isAccepted &&
                                            <button
                                                onClick={_ => onConfirmPartner(sellerRequest._id)}
                                                className='btn btn-sm btn-primary mt-2'>
                                                {t("confirm")}
                                            </button>}
                                        <button
                                            onClick={_ => createChat(sellerRequest.user && sellerRequest.user.email)}
                                            className='btn btn-sm btn-outline-primary mt-2 mx-2'>
                                            Contact
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {props.sellerRequests.length === 0 && <p>{t("no_seller_accepted")}</p>}
                    </div>
                    <div label={t("partnership_profile")}>
                        {info}
                    </div>
                </Tabs>
                <br />
                <br />
                <div className={[styles.PartRight, classes.PartnerShow].join(' ')}>
                    <div className={[classes.HeaderINfoStyle, classes.HeaderINfoStyle3].join(' ')}>
                        <h5>{t("ads_under_me")}</h5>
                    </div>
                    <br />
                    <br />
                    <Tabs>
                        <div label={t("active") + "(" + activeAdsUnderPartner.length + ")"}>
                            <div className={classes.SellerRequests}>
                                {activeAdsUnderPartner.map((adUnder, index) => (
                                    <Link key={index} to={`/adInfo?ad_id=${ adUnder.advertiseId.shortId }`}>
                                        <div className={classes.SellerRequest}>
                                            <div className='d-flex align-items-center mb-2'>
                                                <div>
                                                    <img src={adUnder.seller && adUnder.seller.profile_image} alt='user' />
                                                </div>
                                                <div className='mx-2'>
                                                    <Link to={`/profile/seller/${adUnder.seller &&  adUnder.seller.username }`}><h5>{adUnder.seller && adUnder.seller.full_name}</h5></Link>
                                                    <span className='text-muted'>{adUnder.seller && adUnder.seller.accountType}</span>
                                                </div>
                                            </div>
                                            <div className='my-2'>
                                                <h6 className='my-2'>{adUnder.advertiseId.title}</h6>
                                                <div className='mt-2'>
                                                    <div className={styles.MediaItem}>
                                                        <span className='bg-primary text-white'>{t("ad_media")}</span>
                                                        <span>{adUnder.advertiseId.types.length > 1 ? adUnder.advertiseId.types[0] + ' ...' : adUnder.advertiseId.types[0]}</span>
                                                    </div>
                                                    <div className={styles.MediaItem}>
                                                        <span className='bg-primary text-white'>{t("sub_media")}</span>
                                                        <span>{adUnder.advertiseId.subtypes.length > 1 ? adUnder.advertiseId.subtypes[0] + '...' : adUnder.advertiseId.subtypes[0]}</span>
                                                    </div>
                                                    <div className='bg-light rounded my-3 p-3'>
                                                        <h6>{t("ad_price")} : ${adUnder.paymentId.priceId}</h6>
                                                        <div className='d-flex align-items-center'>
                                                            <div>
                                                                <h6>
                                                                    {t("you_earned")} :
                                                                </h6>
                                                            </div>
                                                            <div className='m-2'>
                                                                <h6>
                                                                    {adUnder.paymentId.paidAmountInfo.forPartner}
                                                                    <sub className='text-secondary'>4% commission</sub>
                                                                </h6>
                                                                <li className='mt-2 text-danger'>
                                                                    {t("non_withdrawable")}
                                                                </li>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                ))}
                            </div>
                            {activeAdsUnderPartner.length === 0 && <p>{t("no_active")}</p>}
                        </div>
                        <div label={t("new") + "(" + newAdsUnderPartner.length + ")"}>
                            <div className={classes.SellerRequests}>
                                {newAdsUnderPartner.map((adUnder, index) => (
                                    <Link key={index} to={`/adInfo?ad_id=${ adUnder.advertiseId.shortId }`}>
                                        <div className={classes.SellerRequest}>
                                            <div className='d-flex align-items-center mb-2'>
                                                <div>
                                                    <img src={adUnder.seller && adUnder.seller.profile_image} alt='user' />
                                                </div>
                                                <div className='mx-2'>
                                                    <Link to={`/profile/seller/${adUnder.seller &&  adUnder.seller.username }`}><h5>{adUnder.seller && adUnder.seller.full_name}</h5></Link>
                                                    <span className='text-muted'>{adUnder.seller && adUnder.seller.accountType}</span>
                                                </div>
                                            </div>
                                            <div className='my-2'>
                                                <h6 className='my-2'>{adUnder.advertiseId.title}</h6>
                                                <div className='mt-2'>
                                                    <div className={styles.MediaItem}>
                                                        <span className='bg-primary text-white'>{t("ad_media")}</span>
                                                        <span>{adUnder.advertiseId.types.length > 1 ? adUnder.advertiseId.types[0] + ' ...' : adUnder.advertiseId.types[0]}</span>
                                                    </div>
                                                    <div className={styles.MediaItem}>
                                                        <span className='bg-primary text-white'>{t("sub_media")}</span>
                                                        <span>{adUnder.advertiseId.subtypes.length > 1 ? adUnder.advertiseId.subtypes[0] + '...' : adUnder.advertiseId.subtypes[0]}</span>
                                                    </div>
                                                    <div className='bg-light rounded my-3 p-3'>
                                                        <h6>{t("ad_price")} : ${adUnder.paymentId.priceId}</h6>
                                                        <div className='d-flex align-items-center'>
                                                            <div>
                                                                <h6>
                                                                    {t("you_earned")} :
                                                                </h6>
                                                            </div>
                                                            <div className='m-2'>
                                                                <h6>
                                                                    {adUnder.paymentId.paidAmountInfo.forPartner}
                                                                    <sub className='text-secondary'>4% commission</sub>
                                                                </h6>
                                                                <li className='mt-2 text-danger'>
                                                                    {t("non_withdrawable")}
                                                                </li>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                ))}
                            </div>
                            {newAdsUnderPartner.length === 0 && <p>{t("no_newAds")}</p>}
                        </div>
                        <div label={t("completed") + "(" + closedAdsUnderPartner.length + ")"}>
                            <div className={classes.SellerRequests}>
                                {closedAdsUnderPartner.map((adUnder, index) => (
                                    <Link key={index} to={`/adInfo?ad_id=${ adUnder.advertiseId.shortId }`}>
                                        <div className={classes.SellerRequest}>
                                            <div className='d-flex align-items-center mb-2'>
                                                <div>
                                                    <img src={adUnder.seller && adUnder.seller.profile_image} alt='user' />
                                                </div>
                                                <div className='mx-2'>
                                                    <Link to={`/profile/seller/${adUnder.seller &&  adUnder.seller.username }`}><h5>{adUnder.seller && adUnder.seller.full_name}</h5></Link>
                                                    <span className='text-muted'>{adUnder.seller && adUnder.seller.accountType}</span>
                                                </div>
                                            </div>
                                            <div className='my-2'>
                                                <h6 className='my-2'>{adUnder.advertiseId.title}</h6>
                                                <div className='mt-2'>
                                                    <div className={styles.MediaItem}>
                                                        <span className='bg-primary text-white'>{t("ad_media")}</span>
                                                        <span>{adUnder.advertiseId.types.length > 1 ? adUnder.advertiseId.types[0] + ' ...' : adUnder.advertiseId.types[0]}</span>
                                                    </div>
                                                    <div className={styles.MediaItem}>
                                                        <span className='bg-primary text-white'>{t("sub_media")}</span>
                                                        <span>{adUnder.advertiseId.subtypes.length > 1 ? adUnder.advertiseId.subtypes[0] + '...' : adUnder.advertiseId.subtypes[0]}</span>
                                                    </div>
                                                    <div className='bg-light rounded my-3 p-3'>
                                                        <h6>{t("ad_price")} : ${adUnder.paymentId.priceId}</h6>
                                                        <div className='d-flex align-items-center'>
                                                            <div>
                                                                <h6>
                                                                    {t("you_earned")} :
                                                                </h6>
                                                            </div>
                                                            <div className='m-2'>
                                                                <h6>
                                                                    {adUnder.paymentId.paidAmountInfo.forPartner}
                                                                    <sub className='text-secondary'>4% commission</sub>
                                                                </h6>
                                                                <li className='mt-2 text-danger'>
                                                                    {t("withdrawable")}
                                                                </li>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                ))}
                            </div>
                            {closedAdsUnderPartner.length === 0 && <p>{t("no_closedAds")}</p>}
                        </div>
                    </Tabs>
                </div>
                <br />
                <br />
                {props.user.role === 'seller' && props.interestedPartners.length !== 0 &&
                    <div className='mt-3'>
                        <div className={[classes.HeaderINfoStyle, classes.HeaderINfoStyle2].join(' ')}>
                            <h5>{t("chosen_partner")}</h5>
                        </div>
                        <br />
                        <br />
                        <div className={classes.SellerRequests}>
                            {props.interestedPartners.map((interestedPartner, index) => (
                                <Link key={index} to={`/partner-request-description?info_id=${ interestedPartner.partner._id }`}>
                                    <div className={classes.SellerRequest}>
                                        <div className='d-flex align-items-center mb-2'>
                                            <div>
                                                <img src={interestedPartner.ownedBy && interestedPartner.ownedBy.profile_image} alt='user' />
                                            </div>
                                            <div className='mx-2'>
                                                <Link key={index} to={`/profile/seller/${interestedPartner.ownedBy &&  interestedPartner.ownedBy.username }`}>
                                                    <h5>{interestedPartner.ownedBy && interestedPartner.ownedBy.full_name}</h5>
                                                </Link>
                                                <p className='text-muted'>{interestedPartner.createdAt && getDate(interestedPartner.createdAt)}</p>
                                            </div>
                                        </div>
                                        {interestedPartner.partner && <div className={styles.MediaItem}>
                                            <span>{interestedPartner.partner.media.length > 0 ? interestedPartner.partner.media[0] + ' ...' : "..."}</span>
                                        </div>}
                                        <div className='mt-3 mb-1'>
                                            <span>{interestedPartner.partner.experience.length > 200 ? interestedPartner.partner.experience.trim().substr(0, 200) + ' ...' : interestedPartner.partner.experience}</span>
                                        </div>
                                        <div>
                                            <h6>.....</h6>
                                        </div>
                                        <div className={styles.MediaItem}>
                                            <span>{interestedPartner.isAccepted ? 'Accepted' : 'Pending'}</span>
                                        </div>
                                    </div>
                                </Link>
                            ))}
                        </div>
                        {props.interestedPartners.length === 0 && <p>{t("not_interested")}</p>}
                    </div>}
            </React.Fragment>
            {/* :
                    <div className="text-center">
                        <h3>{t('dontHavePartner')}</h3>
                        <Link to="/find-partner" className="m-3 btn btn-primary text-white">{t('findPartner')}</Link>
                    </div>
                } */}
            <br />
        </div>
    </div>
}

const mapStateToProps = (state) => {
    return {
        requestPartner: state.partner.requestPartner,
        sellerRequests: state.partner.partnersRequests,
        interestedPartners: state.partner.interestedPartners,
        message: state.partner.message,
        error: state.partner.error,
        loading: state.partner.loading,
        user: state.userAuth.user,
        token: state.userAuth.token,
        confirmPartner: state.partner.confirmPartner,
        adsUnderPartner: state.partner.adsUnderPartner,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onGetUserPartnerRequest: () => dispatch(actions.getUserPartnerRequest()),
        onGetSellerPartnerRequest: () => dispatch(actions.getSellerPartnerRequest()),
        onGetInterestedPartnerRequest: () => dispatch(actions.getInterestedPartnerRequest()),
        onConfirmPartner: (id) => dispatch(actions.onConfirmPartner(id)),
        onGetAdsUnderPartner: () => dispatch(actions.onGetAdsUnderPartner()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(['Partner'])(UserPartnerRequest));

