// @ts-nocheck
import React from 'react'
import classes from '../Tender/Tender.module.css'
import styles from './Partner.module.css'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Multiselect } from 'multiselect-react-dropdown'
import * as actions from '../../../store/actions'
import SimpleSnackbar from '../../../components/Snackbar/Snackbar'
import { SimpleSpinner } from '../../../components/UI/Spinner/Spinner'
import CircularProgress from '@material-ui/core/CircularProgress'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { Link } from 'react-router-dom'
import { onReturnOnlyDigit } from '../../../shared/utility'
import { Translate } from 'react-auto-translate'
import Translation from '../../../shared/Translate'

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="static" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center">
        <Typography variant="caption" component="div" color="primary">
          {props.value}%
        </Typography>
      </Box>
    </Box>
  )
}

class PostPartnerRequest extends React.Component {
  constructor(props) {
    super(props)
    const { t } = this.props
    this.state = {
      businessDomain: [
        { id: 0, domain: t('translation:domainSlider.list.0') },
        { id: 1, domain: t('translation:domainSlider.list.1') },
        { id: 2, domain: t('translation:domainSlider.list.2') },
        { id: 3, domain: t('translation:domainSlider.list.3') },
        { id: 4, domain: t('translation:domainSlider.list.4') },
        { id: 5, domain: t('translation:domainSlider.list.5') },
        { id: 6, domain: t('translation:domainSlider.list.6') },
        { id: 7, domain: t('translation:domainSlider.list.7') },
        { id: 8, domain: t('translation:domainSlider.list.8') },
        { id: 9, domain: t('translation:domainSlider.list.9') },
        { id: 10, domain: t('translation:domainSlider.list.10') },
        { id: 11, domain: t('translation:domainSlider.list.11') },
        { id: 12, domain: t('translation:domainSlider.list.12') },
        { id: 13, domain: t('translation:domainSlider.list.13') },
        { id: 14, domain: t('translation:domainSlider.list.14') },
        { id: 15, domain: t('translation:domainSlider.list.15') },
        { id: 16, domain: t('translation:domainSlider.list.16') },
        { id: 17, domain: t('translation:domainSlider.list.17') },
        { id: 18, domain: t('translation:domainSlider.list.18') },
      ],
      selectedTypeIndex: null,
      partnerData: {
        name: '',
        experience: '',
        status: '',
        specialty: '',
        restrictions: '',
        contact: '',
        location: {
          city: '',
          state: '',
          country: '',
          area: '',
        },
        language: '',
        media: [],
        email: '',
        subMedia: [],
        domains: [],
        cv: null,
        slogan: '',
        partnership: '',
        whereMedia: '',
        about: '',
        websiteURL: '',
        condemned: '',
        isChecked: false,
      },
      message: '',
      mediaItems: [],
      select: null,
      partnerId: null,
      existingFile: null,
      show: false,
    }
  }

  componentDidMount() {
    this.props.onFetchTypeAndSubTypes()
    window.scrollTo(0.0, 0.0)

    if (this.props.location.search) {
      const search = this.props.location.search
      const params = new URLSearchParams(search)
      if (params.get('info_id')) {
        this.props.onGetPartnerRequest(params.get('info_id'))
        this.setState({ partnerId: params.get('info_id') })
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.advertiseData !== nextProps.advertiseData) {
      this.setState({ mediaItems: nextProps.advertiseData })
    }
    const partnerData = { ...this.state.partnerData }
    if (this.props.uploadedCv !== nextProps.uploadedCv) {
      partnerData.cv = nextProps.uploadedCv[0]
      this.setState({ partnerData })
    }
    if (this.props.requestPartner !== nextProps.requestPartner) {
      this.setState({
        partnerData: nextProps.requestPartner,
        existingFile: nextProps.requestPartner.cv,
      })
    }
  }

  onRemoveMedia = (media) => {
    const partnerData = { ...this.state.partnerData }
    partnerData.media = partnerData.media.filter((_media) => media !== _media)
    this.setState({ partnerData, select: false, selectedTypeIndex: null })
  }

  onRemoveSubMedia = (subMedia) => {
    const partnerData = { ...this.state.partnerData }
    partnerData.subMedia = partnerData.subMedia.filter(
      (_subMedia) => subMedia !== _subMedia,
    )
    this.setState({ partnerData })
  }
  showConditions = () => this.setState({ show: !this.state.show })

  onInputChange = (event) => {
    const name = event.target.name
    const value = event.target.value
    const partnerData = { ...this.state.partnerData }

    if (name === 'media') {
      if (partnerData.media.findIndex((media) => value === media) === -1) {
        partnerData.media.push(value)
      }
      this.setState({
        selectedTypeIndex: this.props.advertiseData.findIndex(
          (data) => value === data.type,
        ),
      })
    } else if (name === 'subMedia') {
      if (
        partnerData.subMedia.findIndex((subMedia) => value === subMedia) === -1
      ) {
        partnerData.subMedia.push(value)
      }
    } else if (
      name === 'city' ||
      name === 'state' ||
      name === 'country' ||
      name === 'area'
    )
      partnerData['location'][name] = value
    else if (name === 'isChecked') partnerData[name] = !partnerData.isChecked
    else if (name === 'contact') partnerData[name] = onReturnOnlyDigit(value)
    else partnerData[name] = value

    this.setState({ partnerData, select: true })
  }

  onHandleCv = (event) => {
    // const url = URL.createObjectURL(event.target.files[0]);
    const fileObject = event.target.files[0]

    if (this.bytesToSize(fileObject.size) > 5)
      return alert('File must be less than 5 MB')

    const file = new FormData()
    file.append('files', fileObject)
    file.append('existing', this.state.existingFile)
    this.props.onUploadFiles(file)
  }

  bytesToSize = (bytes) => (bytes / (1024 * 1024)).toFixed(2)

  onSelectAdvertiseDomain = (selectedList, selectedItem) => {
    const partnerData = { ...this.state.partnerData }
    partnerData.domains.push(selectedItem)
    this.setState({ partnerData })
  }

  onRemoveAdvertiseDomain = (selectedList, removedItem) => {
    const partnerData = { ...this.state.partnerData }
    partnerData.domains = selectedList
    this.setState({ partnerData })
  }

  onSubmitNewPartner = (event) => {
    event.preventDefault()
    if (!this.props.isAuthenticated) {
      this.props.history.push('/login')
    } else {
      delete this.state.partnerData.isChecked
      this.props.postNewPartnerRequest(this.state.partnerData)
      setTimeout(() => this.props.history.push('/my-partner-request'), 3000)
    }
  }

  onSubmitEditPartner = (event) => {
    event.preventDefault()
    this.props.onSubmitEditPartner(this.state.partnerData, this.state.partnerId)
    setTimeout(() => this.props.history.push('/my-partner-request'), 3000)
  }

  render() {
    const { t } = this.props
    const {
      partnerData: {
        name,
        status,
        domains,
        specialty,
        experience,
        language,
        location,
        contact,
        media,
        email,
        subMedia,
        restrictions,
        cv,
        slogan,
        partnership,
        about,
        condemned,
        whereMedia,
        websiteURL,
        isChecked,
      },
      partnerId,
    } = this.state

    return (
      <div className={classes.Tender}>
        {this.props.message && !this.props.error && (
          <SimpleSnackbar
            open={true}
            message={this.props.message}
            type="success"
          />
        )}
        {this.props.message && this.props.error && (
          <SimpleSnackbar
            open="true"
            message={this.props.message}
            type="error"
          />
        )}
        {this.props.fileUploadMessage && !this.props.fileUploadError && (
          <SimpleSnackbar
            open={true}
            message={this.props.fileUploadMessage}
            type="success"
          />
        )}
        {this.props.fileUploadMessage && this.props.fileUploadError && (
          <SimpleSnackbar
            open="true"
            message={this.props.fileUploadMessage}
            type="error"
          />
        )}

        <div className={classes.PublicTender}>
          <h5 className="text-center">
            {partnerId
              ? t('Partner:editPartnerRequest')
              : t('Partner:newPartnerRequest')}
          </h5>
          <div onClick={this.showConditions} className={styles.TermsCondition}>
            <span style={{ fontSize: '1.2em' }}>
              {t('Partner:readTerms')}
              <i
                class={
                  this.state.show
                    ? 'fas fa-chevron-up mx-1'
                    : 'fas fa-chevron-down mx-1'
                }></i>
            </span>
          </div>
          {this.state.show && (
            <div className={styles.ModalHeight}>
              <h5>UMEDIAD</h5>
              <p className="mt-0">{t('Partner:uma')} </p>
              <h6 className="mt-3">
                <Translation
                  from={'en'}
                  text="Authorized independent partner."
                  input={<Translate>Authorized independent partner.</Translate>}
                />
              </h6>
              <div className="mt-1">
                <span className="fw-bold">
                  <Link to="/">Umediad</Link>
                </span>{' '}
                {/* {t('Partner:partner_sc_msg')} */}
                <Translation
                  from={'en'}
                  text="welcomes the following partners: Influence and marketing agency, audiovisual and print display media, modeling agency, Artist and athlete manager. Communication agency, sales and marketing agent"
                  input={
                    <Translate>
                      welcomes the following partners: Influence and marketing
                      agency, audiovisual and print display media, modeling
                      agency, Artist and athlete manager. Communication agency,
                      sales and marketing agent
                    </Translate>
                  }
                />
                ...
              </div>
              <div className="mt-3">
                <span className="fw-bold">
                  <Link to="/">UMEDIAD Inc.</Link>
                </span>{' '}
                {/* {t('Partner:umediad_offers')} */}
                <Translation
                  from={'en'}
                  text="Universal Media Advertising, is a marketing platform, which allows users to sell or buy their audience, directly from influencers or local and international media."
                  input={
                    <Translate>
                      Universal Media Advertising, is a marketing platform,
                      which allows users to sell or buy their audience, directly
                      from influencers or local and international media.
                    </Translate>
                  }
                />
              </div>
              <br />
              <div className="mt-1">
                {/* {t('Partner:partner_manages')} */}
                <Translation
                  from={'en'}
                  text="Partners manage their advertising collaborations:"
                  input={
                    <Translate>
                      Partners manage their advertising collaborations:
                    </Translate>
                  }
                />
              </div>
              <div className="mt-3">
                {/* {t('Partner:by_selling')} */}-{' '}
                <Translation
                  from={'en'}
                  text="By selling their clients' audience at the partner's prices."
                  input={
                    <Translate>
                      By selling their clients' audience at the partner's
                      prices.
                    </Translate>
                  }
                />
              </div>

              <div className="mt-1">
                {/* {t('Partner:offering_free')} */}-{' '}
                <Translation
                  from={'en'}
                  text="Offering a free trial account to new subscribers who want to sell their audience, to keep these new customers."
                  input={
                    <Translate>
                      Offering a free trial account to new subscribers who want
                      to sell their audience, to keep these new customers.
                    </Translate>
                  }
                />
              </div>
              <div className="mt-1">
                {/* {t('Partner:giving_discounts')} */}-{' '}
                <Translation
                  from={'en'}
                  text="Giving discounts to our subscribers to get a commission on their subscriptions and future transactions."
                  input={
                    <Translate>
                      Giving discounts to our subscribers to get a commission on
                      their subscriptions and future transactions.
                    </Translate>
                  }
                />
              </div>
              <div className="mt-1">
                {/* {t('Partner:by_targeting')} */}-{' '}
                <Translation
                  from={'en'}
                  text="By targeting available audiences using our search engine to promote their clients in all areas and media from everywhere."
                  input={
                    <Translate>
                      By targeting available audiences using our search engine
                      to promote their clients in all areas and media from
                      everywhere.
                    </Translate>
                  }
                />
              </div>
              <div className="mt-1">
                {/* {t('Partner:by_publishing')} */}-{' '}
                <Translation
                  from={'en'}
                  text="By publishing a call for tenders to obtain the best media according to their budget and other criteria to promote their clients."
                  input={
                    <Translate>
                      By publishing a call for tenders to obtain the best media
                      according to their budget and other criteria to promote
                      their clients.
                    </Translate>
                  }
                />
              </div>
              <div className="mt-1">
                {/* {t('Partner:by_applying')} */}-{' '}
                <Translation
                  from={'en'}
                  text="By applying on the tenders and get more collaborations."
                  input={
                    <Translate>
                      By applying on the tenders and get more collaborations.
                    </Translate>
                  }
                />
              </div>
              <div className="mt-3">
                {/* {t('Partner:becoming_partner')} */}-{' '}
                <Translation
                  from={'en'}
                  text="Becoming an Umediad Inc. Independent Authorized Partner is free but your application is not automatically approved even if you meet all the criteria. Please allow 5 business days following your application to become a Partner for a response.."
                  input={
                    <Translate>
                      Becoming an Umediad Inc. Independent Authorized Partner is
                      free but your application is not automatically approved
                      even if you meet all the criteria. Please allow 5 business
                      days following your application to become a Partner for a
                      response..
                    </Translate>
                  }
                />
              </div>
              <div className="mt-3">
                {/* {t('Partner:the_authorized1')} */}-{' '}
                <Translation
                  from={'en'}
                  text="The Authorized Independent Partner is responsible for ensuring that the transaction between the seller and the buyer is done legally."
                  input={
                    <Translate>
                      The Authorized Independent Partner is responsible for
                      ensuring that the transaction between the seller and the
                      buyer is done legally.
                    </Translate>
                  }
                />
              </div>
              <div className="mt-1">
                {/* {t('Partner:the_authorized2')} */}-{' '}
                <Translation
                  from={'en'}
                  text="The Authorized Independent Partner takes full responsibility for everything they publish and the impacts it may have."
                  input={
                    <Translate>
                      The Authorized Independent Partner takes full
                      responsibility for everything they publish and the impacts
                      it may have.
                    </Translate>
                  }
                />
              </div>

              <h6 className="mt-3">
                <Translation
                  from={'en'}
                  text="ADVANTAGES:"
                  input={<Translate>ADVANTAGES:</Translate>}
                />
              </h6>
              <div className="mt-3">
                -{' '}
                <Translation
                  from={'en'}
                  text="Umediad is optimized and secured with some of the most reliable tools and companies on the market, to perform your payment, retribution, locked messaging transactions."
                  input={
                    <Translate>
                      Umediad is optimized and secured with some of the most
                      reliable tools and companies on the market, to perform
                      your payment, retribution, locked messaging transactions.
                    </Translate>
                  }
                />
              </div>
              <div className="mt-1">
                -{' '}
                <Translation
                  from={'en'}
                  text="Umediad has a unique search engine, allowing you to save time to target the perfect audience in multiple countries."
                  input={
                    <Translate>
                      Umediad has a unique search engine, allowing you to save
                      time to target the perfect audience in multiple countries.
                    </Translate>
                  }
                />
              </div>
              <div className="mt-1">
                -{' '}
                <Translation
                  from={'en'}
                  text="Get better local and international visibility in our partner list with your contact information."
                  input={
                    <Translate>
                      Get better local and international visibility in our
                      partner list with your contact information.
                    </Translate>
                  }
                />
              </div>
              <div className="mt-1">
                -{' '}
                <Translation
                  from={'en'}
                  text="Earn a royalty equal to your discount offered, for each subscriber who chooses to take advantage of your discount."
                  input={
                    <Translate>
                      Earn a royalty equal to your discount offered, for each
                      subscriber who chooses to take advantage of your discount.
                    </Translate>
                  }
                />
              </div>
              <div className="mt-1">
                -{' '}
                <Translation
                  from={'en'}
                  text="Earn a 4% commission on transactions from users who have taken advantage of your discount, as long as that user keeps you as a partner."
                  input={
                    <Translate>
                      Earn a 4% commission on transactions from users who have
                      taken advantage of your discount, as long as that user
                      keeps you as a partner.
                    </Translate>
                  }
                />
              </div>
              <div className="mt-1">
                -{' '}
                <Translation
                  from={'en'}
                  text="Get even more collaborations by accepting discount requests from new audience sellers."
                  input={
                    <Translate>
                      Get even more collaborations by accepting discount
                      requests from new audience sellers.
                    </Translate>
                  }
                />
              </div>
              <div className="mt-1">
                -{' '}
                <Translation
                  from={'en'}
                  text="Post up to 999 ads, with 3 different offers each, to sell your media's audience or the audience of your influencers, models, artists, athletes and other clients or media who choose your professionalism."
                  input={
                    <Translate>
                      Post up to 999 ads, with 3 different offers each, to sell
                      your media's audience or the audience of your influencers,
                      models, artists, athletes and other clients or media who
                      choose your professionalism.
                    </Translate>
                  }
                />
              </div>
              <div className="mt-1">
                -{' '}
                <Translation
                  from={'en'}
                  text="Clients come to you."
                  input={<Translate>Clients come to you.</Translate>}
                />
              </div>
              <div className="mt-1">
                -{' '}
                <Translation
                  from={'en'}
                  text="The tender form not only allows you to respect your budget but also to recruit new candidates."
                  input={
                    <Translate>
                      The tender form not only allows you to respect your budget
                      but also to recruit new candidates.
                    </Translate>
                  }
                />
              </div>

              {/* <div className="mt-3">{t('Partner:umediad_optimized')}</div>
              <div className="mt-1"> {t('Partner:umediad_unique')}</div>
              <div className="mt-1">{t('Partner:get_better')}</div>
              <div className="mt-1">{t('Partner:earn_royalty')}</div>
              <div className="mt-1">{t('Partner:earn_commission')}</div>
              <div className="mt-1"> {t('Partner:get_even_more')}</div>
              <div className="mt-1"> {t('Partner:post_upto')}</div>
              <div className="mt-1"> {t('Partner:clients_came')}</div>
              <div className="mt-1"> {t('Partner:tender_form')}</div> */}

              <div className="mt-1">
                <span>
                  {/* {t('Partner:send_us_via')} */}
                  <Translation
                    from={'en'}
                    text="Send us your request via the following link:"
                    input={
                      <Translate>
                        Send us your request via the following link:
                      </Translate>
                    }
                  />{' '}
                  <Link to="/new-partner-request">
                    https://www.umediad.com/new-partner-request
                  </Link>
                </span>
              </div>
              <div className="mt-3">
                {/* {t('Partner:to_speak')} */}
                <Translation
                  from={'en'}
                  text="To speak to a representative use the name umediad on social networks Instagram and Facebook"
                  input={
                    <Translate>
                      To speak to a representative use the name umediad on
                      social networks Instagram and Facebook
                    </Translate>
                  }
                />
              </div>
              <div className="mt-3">umediad.com @umediad #umediad</div>
            </div>
          )}
          <form
            className="my-3"
            onSubmit={
              partnerId ? this.onSubmitEditPartner : this.onSubmitNewPartner
            }>
            <div className="row justify-content-between mb-3">
              <div className="form-group col-lg-6">
                <label className="form-label">{t('Partner:fullName')} *</label>
                <input
                  required
                  value={name}
                  onChange={this.onInputChange}
                  name="name"
                  type="text"
                  className="form-control"
                  placeholder="Adam"
                />
              </div>
              <div className="col-lg-6 mt-lg-0 mt-3">
                <label className="form-label">{t('Partner:emailInfo')} *</label>
                <input
                  required
                  value={email}
                  onChange={this.onInputChange}
                  name="email"
                  type="email"
                  className="form-control"
                  placeholder="example@mail.com"
                />
              </div>
            </div>
            <div className="form-group col-12 mb-3">
              <label className="form-label">{t('Partner:workExp')} *</label>
              <textarea
                required
                value={experience}
                onChange={this.onInputChange}
                name="experience"
                type="text"
                rows="4"
                className="form-control"
                placeholder={t('Partner:workExp')}
              />
            </div>
            <div className="row justify-content-between mb-3">
              <div className="form-group col-lg-6">
                <label className="form-label">{t('Partner:specialty')} *</label>
                <input
                  required
                  name="specialty"
                  value={specialty}
                  onChange={this.onInputChange}
                  type="text"
                  className="form-control"
                  placeholder={t('Partner:specialty')}
                />
              </div>
              <div className="form-group col-lg-6 mt-lg-0 mt-3">
                <label className="form-label">{t('Partner:empStatus')}</label>
                <input
                  value={status}
                  onChange={this.onInputChange}
                  name="status"
                  type="text"
                  className="form-control"
                  placeholder={t('Partner:empStatus')}
                />
              </div>
            </div>
            <div className="form-group col-12 mb-3">
              <label className="form-label">Slogan *</label>
              <input
                required
                value={slogan}
                onChange={this.onInputChange}
                name="slogan"
                type="text"
                className="form-control"
                placeholder="Slogan"
              />
            </div>
            <div className="form-group col-12 mb-3">
              <label className="form-label">
                {t('Partner:whyPartnership')} *
              </label>
              <input
                required
                value={partnership}
                onChange={this.onInputChange}
                name="partnership"
                type="text"
                className="form-control"
                placeholder={t('Partner:whyPartnership')}
              />
            </div>
            <div className="form-group col-12 mb-3">
              <label className="form-label">{t('Partner:whereMedia')} *</label>
              <input
                required
                value={whereMedia}
                onChange={this.onInputChange}
                name="whereMedia"
                type="text"
                className="form-control"
                placeholder={t('Partner:whereMedia')}
              />
            </div>
            <div className="row justify-content-between mb-3">
              <div className="form-group col-lg-6">
                <label className="form-label">
                  {t('Partner:workingMedia')} *
                </label>
                <select
                  className="form-select"
                  name="media"
                  required
                  onChange={this.onInputChange}>
                  <option
                    disabled
                    selected={
                      this.state.select === false || this.state.select === null
                    }>
                    {t('Partner:select')}
                  </option>
                  {this.props.advertiseData.map((data, index) => (
                    <option key={index} value={data.type}>
                      {data.type}
                    </option>
                  ))}
                </select>
                <div className={classes.MediaItem}>
                  {media.map((media, index) => (
                    <span key={index}>
                      {media}{' '}
                      <i
                        onClick={(_) => this.onRemoveMedia(media)}
                        className="fas fa-times mx-1"></i>
                    </span>
                  ))}
                </div>
              </div>

              <div className="form-group col-lg-6 mt-lg-0 mt-3">
                <label className="form-label">{t('Partner:subMedia')}</label>
                <select
                  className="form-select"
                  name="subMedia"
                  onChange={this.onInputChange}>
                  {!this.state.selectedTypeIndex && (
                    <option disabled selected>
                      {t('Partner:select')} {t('Partner:subMedia')}
                    </option>
                  )}
                  {this.state.selectedTypeIndex && (
                    <React.Fragment>
                      <option disabled selected>
                        {t('Partner:select')} {t('Partner:subMedia')}{' '}
                        {t('Partner:for')}{' '}
                        {
                          this.state.mediaItems[this.state.selectedTypeIndex]
                            .type
                        }
                      </option>
                      {this.state.mediaItems[
                        this.state.selectedTypeIndex
                      ].sub_type.map((sub_type, index) => (
                        <option key={index} value={sub_type}>
                          {sub_type}
                        </option>
                      ))}
                    </React.Fragment>
                  )}
                </select>
                <div className={classes.MediaItem}>
                  {subMedia.map((subMedia, index) => (
                    <span key={index}>
                      {subMedia}{' '}
                      <i
                        onClick={(_) => this.onRemoveSubMedia(subMedia)}
                        className="fas fa-times mx-1"></i>
                    </span>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-12 mb-3">
              <label className="form-label">
                {t('Partner:workingDomain')} *
              </label>
              <Multiselect
                required
                placeholder={t('Partner:select')}
                showArrow
                showCheckbox
                closeIcon="cancel"
                options={this.state.businessDomain}
                selectedValues={domains}
                onSelect={this.onSelectAdvertiseDomain}
                onRemove={this.onRemoveAdvertiseDomain}
                displayValue="domain"
              />
            </div>
            <label className="form-label">{t('Partner:location')} *</label>
            <div className="row justify-content-between mb-3">
              <div className="form-group col">
                <input
                  required
                  name="area"
                  value={location.area}
                  onChange={this.onInputChange}
                  type="text"
                  className="form-control"
                  placeholder={t('Partner:location')}
                />
              </div>
              <div className="form-group col">
                <input
                  required
                  value={location.city}
                  onChange={this.onInputChange}
                  name="city"
                  type="text"
                  className="form-control"
                  placeholder={t('Partner:city')}
                />
              </div>
            </div>
            <div className="row justify-content-between mb-3">
              <div className="form-group col">
                <input
                  required
                  name="state"
                  value={location.state}
                  onChange={this.onInputChange}
                  type="text"
                  className="form-control"
                  placeholder={t('Partner:state')}
                />
              </div>
              <div className="form-group col-6">
                <input
                  required
                  name="country"
                  value={location.country}
                  onChange={this.onInputChange}
                  type="text"
                  className="form-control"
                  placeholder={t('Partner:country')}
                />
              </div>
            </div>
            <div className="row justify-content-between mb-3">
              <div className="form-group col-12">
                <label className="form-label">Restrictions *</label>
                <textarea
                  required
                  value={restrictions}
                  onChange={this.onInputChange}
                  name="restrictions"
                  type="text"
                  rows="2"
                  className="form-control"
                  placeholder="restrictions"
                />
              </div>
            </div>
            <div className="row justify-content-between mb-3">
              <div className="form-group col-lg-6">
                <label className="form-label">{t('Partner:contuctNo')} *</label>
                <input
                  required
                  value={contact}
                  onChange={this.onInputChange}
                  name="contact"
                  type="text"
                  className="form-control"
                  placeholder={t('Partner:contuctNo')}
                />
              </div>
              <div className="form-group col-lg-6 mt-lg-0 mt-3">
                <label className="form-label">{t('Partner:languages')} *</label>
                <input
                  required
                  name="language"
                  value={language}
                  onChange={this.onInputChange}
                  type="text"
                  className="form-control"
                  placeholder="language"
                />
              </div>
            </div>
            <div className="form-group col-12 mb-3">
              <label className="form-label">{t('Partner:aboutYou')} *</label>
              <textarea
                required
                value={about}
                onChange={this.onInputChange}
                name="about"
                rows="4"
                type="text"
                className="form-control"
                placeholder={t('Partner:aboutYou')}
              />
            </div>
            <div className="form-group col-12 mb-3">
              <label className="form-label">{t('Partner:websiteUrl')}</label>
              <input
                value={websiteURL}
                onChange={this.onInputChange}
                name="websiteURL"
                type="text"
                className="form-control"
                placeholder="URL"
              />
            </div>
            <div className="form-group col-12 mb-3">
              <label className="form-label">{t('Partner:beenCondemned')}</label>
              <input
                value={condemned}
                onChange={this.onInputChange}
                name="condemned"
                rows="4"
                type="text"
                className="form-control"
                placeholder="yes"
              />
            </div>
            {/*  */}
            <div className="mb-3">
              <label className="form-label">
                {t('Partner:poi')} (Max- 5MB .jpg, .png, .pdf, .doc)
              </label>
              <div className="col-12">
                {this.props.fileUploadLoading ? (
                  <CircularProgressWithLabel
                    value={this.props.fileUploadPercentage}
                  />
                ) : this.props.uploadedCv[0] ? (
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    to={{ pathname: this.props.uploadedCv[0] }}>
                    POI File
                  </Link>
                ) : (
                  <div className={classes.FileInput}>
                    <input
                      className="form-control"
                      type="file"
                      accept=".pdf,.doc,"
                      name="cv"
                      onChange={this.onHandleCv}
                      id="cv"
                    />
                    <label htmlFor="cv">
                      <i className="fa fa-paperclip" aria-hidden="true"></i>
                    </label>
                  </div>
                )}
                {!this.props.uploadedCv[0] && cv && (
                  <div className="py-2">
                    <Link
                      target="_blank"
                      rel="noopener noreferrer"
                      to={{ pathname: cv }}
                      download="POI File">
                      {t('Partner:poi')}
                    </Link>
                  </div>
                )}
              </div>
            </div>
            {!partnerId && (
              <React.Fragment>
                <div className="form-check col-12 mb-2">
                  <input
                    onChange={this.onInputChange}
                    className="form-check-input"
                    type="checkbox"
                    name="isChecked"
                    checked={isChecked}
                    id="Check1"
                  />
                  <label className="form-check-label mt-1" for="Check1">
                    {t('Partner:iHaveRead')}
                  </label>
                </div>
              </React.Fragment>
            )}
            <div className="form-check-label my-2">
              <label>{t('Partner:requiredFields')}</label>
            </div>
            {this.props.loading ? (
              <SimpleSpinner />
            ) : (
              <button
                // onSubmit={
                //   partnerId ? this.onSubmitEditPartner : this.onSubmitNewPartner
                // }
                type="submit"
                disabled={partnerId ? false : isChecked ? false : true}
                className="btn btn-primary my-3 fw-bold">
                {partnerId ? 'Edit' : 'Submit'} Info
              </button>
            )}
          </form>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.userAuth.isAuthenticated,
    advertiseData: state.dashboardAdvertise.advertiseData,
    message: state.partner.message,
    error: state.partner.error,
    loading: state.partner.loading,
    fileUploadLoading: state.fileUpload.loading,
    fileUploadError: state.fileUpload.error,
    fileUploadMessage: state.fileUpload.message,
    fileUploadPercentage: state.fileUpload.percentage,
    uploadedCv: state.fileUpload.files,
    requestPartner: state.partner.requestPartner,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchTypeAndSubTypes: () => dispatch(actions.fetchTypeAndSubtypes()),
    postNewPartnerRequest: (requestData) =>
      dispatch(actions.postPartnerRequest(requestData)),
    onUploadFiles: (cv) => dispatch(actions.uploadFiles(cv)),
    onGetPartnerRequest: (id) => dispatch(actions.getPartnerRequest(id)),
    onSubmitEditPartner: (updatedData, partnerId) =>
      dispatch(actions.editPartnerRequest(updatedData, partnerId)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation(['Tender', 'Partner'])(PostPartnerRequest))
