import React, { createRef } from "react";
import classes from '../ChatHome.module.css';
import "./chatContent.css";
import { Avatar } from "../List/Avatar";
import { ChatItem } from "./ChatItem";
import ChatProfile from "../Profile";
import { connect } from 'react-redux';
import axios from '../../../../axios';
import { loadStripe } from '@stripe/stripe-js';
import Footer from '../Footer';
import { tokenHeaders } from '../../../../store/actions/HeaderConfig';
import SimpleSnackbar from '../../../../components/Snackbar/Snackbar';
import { stripe_key } from '../../../../config/stripe';
const stripe = loadStripe(stripe_key);


const ChatBody = (props) => {
    const messagesEndRef = createRef();
    const [show, setShow] = React.useState(false);
    const [allConversation, setAllConversation] = React.useState([]);
    const [selected, onSelect] = React.useState(false);
    const [error, setError] = React.useState('');

    const onClickOutsideListener = () => {
        setShow(false)
        document.removeEventListener("click", onClickOutsideListener)
    }

    const addToConversation = (messageObj) => {
        setAllConversation([...allConversation, messageObj])
    }

    const scrollToBottom = () => messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useEffect(() => scrollToBottom(), [allConversation]);

    React.useEffect(() => {

        if (props.currentUser) {
            const url = '/chat/allMessages';
            axios
                .post(
                    url,
                    { singleEmail: props.currentUser.email, userEmail: props.user.email },
                )
                .then((info) => {
                    if (info.status === 200) {
                        let conversationData = []
                        let dd = info.data.messages;
                        dd.map((single) => {
                            if (single.message !== 'System') {
                                let messageObj = {};

                                messageObj['customOrder'] = single.customOrder;
                                messageObj['files'] = single.files;
                                messageObj['message'] = single.message;
                                messageObj['sentAt'] = single.createdAt;

                                if (single.fromEmail === props.user.email) {
                                    messageObj['type'] = 'sent'
                                    conversationData.push(messageObj);
                                    return messageObj;
                                } else {
                                    messageObj['type'] = 'received';
                                    conversationData.push(messageObj);
                                    return messageObj;
                                }
                            } else {
                                return null;
                            }
                        });
                        setAllConversation(conversationData)
                    } else {
                        console.log('Something is wrong!')
                    }
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.currentUser]);

    props.setConversations(allConversation);

    const createCheckoutSessionForProduct = async (data) => {
        const res = await axios({
            method: 'POST',
            url: '/stripe/create-payment-session',
            data: data,
            headers: tokenHeaders(props.token).headers,
        });
        return res.data;
    }

    const payCustomOrder = async (customOrder) => {
        const { seller, advertise, amount, adInfo: { file, title }, deliveryDays } = customOrder;

        var price = amount;
        var stripe_fee = (parseFloat(amount) * 2.9) / 100 + 0.3;
        price = Number((parseFloat(amount) + stripe_fee).toFixed(2));

        const message = '2.9% + 0.30 stripe fee is included and title: ' + title;
        const currentUrl = window.location.href;

        const data = {
            advertiseId: advertise,
            customOrderId: customOrder._id,
            type: 'Custom',
            name: 'Custom Order Info',
            price: price,
            adPrice: amount,
            time: deliveryDays + ' days',
            images: [file],
            info: message,
            sellerId: seller,
            adTitle: title,
            domainURL: currentUrl.split('/inbox')[0]

        };

        try {
            const session = await createCheckoutSessionForProduct(data);
            if (session.error) {
                setError(session.error);
                return;
            }
            const stp = await stripe;
            await stp.redirectToCheckout({ sessionId: session.sessionId });

        } catch (e) {
            setError(e.message);
        }
    }

    return <React.Fragment>
        {error &&
            <SimpleSnackbar open={true} message={error} type='error' />}
        <div className={classes.ChatBody} style={props.screenWidth < 800 && !props.show ? { display: 'none', visibility: 'hidden' } : {
            display: 'block', visibility: 'visible'
        }}>
            <div className="main__chatcontent">
                <div className="content__header">
                    <div className="blocks">
                        {props.screenWidth < 800 &&
                            <div className="back">
                                <div className="btn-nobg" onClick={props.showBody}>
                                    <i className="fa fa-arrow-left" aria-hidden="true"></i>
                                </div>
                            </div>
                        }
                        {props.currentUser && <div className="current-chatting-user">
                            <Avatar
                                isOnline={props.currentUser.status === 'offline' ? '' : "active"}
                                image={props.currentUser.profile_pic}
                            />
                            <p>{props.currentUser.name}</p>
                            {/* <small>{props.currentUser.username}</small> */}
                        </div>}
                    </div>
                    <div className="blocks">
                        {/* <div className="settings">
                        <div className="btn-nobg">
                            <i className="fa fa-cog"></i>
                        </div>
                    </div> */}
                        {props.screenWidth < 1200 && props.profileInfo &&
                            <div className="info">
                                <div className="btn-nobg" onClick={_ => setShow(!show)}>
                                    <i className={`fas ${ show ? 'fa-times' : 'fa-info-circle' }`}></i>
                                </div>
                            </div>
                        }
                    </div>
                </div>

                {props.screenWidth < 1200 && props.profileInfo &&
                    <div className="content__header_info">
                        {show && <div className="content__header_profile" onMouseLeave={() => {
                            document.addEventListener("click", onClickOutsideListener)
                        }}>
                            <ChatProfile
                                isPopUp={true}
                                conversations={allConversation}
                                profileInfo={props.profileInfo} />
                        </div>
                        }
                    </div>
                }
                <div className="content__body" style={selected ? { height: '180px' } : { height: '320px' }}>
                    <div className="chat__items">
                        {allConversation.length > 0 ? allConversation.map((item, index) => {
                            return <ChatItem
                                animationDelay={index + 2}
                                key={item.key}
                                userType={item.type === 'sent' ? "me" : 'other'}
                                msg={item.message}
                                customOrder={item.customOrder}
                                image={item.type === 'sent' ? props.user && props.user.profile_image : props.currentUser.profile_pic}
                                files={item.files}
                                sentAt={item.sentAt}
                                userRole={props.user && props.user.role}
                                payCustomOrder={payCustomOrder}
                            />
                        })
                            : <div className="d-flex mt-5">
                                <span className="bg-white m-auto rounded p-3 text-muted">No conversation yet...!</span>
                            </div>
                        }
                        <div ref={messagesEndRef} />
                    </div>
                </div>
                <div className="FooterChat">
                    <Footer
                        currentUser={props.currentUser}
                        user={props.user && props.user}
                        conversation={allConversation}
                        addToConversation={addToConversation}
                        scrollToBottom={_ => scrollToBottom()}
                        updateLatestMessage={props.updateLatestMessage}
                        onSelectedFiles={selected => onSelect(selected)}
                    />
                </div>
            </div>
        </div>
    </React.Fragment>
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.chat.currentUser,
        user: state.userAuth.user,
        token: state.userAuth.token,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatBody);