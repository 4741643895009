import React from 'react';
import classes from '../SingleAdvertise.module.css';
import { getDate } from '../../../../../shared/utility';
import { ratted, notRatted } from './RatingNotRating';

export const AllReviewsRatings = ({ reviewsRating}) => {
    return reviewsRating.map((data, index) => (
            <React.Fragment>
                <div className={classes.ReviewsDescription} key={index}>
                    <div className={classes.Profile}>
                        <img src={data && data.user && data.user.profile_image} alt="Profile" />
                        <div className={classes.ProfileNameAndDate}>
                            <h6>{data && data.user && data.user.username}</h6>
                            <small>{getDate(data.createdAt)}</small>
                        </div>
                    </div>
                    {data.rating !== 0 && <React.Fragment>
                        <span>
                            {ratted(data.rating)}
                            {notRatted(data.rating)}
                        </span>
                        <br />
                    </React.Fragment>}
                    <div className='my-1'><small>{data.review}</small></div>
                </div>
                {reviewsRating.length > index + 1 && <div className={classes.ReviewsDivider}></div>}
            </React.Fragment>
        ))
    }
