import * as actionTypes from '../ActionTypes';
import axios from '../../../axios';
import { tokenHeadersNormal, tokenHeadersMultipart, headersNormal } from '../HeaderConfig';

const advertiseItemsFetchUploadStart = () => {
    return {
        type: actionTypes.ADVERTISE_ITEMS_FETCH_UPLOAD_START
    }
};
const uploadingPercentage = (percentage) => {
    return {
        type: actionTypes.ADVERTISE_ITEMS_FETCH_UPLOAD_LP,
        payload: { percentage }
    }
}

const advertiseItemsLPDismiss = () => {
    return {
        type: actionTypes.ADVERTISE_ITEMS_FETCH_UPLOAD_LP_DISMISS
    }
}

const percentageDismiss = () => {
    return (dispatch) => {
        setTimeout(() => {
            dispatch(advertiseItemsLPDismiss())
        }, 4000)
    }
}
const advertiseUserItemsFetchUploadSuccess = (userAdvertisesData, message = null) => {
    return {
        type: actionTypes.ADVERTISE_USER_ITEMS_FETCH_UPLOAD_SUCCESS,
        payload: { userAdvertisesData, message }
    }
};
const advertiseItemsFetchUploadSuccess = (advertiseData, message = null) => {
    return {
        type: actionTypes.ADVERTISE_ITEMS_FETCH_UPLOAD_SUCCESS,
        payload: { advertiseData, message }
    }
};

const advertiseShareIdGetSuccess = (shortId, advertiseId, message = null) => {
    return {
        type: actionTypes.ADVERTISE_SHARE_ID_GET_SUCCESS,
        payload: { advertiseId, message, shortId }
    }
};

const advertiseItemsFetchUploadError = (error) => {
    return {
        type: actionTypes.ADVERTISE_ITEMS_FETCH_UPLOAD_ERROR,
        payload: { message: error }
    }
};

export const fetchUserAdvertises = () => {
    return (dispatch, getState) => {
        dispatch(advertiseItemsFetchUploadStart());
        const url = '/dashboardAdvertise/userSpecificAdvertises';
        axios.get(url, { headers: tokenHeadersNormal(getState).headers })
            .then(response => dispatch(advertiseUserItemsFetchUploadSuccess(response.data.userAdvertisesData)))
            .catch(error => {
                if (error.response === undefined) return;
                dispatch(advertiseItemsFetchUploadError(error.response.data.message))
            });
    }
}


export const getUserAdvertisesInfo = () => {
    return (dispatch, getState) => {
        dispatch(advertiseItemsFetchUploadStart());
        const url = '/dashboardAdvertise/getUserAdvertisesInfo';
        axios.get(url, { headers: tokenHeadersNormal(getState).headers })
            .then(response => dispatch(advertiseUserItemsFetchUploadSuccess(response.data.userAdvertisesData)))
            .catch(error => {
                if (error.response === undefined) return;
                dispatch(advertiseItemsFetchUploadError(error.response.data.message))
            });
    }
}

export const fetchTypeAndSubtypes = () => {
    return (dispatch, getState) => {
        dispatch(advertiseItemsFetchUploadStart());
        const url = '/dashboardAdvertise/types';
        axios.get(url, { headers: headersNormal.headers })
            .then(response => dispatch(advertiseItemsFetchUploadSuccess(response.data.typeSubtypes)))
            .catch(error => {
                if (error.response === undefined) return;
                dispatch(advertiseItemsFetchUploadError(error.response.data.message))
            });
    }
}

export const updateTypeAndSubtypes = (typeAndSubtypes) => {
    return (dispatch, getState) => {
        dispatch(advertiseItemsFetchUploadStart());
        const url = '/dashboardAdvertise/types';
        const body = { typeAndSubtypes };
        axios.post(url, body, { headers: tokenHeadersNormal(getState).headers })
            .then(response => dispatch(advertiseItemsFetchUploadSuccess(response.data.typeSubtypes, response.data.message)))
            .catch(error => {
                if (error.response === undefined) return;
                dispatch(advertiseItemsFetchUploadError(error.response.data.message))
            });
    }
}

export const onSubmitAdvertiseForm = (body) => {
    return (dispatch, getState) => {
        dispatch(advertiseItemsFetchUploadStart());
        const url = '/dashboardAdvertise/createAd';
        axios.post(url, body, {
            headers: tokenHeadersMultipart(getState).headers,
            onUploadProgress: (progressEvent) => {
                dispatch(uploadingPercentage(parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total))));
            }
        }).then(response => {

            let adsData = JSON.parse(localStorage.getItem('adsData'));

            adsData = { ...adsData, advertiseId: response.data.advertiseId };
            localStorage.setItem('adsData', JSON.stringify(adsData));
            
            dispatch(advertiseShareIdGetSuccess(response.data._id, response.data.advertiseId, response.data.message));
            dispatch(percentageDismiss());
        }).catch(error => {
            if (error.response === undefined) return;
            dispatch(advertiseItemsFetchUploadError(error.response.data.message))
        });
    }
}

export const onUpdateAdvertiseForm = (body, advertiseId) => {
    return (dispatch, getState) => {
        dispatch(advertiseItemsFetchUploadStart());
        const url = `/dashboardAdvertise/updateAd?advertiseId=${ advertiseId }`;
        axios.put(url, body, {
            headers: tokenHeadersMultipart(getState).headers,
            onUploadProgress: (progressEvent) => {
                dispatch(uploadingPercentage(parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total))));
            }
        }).then(response => {
            dispatch(advertiseShareIdGetSuccess(response.data.shortId, response.data.advertiseId,  response.data.message));
            dispatch(percentageDismiss());
        }).catch(error => {
            if (error.response === undefined) return;
            dispatch(advertiseItemsFetchUploadError(error.response.data.message))
        });
    }
}


export const fetchPublicUserAdvertises = (userInfo) => {
    return (dispatch) => {
        dispatch(advertiseItemsFetchUploadStart());
        const url = `/dashboardAdvertise/userPublicSpecificAdvertises?username=${ userInfo.username }`;
        axios.get(url)
            .then(response => dispatch(advertiseUserItemsFetchUploadSuccess(response.data.userAdvertisesData)))
            .catch(error => {
                if (error.response === undefined) return;
                dispatch(advertiseItemsFetchUploadError(error.response.data.message))
            });
    }
}