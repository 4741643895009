import React from 'react';
import classes from './Spinner.module.css';
import { CircularProgress } from '@material-ui/core';


export const Spinner = () => <div className={classes.Spinner}><CircularProgress /></div>;

export const SimpleSpinner = () => <div><CircularProgress /></div>;

export const SpinnerAbsolute = () => <div className={classes.SpinnerAbsolute}><CircularProgress /></div>;

export const MainSpinner = () => <div className={classes.container}>
    <div className={classes.block}></div>
    <div className={classes.block}></div>
    <div className={classes.block}></div>
    <div className={classes.block}></div>
    <div className={classes.block}></div>
    <div className={classes.block}></div>
    <div className={classes.block}></div>
    <div className={classes.block}></div>
    <div className={classes.block}></div>
    <div className={classes.block}></div>
    <div className={classes.block}></div>
    <div className={classes.block}></div>
    <div className={classes.block}></div>
    <div className={classes.block}></div>
    <div className={classes.block}></div>
    <div className={classes.block}></div> 
</div>
