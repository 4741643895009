import React from 'react'
import { withTranslation } from 'react-i18next'
import classes from './NavigationItems.module.css'
import { NormalNavigationItem } from './NavigationItem/NavigationItem'
import profileImage from '../../../../assets/images/profileImage.png'

class ProfileInfo extends React.Component {
  render() {
    const props = this.props
    const { t } = this.props
    return (
      <div className={classes.dropdownForUser}>
        <img
          src={props.user ? props.user.profile_image : profileImage}
          alt="ProfileImage"
        />
        <div className={classes.dropdownContentForUser}>
          <div className="animate__animated animate__fadeIn">
            <NormalNavigationItem link="/profile">
              <div className={classes.DropMenuStyle}>
                <i className='fa fa-user mx-2'></i>
                <span>
                 {t('Dashboard:DashboardNavigation.2')}
                </span>
              </div>
            </NormalNavigationItem>
            <NormalNavigationItem link={`/settings/${props.user.username}/security`}>
              <div className={classes.DropMenuStyle}>
                <i className='fa fa-user-cog mx-2'></i><span>{t('translation:settings')}</span>
              </div>
            </NormalNavigationItem>
            <NormalNavigationItem link="/contact-us">
              <div className={classes.DropMenuStyle}>
                <i className='fa fa-headset mx-2'></i><span>{t('Dashboard:DashboardNavigation.14')}</span>
              </div>
            </NormalNavigationItem>
            <div className={classes.Divider}></div>
            <NormalNavigationItem link="/logout">
              <div className={classes.DropMenuStyle}>
                <i className='fa fa-sign-out-alt mx-2'></i><span>{t('Dashboard:ProfiledashboardNavigationItems.2')}</span>
              </div>
            </NormalNavigationItem>
          </div>
        </div>
      </div>
    )
  }
}

export default (withTranslation(['Dashboard']))(ProfileInfo)