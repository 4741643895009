// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".HorizontalSlider_ItemsContainer__1Dlqy {\n    display: flex;\n    justify-content: center;\n    flex-flow: row;\n}\n\n.HorizontalSlider_Items__F-yJm {\n    scroll-behavior: smooth;\n    display: flex;\n    flex-flow: row;\n}\n\n.HorizontalSlider_ArrowItem__wP4E3 {\n    align-items: center;\n    display: none;\n    justify-content: center;\n    visibility: hidden;\n}\n\n.HorizontalSlider_Button__1T79L {\n    height: 45px;\n    width: 45px;\n    border-radius: 50%;\n    background: white;\n    cursor: pointer;\n    color: #2A73CC;\n    box-shadow: 0px 2px 1px -3px rgba(48, 46, 46, 0.2), 0px 1px 0px 0px rgba(44, 42, 42, 0.14), 0px 1px 2px 0px rgba(49, 47, 47, 0.12);\n    transition: .7s;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.HorizontalSlider_Button__1T79L:hover {\n    background: #4dabf7;\n    color: white;\n    box-shadow: 0px 2px 4px 0px rgba(109, 108, 108, 0.2);\n}\n\n@media(min-width:800px) {\n    .HorizontalSlider_ItemsContainer__1Dlqy {\n        margin: 0px 10px;\n    }\n    .HorizontalSlider_ArrowItem__wP4E3 {\n        display: flex;\n        visibility: visible;\n    }\n    .HorizontalSlider_Button__1T79L {\n        margin: 0px 5px;\n    }\n}", ""]);
// Exports
exports.locals = {
	"ItemsContainer": "HorizontalSlider_ItemsContainer__1Dlqy",
	"Items": "HorizontalSlider_Items__F-yJm",
	"ArrowItem": "HorizontalSlider_ArrowItem__wP4E3",
	"Button": "HorizontalSlider_Button__1T79L"
};
module.exports = exports;
