import React from 'react';
import { connect } from 'react-redux';
import socket from '../../../../config/socket';
import {
    sendTextMessage
} from '../../../../store/actions/ActionsFiles/Chat';
import EmojiPicker from 'emoji-picker-react';
import { Modal } from '../../../../components/UI/Modal';
import CustomOrder from '../CustomOrder';
import styles from './Chat.module.css';
import * as actions from '../../../../store/actions';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { SimpleSpinner } from '../../../../components/UI/Spinner/Spinner';
import { getFileType } from '../../../../shared/utility';
import { File } from './File';

const Footer = (props) => {
    const [message, setMessage] = React.useState('');
    const { user, currentUser, addToConversation, scrollToBottom } = props;
    const [files, setFiles] = React.useState([]);
    const [showPicker, setShowPicker] = React.useState(false);
    const [showModal, setShowModal] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        if (props.currentUser) {
            socket.on('private message', ({ payload, from }) => {
                if (currentUser.id === payload.username) {
                    let obj = {};

                    obj['message'] = payload.message;
                    obj['customOrder'] = payload.customOrder;
                    obj['files'] = payload.files;
                    obj['sentAt'] = payload.createdAt;
                    if (payload.fromEmail === user.email) {
                        obj['type'] = 'sent';
                    } else {
                        obj['type'] = 'received';
                    }
                    addToConversation(obj)
                    // setMessages([...conversation, _obj])
                }
            });

            return () => {
                socket.off('private message')
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.email, addToConversation])

    const onSendMessage = (message, files) => {
        props.sendTextMessage(message, files, user, currentUser);
        const payload = {
            username: currentUser.id,
            message: message.trim(),
            files: files,
            createdAt: Date.now(),
            fromEmail: user.email,
            toEmail: currentUser.email,
        }
        socket.emit('private message', {
            payload,
            to: currentUser.email,
        })

        let obj = {}
        obj['files'] = files;
        obj['message'] = message.trim()
        obj['sentAt'] = Date.now()
        obj['type'] = 'sent'
        addToConversation(obj);
    }

    const updateMessageTime = (message, files) => {
        const date = new Date().getTime();
        props.updateLatestMessage({ message, date, files });
    }

    const addMessage = (e) => {
        e.preventDefault();
        scrollToBottom();

        if (message !== "") {
            updateMessageTime(message, []);
            onSendMessage(message, []);
            setMessage("");
        }
    }

    const onStateChange = (e) => {
        setMessage(e.target.value);
    };

    const onEmojiClick = (e, emojiObject) => {
        setMessage(message + emojiObject.emoji);
        setShowPicker(false);
    }

    const closeModal = () => setShowModal(false);

    const handleFiles = (event) => {
        let filesArray = [...files];

        for (let i = 0; i < event.target.files.length; i++) {
            const size = (event.target.files[i].size / (1024 * 1024)).toFixed(2);
            if (parseInt(size) > 25) return alert('Your max files limit is 25Mb');
            filesArray.push(event.target.files[i]);
        }
        setFiles(filesArray);
    }

    const uploadFiles = (e) => {
        e.preventDefault();
        setLoading(true);

        const filesData = new FormData();
        for (let i = 0; i < files.length; i++) {
            filesData.append('files', files[i]);
        }
        props.onUploadFiles(filesData);
        setFiles([]);
    }

    const removeFile = (e, index) => {
        const filteredFiles = files.filter((_, fileIndex) => index !== fileIndex);
        setFiles(filteredFiles);
    }

    React.useEffect(() => {
        if (props.files.length !== 0) {
            scrollToBottom();
            onSendMessage(message, props.files);
            updateMessageTime(message, props.files);
            setMessage("");
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.files]);

    props.onSelectedFiles(files.length > 0 ? true : false);

    return <React.Fragment>
        <Modal type='normal' show={showModal}
            modalClosed={closeModal}>
            <CustomOrder
                closeModal={closeModal}
                addToConversation={props.addToConversation}
            />
        </Modal>
        <div className="content__footer">
            <div className={styles.FileDisplay}>
                {files.map((fileInfo, index) => (
                    <div className={styles.File} key={index}>
                        <File file={fileInfo} local={true} />
                        <i className='fa fa-times' onClick={e => removeFile(e, index)}></i>
                        <small>{fileInfo.name.toString().length > 9 ?
                            fileInfo.name.substr(0, props.screenWidth > 800 ? 16 : 10) + '...' + getFileType(fileInfo.name) : fileInfo.name}</small>
                    </div>
                ))}
            </div>
            <form className="sendNewMessage" onSubmit={e => { files.length > 0 ? uploadFiles(e) : addMessage(e); }}>
                <div className={styles.FileInput}>
                    <input
                        type='file'
                        accept='.jpg, .png, .jpeg, .gif, .csv, .xlsx, .xls, .pdf, .doc, .zip, .mp4, .mov, .mkv, .mp3, .aac, .wav'
                        multiple
                        onChange={handleFiles}
                        id='file' />

                    <label htmlFor='file' title='Upload Files'>
                        <AttachFileIcon />
                    </label>
                </div>
                <input
                    type="text"
                    placeholder="Type a message here"
                    onChange={onStateChange}
                    value={message}
                />
                {loading ? <SimpleSpinner /> : <button className="btnSendMsg" id="sendMsgBtn" onClick={e => { files.length > 0 ? uploadFiles(e) : addMessage(e); }}>
                    <i className="fa fa-paper-plane"></i>
                </button>}

            </form>
            <div className={styles.PickerOrder}>
                <div className={styles.Picker}>
                    {showPicker && <EmojiPicker
                        pickerStyle={{ width: '100%' }}
                        onEmojiClick={onEmojiClick} />}
                </div>
                <div className={`${ styles.emoji } d-flex`}>
                    <i className={`${ styles.Hover }  ${ showPicker ? 'fas fa-times' : 'far fa-grin' }`}
                        onClick={() => setShowPicker(!showPicker)} ></i>
                    {props.user.role === 'seller' && <button className={styles.Hover} onClick={_ => setShowModal(true)}>
                        Create custom order
                    </button>}
                </div>
            </div>
        </div>
    </React.Fragment>
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.chat.currentUser,
        user: state.userAuth.user,
        files: state.fileUpload.files,
        fileUploadLoading: state.fileUpload.loading,
        fileUploadError: state.fileUpload.error,
        fileUploadMessage: state.fileUpload.message,
        fileUploadPercentage: state.fileUpload.percentage,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onUploadFiles: (files) => dispatch(actions.uploadChatFiles(files)),
        sendTextMessage: (message, files, user, currentUser) => dispatch(sendTextMessage(message, files, user, currentUser)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
