import * as actionTypes from '../ActionTypes';
import axios from '../../../axios';
import { tokenHeadersNormal } from '../HeaderConfig';

const profileUpdateStart = () => {
    return {
        type: actionTypes.PROFILE_UPDATE_START
    }
}

const submitSupportSuccess = (message) => {
    return {
        type: actionTypes.SUBMIT_SUPPORT_SUCCESS,
        payload: { message }
    }
}

const profileUpdateLPDismiss = (history) => {
    history.replace('/profile');
    return {
        type: actionTypes.PROFILE_UPDATE_LP_DISMISS
    }
}

const uploadingPercentage = (percentage) => {
    return {
        type: actionTypes.PROFILE_UPDATE_LP,
        payload: { percentage }
    }
}

const percentageDismiss = (history) => {
    return (dispatch) => {
        setTimeout(() => {
            dispatch(profileUpdateLPDismiss(history))
        }, 2000)
    }
}

const profileUpdateSuccess = (success) => {
    return {
        type: actionTypes.PROFILE_UPDATE_SUCCESS,
        payload: { message: success }
    }
}

const profileUpdateFail = (error) => {
    return {
        type: actionTypes.PROFILE_UPDATE_FAIL,
        payload: { message: error }
    }
};

const fetchProfileStart = () => {
    return {
        type: actionTypes.PROFILE_FETCH_START
    }
}

const fetchProfileFinished = (profile) => {
    return {
        type: actionTypes.PROFILE_FETCH_FINISHED,
        payload: { profile }
    }
}

const fetchProfileError = (message) => {
    return {
        type: actionTypes.PROFILE_FETCH_ERROR,
        payload: { message }
    }
}

export const profileUpdate = (body, history) => {
    return (dispatch, getState) => {
        dispatch(profileUpdateStart());
        const url = '/user/updateUser';
        axios.patch(url, body, {
            headers: tokenHeadersNormal(getState).headers,
            onUploadProgress: (progressEvent) => {
                dispatch(uploadingPercentage(parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total))));
            }
        }).then(response => {
            dispatch(profileUpdateSuccess(response.data.message));
            dispatch(percentageDismiss(history));
        }).catch(error => {
            if (error.response === undefined) return;
            dispatch(profileUpdateFail(error.response.data.message))
        });
    }
}

export const getProfile = () => {
    return (dispatch, getState) => {
        dispatch(fetchProfileStart());
        const url = '/user/getProfile';
        axios.get(url, { headers: tokenHeadersNormal(getState).headers })
            .then(response => dispatch(fetchProfileFinished(response.data.profile)))
            .catch(error => {
                if (error.response === undefined) return;
                dispatch(fetchProfileError(error.response.data.message))
            });
    }
}

export const getPublicProfile = (userInfo) => {
    return (dispatch, getState) => {
        dispatch(fetchProfileStart());
        const url = `/user/${userInfo.username}/`;
        axios.get(url)
            .then(response => dispatch(fetchProfileFinished(response.data.profile)))
            .catch(error => {
                if (error.response === undefined) return;
                dispatch(fetchProfileError(error.response.data.message))
            });
    }
}

export const onSubmitSupport = (support) => {
    return (dispatch, getState) => {
        axios.post(
            'support/postsSupport',
            support,
            { headers: tokenHeadersNormal(getState).headers })
            .then(response => dispatch(submitSupportSuccess(response.data.message)))
            .catch(error => {
                if (error.response === undefined) return;
            });
    }
}



