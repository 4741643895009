import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import classes from './Profile.module.css';

const Statistics = ({ workingDomains, workingMedia, workingSubMedia }) => {
    return workingDomains.length > 0 && workingMedia.length > 0 && workingSubMedia.length > 0 &&
        <div className='d-flex flex-wrap justify-content-lg-between justify-content-center align-items-center'>
            <div className={classes.GraphItem}>
                <li>Media</li>
                <BarChart
                    width={350}
                    height={250}
                    data={workingMedia}
                    margin={{
                        top: 5,
                        right: 20,
                        left: 10,
                        bottom: 5,
                    }}
                    barSize={15}
                >
                    <XAxis dataKey="name" scale="point" padding={{ left: 10, right: 10 }} />
                    <YAxis />
                    <Tooltip />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Bar dataKey="count" label fill="#0d6efd" background={{ fill: 'rgb(60, 60, 60)' }} />
                </BarChart>
            </div>
            <div className={classes.GraphItem}>
                <li>Sub Media</li>
                <BarChart
                    width={350}
                    height={250}
                    data={workingSubMedia}
                    margin={{
                        top: 5,
                        right: 20,
                        left: 10,
                        bottom: 5,
                    }}
                    barSize={15}
                >
                    <XAxis dataKey="name" scale="point" padding={{ left: 10, right: 10 }} />
                    <YAxis />
                    <Tooltip />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Bar dataKey="count" label fill="red" background={{ fill: 'rgb(60, 60, 60)' }} />
                </BarChart>
            </div>
            <div className={classes.GraphItem}>
                <li>Domains</li>
                <BarChart
                    width={350}
                    height={250}
                    data={workingDomains}
                    margin={{
                        top: 5,
                        right: 20,
                        left: 10,
                        bottom: 5,
                    }}
                    barSize={15}
                >
                    <XAxis dataKey="name" scale="point" padding={{ left: 10, right: 10 }} />
                    <YAxis />
                    <Tooltip />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Bar dataKey="count" label fill="#1DBF73" background={{ fill: 'rgb(60, 60, 60)' }} />
                </BarChart>
            </div>
        </div>
}


export default Statistics;