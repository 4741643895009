import React, { Component } from 'react';
import classes from '../CreateAdd.module.css';
import Button from '../../../../components/UI/Button/Button';
import StepProgress from './StepProgress';
import { withGoogleMap, GoogleMap, withScriptjs, InfoWindow, Marker } from "react-google-maps";
import Geocode from "react-geocode";
import Autocomplete from 'react-google-autocomplete';
import { Divider } from '../../../../components/UI/Spacing';
import { Location } from '../../../../shared/Location';

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_API_KEY);
Geocode.enableDebug();

const LocationInfoItem = ({ label, value }) => <React.Fragment>
    <div className={classes.LocationInfoItem}>
        <div className={classes.LocationInfoItemIndex}>{label}</div>
        <div className={classes.LocationInfoItemValue}>{value}</div>
    </div>
</React.Fragment>

class Step6 extends Component {
    state = {
        isClicked: false,
        address: '',
        city: '',
        area: '',
        state: '',
        country: '',
        zoom: 15,
        height: 400,
        mapPosition: {
            lat: 0,
            lng: 0,
        },
        markerPosition: {
            lat: 0,
            lng: 0,
        }
    }

    continue = (event, isFieldOk) => {
        event.preventDefault();
        if (isFieldOk) this.props.nextStep();
        this.setState({ isClicked: true });
        setTimeout(() => {
            this.setState({ isClicked: false });
        }, 4000)
    };

    back = e => {
        e.preventDefault();
        this.props.prevStep();
    };

    componentDidMount() {
        window.scroll(0.0, 0.0);
        this.getLocation();
    };

    getLocation = () => {
        if (navigator.geolocation && !this.state.address && !this.props.values.location) {
            navigator.geolocation.getCurrentPosition(position => {
                this.setState({
                    mapPosition: {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    },
                    markerPosition: {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    }
                }, () => {
                    Geocode.fromLatLng(position.coords.latitude.toString(), position.coords.longitude.toString()).then(
                        response => {
                            const address = response.results[0].formatted_address,
                                addressArray = response.results[0].address_components,
                                city = Location.getCity(addressArray),
                                area = Location.getArea(addressArray),
                                state = Location.getState(addressArray),
                                country = Location.getCountry(addressArray);
                            this.setState({
                                address, area, city, state, country,
                            })
                        },
                        error => console.error(error)
                    )
                })
            });
        } else console.error("Geolocation is not supported by this browser!");
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.address !== prevState.address) {
            this.props.getAddress({
                area: this.state.area,
                city: this.state.city,
                country: this.state.country.toLowerCase(),
                state: this.state.state.split(' ')[0].toLowerCase(),
                address: this.state.address,
                coordinates: this.state.mapPosition
            });
        }
    }

    onChange = (event) => this.setState({ [event.target.name]: event.target.value });

    onInfoWindowClose = (event) => { };

    onMarkerDragEnd = (event) => {
        let newLat = event.latLng.lat(),
            newLng = event.latLng.lng();

        Geocode.fromLatLng(newLat, newLng).then(
            response => {
                const address = response.results[0].formatted_address,
                    addressArray = response.results[0].address_components,
                    city = Location.getCity(addressArray),
                    area = Location.getArea(addressArray),
                    state = Location.getState(addressArray),
                    country = Location.getCountry(addressArray);
                this.setState({
                    address, area, city, state, country,
                    markerPosition: {
                        lat: newLat,
                        lng: newLng
                    },
                    mapPosition: {
                        lat: newLat,
                        lng: newLng
                    },
                })
            },
            error => console.error(error)
        );
    };

    onPlaceSelected = (place) => {
        const address = place.formatted_address,
            addressArray = place.address_components,
            city = Location.getCity(addressArray),
            area = Location.getArea(addressArray),
            state = Location.getState(addressArray),
            latValue = place.geometry.location.lat(),
            lngValue = place.geometry.location.lng();

        // Set these values in the state.
        this.setState({
            address: (address) ? address : '',
            area: (area) ? area : '',
            city: (city) ? city : '',
            state: (state) ? state : '',
            markerPosition: {
                lat: latValue,
                lng: lngValue
            },
            mapPosition: {
                lat: latValue,
                lng: lngValue
            },
        })
    };

    resetLocation = () => this.setState({ address: '' });

    render() {
        const { resetLocation } = this.props;
        const { screenWidth, location } = this.props.values;
        const { t } = this.props;
        let isFieldOk = location ? true : false;
        const address = location ? location : this.state.address;

        if (!location && !this.state.address) this.getLocation();
        const AsyncMap = withScriptjs(
            withGoogleMap(
                props => (
                    <GoogleMap
                        streetView
                        defaultZoom={this.state.zoom}
                        defaultCenter={{ lat: this.state.mapPosition.lat, lng: this.state.mapPosition.lng }}>
                        <Autocomplete
                            placeholder={t('MyAds:CreateAdd.Step5.2')}
                            style={{
                                width: '100%',
                                height: '40px',
                                paddingLeft: '16px',
                                margin: '10px 0px'
                            }}
                            onPlaceSelected={this.onPlaceSelected}
                            types={['(regions)']}
                        />
                        <Marker
                            google={this.props.google}
                            name={'Dolores park'}
                            draggable={true}
                            onDragEnd={this.onMarkerDragEnd}
                            position={{ lat: this.state.markerPosition.lat, lng: this.state.markerPosition.lng }}
                        />
                        <InfoWindow
                            onClose={this.onInfoWindowClose}
                            position={{ lat: (this.state.markerPosition.lat + 0.0018), lng: this.state.markerPosition.lng }} >
                            <div>
                                <span style={{ padding: 0, margin: 0 }}>{this.state.address}</span>
                            </div>
                        </InfoWindow>
                        <Marker />
                    </GoogleMap>
                )));
        return <React.Fragment>
            <Divider orientation='v' space='2' />
            <StepProgress step={this.props.step} />
            <div style={{ padding: '1rem', margin: '0 auto', maxWidth: 1000 }}>
                <h5>{t('MyAds:CreateAdd.Step5.3')}</h5>
                <br />
                <div className={classes.LocationInfo}>
                    <LocationInfoItem label={t('MyAds:CreateAdd.Step5.5')} value={address.city} />
                    <LocationInfoItem label={t('MyAds:CreateAdd.Step5.6')} value={address.area} />
                    <LocationInfoItem label={t('MyAds:CreateAdd.Step5.7')} value={address.state} />
                    <LocationInfoItem label={t('MyAds:CreateAdd.Step5.8')} value={address.country} />
                    <LocationInfoItem label={t('MyAds:CreateAdd.Step5.9')} value={address.address} />

                    <LocationInfoItem label={<button
                        className='btn btn-primary w-100'
                        type='button'
                        onClick={(e) => { this.resetLocation(); resetLocation(e) }}
                    >
                        Reset Location
                    </button>} value='' />

                </div>
                {!isFieldOk && this.state.isClicked &&
                    <div className={classes.Error}>
                        <strong>{t('MyAds:CreateAdd.Step5.4')}
                        </strong>
                    </div>}
                <AsyncMap
                    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${ process.env.REACT_APP_GOOGLE_MAP_API_KEY }&libraries=places`}
                    loadingElement={
                        <div style={{ height: `100%` }} />
                    }
                    containerElement={
                        <div style={{ height: screenWidth < 700 ? 300 : this.state.height }} />
                    }
                    mapElement={
                        <div style={{ height: `100%`, borderRadius: '5px' }} />
                    }
                />
            </div>
            <br />
            <br />
            <div className={classes.Divider}></div>
            <div className={classes.StepsButton}>
                <Button buttonType='AppleDark' clicked={this.back} >  <i className='fas fa-chevron-left'></i>{t('MyAds:CreateAdd.button2')}</Button>
                <Button
                    buttonType='Submit'
                    clicked={(e) => this.continue(e, isFieldOk)}
                >{t('MyAds:CreateAdd.button1')}  <i className='fas fa-chevron-right'></i></Button>
            </div>
        </React.Fragment>
    }
}

export default Step6;

