// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".NavigationItem_NavigationItem__14wzu {\n    width: 100%;\n    display: flex;\n    height: 100%;\n    width: auto;\n    align-items: center;\n    padding: 3px 0px;\n}\n\n.NavigationItem_NavigationItem__14wzu a {\n    text-decoration: none;\n    width: 100%;\n    box-sizing: border-box;\n    display: block;\n    padding: 11px 10px;\n    color: rgb(66, 62, 62);\n    transition: .5s;\n    text-align: center;\n}\n\n.NavigationItem_NavigationItem__14wzu a:hover, .NavigationItem_NavigationItem__14wzu a:active, .NavigationItem_NavigationItem__14wzu a.NavigationItem_active__3t9Zm {\n    border-radius: 3px;\n    color: #0d6efd;\n    background-color: rgba(246, 246, 246, 1)\n}\n\n.NavigationItem_NavigationItem__14wzu img {\n    border-radius: 50%;\n    height: 100px;\n    width: 100px;\n}\n\n.NavigationItem_OnScrollNavigationItem__3IBa2 {\n    width: 100%;\n    display: flex;\n    height: 100%;\n    width: auto;\n    align-items: center;\n}\n\n.NavigationItem_OnScrollNavigationItem__3IBa2 a {\n    text-decoration: none;\n    width: 100%;\n    box-sizing: border-box;\n    display: block;\n    margin: 0px 5px;\n    padding: 8px 10px;\n    border-radius: 3px;\n    color: white;\n    font-weight: 600;\n}\n\n.NavigationItem_OnScrollNavigationItem__3IBa2 img {\n    border-radius: 50%;\n    height: 100px;\n    width: 100px;\n}", ""]);
// Exports
exports.locals = {
	"NavigationItem": "NavigationItem_NavigationItem__14wzu",
	"active": "NavigationItem_active__3t9Zm",
	"OnScrollNavigationItem": "NavigationItem_OnScrollNavigationItem__3IBa2"
};
module.exports = exports;
