import React from 'react';

export const RowItem = ({ label, value }) => <div className='row justify-content-between align-items-center my-3'>
    <div className='col-5'>
        <p className='text-secondary fw-lighter'>{label}</p>
    </div>
    <div className='col-5'>
        <p className='text-dark fw-bold'>{value}</p>
    </div>
</div>

export const ProfileRowItem = ({ icon, label, value }) => <div className='row justify-content-between align-items-center my-3'>
    <div className='col-7'>
        <span className='text-secondary fw-lighter'><i class={`${icon} mx-2`}></i>{label}</span>
    </div>
    <div className='col-4'>
        <span className='text-dark fw-bold'>{value}</span>
    </div>
</div>

export const Label = ({label}) => <div className='col-12 mb-2'>
    <h6 className='text-dark fw-bold'>{label}</h6>
</div>

export const LabelValue = ({value}) => <div className='col-12 mb-2'>
    <span className='text-secondary'>{value}</span>
</div>