import * as actionTypes from "../ActionTypes";
import axios from "../../../axios";
import { tokenHeadersNormal } from "../HeaderConfig";

const partnerStart = () => {
    return {
        type: actionTypes.START_PARTNER_REQUEST,
    };
};

const partnersSuccess = (requests, message) => {
    return {
        type: actionTypes.PARTNER_FETCH_SUCCESS,
        payload: { requests, message }
    };
};

const confirmOrDeletePartner = (message) => {
    return {
        type: actionTypes.PARTNER_CONFIRM_SUCCESS,
        payload: { message }
    };
};

const partnersInterested = (interested) => {
    return {
        type: actionTypes.PARTNER_INTERESTED_SUCCESS,
        payload: { interested }
    };
};

const partnerSuccess = (requestPartner) => {
    return {
        type: actionTypes.REQUEST_FETCH_SUCCESS,
        payload: { requestPartner }
    };
};


const partnerFail = (message) => {
    return {
        type: actionTypes.PARTNER_FAIL,
        payload: { message }
    };
};

const sellerHasPartner = (hasPartner) => {
    return {
        type: actionTypes.SELLER_HAS_PARTNER,
        payload: { hasPartner }
    };
};

const getAdsUnderPartner = (adsUnderPartner) => {
    return {
        type: actionTypes.ADS_UNDER_PARTNER,
        payload: { adsUnderPartner }
    };
};

export const postPartnerRequest = (partnerRequest) => {
    return (dispatch, getState) => {
        dispatch(partnerStart());
        axios
            .post('/partner/new-partner-request',
                partnerRequest,
                { headers: tokenHeadersNormal(getState).headers })
            .then(response => dispatch(partnersSuccess([], response.data.message)))
            .catch(error => {
                if (error.response === undefined) return;
                dispatch(partnerFail(error.response.data.message));
            });
    };
};

export const editPartnerRequest = (partnerRequest, partnerId) => {
    return (dispatch, getState) => {
        dispatch(partnerStart());
        axios
            .post('/partner/edit-partner-request',
                { partnerRequest, partnerId },
                { headers: tokenHeadersNormal(getState).headers })
            .then(response => dispatch(partnersSuccess([], response.data.message)))
            .catch(error => {
                if (error.response === undefined) return;
                dispatch(partnerFail(error.response.data.message));
            });
    };
};

export const onGetPartnerRequests = (filters) => {
    return (dispatch) => {
        dispatch(partnerStart());
        axios
            .post('/partner/getPartnerRequests', filters)
            .then((response) => dispatch(partnersSuccess(response.data.partners, '')))
            .catch(error => {
                if (error.response === undefined) return;
                dispatch(partnerFail(error.response.data.message));
            });
    };
};

export const checkHeHasPartner = () => {
    return (dispatch, getState) => {
        if (!getState().userAuth.token) return;
        axios
            .get('/partner/checkHeHasPartner', { headers: tokenHeadersNormal(getState).headers })
            .then((response) => dispatch(sellerHasPartner(response.data.hasPartner)))
            .catch(error => {
                if (error.response === undefined) return;
                dispatch(partnerFail(error.response.data.message));
            });
    };
};


export const getPartnerRequest = (id) => {
    return (dispatch) => {
        dispatch(partnerStart());
        axios
            .get(`/partner/getPartnerRequest?partnerId=${ id }`)
            .then((response) => dispatch(partnerSuccess(response.data.requestPartner)))
            .catch(error => {
                if (error.response === undefined) return;
                dispatch(partnerFail(error.response.data.message));
            });
    };
};

export const getUserPartnerRequest = () => {
    return (dispatch, getState) => {
        dispatch(partnerStart());
        axios
            .get(`/partner/getUserPartnerRequest`, { headers: tokenHeadersNormal(getState).headers })
            .then((response) => dispatch(partnerSuccess(response.data.requestPartner)))
            .catch(error => {
                if (error.response === undefined) return;
                dispatch(partnerFail(error.response.data.message));
            });
    };
};

export const createNewInterest = (id) => {
    return (dispatch, getState) => {
        dispatch(partnerStart());
        axios
            .get(`/partner/createNewInterest?partnerId=${ id }`, { headers: tokenHeadersNormal(getState).headers })
            .then((response) => dispatch(partnersSuccess([], response.data.message)))
            .catch(error => {
                console.log(error.response.data.message);
                if (error.response === undefined) return;
                dispatch(partnerFail(error.response.data.message));
            });
    };
};

export const getSellerPartnerRequest = () => {
    return (dispatch, getState) => {
        dispatch(partnerStart());
        axios
            .get('/partner/getSellerPartnerRequest', { headers: tokenHeadersNormal(getState).headers })
            .then((response) => dispatch(partnersSuccess(response.data.requestedSeller, '')))
            .catch(error => {
                if (error.response === undefined) return;
                dispatch(partnerFail(error.response.data.message));
            });
    };
};

export const getInterestedPartnerRequest = () => {
    return (dispatch, getState) => {
        axios
            .get('/partner/getInterestedPartnerRequest', { headers: tokenHeadersNormal(getState).headers })
            .then((response) => dispatch(partnersInterested(response.data.interestedPartners)))
            .catch(error => {
                if (error.response === undefined) return;
                dispatch(partnerFail(error.response.data.message));
            });
    };
};

export const onConfirmPartner = (id) => {
    return (dispatch, getState) => {
        axios
            .put('/partner/confirmPartner', { id }, { headers: tokenHeadersNormal(getState).headers })
            .then((response) => dispatch(confirmOrDeletePartner(response.data.message)))
            .catch(error => {
                if (error.response === undefined) return;
                dispatch(partnerFail(error.response.data.message));
            });
    };
};


export const onGetAdsUnderPartner = () => {
    return (dispatch, getState) => {
        axios
            .get('/partner/getAdsUnderPartner', { headers: tokenHeadersNormal(getState).headers })
            .then((response) => {
                dispatch(getAdsUnderPartner(response.data.adsUnderPartner))
            })
            .catch(error => {
                if (error.response === undefined) return;
                dispatch(partnerFail(error.response.data.message));
            });
    };
};



