// @ts-nocheck
import React from 'react'
import {withTranslation} from 'react-i18next'
import {images} from '../../../assets/images/start_selling'
import classes from './StartSelling.module.css'
import Button from '../../../components/UI/Button/Button'
import {Bounce, Fade, Slide} from 'react-reveal'
import {Translate} from 'react-auto-translate'
import Translation from '../../../shared/Translate'
import Translator from '../../../components/Translator'

import {
  company,
  event,
  media,
  entrepreneur,
} from '../../../assets/images/user_reviews'
import {MediaItems} from './MediaItems'
import {HorizontalSlider} from '../../../components/HorizontalSlider'
import {connect} from 'react-redux'
import axios from '../../../axios'

const JoinCommunity = [
  {
    image:
      'https://api.umediad.com/public/uploads/allFiles/$12132705210277.jpeg',
    text: 'BAYDJOB',
    link: '',
  },
  {
    image: 'https://api.umediad.com/public/uploads/allFiles/$12181921355199',
    text: 'Kekeltransport Inc.',
    link: '',
  },
  {
    image:
      'https://cdn.pixabay.com/photo/2017/08/02/00/03/interior-2568850__340.jpg',
    text: 'Dtth Hertew',
    link: '',
  },
  {
    image:
      'https://www.chinadaily.com.cn/business/img/attachement/jpg/site1/20151112/b083fe955aa117ae40e81e.jpg',
    text: 'Dali Foods Group',
    link: '',
  },
  {
    image:
      'https://cdn.pixabay.com/photo/2015/01/05/19/50/apple-589640_960_720.jpg',
    text: 'Huadong Medicine',
    link: '',
  },
  {
    image:
      'https://cdn.pixabay.com/photo/2018/05/17/15/10/social-3408791_960_720.jpg',
    text: 'Netmarble',
    link: '',
  },
  {
    image:
      'https://cdn.pixabay.com/photo/2016/12/28/17/54/columbus-1936633__340.jpg',
    text: 'Midea Group',
    link: '',
  },
  {
    image:
      'https://image.freepik.com/free-vector/organic-flat-people-business-training-illustration_23-2148922296.jpg',
    text: 'S.F. Holding',
    link: '',
  },
]

class StartSelling extends React.Component {
  componentWillUnmount() {
    window.scroll(0, 0)
  }

  tokenHeadersNormal = () => {
    const headersNormal = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    const token = this.props.token
    if (token) {
      headersNormal.headers['x-auth-header'] = token
    }
    return headersNormal
  }

  fetchSubscriptionData = async () => {
    const res = await axios({
      method: 'GET',
      url: '/user/checkSubscription',
      headers: this.tokenHeadersNormal().headers,
    })
    return res.data.data
  }

  switchUserAxios = async (data) => {
    const res = await axios({
      method: 'POST',
      url: '/user/switch',
      data: data,
      headers: this.tokenHeadersNormal().headers,
    })
    return res.data
  }

  switchUser = async (data) => {
    let cc = ''
    try {
      cc = await this.switchUserAxios(data)
      this.props.history.push('/create-new-ad')
      if (cc.message === 'Switched successfully!') {
        setTimeout(() => {
          window.location.reload()
        }, 200)
      }
    } catch (e) {
      console.log(e, 'error in switchUserAxios')
    }
  }

  handleSelling = async () => {
    if (this.props.isAuthenticated) {
      var ss
      try {
        ss = await this.fetchSubscriptionData()
        // console.log(ss, 'sss')
        if (ss && ss.status === 'active') {
          if (this.props.user.role === 'seller') {
            this.props.history.push('/create-new-ad')
          } else {
            this.switchUser({okay: 'seller'})
          }
        } else {
          this.props.history.push('/subscription-packages')
        }
      } catch (e) {
        console.log(e, 'handleSelling')
      }
    } else {
      this.props.history.push('/subscription-packages')
    }
  }

  render() {
    const list = []
    const {t} = this.props
    const mediaItems = [
      {
        image: media,
        name: t('StartSelling:below.stories.0.00'),
        desc: t('StartSelling:below.stories.0.01'),
        color: 'gray',
      },
      {
        image: company,
        name: t('StartSelling:below.stories.1.00'),
        desc: t('StartSelling:below.stories.1.01'),
        color: 'green',
      },
      {
        image: entrepreneur,
        name: t('StartSelling:below.stories.2.00'),
        desc: t('StartSelling:below.stories.2.01'),
        color: 'gold',
      },
      {
        image: event,
        name: t('StartSelling:below.stories.3.00'),
        desc: t('StartSelling:below.stories.3.01'),
        color: 'blue',
      },
    ]
    mediaItems.map((item, index) =>
      list.push(
        <MediaItems
          key={index}
          image={item.image}
          heading={item.name}
          color={item.color}
          desc={item.desc}
        />,
      ),
    )

    return (
      <div className={classes.StartSelling}>
        <div className={classes.SellingMain}>
          <div className={classes.HeaderImage}>
            {this.props.screenWidth <= 499 ? null : (
              <img src={images[0]} alt="HeaderImage" />
            )}
            <div className={classes.Description}>
              <h2>
                <Translation
                  from={'en'}
                  text={'Sell Whatever You Want'}
                  input={<Translate>Sell Whatever You Want</Translate>}
                />
              </h2>
              <h4>
                {/* {t('StartSelling:above.1')} */}
                <Translation
                  from={'en'}
                  text={'We will make earning easy'}
                  input={<Translate>We will make earning easy</Translate>}
                />
              </h4>
              <br />
              <Button buttonType="Common" clicked={this.handleSelling}>
                {/* {t('StartSelling:above.2')} */}
                <Translation
                  from={'en'}
                  text={'Sell Your Audience'}
                  input={<Translate>Sell Your Audience</Translate>}
                />
              </Button>
            </div>
            <Slide top>
              <div className={classes.FooterPart}>
                <div className={classes.FooterItem}>
                  <h4>
                    {/* {t('StartSelling:above.sections.0.00')} */}
                    <Translation
                      from={'en'}
                      text={'Start With Free Subscription'}
                      input={
                        <Translate>Start With Free Subscription</Translate>
                      }
                    />
                  </h4>
                  <span>
                    {/* {t('StartSelling:above.sections.0.01')} */}
                    <Translation
                      from={'en'}
                      text={'keep 80% of each transaction'}
                      input={
                        <Translate>keep 80% of each transaction</Translate>
                      }
                    />
                  </span>
                </div>
                <div className={classes.FooterItem}>
                  <h4>
                    {/* {t('StartSelling:above.sections.1.10')} */}
                    <Translation
                      from={'en'}
                      text={'Paid Subscription'}
                      input={<Translate>Paid Subscription</Translate>}
                    />
                  </h4>
                  <span>
                    {/* {t('StartSelling:above.sections.1.11')} */}
                    <Translation
                      from={'en'}
                      text={'Fixed Fee'}
                      input={<Translate>Fixed Fee</Translate>}
                    />
                  </span>
                </div>
                <div className={classes.FooterItem}>
                  <h4>
                    {/* {t('StartSelling:above.sections.2.20')} */}
                    <Translation
                      from={'en'}
                      text={'Transaction Range'}
                      input={<Translate>Transaction Range</Translate>}
                    />
                  </h4>
                  <span>
                    <Translation
                      from={'en'}
                      text={'$5 - $25’000 US'}
                      input={<Translate>$5 - $25’000 US</Translate>}
                    />
                  </span>
                </div>
              </div>
            </Slide>
          </div>
          <div className={classes.JoinCommunity}>
            <h3>
              {/* {t('StartSelling:middle.0')}{' '} */}
              <Translation
                from={'en'}
                text={'Join Our Growing Community'}
                input={<Translate>Join Our Growing Community</Translate>}
              />
            </h3>
            <p>
              {/* {t('StartSelling:middle.1')}{' '} */}
              <Translation
                from={'en'}
                text={
                  'Whether you are an established media outlet, a professional or new influencer, an event, a public figure, a company, even a city, YOUR VISIBILITY MATTERS. Your audience, your price! Choose the subscription that suits you best today and start generating profits from your popularity. * Be the one to offer the perfect ad *'
                }
                input={
                  <Translate>
                    Whether you are an established media outlet, a professional
                    or new influencer, an event, a public figure, a company,
                    even a city, YOUR VISIBILITY MATTERS. Your audience, your
                    price! Choose the subscription that suits you best today and
                    start generating profits from your popularity. * Be the one
                    to offer the perfect ad *
                  </Translate>
                }
              />
            </p>
            <div className={classes.CommunityImage}>
              {JoinCommunity.map((community, index) => (
                <Bounce delay={200 * (index + 1)}>
                  <div className={[classes.zoomIn, classes.Frame].join(' ')}>
                    <img src={community.image} alt="HeaderImage" />
                    <h4>{community.text}</h4>
                  </div>
                </Bounce>
              ))}
            </div>

            <div className={classes.HowItWorks}>
              <h3>
                {/* {t('StartSelling:below.name')}{' '} */}
                <Translation
                  from={'en'}
                  text={'How It Works'}
                  input={<Translate>How It Works</Translate>}
                />
              </h3>
              <div className={classes.HowItWorksItems}>
                <Fade delay={0}>
                  <div className={classes.HowItWorksItem}>
                    <i class="far fa-file-alt"></i>
                    <div className={classes.Divider}></div>
                    <h5>
                      {/* {t('StartSelling:below.options.0.00')}{' '} */}
                      <Translation
                        from={'en'}
                        text={'1. Create An Advertise'}
                        input={<Translate>1. Create An Advertise</Translate>}
                      />
                    </h5>
                    <div className={classes.Divider}></div>
                    <p>
                      {' '}
                      {/* {t('StartSelling:below.options.0.01')} */}
                      <Translation
                        from={'en'}
                        text={
                          'How you will promote your buyer. Country and city, media, Domain, visibility, Price'
                        }
                        input={
                          <Translate>
                            How you will promote your buyer. Country and city,
                            media, Domain, visibility, Price
                          </Translate>
                        }
                      />
                    </p>
                  </div>
                </Fade>
                <Fade delay={500}>
                  {' '}
                  <div className={classes.HowItWorksItem}>
                    <i class="fas fa-shipping-fast"></i>
                    <div className={classes.Divider}></div>
                    <h5>
                      {/* {t('StartSelling:below.options.1.10')}{' '} */}
                      <Translation
                        from={'en'}
                        text={'2. Deliver Great Work'}
                        input={<Translate>2. Deliver Great Work</Translate>}
                      />
                    </h5>
                    <div className={classes.Divider}></div>
                    <p>
                      {/* {' '}
                      {t('StartSelling:below.options.1.11')}{' '} */}
                      <Translation
                        from={'en'}
                        text={
                          'Share your services, receive offers, chat, make a deal and deliver the perfect ad.'
                        }
                        input={
                          <Translate>
                            Share your services, receive offers, chat, make a
                            deal and deliver the perfect ad.
                          </Translate>
                        }
                      />
                    </p>
                  </div>
                </Fade>
                <Fade delay={1000}>
                  <div className={classes.HowItWorksItem}>
                    <i class="fas fa-hand-holding-usd"></i>
                    <div className={classes.Divider}></div>
                    <h5>
                      {/* {t('StartSelling:below.options.2.20')}{' '} */}
                      <Translation
                        from={'en'}
                        text={'3. Get Paid'}
                        input={<Translate>3. Get Paid</Translate>}
                      />
                    </h5>
                    <div className={classes.Divider}></div>
                    <p>
                      {/* {' '}
                      {t('StartSelling:below.options.2.21')}{' '} */}
                      <Translation
                        from={'en'}
                        text={
                          'From $5 to complete your transaction, up to 12 payments of 25k per transaction.'
                        }
                        input={
                          <Translate>
                            From $5 to complete your transaction, up to 12
                            payments of 25k per transaction.
                          </Translate>
                        }
                      />
                    </p>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
          <div className={classes.Stories}>
            <h3>
              {/* {t('StartSelling:below.stories.name')} */}
              <Translation
                from={'en'}
                text={'Sellers'}
                input={<Translate>Sellers</Translate>}
              />
            </h3>
            <HorizontalSlider items={list} />
          </div>
          <div className={classes.LetsStart}>
            <h5>
              {/* {t('StartSelling:below.others.0')} */}
              <Translation
                from={'en'}
                text={'Sign up and create your first Advertise today'}
                input={
                  <Translate>
                    Sign up and create your first Advertise today
                  </Translate>
                }
              />
            </h5>
            <Button
              buttonType="Common"
              clicked={() => this.props.history.push('/subscription-packages')}>
              {/* {t('StartSelling:below.others.1')}{' '} */}
              <Translation
                from={'en'}
                text={'Get Started'}
                input={<Translate>Get Started</Translate>}
              />
            </Button>
          </div>
        </div>
        <Translator />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    screenWidth: state.responsiveness.screenWidth,
    user: state.userAuth.user,
    isAuthenticated: state.userAuth.isAuthenticated,
    token: state.userAuth.token,
  }
}

export default connect(
  mapStateToProps,
  null,
)(withTranslation(['translation', 'StartSelling'])(StartSelling))
