// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Search_SearchResults__1UzRA {\n    max-height: 220px;\n    overflow-y: auto;\n    scroll-behavior: smooth;\n    width: 97%;\n    border-radius: 5px;\n    border: 1px solid rgb(235, 235, 235);\n    box-shadow: 0px 2px 2px -3px rgba(48, 46, 46, 0.2), 0px 2px 2px 0px rgba(44, 42, 42, 0.14), 0px 2px 1px 0px rgba(49, 47, 47, 0.12);\n    z-index: 10;\n    background: white;\n    position: absolute;\n    color: rgb(78, 78, 150);\n    padding: 15px 15px;\n    margin-top: 2px;\n}\n\n.Search_SearchResults__1UzRA li {\n    margin: 5px 0px;\n    padding: 10px 10px;\n    color: rgb(60, 60, 60);\n}\n\n.Search_SearchResults__1UzRA li:hover {\n    background: whitesmoke;\n    border-radius: 3px;\n    color: inherit;\n    cursor: pointer;\n}", ""]);
// Exports
exports.locals = {
	"SearchResults": "Search_SearchResults__1UzRA"
};
module.exports = exports;
