export const headersNormal = {
    headers: {
        'Content-Type': 'application/json'
    }
};

export const headersMultipart = {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
}

export const tokenHeadersNormal = (getState) => {
    const token = getState().userAuth.token;
    if (token) {
        headersNormal.headers['x-auth-header'] = token;
    }
    return headersNormal;
};

export const tokenHeaders = (token) => {
    if (token) {
        headersNormal.headers['x-auth-header'] = token;
    }
    return headersNormal;
};

export const tokenHeadersMultipart = (getState) => {
    const token = getState().userAuth.token;
    if (token) {
        headersMultipart.headers['x-auth-header'] = token;
    }
    return headersMultipart;
};

export const authHeadersNormal = (token) => {
    if (token) {
        headersNormal.headers['x-auth-header'] = token;
    }
    return headersNormal;
}