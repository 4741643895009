// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Translator_Translator__3NWkA{\n    position: fixed;\n    bottom: 10px;\n    z-index: 100;\n    right: 10px;\n    background-color: #2086da;\n    color: white !important;\n    padding: 10px;\n    border-radius: 5px;\n    box-shadow: 0px 3px 3px -3px rgba(48, 46, 46, 0.2), 0px 3px 3px 0px rgba(44, 42, 42, 0.14), 0px 3px 3px 0px rgba(49, 47, 47, 0.12);\n}\n\n.Translator_LanguageButton__xwdHM{\n    position: fixed;\n    bottom: 10px;\n    z-index: 100;\n    right: 15px;\n    background-color: #2086da;\n    color: white;\n    padding: 14px 12px;\n    border-radius: 25px;\n    box-shadow: 0px 3px 3px -3px rgba(48, 46, 46, 0.2), 0px 3px 3px 0px rgba(44, 42, 42, 0.14), 0px 3px 3px 0px rgba(49, 47, 47, 0.12);\n}", ""]);
// Exports
exports.locals = {
	"Translator": "Translator_Translator__3NWkA",
	"LanguageButton": "Translator_LanguageButton__xwdHM"
};
module.exports = exports;
