import React, {Component} from 'react'
import TaskList from '../Chat'

class Inbox extends Component {
  render() {
    
    return (
      <div style={{marginTop: '83px'}}>
        <div className="m-2">
          <TaskList />
        </div>
      </div>
    )
  }
}

export default Inbox
