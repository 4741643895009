import React from "react";
import { Line } from '../../../../components/UI/Spacing';
import Header from '../CustomOrder/Header';
import '../CustomOrder/CustomOrder.css';

export const OrderInfo = ({ closeModal, scopes, title, file, description, amount, deliveryTime }) => {
    return <React.Fragment>
        <Header closeModal={closeModal} title='Custom Offer Info'></Header>
        <div className='p-sm-4 p-3 CustomOrderMain'>
            <div className='OrderFormHeader my-1'>
                <div>
                    <img src={file} alt='Ad img' style={{ height: '180px' }}></img>
                </div>
                <h6 className="py-2">{title}</h6>
                <div className="py-2 text-secondary">
                    <p> {description}</p>
                </div>
            </div>
            <div className='rounded border bg-light my-3 p-3'>
                <div className='row align-items-center'>
                    <label className='text-secondary col-9'>Total Offer Amount</label>
                    <div className="form-group col-3">
                        ${amount}
                    </div>
                </div>
                <Line height='1' width='100' orientation='h' space='2' />
                <div className='row align-items-center'>
                    <label className='text-secondary col-9'>Delivery Within</label>
                    <div className="form-group col-3">
                        {deliveryTime} days
                    </div>
                </div>
            </div>
            <div className='my-2'>
                <div>
                    <label className='text-dark'>Offer Scopes</label>
                </div>
            </div>
            {scopes.map((scope, index) => {
                return <div className='d-flex p-2' key={index}>
                    <div className='ScopeIndex col-1'>{index + 1}</div>
                    <div className='mx-2 col-11 text-muted mt-1'>
                        <label>{scope}</label>
                    </div>
                </div>
            })}
        </div>
    </React.Fragment>
}
