import React from 'react';
import classes from './Button.module.css';

const SmButton = (props) =>
    <button
        disabled={props.disabled}
        className={[classes.Button, classes.Ripple, classes[props.buttonType]].join(' ')}
        onClick={props.clicked}
        style={{ height: '35px', borderRadius:'6px' }}>
        {props.children}
    </button>

export default SmButton;