// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Earnings_earningParent__3wjts {\n  background-color: white;\n  padding: 10px 10px;\n  margin: 80px 5px;\n  border-radius: 10px;\n  box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;\n}\n\n.Earnings_ListImage__2MGLR img {\n  height: 80px;\n  width: 120px;\n}\n\n.Earnings_Balance__1UehS {\n  margin: 5px 0px;\n  padding: 15px;\n  border-radius: 7px;\n  width: 100%;\n}\n\n.Earnings_Amount__3NpLY {\n  color: #06c445;\n  font-size: 30px;\n  font-weight: bold;\n  height: 25px;\n  width: 25px;\n  background: white;\n}\n\n@media (min-width: 950px) {\n  .Earnings_earningParent__3wjts {\n    padding-top: 30px;\n    padding-bottom: 30px;\n    margin: 100px auto;\n    max-width: 950px;\n  }\n}", ""]);
// Exports
exports.locals = {
	"earningParent": "Earnings_earningParent__3wjts",
	"ListImage": "Earnings_ListImage__2MGLR",
	"Balance": "Earnings_Balance__1UehS",
	"Amount": "Earnings_Amount__3NpLY"
};
module.exports = exports;
