// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Customers_Dashboard__7fF6E {\n    margin-top: 90px;\n    margin-bottom: 30%;\n    text-align: center;\n    justify-items: center;\n}", ""]);
// Exports
exports.locals = {
	"Dashboard": "Customers_Dashboard__7fF6E"
};
module.exports = exports;
