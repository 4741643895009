import React from 'react';
import './CustomOrder.css';
import { Line } from '../../../../components/UI/Spacing';
import Header from './Header';
import CustomOrderForm from './CustomOrderForm';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions';


const CustomOrder = (props) => {
    const [showOrderForm, setShowOrderForm] = React.useState(false);
    const [adData, setAdData] = React.useState(null);

    React.useEffect(() => {
        if (props.user) {
            props.getUserAdvertisesInfo();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return <React.Fragment>
        <Header closeModal={props.closeModal} title='Create a Custom Offer' />
        {showOrderForm ? <CustomOrderForm
            adData={adData}
            closeModal={props.closeModal}
            addToConversation={props.addToConversation}
            goBack={_ => { setShowOrderForm(false); setAdData(null) }} /> :
            <div className='p-3 CustomOrderMain'>
                {props.advertisesData ?
                    props.advertisesData.map((data, index) => (
                        <React.Fragment key={index}>
                            <div className='_CustomOrder d-flex align-items-center' onClick={_ => { setShowOrderForm(true); setAdData(data) }}>
                                <div>
                                    <img src={data.files[0]} alt='Ad Img'></img>
                                </div>
                                <div className='mx-2 fw-bold'>
                                    <label>{data.title}</label>
                                </div>
                            </div>
                            {index < props.advertisesData.length - 1 && <Line height='1' width='100' orientation='h' />}
                        </React.Fragment>
                    ))
                    : <p>No ads found...!</p>}
            </div>}
    </React.Fragment >
}


const mapStateToProps = (state) => {
    return {
        currentUser: state.chat.currentUser,
        user: state.userAuth.user,
        advertisesData: state.dashboardAdvertise.userCreatedAdvertises,
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        getUserAdvertisesInfo: () => dispatch(actions.getUserAdvertisesInfo())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomOrder);
