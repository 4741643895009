import React from 'react'
import classes from './Advertises.module.css'
import { Link } from 'react-router-dom'
import {
  ratted,
  notRatted,
} from './SingleAdvertise/AllComponents/RatingNotRating'
import { Translate } from 'react-auto-translate'
import Translation from '../../../shared/Translate';
import AD_LOGO from '../../../assets/images/New_Logo.jpg';


export const AdvertiseItem = (props) => {
  const { shortId, user, types, title, rating, files, startingAtPrice } =
    props.advertise
  // const title = advertise.title.split(' ').join('-');
  // const newTo = {
  //     pathname: `/advertise/description/${advertise._id}`,
  //     param: advertise._id
  // };
  // shortId
  return <div className={classes.BoxRowElement} title={title}>
    <div className={classes.CardHeader}>
      <div className={classes.CardBackgroundImg}>
        <img src={files[0] ? files[0] : AD_LOGO} alt="advertiseImg" />
      </div>
    </div>
    <div className={classes.ProfileInfo}>
      <div>
        <img src={user && user.profile_image} alt="userImg" />
      </div>
      <div className={classes.ProvidedBy}>
        <Link to={`/profile/seller/${user &&  user.username }`} target="_blank">
          <p>{user && user.full_name}</p>
        </Link>
        {/* <span>Top rated Seller</span> */}
      </div>
    </div>
    <Link to={`/adInfo?ad_id=${ shortId }`}>
      <div className={classes.BoxRowElementItems}>
        <p>
          <Translation
            from={
              types[0] === 'Internet' || types[0] === 'Sports' ? 'fr' : 'en'
            }
            text={types[0]}
            input={<Translate>{types[0]}</Translate>}
          />
        </p>
        <span className="fw-lighter">
          <Translation
            text={title.trim().substr(0, 50)}
            input={<Translate>{title.trim().substr(0, 50)}</Translate>}
          />
          ...
        </span>
      </div>
    </Link>

    <div className={classes.PriceAndRating}>
      {' '}
      {props.children ? (
        props.children
      ) : (
        <React.Fragment>
          <span>
            <Translation
              from={'en'}
              text="Starting Price"
              input={<Translate>Starting Price</Translate>}
            />
            : <span className={classes.Price}>${startingAtPrice}</span>
          </span>
          <span className="my-1">
            <Translation
              from={'en'}
              text="Rating"
              input={<Translate>Rating</Translate>}
            />
            : {ratted(parseInt(rating))}
            {notRatted(parseInt(rating))}
          </span>
        </React.Fragment>
      )}
    </div>
  </div>
}
