// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".NavigationItem_NavigationItem__I737g {\n    font-weight: bolder;\n    margin-bottom: 5px;\n}\n\n.NavigationItem_NavigationItem__I737g a {\n    color: rgb(50, 54, 54);\n    text-decoration: none;\n    display: block;\n    transition: .5s;\n    padding: 5px 0px;\n    height: 35px;\n    border-left: 3px solid white;\n}\n\n\n.NavigationItem_NavigationItem__I737g a:hover, .NavigationItem_NavigationItem__I737g a:active, .NavigationItem_NavigationItem__I737g a.NavigationItem_active__19Bx1 {\n    background-color: #edf1f8;\n    color: blueviolet;\n    border-left: 3px solid rgb(107, 163, 228);\n}\n\n.NavigationItem_NavigationItem__I737g img {\n    border-radius: 50%;\n    height: 100px;\n    width: 100px;\n}", ""]);
// Exports
exports.locals = {
	"NavigationItem": "NavigationItem_NavigationItem__I737g",
	"active": "NavigationItem_active__19Bx1"
};
module.exports = exports;
