import React, {Component} from 'react'
import {Route, Redirect, Switch, withRouter} from 'react-router-dom'
import * as actions from './store/actions'
import {connect} from 'react-redux'
import SimpleSnackbar from './components/Snackbar/Snackbar'
import socket from './config/socket'
import Geocode from 'react-geocode'

/** Layouts **/
import NormalUserLayoutRoute from './hoc/Layout/NormalUserLayout'
import DashboardLayoutRoute from './hoc/Layout/ProfileDashboardLayout'
import AppStartPageRoute from './hoc/Layout/AppStartLayout'

// import { NoMatch } from './hoc/Fragment/NoMatch'

/** App Start Page*/
import AppStartPage from './containers/AppStartPage'

/** Containers for  NormalUserLayout*/
import {
  Login,
  SignUp,
  ForgotPassword,
  FPEmailSend,
} from './containers/NormalUser/Auth'
import Logout from './containers/NormalUser/Auth/Logout/Logout'
import Packages from './containers/NormalUser/Packages/Packages'
import Contact from './containers/NormalUser/Others/Contact/Contact'
import About from './containers/NormalUser/Others/About/About'
import StartSelling from './containers/NormalUser/StartSelling/StartSelling'
import TermsConditions from './containers/NormalUser/Others/TermsConditionsPrivacyPolicy/TermsConditions'
import PrivacyPolicy from './containers/NormalUser/Others/TermsConditionsPrivacyPolicy/PrivacyPolicy'
import {NotFoundPage} from './containers/404Page'
import FAQPage from './containers/NormalUser/Others/FAQPage'

import {
  SingleTender,
  SellerBids,
  BuyerTenders,
  PostTender,
  FindTenders,
} from './containers/NormalUser/Tender'

import {
  PartnerRequests,
  SinglePartnerRequest,
  PostPartnerRequest,
  UserPartnerRequest,
  GetPaid,
} from './containers/NormalUser/Partner'

import {Location} from './shared/Location'

/** Containers for  ProfileDashboardLayout*/
import {
  SellerDashboard,
  BuyerDashboard,
  Earnings,
  // Inbox,
  // MyAdds,
  CreateAdd,
  ProfileInfo,
  Customers,
  PublicProfile,
  EditProfile,
  AllOrders,
  ManageSpecificOrder,
  VerifyEmail,
  MyAllAds,
} from './containers/ProfileDashBoard'

import {
  Account,
  Security,
  Balance,
  Notifications,
  BillingInformation,
} from './containers/ProfileDashBoard/Settings/Components'

import asyncComponent from './hoc/AsyncComponent/asyncComponent'
// import AppContextProvider from './containers/ProfileDashBoard/Chat/coremat/AppContextProvider';
import Chatroom from './containers/ProfileDashBoard/_Chat'

/**  lazy imports for  NormalUserLayout*/
const asyncAdvertise = asyncComponent(() => {
  return import('./containers/NormalUser/Advertises/Advertises')
})

const asyncSingleAdvertise = asyncComponent(() => {
  return import(
    './containers/NormalUser/Advertises/SingleAdvertise/AdvertiseItems'
  )
})

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_API_KEY)
Geocode.enableDebug()

// const Inboxes = () => {
//   let { path } = useRouteMatch();
//   return (
//     <AppContextProvider>
//       <Switch>
//         <DashboardLayoutRoute exact path='/inbox' component={Inbox} />
//         {/* <DashboardLayoutRoute path={`${path}/:topicId`} component={Chatroom} /> */}
//         {/* <DashboardLayoutRoute path={`${path}/chatroom`} component={Chatroom} /> */}
//       </Switch>
//     </AppContextProvider>
//   )
// }

class App extends Component {
  state = {isDisconnected: false, isAuthenticated: false, user: {}}

  componentWillUnmount() {
    window.removeEventListener('online', this.handleConnectionChange)
    window.removeEventListener('offline', this.handleConnectionChange)
    window.removeEventListener('resize', this.updateWindowDimensions)

    socket.off('connect_error')
    // localStorage.removeItem('sessionID')
    localStorage.removeItem('tabStatus')
  }

  handleLocationError = (error) => {
    localStorage.setItem('translateTo', 'en')
    localStorage.setItem('i18nextLng', 'en')
    window.location.reload()

    switch (error.code) {
      case error.PERMISSION_DENIED:
        alert(
          "Either your device's location is off or you decided not to allow us to access your location.",
        )
        break
      case error.POSITION_UNAVAILABLE:
        alert('Location information is unavailable.')
        break
      case error.TIMEOUT:
        alert('The request to get user location timed out.')
        break
      case error.UNKNOWN_ERROR:
        alert('An unknown error occurred.')
        break
      default:
        alert('An unknown error')
    }
  }

  getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          localStorage.setItem('hasPermission', 'ok')
          Geocode.fromLatLng(
            position.coords.latitude.toString(),
            position.coords.longitude.toString(),
          ).then(
            (response) => {
              const addressArray = response.results[0].address_components

              if (
                !localStorage.getItem('translateTo') ||
                !localStorage.getItem('i18nextLng')
              ) {
                if (
                  Location.getCountry(addressArray).includes('France') ||
                  Location.getState(addressArray).includes('Quebec') ||
                  Location.getState(addressArray).includes('Québec') ||
                  Location.getCity(addressArray).includes('Quebec') ||
                  Location.getCity(addressArray).includes('Québec')
                ) {
                  localStorage.setItem('translateTo', 'fr')
                  localStorage.setItem('i18nextLng', 'fr')
                  window.location.reload()
                } else if (
                  Location.getCountry(addressArray).includes('Bangladesh') ||
                  Location.getCountry(addressArray) === 'BD'
                ) {
                  localStorage.setItem('translateTo', 'bn')
                  localStorage.setItem('i18nextLng', 'en')
                  window.location.reload()
                } else {
                  localStorage.setItem('translateTo', 'en')
                  localStorage.setItem('i18nextLng', 'en')
                  window.location.reload()
                }
              }
            },
            (error) => alert(error),
          )
        },
        this.handleLocationError,
        {enableHighAccuracy: true, timeout: 5000, maximumAge: 0},
      )
    } else alert('Geolocation is not supported by this browser!')
  }

  handleConnectionChange = () => {
    const condition = window.navigator.onLine ? 'online' : 'offline'
    if (condition === 'online') {
      const webPing = setInterval(() => {
        fetch('//google.com', {
          mode: 'no-cors',
        })
          .then(() => {
            this.setState({isDisconnected: false}, () => clearInterval(webPing))
          })
          .catch(() => this.setState({isDisconnected: true}))
      }, 1000)
      return
    }
    return this.setState({isDisconnected: true})
  }

  componentDidMount() {
    this.handleConnectionChange()
    if (!localStorage.getItem('hasPermission')) {
      if (
        !localStorage.getItem('translateTo') ||
        !localStorage.getItem('i18nextLng')
      )
        this.getLocation()
    }

    window.addEventListener('online', this.handleConnectionChange)
    window.addEventListener('offline', this.handleConnectionChange)
    window.scroll(0.0, 0.0)
    window.addEventListener('resize', this.updateWindowDimensions)

    if (!this.props.isAuthenticated)
      this.props.onTryAutoSignup(this.props.history)
    localStorage.removeItem('tabStatus')
  }

  updateWindowDimensions = () =>
    this.props.onChangeScreenSize(window.innerWidth)

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isAuthenticated !== prevProps.isAuthenticated) {
      this.setState({
        isAuthenticated: this.props.isAuthenticated,
        user: this.props.user,
      })
    }
  }

  render() {
    let routes = (
      <Switch>
        <NormalUserLayoutRoute path="/login" component={Login} />
        <NormalUserLayoutRoute path="/join" component={SignUp} />
        <NormalUserLayoutRoute path="/logout" component={Logout} />
        <NormalUserLayoutRoute
          path="/forgot-password"
          component={ForgotPassword}
        />
        <NormalUserLayoutRoute path="/reset-password" component={FPEmailSend} />
        <NormalUserLayoutRoute
          path="/find/advertises/:searchWith"
          component={asyncAdvertise}
        />
        <NormalUserLayoutRoute path="/find-tenders" component={FindTenders} />
        <NormalUserLayoutRoute
          path="/find-partner"
          component={PartnerRequests}
        />

        <NormalUserLayoutRoute
          path="/new-partner-request"
          component={PostPartnerRequest}
        />
        <NormalUserLayoutRoute path="/launch-tender" component={PostTender} />
        <NormalUserLayoutRoute
          path="/tender-description"
          component={SingleTender}
        />
        <NormalUserLayoutRoute
          path="/partner-request-description"
          component={SinglePartnerRequest}
        />

        <NormalUserLayoutRoute
          path="/adInfo"
          component={asyncSingleAdvertise}
        />
        <NormalUserLayoutRoute
          exact
          path="/find-audience"
          component={asyncAdvertise}
        />
        <NormalUserLayoutRoute
          path="/subscription-packages"
          component={Packages}
        />

        <NormalUserLayoutRoute path="/contact-us" component={Contact} />
        <NormalUserLayoutRoute path="/about-us" component={About} />
        <NormalUserLayoutRoute path="/start-selling" component={StartSelling} />
        <NormalUserLayoutRoute
          path="/terms-and-conditions"
          component={TermsConditions}
        />
        <NormalUserLayoutRoute
          path="/privacy-policy"
          component={PrivacyPolicy}
        />

        <NormalUserLayoutRoute path="/faq" component={FAQPage} />

        <NormalUserLayoutRoute
          path="/profile/:role/:username"
          component={PublicProfile}
        />
        <AppStartPageRoute path="/" exact component={AppStartPage} />
        <Route render={(_) => <NotFoundPage />} />
      </Switch>
    )

    if (this.state.isAuthenticated) {
      routes = (
        <Switch>
          <Route exact path="/join">
            <Redirect to="/profile" />
          </Route>
          <Route exact path="/login">
            <Redirect to="/profile" />
          </Route>

          <NormalUserLayoutRoute
            path="/profile/:role/:username"
            component={PublicProfile}
          />
          <DashboardLayoutRoute path="/profile" component={ProfileInfo} />
          <DashboardLayoutRoute path="/edit-profile" component={EditProfile} />
          <NormalUserLayoutRoute path="/logout" component={Logout} />
          <DashboardLayoutRoute
            path="/account/verify-email"
            component={VerifyEmail}
          />
          <DashboardLayoutRoute
            exact={true}
            path="/my-partner-request"
            component={UserPartnerRequest}
          />
          <NormalUserLayoutRoute
            exact
            path="/find-audience"
            component={asyncAdvertise}
          />
          <NormalUserLayoutRoute
            path="/find/advertises/:searchWith"
            component={asyncAdvertise}
          />
          <NormalUserLayoutRoute
            path="/adInfo"
            component={asyncSingleAdvertise}
          />
          <NormalUserLayoutRoute
            path="/tender-description"
            component={SingleTender}
          />
          <NormalUserLayoutRoute
            path="/edit-partner-request"
            component={PostPartnerRequest}
          />
          <NormalUserLayoutRoute
            path="/new-partner-request"
            component={PostPartnerRequest}
          />
          <NormalUserLayoutRoute
            path="/subscription-packages"
            component={Packages}
          />

          <NormalUserLayoutRoute path="/contact-us" component={Contact} />

          <NormalUserLayoutRoute path="/about-us" component={About} />

          <NormalUserLayoutRoute
            path="/start-selling"
            component={StartSelling}
          />

          <NormalUserLayoutRoute
            path="/terms-and-conditions"
            component={TermsConditions}
          />

          <NormalUserLayoutRoute
            path="/privacy-policy"
            component={PrivacyPolicy}
          />

          <NormalUserLayoutRoute path="/faq" component={FAQPage} />

          <AppStartPageRoute path="/" exact component={AppStartPage} />
          {!this.state.user.isProfileOk && <Redirect to="/profile" />}

          <DashboardLayoutRoute path="/customers" component={Customers} />
          <DashboardLayoutRoute
            path="/settings/:username/account"
            component={Account}
          />

          <DashboardLayoutRoute
            path="/settings/:username/security"
            component={Security}
          />

          <DashboardLayoutRoute
            path="/settings/:username/notifications"
            component={Notifications}
          />

          <DashboardLayoutRoute
            path="/settings/:username/billing-info"
            component={BillingInformation}
          />

          <DashboardLayoutRoute
            path="/settings/:username/payment-methods"
            component={Balance}
          />

          <DashboardLayoutRoute path="/inbox" component={Chatroom} />

          {/* <Inboxes /> */}

          <DashboardLayoutRoute path="/get-paid" component={GetPaid} />

          <DashboardLayoutRoute
            path="/manage-order/:orderId/:advertiseId/:tabStatus"
            exact
            component={ManageSpecificOrder}
          />

          {this.state.user.role === 'buyer' && (
            <React.Fragment>
              <DashboardLayoutRoute
                path="/buyer/dashboard"
                component={BuyerDashboard}
              />
              <NormalUserLayoutRoute
                path="/launch-tender"
                component={PostTender}
              />

              <DashboardLayoutRoute
                path="/my-all-tenders"
                component={BuyerTenders}
              />

              <DashboardLayoutRoute
                path="/buyer/all-orders"
                component={AllOrders}
              />

              <NormalUserLayoutRoute
                path="/edit-tender"
                component={PostTender}
              />
            </React.Fragment>
          )}

          {this.state.user.role === 'seller' && (
            <React.Fragment>
              <DashboardLayoutRoute
                path="/seller/dashboard"
                component={SellerDashboard}
              />

              <DashboardLayoutRoute
                path="/seller/all-orders"
                component={AllOrders}
              />
              <DashboardLayoutRoute
                path="/seller/earnings"
                component={Earnings}
              />

              <NormalUserLayoutRoute
                path="/find-tenders"
                component={FindTenders}
              />

              <DashboardLayoutRoute
                path="/create-new-ad"
                component={CreateAdd}
              />

              <DashboardLayoutRoute
                path="/edit-advertise"
                component={CreateAdd}
              />

              <DashboardLayoutRoute
                path="/my-all-bids"
                component={SellerBids}
              />

              <DashboardLayoutRoute
                path="/all-audiences"
                component={MyAllAds}
              />

              <NormalUserLayoutRoute
                path="/find-partner"
                component={PartnerRequests}
              />

              <NormalUserLayoutRoute
                path="/partner-request-description"
                component={SinglePartnerRequest}
              />
            </React.Fragment>
          )}
          {/* <Route component={NoMatch} /> */}
          {/* <Route render={_ => <NotFoundPage />} /> */}
        </Switch>
      )
    }
    return (
      <div>
        <React.Fragment>
          {this.state.isDisconnected ? (
            <SimpleSnackbar
              open={true}
              message="You are offline now, go to online."
              type="warning"
              autoHide="autoHide"
            />
          ) : null}
          {routes}
        </React.Fragment>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.userAuth.isAuthenticated,
    user: state.userAuth.user,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onTryAutoSignup: (history) => dispatch(actions.authCheckState(history)),
    onChangeScreenSize: (screenWidth) =>
      dispatch(actions.onChangeScreenSize(screenWidth)),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
