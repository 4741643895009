import moment from 'moment'
import * as actionTypes from '../actions/ActionTypes'

const INIT_STATE = {
  users: [],
  contacts: [],
  conversation: [],
  currentUser: null,
  filterData: {
    search: '',
  },
  unreadmessages: 0,
}

export const ChatReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionTypes.SET_CHAT_USERS: {
      let data = []
      let usersD = action.payload.users;
      for (let i = 0; i < usersD.length; i++) {
        let obj = {}
        obj['id'] = usersD[i]._id;
        obj['channelId'] = usersD[i]._id;
        obj['name'] = usersD[i].user ? usersD[i].user.full_name : '';
        obj['profile_pic'] = usersD[i].user
          ? usersD[i].user.profile_image
          : 'https://via.placeholder.com/150x150'
        
        obj['status'] = 'offline';
        obj['mood'] = usersD[i].createdAt;
        obj['lastMessage'] =
          usersD[i].message === 'System' ? 'Tap to chat' : usersD[i].message;
        obj['readMessage'] = usersD[i].read;
        obj['files'] = usersD[i].files;
        obj['sender'] = usersD[i].sender;
        obj['receiver'] = usersD[i].receiver;
        obj['email'] = usersD[i].user ? usersD[i].user.email : ''
        data.push(obj)
      }
      return {...state, users: data}
    }
    case actionTypes.SET_CURRENT_USER: {
      return {...state, currentUser: action.payload}
    }
      
    case actionTypes.SET_CONVERSATION_DATA: {
      let dd = action.payload.messages;
      let user = action.payload.user;
      let conversationData = [];

      dd.map((single) => {
        if (single.message !== 'System') {
          let obj = {}
          obj['message'] = single.message;
          obj['sentAt'] = single.createdAt;
          obj['files'] = single.files;

          if (single.fromEmail === user.email) {
            obj['type'] = 'sent';
            conversationData.push(obj);
            return obj;
          } else {
            obj['type'] = 'received';
            conversationData.push(obj);
            return obj;
          }
        } else {
          return null;
        }
      })
      return {
        ...state,
        conversation: conversationData,
      }
    }
    case actionTypes.SEND_NEW_CHAT_MESSAGE: {
      return {
        ...state,
        conversation: state.conversation.concat({
          id: new Date().getTime(),
          user: state.currentUser,
          message: action.payload,
          type: 'sent',
          sentAt: moment(),
        }),
      }
    }
    case actionTypes.UNREAD_MESSAGES: {
      let unread = action.payload.unread
      return {
        ...state,
        unreadmessages: unread,
      }
    }
    default:
      return state;
  }
}
