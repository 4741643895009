// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VerifyEmail_VerifyEmail__vX5ya {\n    display: flex;\n    align-items: center;\n    width: 100%;\n    height: 100vh;\n    justify-content: center;\n    margin: auto;\n}\n\n.VerifyEmail_VerifyEmailItem__2NIF6 {\n    border: 1px solid rgb(230, 230, 230);\n    width: 95%;\n    padding: 10px 10px;\n    background: white;\n    border-radius: 5px;\n}\n\n.VerifyEmail_VerifyEmail__vX5ya h6 {\n    border-bottom: 1px solid rgb(230, 230, 230);\n}\n\n@media (min-width: 500px) {\n    .VerifyEmail_VerifyEmailItem__2NIF6 {\n        width: 500px;\n    }\n}", ""]);
// Exports
exports.locals = {
	"VerifyEmail": "VerifyEmail_VerifyEmail__vX5ya",
	"VerifyEmailItem": "VerifyEmail_VerifyEmailItem__2NIF6"
};
module.exports = exports;
