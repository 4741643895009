import React from "react";
import classes from '../ChatHome.module.css';
import "./userProfile.css";
import { flag } from 'country-emoji';
import { getDate } from '../../../../shared/utility';
import { File } from "../Footer/File";

const ChatProfile = (props) => {
    const [open, setOpen] = React.useState(false);
    const [media, openMedia] = React.useState(false);

    const { user: { full_name, username, createdAt, accountType, profile_image }, description, country, language } = props.profileInfo;

    let files = []
    props.conversations.forEach((conversation) => {
        if (conversation.files) {
            files.push(...conversation.files);
        }
    });

    return <div className={classes.ChatProfile} style={props.isPopUp ? { width: '100%' } : {}}>
        <div className="main__userprofile">
            <div className="profile__card user__profile__image" style={props.isPopUp ? { boxShadow: '#ebe7fb 0px 0px 5px' } : {}}>
                <div className="profile__image">
                    <img src={profile_image} alt="Pic" />
                </div>
                <h5 className="mt-2">{full_name}</h5>
                <p>{accountType ? accountType : username}</p>
            </div>
            <div className="profile__card" style={props.isPopUp ? { boxShadow: '#ebe7fb 0px 0px 7px' } : {}}>
                <div className="card__header" onClick={_ => { setOpen(!open); openMedia(false) }}>
                    <h6>Information</h6>
                    <i className={`fa fa-angle-${ open ? 'up' : 'down' }`}></i>
                </div>
                <div className="card__content" style={open ? { display: 'block' } : { display: 'none' }}>
                    {description}
                    <div className="border-top my-1"></div>
                    <div className="d-flex justify-content-between">
                        <small className="text-secondary">Reviews</small>
                        <span> <i className="fas fa-star text-warning"></i> <small className="text-primary fw-bold">5.0</small>(1)</span>
                    </div>
                    <div className="d-flex justify-content-between">
                        <small className="text-secondary">Average Response Time</small>
                        <small className="text-dark fw-bold">1H</small>
                    </div>
                    <div className="d-flex justify-content-between">
                        <small className="text-secondary">From</small>
                        <small className="text-dark fw-bold">{flag(country)} {country}</small>
                    </div>
                    <div className="d-flex justify-content-between">
                        <small className="text-secondary">Member Since</small>
                        <small className="text-dark fw-bold">{getDate(createdAt)}</small>
                    </div>
                    <div className="d-flex justify-content-between">
                        <small className="text-secondary">Language</small>
                        <small className="text-dark fw-bold">{language ? language : 'English'}</small>
                    </div>
                </div>
            </div>
            <div className="profile__card" style={props.isPopUp ? { boxShadow: '#ebe7fb 0px 0px 5px' } : {}}>
                <div className="card__header" onClick={_ => { setOpen(false); openMedia(!media) }}>
                    <h6>Media</h6>
                    <i className={`fa fa-angle-${ media ? 'up' : 'down' }`}></i>
                </div>
                <div className="card__content" style={media ? { display: 'block' } : { display: 'none' }}>
                    <div className="chat__msg_files_list my-2">
                        {files.map((file, index) => {
                            return <File key={index} file={file} local={false} />
                        })}
                        {files.length === 0 && <small className="text-muted">No media found!</small>}
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default ChatProfile;
