import { io } from 'socket.io-client';

// const URL = 'http://localhost:8888'
const URL = 'https://api.umediad.com';
const socket = io(URL, { autoConnect: false });

socket.onAny((event, ...args) => {
  // console.log(event, args)
})

export default socket