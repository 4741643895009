import React from 'react';

export const ratted = (rating) => {
    const ratings = [];
    for (let i = 0; i < parseInt(rating); i++) {
        ratings.push(<i className="fas fa-star"></i>);
    }
    return ratings;
}

export const notRatted = (rating) => {
    const ratings = [];
    for (let i = 0; i < 5 - parseInt(rating); i++) {
        ratings.push(<i className="far fa-star"></i>);
    }
    return ratings;
}
