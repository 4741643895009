// @ts-nocheck
import React from 'react'
import i18next from 'i18next'
import { withTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { getAllUnreadMessages } from '../../../../store/actions/ActionsFiles/Chat'
import classes from './NavigationItems.module.css'
import NavigationItem from './NavigationItem/NavigationItem'
import SwitchUser from '../../SwitchUser'

import {
  MessageOutlined,
  DashboardOutlined,
  HomeOutlined,
  ViewListOutlined,
  LocalActivityOutlined,
  AllOutOutlined,
  MoneyOutlined,
  AccountCircleOutlined,
  AttachMoneyOutlined,
  SupervisedUserCircleOutlined,
} from '@material-ui/icons'

class NavigationItems extends React.Component {
  constructor(props) {
    super(props)
    let lang = localStorage.getItem('i18nextLng')
    this.state = {
      selectedValue: lang,
      showMenu: false,
      showBrowse: false,
      showPartner: false,
    }
    this.showMenu = this.showMenu.bind(this)
    this.closeMenu = this.closeMenu.bind(this)
    this.showBrowse = this.showBrowse.bind(this)
    this.closeBrowse = this.closeBrowse.bind(this)
    this.showPartner = this.showPartner.bind(this)
    this.closePartner = this.closePartner.bind(this)
  }

  componentDidMount() {
    this.props.allUnreadMessages()
  }

  showMenu(event, showMenu) {
    event.preventDefault()
    this.setState({ showMenu }, () => {
      document.addEventListener('click', this.closeMenu)
    })
  }

  closeMenu() {
    this.setState({ showMenu: false }, () => {
      document.removeEventListener('click', this.closeMenu)
    })
  }

  showBrowse(event, showBrowse) {
    event.preventDefault()
    this.setState({ showBrowse }, () => {
      document.addEventListener('click', this.closeBrowse)
    })
  }

  closeBrowse() {
    this.setState({ showBrowse: false }, () => {
      document.removeEventListener('click', this.closeBrowse)
    })
  }

  showPartner(event, showPartner) {
    event.preventDefault()
    this.setState({ showPartner }, () => {
      document.addEventListener('click', this.closePartner)
    })
  }

  closePartner() {
    this.setState({ showPartner: false }, () => {
      document.removeEventListener('click', this.closePartner)
    })
  }

  handleChange = (e) => {
    this.setState({
      selectedValue: e.target.value,
    })
    i18next.changeLanguage(e.target.value)
  }

  render() {
    const { t } = this.props
    return (
      <ul className={classes.NavigationItems}>
        <NavigationItem link="/" exact={true}>
          <div className={classes.DropMenuStyle}>
            <HomeOutlined />
            <span>{t('Dashboard:DashboardNavigation.0')}</span>
          </div>
        </NavigationItem>
        <React.Fragment>
          <div className={classes.dropButton}>
            <div
              className={classes.DropMenuStyle}
              onClick={(e) => this.showMenu(e, true)}>
              <DashboardOutlined />
              <span>
                {/* My Dashboard */}
                {t('translation:appStartNavigation.1')}
                <i className={!this.state.showMenu ? 'fas fa-angle-down' : 'fas fa-angle-up'}></i>
              </span>
            </div>
          </div>
          {this.state.showMenu && (
            <div className={classes.dropdown}>
              <NavigationItem link={`/${ this.props.user.role }/dashboard`}>
                <div className={classes.DropSubMenuStyle}>
                  <AccountCircleOutlined />
                  <span>{t('Dashboard:DashboardNavigation.1')}</span>
                </div>
              </NavigationItem>
              {this.props.user.role === 'seller' ?
                <React.Fragment>
                  <NavigationItem link="/all-audiences">
                    <div className={classes.DropSubMenuStyle}>
                      <AllOutOutlined />
                      <span>
                        {/* My Audiences */}
                        {t('translation:navigations.6')}
                      </span>
                    </div>
                  </NavigationItem>
                  <NavigationItem
                    link={`/my-all-bids`}>
                    <div className={classes.DropSubMenuStyle}>
                      <AttachMoneyOutlined />
                      <span>
                        {/* All Bids */}
                        {t('translation:navigations.7')}
                      </span>
                    </div>
                  </NavigationItem>
                </React.Fragment>
                :
                <NavigationItem
                  link={`/my-all-tenders`}>
                  <div className={classes.DropSubMenuStyle}>
                    <AllOutOutlined />
                    <span>
                      {/* All Tenders */}
                      {t('translation:navigations.8')}
                    </span>
                  </div>
                </NavigationItem>
              }
              <NavigationItem link={`/${ this.props.user.role }/all-orders`}>
                <div className={classes.DropSubMenuStyle}>
                  <ViewListOutlined />
                  <span>{t('Dashboard:DashboardNavigation.4')}</span>
                </div>
              </NavigationItem>
              {this.props.user.role === 'seller' && (
                <NavigationItem link="/seller/earnings">
                  <div className={classes.DropSubMenuStyle}>
                    <MoneyOutlined />
                    <span>{t('translation:earnings')}</span>
                  </div>
                </NavigationItem>
              )}
              <NavigationItem link="/inbox">
                <div className={classes.DropSubMenuStyle}>
                  <MessageOutlined />
                  <span>
                    {t('Dashboard:DashboardNavigation.11')}
                    {this.props.unreadmessages !== 0 && (
                      <span
                        style={{
                          background: 'red',
                          borderRadius: '50%',
                          display: 'inline-block',
                          fontSize: 12,
                          padding: '4px',
                          margin: '1px',
                          color: 'white',
                        }}></span>
                    )}
                  </span>
                </div>
              </NavigationItem>
            </div>
          )}
        </React.Fragment>
        <React.Fragment>
          <div className={classes.dropButton}>
            <div
              className={classes.DropMenuStyle}
              onClick={(e) => this.showPartner(e, true)}>
              <SupervisedUserCircleOutlined />
              <span style={{ fontWeight: "bold" }}>
                {t("translation:partnership")}
                <i className={!this.state.showPartner ? 'fas fa-angle-down' : 'fas fa-angle-up'}></i>
              </span>
            </div>
          </div>
          {this.state.showPartner && (
            <div className={classes.dropdown}>
              <NavigationItem
                exact={true}
                link='/new-partner-request'>
                <div className={classes.TextMenu}>
                  <span>
                    {t("translation:be_partner")}
                  </span>
                </div>
              </NavigationItem>
              {this.props.user.role === 'seller' &&
                <NavigationItem
                  exact={true}
                  link="/find-partner">
                  <div className={classes.TextMenu}>
                    <span>
                      {t("translation:partner_request")}
                    </span>
                  </div>
                </NavigationItem>}
              <NavigationItem
                link="/my-partner-request">
                <div className={classes.TextMenu}>
                  <span>
                    {t("translation:my_partnership")}
                  </span>
                </div>
              </NavigationItem>
              {/* <NavigationItem
                link="/get-paid">
                <div className={classes.TextMenu}>
                  <span><i className='fa fa-angle-right mx-2'></i>Get paid</span>
                </div>
              </NavigationItem> */}
            </div>
          )}
        </React.Fragment>
        <div className={classes.dropButton}>
          <div
            className={classes.DropMenuStyle}
            onClick={(e) => this.showBrowse(e, true)}>
            <LocalActivityOutlined />
            <span>
              {/* Browse Services */}
              {t('translation:navigations.5')}
              <i className={!this.state.showBrowse ? 'fas fa-angle-down' : 'fas fa-angle-up'}></i>
            </span>
          </div>
        </div>
        {this.state.showBrowse && (
          <React.Fragment>
            {this.props.user.role === 'buyer' ? (
              <React.Fragment>
                <NavigationItem exact={true} link="/find-audience">
                  <div className={classes.TextMenu}>
                    <span>
                      {t('translation:navigations.2')}
                    </span>
                  </div>
                </NavigationItem>
                <NavigationItem link="/launch-tender" exact={true}>
                  <div className={classes.TextMenu}>
                    <span>
                      {t('translation:navigations.0')}
                    </span>
                  </div>
                </NavigationItem>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <NavigationItem link="/start-selling">
                  <div className={classes.TextMenu}>
                    <span>
                      {t('translation:navigations.10')}
                    </span>
                  </div>
                </NavigationItem>
                <NavigationItem exact={true} link="/find-tenders">
                  <div className={classes.TextMenu}>
                    <span>
                      {t('translation:navigations.3')}
                    </span>
                  </div>
                </NavigationItem>

              </React.Fragment>
            )}
          </React.Fragment>
        )}
        <div className="pt-3" />
        <SwitchUser user={this.props.user} t={t} token={this.props.token} />
        <div className="pt-3" />
        <div className={classes.Divider}></div>
        <NavigationItem link="/profile">
          <div className={classes.TextMenu}>
            <span>{t('Dashboard:DashboardNavigation.2')}</span>
          </div>
        </NavigationItem>

        <NavigationItem link="/contact-us">
          <div className={classes.TextMenu}>
            <span>{t('Dashboard:DashboardNavigation.14')}</span>
          </div>
        </NavigationItem>
        <NavigationItem link={`/settings/${ this.props.user.username }/security`}>
          <div className={classes.TextMenu}>
            <span>{t('translation:settings')}</span>
          </div>
        </NavigationItem>
        <NavigationItem link="/logout">
          <div className={classes.TextMenu}>
            <span>{t('Dashboard:ProfiledashboardNavigationItems.2')}</span>
          </div>
        </NavigationItem>

        <div className="ms-2">
          <select
            className="form-select"
            value={this.state.selectedValue}
            onChange={this.handleChange}>
            <option value="en-US">
              {t('translation:appStartNavigation.6')}
            </option>
            <option value="fr">{t('translation:appStartNavigation.7')}</option>
          </select>
        </div>
      </ul>
    )
  }
}

const NavigationItemsFunc = (props) => {
  const history = useHistory()
  return <NavigationItems {...props} history={history} />
}

const mapStateToProps = (state) => {
  return {
    user: state.userAuth.user,
    token: state.userAuth.token,
    unreadmessages: state.chat.unreadmessages,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    allUnreadMessages: () => dispatch(getAllUnreadMessages()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation(['Dashboard', 'translation'])(NavigationItemsFunc))
// export default withTranslation()(NavigationItemsFunc)
