import React from 'react'
import classes from './Partner.module.css'
import {connect} from 'react-redux'
import * as actions from '../../../store/actions'
import {SimpleSpinner} from '../../../components/UI/Spinner/Spinner'
import SingleRequest from './components/SingleRequest'
import SimpleSnackbar from '../../../components/Snackbar/Snackbar'
import {withTranslation} from 'react-i18next'
import {Translate} from 'react-auto-translate'
import Translation from '../../../shared/Translate'
import Translator from '../../../components/Translator'

const SinglePartnerRequest = (props) => {
  const [message, setMessage] = React.useState('')
  const [color, setColor] = React.useState('#0d6efd')

  React.useEffect(() => {
    window.scrollTo(0.0, 0.0)
    if (props.location.search) {
      const search = props.location.search
      const params = new URLSearchParams(search)
      if (params.get('info_id')) {
        props.onGetPartnerRequest(params.get('info_id'))
      }
      if (params.get('c')) {
        setColor(params.get('c'))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  let info = (
    <div className="py-5 w-100 d-flex justify-content-center">
      <SimpleSpinner />
    </div>
  )

  const onInterest = (id) => {
    if (!props.isAuthenticated) {
      setMessage('Please, Log in as seller to be interested on this partner.')
      setTimeout(() => {
        props.history.push('/login')
        setMessage('')
      }, 3000)
    } else {
      if (props.user.role === 'seller') {
        props.onNewInterest(id)
        setTimeout(() => props.history.push('/my-partner-request'), 3000)
      } else {
        setMessage('Please, Log in as seller to be interested on this partner.')
        setTimeout(() => setMessage(''), 3000)
      }
    }
  }

  if (!props.loading && props.requestPartner)
    info = (
      <SingleRequest
        onInterest={onInterest}
        requestPartner={props.requestPartner}
        message={message}
        color={color}
      />
    )

//   const {t} = props

  return (
    <div className={classes.Partner}>
      {props.message && !props.error && (
        <SimpleSnackbar open={true} message={props.message} type="success" />
      )}
      {props.message && props.error && (
        <SimpleSnackbar open="true" message={props.message} type="error" />
      )}
      <div className={classes.PartnerTop} style={{background: color}}>
        <h2 className="text-light text-start">
          {/* {t('partnerInfo')} */}
          <Translation
            from={'en'}
            text={"Partner's Information"}
            input={<Translate>Partner's Information</Translate>}
          />
        </h2>
      </div>
      {info}
      <Translator />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.userAuth.isAuthenticated,
    user: state.userAuth.user,
    requestPartner: state.partner.requestPartner,
    message: state.partner.message,
    error: state.partner.error,
    loading: state.partner.loading,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onGetPartnerRequest: (id) => dispatch(actions.getPartnerRequest(id)),
    onNewInterest: (id) => dispatch(actions.createNewInterest(id)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation(['Partner'])(SinglePartnerRequest))
