import React from "react";
import { Avatar } from "../List/Avatar";
import { getCurrentDate } from '../../../../shared/utility';
import SmButton from '../../../../components/UI/Button/SmButton';
import { OrderInfo } from "./OrderInfo";
import { Modal } from '../../../../components/UI/Modal';
import './chatContent.css';
import { File } from "../Footer/File";

export const ChatItem = (props) => {
  const { sentAt } = props;
  const [showModal, setShowModal] = React.useState(false);
  const closeModal = () => setShowModal(false);

  return <React.Fragment>
    {props.customOrder && <Modal type='normal' show={showModal}
      modalClosed={closeModal}>
      <OrderInfo
        closeModal={closeModal}
        scopes={props.customOrder.scopes}
        title={props.customOrder.adInfo.title}
        file={props.customOrder.adInfo.file}
        description={props.customOrder.adInfo.title}
        amount={props.customOrder.amount}
        deliveryTime={props.customOrder.deliveryDays}
      />
    </Modal>}

    <div
      style={{ animationDelay: `0.8s` }}
      className={`chat__item ${ props.userType ? props.userType : "" }`}
    >
      <div className="chat__item__content">
        <div className="chat__msg">
          {props.msg}
        </div>
        {props.files.length !== 0 &&
          <div className="chat__msg_files my-2">
            {props.files.map((file, index) => {
              return <File key={index} file={file} local={false} />
            })}
          </div>}
        {props.customOrder &&
          <div className="customOrder my-1">
            <img src={props.customOrder.adInfo.file} alt="Photos" />
            <label className="py-2 fw-bolder ">{props.customOrder.adInfo.title}</label>
            <div className="py-2">
              <small>
                <small className="text-primary bg-light fw-bolder badge">Description </small>
                <small> {props.customOrder.description.length > 150 ? props.customOrder.description.substr(0, 150) + '...' : props.customOrder.description}</small>
              </small>
            </div>
            <div className="py-1">
              <small>
                <small className="fw-bold">Total Offer Amount </small>
                <small className="text-primary bg-light badge"> ${props.customOrder.amount}</small>
              </small>
            </div>
            <div className="py-1">
              <small>
                <small className="fw-bold ">Delivery Within </small>
                <small className="text-primary bg-light badge"> {props.customOrder.deliveryDays} days</small>
              </small>
            </div>
            <div className="mt-4 w-100 d-flex">
              <SmButton buttonType='Chat' clicked={_ => setShowModal(true)}>More Info</SmButton>
              {props.userType !== 'me' && props.userRole === 'buyer' && <SmButton buttonType='Submit' clicked={_ => props.payCustomOrder(props.customOrder)}>Accept Order</SmButton>}
              {/* <SmButton buttonType='Submit' clicked={_ => props.payCustomOrder(props.customOrder)}>Accept Order</SmButton> */}
            </div>
          </div>}
        <div className="chat__meta">
          <span>{getCurrentDate(sentAt)}</span>
        </div>
      </div>
      <Avatar isOnline="active" image={props.image} />
    </div>
  </React.Fragment>
}
