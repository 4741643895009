import * as actionTypes from '../ActionTypes'
import axios from '../../../axios'
import {tokenHeadersNormal} from '../HeaderConfig'

export const uploadBuyData = (data) => {
  return (dispatch, getState) => {
    axios
      .post('/stripe/savePaymentToDatabase', data, {
        headers: tokenHeadersNormal(getState).headers,
      })
      .then((response) => {
        dispatch({
          type: actionTypes.UPLOAD_BUY,
          payload: {
            uploadedData: response.data,
          },
        })
      })
      .catch((error) => {
        if (error.response === undefined) return
        dispatch({
          type: actionTypes.ERROR_BUY,
          payload: {
            error: error.response.data.message,
          },
        })
      })
  }
}

export const getBuyInfo = () => {
  return (dispatch, getState) => {
    const url = `/stripe/checkPaymentForBuyer`
    axios
      .get(url, {headers: tokenHeadersNormal(getState).headers})
      .then((response) => {
        dispatch({
          type: actionTypes.LOAD_BUY,
          payload: {
            loadedData: response.data.data,
            ids: response.data.ids,
          },
        })
      })
      .catch((error) => {
        if (error.response === undefined) return
        dispatch({
          type: actionTypes.ERROR_BUY,
          payload: {
            error: error.response.data.message,
          },
        })
      })
  }
}

export const getSellInfo = () => {
  return (dispatch, getState) => {
    const url = `/stripe/checkPaymentForSeller`
    axios
      .get(url, {headers: tokenHeadersNormal(getState).headers})
      .then((response) => {
        dispatch({
          type: actionTypes.LOAD_BUY,
          payload: {
            loadedData: response.data.data,
            ids: response.data.ids,
          },
        })
      })
      .catch((error) => {
        if (error.response === undefined) return
        dispatch({
          type: actionTypes.ERROR_BUY,
          payload: {
            error: error.response.data.message,
          },
        })
      })
  }
}