import React from 'react'
import { connect } from 'react-redux'
import classes from '../Settings.module.css'
import SettingsNavigation from '../SettingsNavigation'
import SimpleSnackbar from '../../../../components/Snackbar/Snackbar'
import axios from '../../../../axios'
import { withTranslation } from 'react-i18next'
// import { Line } from '../../../../components/UI/Spacing';

class Security extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      password: '',
      validPassword: true,
      newPassword: '',
      validNewPassword: 'ok',
      confirmPassword: '',
      validConfirmPassword: 'ok',
      matched: true,
      updated: '',
    }
  }
  handlePassword = (event) => {
    this.setState({ password: event.target.value, updated: '' })
    if (event.target.value.length >= 8) {
      this.setState({ validPassword: true })
    } else {
      this.setState({ validPassword: false })
    }
  }
  handleNewPassword = (event) => {
    this.setState({ newPassword: event.target.value, updated: '' })
    if (event.target.value.length >= 8) {
      this.setState({ validNewPassword: true })
    } else {
      this.setState({ validNewPassword: false })
    }
  }
  handleConfirmPassword = (event) => {
    this.setState({ confirmPassword: event.target.value, updated: '' })
    if (event.target.value.length >= 8) {
      this.setState({ validConfirmPassword: true })
      if (this.state.newPassword !== event.target.value) {
        this.setState({ matched: false })
      } else {
        this.setState({ matched: true })
      }
    } else {
      this.setState({ validConfirmPassword: false })
    }
  }
  tokenHeadersNormal = () => {
    const headersNormal = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    const token = this.props.token
    if (token) {
      headersNormal.headers['x-auth-header'] = token
    }
    return headersNormal
  }
  changePasswordApi = async (data) => {
    const res = await axios({
      method: 'POST',
      data: data,
      url: '/user/changePassword',
      headers: this.tokenHeadersNormal().headers,
    })
    return res.data
  }

  submitPassword = async () => {
    const { password, newPassword, confirmPassword } = this.state
    if (password.length < 8) {
      this.setState({ validPassword: false })
      return
    }
    if (newPassword.length < 8) {
      this.setState({ validNewPassword: false })
      return
    }
    if (confirmPassword.length < 8) {
      this.setState({ validConfirmPassword: false })
      return
    }
    if (newPassword !== confirmPassword) {
      this.setState({ matched: false })
      return
    }

    const data = { oldPassword: password, newPassword: confirmPassword }
    var res
    try {
      res = await this.changePasswordApi(data)
      if (res) {
        this.setState({ updated: res.message })
        if (res.message === 'changed' || res.message === 'social_account') {
          this.setState({
            password: '',
            validPassword: true,
            newPassword: '',
            validNewPassword: 'ok',
            confirmPassword: '',
            validConfirmPassword: 'ok',
            matched: true,
          })
        }
      }
    } catch (e) {
      console.error(e, 'submitPassword')
    }
  }
  render() {
    const {
      password,
      validPassword,
      newPassword,
      validNewPassword,
      confirmPassword,
      validConfirmPassword,
      matched,
      updated,
    } = this.state
    const { t } = this.props;
    return (
      <div className={classes.Settings}>
        {updated && updated === 'changed' ? (
          <SimpleSnackbar
            open={true}
            message="Password has been changed successfully!"
            type="success"
          />
        ) : updated === 'social_account' ? (
          <SimpleSnackbar
            open={true}
            message="You're logged in from a social account. You can't change password here."
            type="error"
          />
        ) : updated === 'not_matched' ? (
          <SimpleSnackbar
            open={true}
            message="Your current password isn't matched. Please give us your valid current password."
            type="error"
          />
        ) : null}
        <div className={classes.PartLeft}>
          <SettingsNavigation user={this.props.user} />
        </div>
        <div className={classes.PartRight}>
          <div className="row mb-3">
            <small className="text-dark fw-bold">{t('changePassword.title').toUpperCase()}</small>
          </div>
          <div className="row justify-content-between align-items-center py-sm-2 py-lg-3 mb-3">
            <div className="col-lg-4 col-md-4 col-sm-12">
              <small className="text-secondary fw-bold ">
                {t('changePassword.fields.0')}
              </small>
            </div>
            <div className="col-lg-8 col-md-8 col-sm-12 mt-2">
              <div className="form-group">
                <input
                  type="password"
                  name="password"
                  className="form-control"
                  minLength={8}
                  maxLength={16}
                  value={password}
                  onChange={this.handlePassword}
                />
                {!validPassword && (
                  <div className="pt-2" style={{ color: 'red' }}>
                    {t('changePassword.errorLength')}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row justify-content-between align-items-center py-sm-2 py-lg-3 mb-3">
            <div className="col-lg-4 col-md-4 col-sm-12">
              <small className="text-secondary fw-bold ">{t('changePassword.fields.1')}</small>
            </div>
            <div className="col-lg-8 col-md-8 col-sm-12 mt-2">
              <div className="form-group">
                <input
                  type="password"
                  name="newPassword"
                  className="form-control"
                  minLength={8}
                  maxLength={16}
                  value={newPassword}
                  onChange={this.handleNewPassword}
                />
                {!validNewPassword && (
                  <div className="pt-2" style={{ color: 'red' }}>
                    {t('changePassword.errorLength')}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row justify-content-between align-items-center py-sm-2 py-lg-3">
            <div className="col-lg-4 col-md-4 col-sm-12">
              <small className="text-secondary fw-bold ">
                {t('changePassword.fields.2')}
              </small>
            </div>
            <div className="col-lg-8 col-md-8 col-sm-12 mt-2">
              <div className="form-group">
                <input
                  type="password"
                  name="confirmPassword"
                  className="form-control"
                  minLength={8}
                  maxLength={16}
                  value={confirmPassword}
                  onChange={this.handleConfirmPassword}
                />
                {!validConfirmPassword && (
                  <div className="pt-2" style={{ color: 'red' }}>
                    {t('changePassword.errorLength')}
                  </div>
                )}
                {!matched && (
                  <div className="pt-2" style={{ color: 'red' }}>
                    {t('changePassword.errorMatch')}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-12 py-3 mt-3">
            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="btn btn-primary"
                onClick={this.submitPassword}>
                {t('changePassword.saveChanges')}
              </button>
            </div>
          </div>
          {/* <Line orientation='h' space='4' height='1' width='100' />
            <small className='text-secondary fw-bold'>CONNECTED DEVICES</small>
            <div className={classes.PartRight} style={{width:'100%', marginTop:'20px'}}>
                <small className='text-muted'>Device Information</small>
            </div> */}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userAuth.user,
    token: state.userAuth.token,
  }
}

export default connect(mapStateToProps, null)(withTranslation(['UserProfile'])(Security));
