import React, { useContext, useEffect } from 'react'
import { Box, withWidth } from '@material-ui/core';
import { useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import PerfectScrollbar from 'react-perfect-scrollbar';
import useStyles from './index.style';
import axios from '../../../axios';
// import {getConversation} from '../../../store/actions/ActionsFiles/Chat'
import Conversation from './Conversation'
import ContentHeader from './ContentHeader'
import ChatFooter from './ChatFooter'
import { getChatContainerHeight } from './coremat/AppConstants'
import AppContext from './coremat/AppContextProvider/AppContext'
// let socket

const ChatContainer = ({ width }) => {
  const { showFooter } = useContext(AppContext)
  const classes = useStyles({
    height: getChatContainerHeight(width, showFooter),
  })
  // const dispatch = useDispatch()
  let scrollBarRef = null

  const {
    currentUser,
    // conversation
  } = useSelector(({ chat }) => chat)
  const { user } = useSelector(({ userAuth }) => userAuth)

  const { screenWidth } = useSelector(({ responsiveness }) => responsiveness)
  const [allConv, setAllConv] = React.useState([])

  const addToConversation = (object) => {
    setAllConv([...allConv, object])
  }

  // const headersNormal = {
  //   headers: {
  //     'Content-Type': 'application/json',
  //   },
  // }
  // const tokenHeadersNormal = useCallback(() => {
  //   if (token) {
  //     headersNormal.headers['x-auth-header'] = token
  //   }
  //   return headersNormal
  // }, [token, headersNormal])

  useEffect(() => {
    // console.log('axios called')
    if (currentUser) {
      const url = '/chat/allMessages'
      axios
        .post(
          url,
          { singleEmail: currentUser.email, userEmail: user.email },
          // {headers: tokenHeadersNormal().headers},
        )
        .then((info) => {
          if (info.status === 200) {
            let conversationData = []
            let dd = info.data.messages;
            dd.map((single) => {
              if (single.message !== 'System') {
                let _obj = {}
                _obj['messageType'] = single.messageType ? single.messageType : 'text';
                _obj['message'] = single.message;
                _obj['sentAt'] = single.createdAt;
                if (single.fromEmail === user.email) {
                  _obj['type'] = 'sent'
                  conversationData.push(_obj)
                  return _obj
                } else {
                  _obj['type'] = 'received'
                  conversationData.push(_obj)
                  return _obj
                }
              } else {
                return null
              }
            })
            setAllConv(conversationData)
          } else {
            console.log('Something is wrong!')
          }
        })
    }
  }, [user.email, currentUser])

  // useEffect(() => {
  //   if (currentUser)
  //     dispatch(getConversation({singleEmail: currentUser.email}, user))
  //   setAllConv(conversation)
  // }, [currentUser, dispatch, user])

  useEffect(() => {
    if (scrollBarRef) scrollBarRef._container.scrollTop = 99999
  }, [scrollBarRef, allConv]) //conversation

  const loadPrevious = () => {
    console.log('loadPrevious trigger')
  }

  if (!currentUser) {
    return (
      <Box className={classes.chatBoxRoot}>
        <Typography className={classes.chatBoxTitle}>
          Welcome to Umediad Chat
        </Typography>
      </Box>
    )
  }

  return (
    <Box className={classes.inBuildAppMainContent}>
      <ContentHeader user={currentUser} />
      {screenWidth > 720 ? (
        <PerfectScrollbar
          onScrollY={(container) => {
            if (container.scrollTop === 0) {
              loadPrevious()
            }
          }}
          className={classes.perfectScrollbarChatCon}
          ref={(ref) => {
            scrollBarRef = ref
          }}>
          <Conversation conversation={allConv} selectedUser={currentUser} />
        </PerfectScrollbar>
      ) : (
        <div className={classes.perfectScrollbarChatConForSmall}>
          <Conversation conversation={allConv} selectedUser={currentUser} />
        </div>
      )}

      <ChatFooter
        currentUser={currentUser}
        user={user}
        conversation={allConv}
        addToConversation={addToConversation}
      />
    </Box>
  )
}

export default withWidth()(ChatContainer)

// import React, {useContext, useEffect} from 'react'
// import {Box, withWidth} from '@material-ui/core'
// import {connect, useSelector} from 'react-redux'
// import Typography from '@material-ui/core/Typography'
// import PerfectScrollbar from 'react-perfect-scrollbar'
// import useStyles from './index.style'
// import axios from '../../../axios'
// import Conversation from './Conversation'
// import ContentHeader from './ContentHeader'
// import ChatFooter from './ChatFooter'
// import {getChatContainerHeight} from './coremat/AppConstants'
// import AppContext from './coremat/AppContextProvider/AppContext'

// class ChatContainerClass extends React.Component {
//   scrollBarRef = null

//   state = {
//     allConv: [],
//   }

//   componentDidMount() {
//     const {currentUser, user} = this.props
//     if (this.scrollBarRef) this.scrollBarRef._container.scrollTop = 99999
//     if (currentUser) {
//       console.log('called')
//       const url = '/chat/allMessages'
//       axios
//         .post(
//           url,
//           {singleEmail: currentUser.email},
//           {headers: this.tokenHeadersNormal().headers},
//         )
//         .then((info) => {
//           if (info.status === 200) {
//             let conversationData = []
//             let dd = info.data.messages
//             dd.map((single) => {
//               if (single.message !== 'System') {
//                 let _obj = {}
//                 _obj['messageType'] = 'text'
//                 _obj['message'] = single.message
//                 _obj['sentAt'] = single.createdAt
//                 if (single.fromEmail === user.email) {
//                   _obj['type'] = 'sent'
//                   conversationData.push(_obj)
//                   return _obj
//                 } else {
//                   _obj['type'] = 'received'
//                   conversationData.push(_obj)
//                   return _obj
//                 }
//               } else {
//                 return null
//               }
//             })
//             this.setState({allConv: conversationData})
//             // setAllConv(conversationData)
//           } else {
//             console.log('Something is wrong!')
//           }
//         })
//     }
//   }

//   addToConversation = (object) => {
//     const {allConv} = this.state
//     console.log(allConv, 'container Add to conversation age')
//     // setAllConv([...allConv, object])
//     this.setState({allConv: [...allConv, object]})
//     console.log(allConv, 'container Add to conversation pore')
//   }

//   tokenHeadersNormal = () => {
//     const headersNormal = {
//       headers: {
//         'Content-Type': 'application/json',
//       },
//     }
//     const token = this.props.token;
//     if (token) {
//       headersNormal.headers['x-auth-header'] = token
//     }
//     return headersNormal
//   }

//   loadPrevious = () => {
//     console.log('loadPrevious trigger')
//   }

//   render() {
//     // let scrollBarRef = null
//     const {classes, currentUser, screenWidth, ChatFooter, user} = this.props
//     const {allConv} = this.state
//     if (!currentUser) {
//       return (
//         <Box className={classes.chatBoxRoot}>
//           <Typography className={classes.chatBoxTitle}>
//             Welcome to Umediad Chat
//           </Typography>
//         </Box>
//       )
//     }
//     return (
//       <Box className={classes.inBuildAppMainContent}>
//         <ContentHeader user={currentUser} />
//         {screenWidth > 720 ? (
//           <PerfectScrollbar
//             onScrollY={(container) => {
//               if (container.scrollTop === 0) {
//                 this.loadPrevious()
//               }
//             }}
//             className={classes.perfectScrollbarChatCon}
//             ref={(ref) => {
//               this.scrollBarRef = ref
//             }}>
//             <Conversation conversation={allConv} selectedUser={currentUser} />
//           </PerfectScrollbar>
//         ) : (
//           <div className={classes.perfectScrollbarChatConForSmall}>
//             <Conversation conversation={allConv} selectedUser={currentUser} />
//           </div>
//         )}

//         <ChatFooter
//           currentUser={currentUser}
//           user={user}
//           conversation={allConv}
//           addToConversation={this.addToConversation}
//         />
//       </Box>
//     )
//   }
// }

// const mapStateToProps = (state) => {
//   return {
//     currentUser: state.chat.currentUser,
//     user: state.userAuth.user,
//     token: state.userAuth.token,
//     screenWidth: state.responsiveness.screenWidth,
//   }
// }

// const ChatContainer = (props) => {
//   const {width} = props
//   const {showFooter} = useContext(AppContext)
//   const classes = useStyles({
//     height: getChatContainerHeight(width, showFooter),
//   })
//   console.log(props)
//   // useEffect(() => {
//   //   if (currentUser)
//   //     dispatch(getConversation({singleEmail: currentUser.email}, user))
//   // }, [currentUser, dispatch, user])

//   // useEffect(() => {
//   //   if (scrollBarRef) scrollBarRef._container.scrollTop = 99999
//   // }, [scrollBarRef, allConv]) //conversation

//   return (
//     <ChatContainerClass {...props} classes={classes} showFooter={showFooter} />
//   )
// }

// export default connect(mapStateToProps, null)(withWidth()(ChatContainer))
