import React, { Component, Fragment } from 'react'
import classes from '../CreateAdd.module.css'
import Button from '../../../../components/UI/Button/Button'
import StepProgress from './StepProgress'
import SimpleSnackbar from '../../../../components/Snackbar/Snackbar'
// @ts-ignore
import { Multiselect } from 'multiselect-react-dropdown'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { Divider } from '../../../../components/UI/Spacing'
import styles from '../../../../containers/NormalUser/Tender/Tender.module.css'

export class Step1 extends Component {
  constructor(props) {
    super(props)
    const { t } = this.props
    this.state = {
      isClicked: false,
      custom_domain: '',
      businessDomain: [
        { id: 0, domain: t('translation:domainSlider.list.0') },
        { id: 1, domain: t('translation:domainSlider.list.1') },
        { id: 2, domain: t('translation:domainSlider.list.2') },
        { id: 3, domain: t('translation:domainSlider.list.3') },
        { id: 4, domain: t('translation:domainSlider.list.4') },
        { id: 5, domain: t('translation:domainSlider.list.5') },
        { id: 6, domain: t('translation:domainSlider.list.6') },
        { id: 7, domain: t('translation:domainSlider.list.7') },
        { id: 8, domain: t('translation:domainSlider.list.8') },
        { id: 9, domain: t('translation:domainSlider.list.9') },
        { id: 10, domain: t('translation:domainSlider.list.10') },
        { id: 11, domain: t('translation:domainSlider.list.11') },
        { id: 12, domain: t('translation:domainSlider.list.12') },
        { id: 13, domain: t('translation:domainSlider.list.13') },
        { id: 14, domain: t('translation:domainSlider.list.14') },
        { id: 15, domain: t('translation:domainSlider.list.15') },
        { id: 16, domain: t('translation:domainSlider.list.16') },
        { id: 17, domain: t('translation:domainSlider.list.17') },
        { id: 18, domain: t('translation:domainSlider.list.18') },
      ],
      selected: [],
      formData: {
        type: '',
        subType: '',
      },
    }
  }

  componentDidMount() {
    window.scroll(0.0, 0.0)
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.selected !== this.state.selected) {
      this.props.onSelectDomains(this.state.selected)
    }
  }

  handleTypeAndSubTypeInput = (event) => {
    const value = event.target.value
    const name = event.target.name
    const formData = { ...this.state.formData }

    formData[name] = value
    this.setState({ formData })
  }
  continue = (event, isFieldOk) => {
    event.preventDefault()
    if (isFieldOk) this.props.nextStep()
    this.setState({ isClicked: true })
    setTimeout(() => {
      this.setState({ isClicked: false })
    }, 4000)
  }
  handleCustomDomain = (event) =>
    this.setState({ custom_domain: event.target.value })

  handleAddCustomDomain = (event) => {
    event.preventDefault()
    const businessDomain = [...this.state.businessDomain]
    const selected = [...this.state.selected]
    if (this.state.custom_domain.trim().length > 0) {
      const newDomain = {
        id: businessDomain[businessDomain.length - 1].id + 1,
        domain: this.state.custom_domain.trim(),
      }
      businessDomain.push(newDomain)
      selected.push(newDomain)
      this.setState({ businessDomain, selected, custom_domain: '' })
    }
  }

  onSelectAdvertiseDomain = (selectedList, selectedItem) => {
    const selected = [...this.state.selected]
    selected.push(selectedItem)
    this.setState({ selected })
  }

  onRemoveAdvertiseDomain = (selectedList, removedItem) =>
    this.setState({ selected: selectedList })

  render() {
    const {
      values,
      handleTextInputChange,
      getSelectedOption,
      onRemoveType,
      onRemoveSubType,
      addTypeOrSubType,
    } = this.props
    const modules = {
      toolbar: [
        [{ header: [1, 2, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
        ['link', 'image'],
        ['clean'],
      ],
    }

    const formats = [
      'header',
      'bold',
      'italic',
      'underline',
      'strike',
      'blockquote',
      'list',
      'bullet',
      'indent',
      'link',
      'image',
    ]
    const { t } = this.props
    let isFieldOk = values.title.length > 10;
    isFieldOk = isFieldOk && values.types.length > 0;
    // isFieldOk = isFieldOk && values.sub_types.length > 0
    isFieldOk = isFieldOk && values.domains.length > 0;
    const { type, subType } = this.state.formData
    return (
      <Fragment>
        <Divider orientation="v" space="2" />
        <StepProgress step={this.props.step} />
        {this.props.message && !this.props.error && (
          <SimpleSnackbar
            open={true}
            message={this.props.message}
            type="success"
          />
        )}
        {this.props.message && this.props.error && (
          <SimpleSnackbar
            open="true"
            message={this.props.message}
            type="error"
          />
        )}
        {/* <div className={classes.NB}>
          <small>{t('MyAds:CreateAdd.NB')}</small>
        </div> */}
        <div className={classes.NB}>
          <small className="text-gray">
            *{t('MyAds:MyAdds.unsavedMsg')}
          </small>
        </div>
        <h5>{t('MyAds:CreateAdd.Step1.secondSection.title')}</h5>
        <div className={classes.Divider}></div>
        <div className={classes.InputItems}>
          <label htmlFor="title" className="form-label">
            {t('MyAds:MyAdds.columnNames.1')}
          </label>
        </div>
        <div
          className={[classes.InputItems, classes.InputItemsTitle].join(' ')}>
          <textarea
            id="title"
            className="form-control"
            inputMode="text"
            minLength={10}
            maxLength={150}
            placeholder={t('MyAds:CreateAdd.Step1.secondSection.fields.0')}
            value={values.title}
            autoFocus={true}
            onChange={(event) =>
              handleTextInputChange(event, 'title')
            }></textarea>
        </div>
        <div className={classes.InputItems}>
          <div className={classes.ErrorInfo}>
            <strong className={classes.Info}>
              {values.title.length}/150 (max-150,min-10)
            </strong>
            {values.title.length < 15 && this.state.isClicked && (
              <strong className={classes.Error}>
                {t('MyAds:CreateAdd.Step1.errors.0')}
              </strong>
            )}
          </div>
        </div>
        {/* <Divider orientation='v' space='1' /> */}
        <div className={classes.InputItems}>
          <div className={classes.InputItem}>
            <div className="py-2">
              <label htmlFor="domain" className="form-label mb-2">
                {t('MyAds:CreateAdd.Step1.firstSection.typeOptions.3')}
              </label>
              <Multiselect
                placeholder={t(
                  'MyAds:CreateAdd.Step1.firstSection.typeOptions.4',
                )}
                // showArrow
                showCheckbox
                closeIcon="cancel"
                options={this.state.businessDomain}
                selectedValues={values.domains}
                onSelect={this.onSelectAdvertiseDomain}
                onRemove={this.onRemoveAdvertiseDomain}
                displayValue="domain"
                className="form-control"
              />
              <div className={classes.ErrorInfo}>
                {values.domains.length === 0 && this.state.isClicked && (
                  <strong className={classes.Error}>
                    {t('MyAds:CreateAdd.Step1.errors.1')}
                  </strong>
                )}
              </div>
            </div>
          </div>
          {/* <div className={classes.DividerH}></div> */}
          <div className={classes.InputItem}>
            <form
              className={`${ classes.CustomInputItem } my-2`}
              onSubmit={this.handleAddCustomDomain}>
              <label htmlFor="custom_domain" className="form-label">
                {t('MyAds:MyAdds.createDomain')}
              </label>
              <input
                id="custom_domain"
                placeholder={t('MyAds:MyAdds.domain')}
                maxLength={50}
                type="text"
                value={this.state.custom_domain}
                name="custom_domain"
                onChange={this.handleCustomDomain}
                className="form-control"
              />
            </form>
            {values.screenWidth < 1024 && this.state.custom_domain.length > 0 && (
              <button
                onClick={this.handleAddCustomDomain}
                className="btn btn-sm btn-outline-primary mt-3">
                <i className="fas fa-plus mx-1"></i>
                {t('MyAds:addNew')}
              </button>
            )}
          </div>
        </div>
        <Divider orientation="v" space="3" />
        {/* <h6 className='my-2'>{t('MyAds:CreateAdd.Step1.firstSection.title')}</h6> */}
        <div className={classes.InputItems}>
          <div className={classes.InputItem}>
            <label htmlFor="type" className="form-label">
              {t('MyAds:MyAdds.columnNames.3')}
            </label>
            <select
              id="type"
              className="form-select"
              onChange={(event) => getSelectedOption(event, 'type')}>
              <option disabled selected>
                {t('MyAds:CreateAdd.Step1.firstSection.typeOptions.0')}
              </option>
              {values.select.map((value, index) => (
                <option
                  // selected={values.type === value.type}
                  key={index}
                  value={index}>
                  {value.type}
                </option>
              ))}
            </select>
            <div className={classes.ErrorInfo}>
              {values.types.length === 0 && this.state.isClicked && (
                <strong className={classes.Error}>
                  {t('MyAds:CreateAdd.Step1.errors.2')}
                </strong>
              )}
            </div>
            <div className={styles.MediaItem}>
              {values.types.map((type, index) => (
                <span key={index}>
                  {type}
                  <i
                    onClick={(_) => onRemoveType(type)}
                    className="fas fa-times mx-1"></i>
                </span>
              ))}
            </div>
            <form
              onSubmit={(e) => addTypeOrSubType(e, type, 'type')}
              className={`${ classes.CustomInputItem } my-3`}>
              <label className="form-label">
                {t('MyAds:createCustomMedia')}
              </label>
              <input
                placeholder={t('MyAds:MyAdds.columnNames.3')}
                maxLength={50}
                type="text"
                value={type}
                name="type"
                onChange={this.handleTypeAndSubTypeInput}
                className="form-control"
              />
            </form>
            {values.screenWidth < 1024 && type.length > 0 && (
              <button
                onClick={(e) => addTypeOrSubType(e, type, 'type')}
                className="btn btn-sm btn-outline-primary mt-3">
                <i className="fas fa-plus mx-1"></i>
                {t('MyAds:addNew')}
              </button>
            )}
          </div>
          {/* <div className={classes.DividerH}></div> */}
          <div className={classes.InputItem}>
            <label htmlFor="sub_type" className="form-label">
              {t('MyAds:MyAdds.columnNames.4')}
            </label>
            <select
              id="sub_type"
              className="form-select"
              onChange={(event) => getSelectedOption(event, 'sub_type')}>
              <option disabled selected>
                {t('MyAds:CreateAdd.Step1.firstSection.subtypeOptions.0')}{' '}
                {values.select[values.selectedIndex] &&
                  values.select[values.selectedIndex].type}
              </option>
              {values.select[values.selectedIndex] &&
                values.select[values.selectedIndex].sub_type.map(
                  (subType, index) => (
                    <option
                      key={index}
                      // selected={values.sub_type === subType}
                      value={index}>
                      {subType}
                    </option>
                  ),
                )}
            </select>
            <div className={styles.MediaItem}>
              {values.sub_types.map((sub_type, index) => (
                <span key={index}>
                  {sub_type}
                  <i
                    onClick={(_) => onRemoveSubType(sub_type)}
                    className="fas fa-times mx-1"></i>
                </span>
              ))}
            </div>
            <form
              onSubmit={(e) => addTypeOrSubType(e, subType, 'subType')}
              className={`${ classes.CustomInputItem } my-3`}>
              <label className="form-label">
                {t('MyAds:createCustomSubMedia')}
              </label>
              <input
                placeholder={t('MyAds:MyAdds.columnNames.4')}
                maxLength={50}
                type="text"
                value={subType}
                name="subType"
                onChange={this.handleTypeAndSubTypeInput}
                className="form-control"
              />
            </form>
            {values.screenWidth < 1024 && subType.length > 0 && (
              <button
                onClick={(e) => addTypeOrSubType(e, subType, 'subType')}
                className="btn btn-sm btn-outline-primary mt-3">
                <i className="fas fa-plus mx-1"></i>
                {t('MyAds:addNew')}
              </button>
            )}
            {/* <div className={classes.ErrorInfo}>
              {values.sub_types.length === 0 && this.state.isClicked && (
                <strong className={classes.Error}>
                  {t('MyAds:CreateAdd.Step1.errors.3')}
                </strong>
              )}
            </div> */}
          </div>
        </div>
        <Divider orientation="v" space="3" />
        {/* <h6 className='my-3'>{t('MyAds:CreateAdd.Step1.firstSection.typeOptions.5')}</h6> */}
        <div className={classes.InputItems}>
          <label htmlFor="description" className="form-label">
            {t('MyAds:CreateAdd.Step1.firstSection.typeOptions.5')}
          </label>
        </div>
        <div className={classes.InputItems}>
          <ReactQuill
            id="description"
            style={{ height: '200px' }}
            placeholder={t('MyAds:CreateAdd.Step1.secondSection.fields.1')}
            value={values.description}
            theme="snow"
            modules={modules}
            className={`w-100 ${ classes.Space }`}
            formats={formats}
            onChange={(value) => handleTextInputChange(value, 'description')}
          />
        </div>
        <div className={classes.StepsButton}>
          <Button
            buttonType="Submit"
            clicked={(event) => this.continue(event, isFieldOk)}>
            {t('MyAds:CreateAdd.button1')}
            <i className="fas fa-chevron-right"></i>
          </Button>
        </div>
      </Fragment>
    )
  }
}

export default Step1
