import React from 'react';
import NavigationItems from './NavigationItems/NormalUserNavigationItems';
import classes from './SideDrawer.module.css';
import Backdrop from '../../UI/Backdrop/Backdrop';
import Aux from '../../../hoc/Fragment/Fragment';
import profileImage from '../../../assets/images/profileImage.png';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    Close
} from '@material-ui/icons';

const SideDrawer = (props) => {
    let attachedClasses = [classes.SideDrawer, classes.Close];
    if (props.open) {
        attachedClasses = [classes.SideDrawer, classes.Open];
    }
    return <Aux>
        <Backdrop show={props.open} clicked={props.closed} />
        <div className={attachedClasses.join(' ')} >
            <div className={classes.UserInfo}>
                <div className={classes.closeHandler}>

                    {props.user ? <Link
                        to="/profile">
                        <img src={props.user ? props.user.profile_image : profileImage} alt='ProfileImage' />
                    </Link> : <img src={profileImage} alt='ProfileImage' />}
                    <div className={classes.CloseHandler}>
                        <Close onClick={props.closed} />
                    </div>
                    <h3>{props.user ? props.user.full_name : ''}</h3>
                    <p>{props.user ? props.user.email : ''}</p>
                </div>
            </div>

            <nav>
                <NavigationItems closeSidebar={props.closed} isAuthenticated={props.isAuthenticated } user={props.user}/>
            </nav>
        </div>
    </Aux>
};

const mapStateToProps = (state) => {
    return {
        user: state.userAuth.user,
        isAuthenticated: state.userAuth.token !== null,
    }
};
export default connect(mapStateToProps)(SideDrawer);