import React from 'react';
import classes from './components/Profile.module.css';
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux';
import Autocomplete from '../../../components/AutoComplete/AutoComplete';
import { countryData } from '../../../shared/utils';
import './components/AutocompleteStyles.css';
import * as actions from '../../../store/actions/index';
import PropTypes from 'prop-types';
import SimpleSnackbar from '../../../components/Snackbar/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { AddAPhoto } from '@material-ui/icons';
import { CoverPhotoModal } from './components/CoverPhotoModal';

function CircularProgressWithLabel(props) {
    return <Box position="relative" display="inline-flex">
        <CircularProgress variant="static" {...props} color='secondary' />
        <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
        >
            <Typography variant="caption" component="div" color="primary">{
                props.value}%</Typography>
        </Box>
    </Box>
}


class EditUserProfile extends React.Component {
    state = {
        formData: {
            full_name: '',
            username: '',
            gender: '',
            birth: '',
            description: '',
            profile_photo: null,
            cover_photo: null,
            country: '',
            contact_number: '',
            street: '',
            state: '',
            city: '',
            zip_code: '',
            accountType: ''
        },
        fileSelectedFor: '',
        userAddress: null,
        code: '',
        formError: false,
        userNameError: false,
        showModal: false
    }

    UNSAFE_componentWillMount() {
        if (!this.props.profile) this.props.onGetProfile();
    }

    componentDidMount() {
        if (this.props.profile) {
            const { gender, birth, description, country, contact_number, street, state, city, zip_code, cover_photo, user } = this.props.profile;
            const { full_name, profile_image, username, accountType } = user;

            let formData = { ...this.state.formData };

            formData['full_name'] = full_name;
            formData['username'] = username;
            formData['accountType'] = accountType;
            formData['gender'] = gender;
            formData['birth'] = birth;
            formData['description'] = description;
            formData['country'] = country;
            formData['contact_number'] = contact_number ? contact_number.split('$', 2)[1] : '';
            formData['street'] = street;
            formData['state'] = state;
            formData['city'] = city;
            formData['zip_code'] = zip_code;

            formData['cover_photo'] = cover_photo;
            formData['profile_photo'] = profile_image;

            this.setState({
                formData: formData,
                code: contact_number ? contact_number.split('$', 2)[0] : '',
            });
        }
    }

    handleFile = (event, name) => {
        let url = null;
        let fileObject = null;

        if (name === 'profile_photo') {
            fileObject = event.target.files[0];
            url = URL.createObjectURL(event.target.files[0]);
        } else if (name === 'cover_photo') {
            fileObject = event;
            url = URL.createObjectURL(event);
        }
        
        if (parseFloat(this.bytesToSize(fileObject.size)) > 3 && name === 'profile_photo') {
            return alert('File must be less than 3 MB');
        } else if (parseFloat(this.bytesToSize(fileObject.size)) > 6 && name === 'cover_photo') {
            return alert('File must be less than 6 MB');
        }
        else {
            let formData = { ...this.state.formData };
            const file = new FormData();
            file.append('files', fileObject);
            file.append('existing', formData[name]);
            formData[name] = url;
            this.setState({ formData, showModal: false, fileSelectedFor: name });
            this.props.onUploadFiles(file);
        }
    }

    onReturnOnlyDigit = (value) => value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');

    handleInputChange = (event) => {
        const formData = { ...this.state.formData };

        if (event.target.name === 'contact_number') {
            formData[event.target.name] = this.onReturnOnlyDigit(event.target.value);
        } else if (event.target.name === 'username') {
            this.setState({
                userNameError: /\s/g.test(event.target.value)
            });
            formData[event.target.name] = event.target.value;
        }
        else {
            formData[event.target.name] = event.target.value;
        }
        this.setState({ formData: formData });
    }

    onSubmit = async event => {
        event.preventDefault();
        const formData = { ...this.state.formData };

        formData['contact_number'] = this.state.code + '$' + formData.contact_number;
        formData.isChangedUsername = this.props.profile.user.username !== formData.username.trim();

        if (!formData.username || !formData.description || !formData.country || !formData.accountType) {
            this.setState({ formError: true });
            setTimeout(() => {
                this.setState({ formError: false });
            }, 3000);
        } else {
            this.props.onProfileUpdate({
                userInfo: formData
            }, this.props.history);
            this.setState({ formError: false });
        }
    }

    bytesToSize = (bytes) => (bytes / (1024 * 1024)).toFixed(2);

    openModal = () => this.setState({ showModal: true });
    closeModal = () => this.setState({ showModal: false });

    gettingValue = (name) => {
        const data = countryData().filter(value => value.name.toLocaleLowerCase() === name.toLocaleLowerCase());
        let formData = { ...this.state.formData };
        formData['country'] = name;
        this.setState({
            formData: formData,
            code: data[0].code
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const formData = { ...this.state.formData };
        if (this.props.files !== nextProps.files) {
            formData[this.state.fileSelectedFor] = nextProps.files[0];
            this.setState({ formData })
        }
    }

    render() {
        const { t } = this.props;
        const { full_name, username, birth, description, street, contact_number, state, city, zip_code, gender, country, profile_photo, cover_photo, accountType } = this.state.formData;

        const accountTypes = ['Event-City-Social movement', 'Company', 'Media', 'Influencer-Team-Entrepreneur'];

        // console.log(this.props.files);
        return <div className={classes.Profile}>
            {this.props.message && !this.props.error &&
                <SimpleSnackbar open={true} message={this.props.message} type='success' height='40' />}
            {this.props.message && this.props.error &&
                <SimpleSnackbar open='true' message={this.props.message} type='error' height='50' />}
            <CoverPhotoModal
                showModal={this.state.showModal}
                closeModal={this.closeModal}
                handleCoverFile={this.handleFile} />
            <div className={classes.PartLeft}>
                <div className={classes.CoverPhoto}>
                    <img src={cover_photo} alt='cover img' />
                    <div className={classes.TakeCover}>
                        {this.props.fileUploadLoading && this.state.fileSelectedFor === 'cover_photo' ?
                            <CircularProgressWithLabel value={this.props.fileUploadPercentage} /> :
                            <div className={classes.TakeCoverPhoto} onClick={this.openModal} title='Edit cover photo'> <AddAPhoto fontSize="small" style={{ color: 'white' }} />
                            </div>}
                    </div>
                    <div className={classes.UserImage}>
                        <img src={profile_photo} alt='profile' />
                        {this.props.fileUploadLoading && this.state.fileSelectedFor === 'profile_photo' ?
                            <div className={classes.AddImageLoading}>
                                <CircularProgressWithLabel value={this.props.fileUploadPercentage} />
                            </div>
                            :
                            <div className={classes.AddImage} title='Edit profile photo'>
                                <input
                                    type='file'
                                    accept='.png,.jpg,.jpeg'
                                    onChange={(event) => this.handleFile(event, 'profile_photo')}
                                    id='profile_photo'
                                />
                                <label htmlFor='profile_photo' className={classes.FileInput}>
                                    <AddAPhoto fontSize="large" color='primary' />
                                </label>
                            </div>}
                    </div>
                </div>
                <div
                    className={classes.PartLeftItem}
                    style={{ borderTopLeftRadius: '0px', borderTopRightRadius: '0px', borderTop: 'none' }}
                >
                    <div class="form-group">
                        <label className='mt-3 mb-2'>Full name</label>
                        <input
                            className='form-control'
                            type='text'
                            placeholder='Name'
                            value={full_name}
                            maxLength='100'
                            name='full_name'
                            onChange={this.handleInputChange}
                        />
                    </div>
                    <div class="form-group">
                        <label className='mt-3 mb-2'>User name *</label>
                        <input
                            className='form-control'
                            type='text'
                            placeholder='Name'
                            value={username}
                            maxLength='50'
                            name='username'
                            onChange={this.handleInputChange} />
                    </div>
                    <div class="form-group">
                        <label className='mt-3 mb-2'>Account type *</label>
                        <select
                            className='form-select'
                            name='accountType'
                            onChange={this.handleInputChange}>
                            <option selected disabled>Select your account type</option>
                            {accountTypes.map((_accountType, index) => (
                                <option key={index} selected={_accountType === accountType} value={_accountType}>{_accountType}</option>
                            ))}
                        </select>
                    </div>
                    <div class="form-group">
                        <label className='mt-3 mb-2'>{t('UserProfile:EditProfile.firstSection.fields.2')} *</label>
                        <textarea
                            className='form-control'
                            value={description}
                            maxLength='400'
                            rows='6'
                            name='description'
                            onChange={this.handleInputChange}
                            placeholder={t('UserProfile:EditProfile.firstSection.fields.2')} />
                    </div>
                    {/* {this.props.loading ?
                        <div className='d-flex mt-3 mt-lg-5 justify-content-center align-items-center'>
                            <CircularProgressWithLabel value={this.props.loadingPercentage} />
                        </div>
                        : */}
                </div>

            </div>
            <div className={classes.PartRight}>
                <div className='row'>
                    <div className="col-lg-6 col-12">
                        <label className='form-label'>Gender</label>
                        <select className='form-select' name='gender' onChange={this.handleInputChange}>
                            <option disabled selected>{t('UserProfile:EditProfile.firstSection.fields.gender.0')}</option>
                            <option selected={gender === 'Male'} value='Male'>{t('UserProfile:EditProfile.firstSection.fields.gender.1')}</option>
                            <option selected={gender === 'Female'} value='Female'>{t('UserProfile:EditProfile.firstSection.fields.gender.2')}</option>
                            <option selected={gender === 'None'} value='None'>{t('UserProfile:EditProfile.firstSection.fields.gender.3')}</option>
                        </select>
                    </div>
                    <div class="col-lg-6 col-12 mt-3 mt-lg-0">
                        <label className='form-label'>Date of birth</label>
                        <input
                            className='form-control'
                            type='date'
                            value={birth}
                            name='birth'
                            onChange={this.handleInputChange}
                        />
                        <div className='text-end'>
                            <small className="text-secondary">Private</small>
                        </div>
                        {this.state.birthError &&
                            <strong>{t('UserProfile:EditProfile.firstSection.error')} </strong>}
                    </div>
                </div>
                <label className='my-2'>Contact number</label>
                <div className='row'>
                    <div className='col-lg-6 col-12'>
                        <div className={classes.ContactNumberItem}>{this.state.code ? this.state.code : 'Number code'}</div>
                        {this.state.codeError &&
                            <strong>{t('UserProfile:EditProfile.forthSection.error')}</strong>}
                    </div>
                    <div className='col-lg-6 col-12 mt-3 mt-lg-0'>
                        <input
                            className='form-control'
                            type='text'
                            maxLength='15'
                            value={contact_number}
                            name='contact_number'
                            onChange={this.handleInputChange}
                            placeholder={t('UserProfile:EditProfile.forthSection.fields.1')} />
                        <div className='text-end'>
                            <small className="text-secondary">Private</small>
                        </div>
                    </div>
                </div>
                <div className='col-12 my-2'>
                    <label className='form-label'>Country *</label>
                    <Autocomplete
                        suggestions={countryData()}
                        getValue={this.gettingValue}
                        prevValue={country}
                        t={t} />
                </div>
                <h6 className='my-3'>Address</h6>
                <div className='row'>
                    <div className='col-lg-6 col-12'>
                        <label className='form-label'>Street</label>
                        <input
                            className='form-control'
                            value={street}
                            type='text'
                            maxLength='50'
                            name='street'
                            onChange={this.handleInputChange}
                            placeholder={t('UserProfile:EditProfile.forthSection.fields.2')} />
                        <div className='text-end'>
                            <small className="text-secondary">Private</small>
                        </div>
                    </div>
                    <div className='col-lg-6 col-12 mt-3 mt-lg-0'>
                        <label className='form-label'>City</label>
                        <input
                            className='form-control'
                            value={city}
                            type='text'
                            maxLength='25'
                            name='city'
                            onChange={this.handleInputChange}
                            placeholder={t('UserProfile:EditProfile.forthSection.fields.3')} />
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className='col-lg-6 col-12'>
                        <label className='mb-2'>State</label>
                        <input
                            className='form-control'
                            value={state}
                            type='text'
                            maxLength='25'
                            name='state'
                            onChange={this.handleInputChange}
                            placeholder={t('UserProfile:EditProfile.forthSection.fields.4')} />
                    </div>
                    <div className='col-lg-6 col-12 mt-3 mt-lg-0'>
                        <label className='form-label'>Zip code</label>
                        <input
                            className='form-control'
                            value={zip_code}
                            type='text'
                            maxLength='25'
                            name='zip_code'
                            onChange={this.handleInputChange}
                            placeholder={t('UserProfile:EditProfile.forthSection.fields.5')} />
                        <div className='text-end'>
                            <small className="text-secondary">Private</small>
                        </div>
                    </div>
                </div>
                {/* {this.props.loading ?
                    <div className='d-flex mt-3 mt-lg-5 justify-content-center align-items-center'>
                        <CircularProgressWithLabel value={this.props.loadingPercentage} />
                    </div>
                    : */}
                <strong className='badge bg-primary text-bold mt-3'>{t('Partner:requiredFields')}</strong>
                <div className='mt-4'>
                    <button
                        onClick={this.onSubmit}
                        type="button"
                        class="btn btn-primary w-100 fw-bold"
                    ><i class="fa fa-paper-plane mx-1" aria-hidden="true"></i>Update Info
                    </button>
                </div>
                {this.state.formError &&
                    <div className='btn btn-sm text-bold btn-warning mt-2'>{t('UserProfile:EditProfile.error')}...</div>}
            </div>
        </div>
    }
}

EditUserProfile.propTypes = {
    error: PropTypes.bool,
    onProfileUpdate: PropTypes.func,
    loadingPercentage: PropTypes.string,
    message: PropTypes.string
}

const mapStateToProps = (state) => {
    return {
        error: state.profile.error,
        loading: state.profile.loading,
        loadingPercentage: state.profile.loading_percentage,
        message: state.profile.message,
        profile: state.profile.profile,
        fileUploadLoading: state.fileUpload.loading,
        fileUploadError: state.fileUpload.error,
        fileUploadMessage: state.fileUpload.message,
        fileUploadPercentage: state.fileUpload.percentage,
        files: state.fileUpload.files
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onProfileUpdate: (body, history) => dispatch(actions.profileUpdate(body, history)),
        onGetProfile: () => dispatch(actions.getProfile()),
        onUploadFiles: (files) => dispatch(actions.uploadFiles(files)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(['translation', 'UserProfile', 'Partner'])(EditUserProfile));