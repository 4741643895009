import React from 'react';
import classes from '../../NormalUser/Advertises/SingleAdvertise/SingleAdvertise.module.css';
import { CircularProgress } from '@material-ui/core';
import { Close } from '@material-ui/icons';

export const GiveReview = ({ onSubmitReviewRating, loading, onClose }) => {
    const [countRating, setRating] = React.useState(0);
    const [review, setReview] = React.useState('');

    const onChangeReviews = (e) => setReview(e.target.value);
    const setIncreaseRating = () => setRating(countRating + 1);
    const setDecreaseRating = () => setRating(countRating - 1);

    const onSubmit = (e) => {
        e.preventDefault();
        if (review) onSubmitReviewRating(countRating, review);
        setReview('');
        setRating(0);
    }

    const ratted = () => {
        const ratings = [];
        for (let i = 0; i < countRating; i++) {
            ratings.push(<i className="fas fa-star mx-1" onClick={setDecreaseRating}></i>);
        }
        return ratings;
    }
    const notRatted = () => {
        const ratings = [];
        for (let i = 0; i < 5 - countRating; i++) {
            ratings.push(<i className="far fa-star mx-1" onClick={setIncreaseRating}></i>);
        }
        return ratings;
    }
    return <div className='p-3'>
        <div className='d-flex justify-content-between align-items-center'>
            <h5>Give your reviews and rating</h5>
            <div className='d-flex justify-content-end' onClick={onClose}>
                <Close style={{ cursor: 'pointer' }} />
            </div>
        </div>
        <div className={classes.Ratings}>
            <span>
                {ratted()}
                {notRatted()}
            </span>
        </div>
        <div className='py-3'>
            <div class="form-group">
                <textarea
                    class="form-control"
                    placeholder='Give your review'
                    id="exampleFormControlTextarea1"
                    rows="4"
                    onChange={onChangeReviews}
                    value={review}>
                </textarea>
                <div className='mt-3'>
                    {loading ? <CircularProgress /> : <button
                        className='btn btn-primary'
                        onClick={onSubmit}
                    >
                        Submit
                    </button>}
                </div>
            </div>
        </div>
    </div>
}