import React from 'react'
import classes from '../Settings.module.css';
import SettingsNavigation from '../SettingsNavigation';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Line } from '../../../../components/UI/Spacing';

const BillingInformation = (props) => {
    return <div className={classes.Settings}>
        <div className={classes.PartLeft}>
            <SettingsNavigation user={props.user} />
        </div>
        <div className={classes.PartRight}>
            <div className='row justify-content-between align-items-center my-2'>
                <div className='col-lg-4 col-md-4 col-sm-12'>
                    <small className='text-dark fw-bold'>PAYPAL FOR WITHDRAWALS</small>
                </div>
                <div className='col-lg-8 col-md-8 col-sm-12 mt-2'>
                    <small className='text-secondary'>m********************gmail.com</small><small className='text-success mx-2'><Link to='/profile' className="link-success">Change</Link></small>
                </div>
            </div>
            <Line orientation='h' space='4' height='1' width='100' backgroundColor={undefined} />
            <form>
                <div className="form-group my-4 col-lg-10 col-sm-12 col-md-10">
                    <label htmlFor="fullName">Full name</label>
                    <input type="text" className="form-control mt-3" id="fullName" />
                </div>
                <div className="form-group my-4 col-lg-10 col-sm-12 col-md-10">
                    <label htmlFor="company">Company name</label>
                    <input type="text" className="form-control mt-3" id="company" />
                </div>
                <div className="form-group col-lg-10 col-sm-12 col-md-10 my-4">
                    <label>Country</label>
                    <select className="form-select h-100 text-secondary mt-3">
                        <option selected disabled>Search Country</option>
                    </select>
                </div>
                <div className="form-group my-4 col-lg-10 col-sm-12 col-md-10">
                    <label htmlFor="state">State/Province/Territory</label>
                    <input type="text" className="form-control mt-3" id="state" />
                </div>
                <div className="form-group my-4 col-lg-10 col-sm-12 col-md-10">
                    <label htmlFor="address">Address</label>
                    <input type="text" className="form-control mt-3" id="address" />
                </div>
                <div className='row '>
                    <div className="form-group  col-lg-5 col-md-5 col-sm-6 mb-3">
                        <label htmlFor="city">City</label>
                        <input type="text" className="form-control mt-3" id="city" />
                    </div>
                    <div className="form-group  col-lg-5 col-md-5 col-sm-6">
                        <label  htmlFor="postal">Postal code</label>
                        <input type="text" className="form-control mt-3" id="postal" />
                    </div>
                </div>
                <div className="form-group my-2 col-lg-10 col-sm-12 col-md-10">
                    <label htmlFor="vat">VAT number</label>
                    <input type="text" className="form-control mt-3" id="vat" />
                </div>
                <div className="form-group my-4 col-lg-10 col-sm-12 col-md-10">
                    <label htmlFor="invoices">Invoices<i className="fas fa-question-circle"></i></label>
                    <div className="form-check mt-2">
                        <input
                            className="form-check-input "
                            type="checkbox"
                            id='yes'
                        />
                        <label className='mt-1' htmlFor="yes">Yes, email my billing info and original invoices.</label>
                    </div>
                </div>
                <div className="col-lg-10 col-sm-12 col-md-10 py-3 mt-3">
                    <div className='d-flex justify-content-end'>
                        <button
                            type="button"
                            className="btn btn-success fw-bold"
                        >Save Changes</button></div>
                </div>
            </form>
        </div>
    </div>

}


const mapStateToProps = (state) => {
    return {
        user: state.userAuth.user,
    }
}


export default connect(mapStateToProps, null)(BillingInformation);