import React from 'react'
import { AdvertiseItem } from '../../../NormalUser/Advertises/AdvertiseItem';

export const AdsComponent = (props) => {
    const { advertisesData, index, user } = props;

    const advertise = {
        shortId: advertisesData.shortId,
        user: user,
        types: advertisesData.types,
        title: advertisesData.title,
        rating: advertisesData.rating,
        files: advertisesData.files,
        startingAtPrice: advertisesData.startingAtPrice,
    }
    return <AdvertiseItem
        key={index}
        advertise={advertise}
    >{props.children}</AdvertiseItem>
}
