import React from 'react'
import {LanguagesList} from '../../assets/langs'
// @ts-ignore
import classes from './Translator.module.css'

const Translator = (props) => {
  const [value, setValue] = React.useState(localStorage.getItem('translateTo'))
  const [isHover, setHover] = React.useState(false)

  const handleChange = (event) => {
    const selected = event.target.value
    setValue(selected)
    localStorage.setItem('translateTo', selected)
    localStorage.setItem('i18nextLng', selected ?? 'en')
    window.location.reload()
  }

  const handleHover = () => {
    setHover(true)
    setTimeout(function () {
      setHover(false)
    }, 5000)
  }

  return isHover ? (
    <div className={classes.Translator}>
      <label className="mb-1">Page Translation</label>
      <select className="form-select" value={value} onChange={handleChange}>
        <option selected disabled>
          Page Translate To
        </option>
        {LanguagesList.map((lang) => (
          <option key={lang[1]} value={lang[1]}>
            {lang[0]}
          </option>
        ))}
      </select>
    </div>
  ) : (
    <div className={classes.LanguageButton}>
      <i
        className="fas fa-language"
        style={{color: 'white'}}
        onMouseEnter={handleHover}
        onClick={handleHover}></i>
    </div>
  )
}

export default Translator
