import React from 'react'
import classes from './About.module.css'
import HeaderImage from '../../../../assets/images/about-header.jpg'
import Translation from '../../../../shared/Translate'
import Translator from '../../../../components/Translator'
import {Translate} from 'react-auto-translate'
import {Link} from 'react-router-dom'

class About extends React.Component {
  render() {
    return (
      <div className={classes.About}>
        <div className={classes.AboutHeader}>
          <img src={HeaderImage} alt="HeaderImage" />
          <div className={classes.HeaderDescription}>
            <div>
              <h2>
                <Translation
                  input={<Translate>About Us</Translate>}
                  text={'About Us'}
                  from={'en'}
                />
              </h2>
            </div>
          </div>
        </div>
        <div className={classes.AboutDescription}>
          <div className={classes.AboutDescriptionItems}>
            <span className="fw-bold">Umediad Inc.</span>{' '}
            <Translation
              input={
                <Translate>
                  is a marketing company (platform), which allows users to buy
                  and sell their audience, directly to local and international
                  media and influencers.
                </Translate>
              }
              text={
                'is a marketing company (platform), which allows users to buy and sell their audience directly to local and international media and influencers.'
              }
              from={'en'}
            />
          </div>
          <div className={classes.AboutDescriptionItems}>
            <Translation
              input={
                <Translate>
                  The original idea in 2007 by Jean Kindar Bury, was to sell new
                  advertising sites, 13 years later,
                </Translate>
              }
              text={
                'The original idea in 2007 by Jean Kindar Bury, was to sell new advertising sites, 13 years later,'
              }
              from={'en'}
            />{' '}
            <span className="fw-bold">Umediad</span>{' '}
            <Translation
              input={
                <Translate>
                  aims at a larger market with efficient tools and new Partners
                  to provide you with a better service online around the world.
                </Translate>
              }
              text={
                'aims at a larger market with efficient tools and new Partners to provide you with a better service online around the world.'
              }
              from={'en'}
            />
          </div>
          <h5>
            <Translation
              input={<Translate>BUY OR SELL THE PERFECT ADS !</Translate>}
              text={'BUY OR SELL THE PERFECT ADS !'}
              from={'en'}
            />
          </h5>
          <h6 className="mt-2">
            <Translation
              input={<Translate>Our mission :</Translate>}
              text={'Our mission :'}
              from={'en'}
            />
          </h6>
          <div className="mt-1">
            <Translation
              input={
                <Translate>
                  To be the world leader in promoting the growth of your
                  business.
                </Translate>
              }
              text={
                'To be the world leader in promoting the growth of your business.'
              }
              from={'en'}
            />
          </div>

          <h6 className="mt-2">
            <Translation
              input={<Translate>How :</Translate>}
              text={'How :'}
              from={'en'}
            />
          </h6>
          <div className="mt-1">
            <Translation
              input={
                <Translate>
                  Finding sponsors is a daily challenge for many industries, who
                  must manage between doing what they do best (their core
                  business) and being successful in their fields.
                </Translate>
              }
              text={
                'Finding sponsors is a daily challenge for many industries, who must manage between doing what they do best (their core business) & being successful in their fields.'
              }
              from={'en'}
            />
            <br />
            <Translation
              input={undefined}
              text={
                ' Sponsors usually have one common vital need, to find the perfect audience that will provide them with the desired visibility while increasing their revenues. At Umediad Inc'
              }
              from={'en'}
            />{' '}
            <Translation
              input={
                <Translate>
                  Universal Media Advertising we leverage your values to
                  facilitate a secure transaction between advertisers and your
                  interests.
                </Translate>
              }
              text={
                'Universal Media Advertising we leverage your values to facilitate a secure transaction between advertisers and your interests.'
              }
              from={'en'}
            />
          </div>
          <div className={classes.AboutDescriptionItems}>
            <Translation
              input={<Translate>Get sponsors to find you!</Translate>}
              text={'Get sponsors to find you!'}
              from={'en'}
            />
            <br />
            <Translation
              input={
                <Translate>
                  Whether you are an established media outlet, a professional, a
                  influencer, an event, a public figure, a company, even a city.
                </Translate>
              }
              text={
                'Whether you are an established media outlet, a professional, a influencer, an event, a public figure, a company, even a city.'
              }
              from={'en'}
            />
            <br />
            <Translation
              input={
                <Translate>
                  YOUR VISIBILITY MATTERS. Sell your audience at your price!
                  Choose the subscription that suits you best today and start
                  generating profits from your audience (visibility, listening,
                  presence ...) Be the one to offer the perfect ad.
                </Translate>
              }
              text={
                'YOUR VISIBILITY MATTERS. Sell your audience at your price! Choose the subscription that suits you best today and start generating profits from your audience (visibility, listening, presence ...) Be the one to offer the perfect ad.'
              }
              from={'en'}
            />
          </div>
          <div className={classes.AboutDescriptionItems}>
            <Translation
              input={
                <Translate>
                  Register for free on umediad.com, fill in your profile
                  including your payment information, choose [My Audience] from
                  the menu, to prepare your audience sale ad by providing as
                  much information as possible [VENDOR TYPE - LOCATION - MEDIA -
                  AREA - AUDIENCE - SEGMENT - YOUR PRICE] and much more.
                </Translate>
              }
              text={
                'Register for free on umediad.com, fill in your profile including your payment information, choose [My Audience] from the menu, to prepare your audience sale ad by providing as much information as possible [VENDOR TYPE - LOCATION - MEDIA - AREA - AUDIENCE - SEGMENT - YOUR PRICE] and much more.'
              }
              from={'en'}
            />
            <br />
            <Translation
              input={
                <Translate>
                  Take the subscription that suits you best to post your ads,
                  copy the URL of your ad, add the link in your bio and
                  elsewhere, start dealing with buyers and all the revenue you
                  earn during the current month is paid on the 15th day of the
                  next month.
                </Translate>
              }
              text={
                'Take the subscription that suits you best to post your ads, copy the URL of your ad, add the link in your bio and elsewhere, start dealing with buyers and all the revenue you earn during the current month is paid on the 15th day of the next month.'
              }
              from={'en'}
            />
          </div>

          <h6 className="mt-2">
            <Translation
              input={<Translate>Benefits of subscriptions</Translate>}
              text={'Benefits of subscriptions'}
              from={'en'}
            />
          </h6>
          <div className="mt-1">
            <Translation
              input={
                <Translate>
                  1. A sellers account, allowing you to generate profit by
                  selling your visibility according to your location, your
                  media, your domain and more.
                </Translate>
              }
              text={
                '1. A sellers account, allowing you to generate profit by selling your visibility according to your location, your media, your domain and more.'
              }
              from={'en'}
            />
          </div>
          <div className="mt-1">
            <Translation
              input={
                <Translate>
                  2. Sellers can contact Buyers, without subscription you see
                  the offers without the possibility of getting in touch and
                  accepting the contract.
                </Translate>
              }
              text={
                '2. Sellers can contact Buyers, without subscription you see the offers without the possibility of getting in touch and accepting the contract.'
              }
              from={'en'}
            />
          </div>
          <div className="mt-1">
            <Translation
              input={
                <Translate>
                  3. Chat in private without the possibility of erasing the
                  messages which are your words of engagement.
                </Translate>
              }
              text={
                '3. Chat in private without the possibility of erasing the messages which are your words of engagement.'
              }
              from={'en'}
            />
          </div>
          <div className="mt-1">
            <Translation
              input={
                <Translate>
                  4. Secure payments to subscribe, buy an audience, sell your
                  audience and receive all your transaction commissions for the
                  current month, on the 15th day of the following month.
                </Translate>
              }
              text={
                '4. Secure payments to subscribe, buy an audience, sell your audience and receive all your transaction commissions for the current month, on the 15th day of the following month.'
              }
              from={'en'}
            />
          </div>
          <div className="mt-1">
            <Translation
              input={
                <Translate>
                  {' '}
                  5. Your transaction information is managed by Stripe, a world
                  leader for years in the field of online payments, which is
                  used by the largest companies.
                </Translate>
              }
              text={
                ' 5. Your transaction information is managed by Stripe, a world leader for years in the field of online payments, which is used by the largest companies.'
              }
              from={'en'}
            />
          </div>
          <div className="mt-1">
            <Translation
              input={
                <Translate>
                  6. Additional visibility in search results on
                </Translate>
              }
              text={'6. Additional visibility in search results on'}
              from={'en'}
            />
            <span className="fw-bold">Umediad</span>,{' '}
            <Translation
              input={
                <Translate>
                  which allows you to obtain new contracts from buyers who have
                  just discovered you.
                </Translate>
              }
              text={
                'which allows you to obtain new contracts from buyers who have just discovered you.'
              }
              from={'en'}
            />
          </div>
          <div className="mt-1">
            <Translation
              input={
                <Translate>
                  7. The possibility of having an authorized independent
                  partner, who offers you additional discounts and manages your
                  transactions professionally to give you time to accomplish
                  other tasks if you wish. Buy the perfect audience and post
                  your tenders!
                </Translate>
              }
              text={
                '7. The possibility of having an authorized independent partner, who offers you additional discounts and manages your transactions professionally to give you time to accomplish other tasks if you wish. Buy the perfect audience and post your tenders!'
              }
              from={'en'}
            />
          </div>
          <div className={classes.AboutDescriptionItems}>
            <span className="fw-bold">Umediad</span>{' '}
            <Translation
              input={
                <Translate>
                  makes it easy for you to find your perfect audience. Do you
                  want to target a local or international audience? Our search
                  engine is not only the best, but the only one of its kind and
                  our database is growing daily.
                </Translate>
              }
              text={
                'makes it easy for you tofind your perfect audience. Do you want to target a local or international audience? Our search engine is not only the best, but the only one of its kind and our database is growing daily.'
              }
              from={'en'}
            />
            <br />
            <Link to="/join">
              <Translation
                input={<Translate>Sign up</Translate>}
                text={'Sign up'}
                from={'en'}
              />
            </Link>{' '}
            <Translation
              input={
                <Translate>
                  now on Umediad to buy your audience, complete your buyer
                  profile, create your call for tenders when you don't find what
                  you are looking for right away and let the sellers come to
                  you.
                </Translate>
              }
              text={
                'now on <span className="fw-bold"> Umediad </span> to buy your audience, complete your buyer profile, create your call for tenders when you don\'t find what you are looking for right away and let the sellers come to you.'
              }
              from={'en'}
            />
          </div>

          <h6 className="mt-2">
            <Translation
              input={<Translate>BUYERS:</Translate>}
              text={'BUYERS:'}
              from={'en'}
            />
          </h6>
          <div className="mt-1">
            <span className="fw-bold">
              <Link to="/">Umediad</Link>
            </span>{' '}
            <Translation
              input={
                <Translate>
                  makes it easy for you to find your perfect audience. Do you
                  want to target a local or international audience? Our search
                  engine is not only the best, but the only one of its kind and
                  our database is growing daily.
                </Translate>
              }
              text={
                'makes it easy for you to find your perfect audience. Do you want to target a local or international audience? Our search engine is not only the best, but the only one of its kind and our database is growing daily.'
              }
              from={'en'}
            />{' '}
            <Link to="/join">
              <Translation
                input={<Translate>Sign up</Translate>}
                text={'Sign up'}
                from={'en'}
              />
            </Link>{' '}
            <Translation
              input={<Translate>now on</Translate>}
              text={'now on'}
              from={'en'}
            />
            <span className="fw-bold">
              <Link to="/">Umediad</Link>
            </span>{' '}
            <Translation
              input={
                <Translate>
                  to buy your audience, complete your buyer profile, create your
                  call for tenders when you don't find what you are looking for
                  right away and let the sellers come to you.
                </Translate>
              }
              text={
                "to buy your audience, complete your buyer profile, create your call for tenders when you don't find what you are looking for right away and let the sellers come to you."
              }
              from={'en'}
            />{' '}
          </div>

          <h5 className="mt-2">
            <Translation
              input={
                <Translate>BECOME : Authorized independent partner</Translate>
              }
              text={'BECOME : Authorized independent partner'}
              from={'en'}
            />
          </h5>
          <h6 className="mt-3">
            <Translation
              input={<Translate>NOTICE</Translate>}
              text={'NOTICE'}
              from={'en'}
            />
          </h6>
          <div className="mt-1">
            <Translation
              input={
                <Translate>
                  ⁃ Becoming an Authorized Independent Partner of Umediad Inc.
                  is free but your application is not automatically approved
                  even if you meet all the basic criteria.
                </Translate>
              }
              text={
                '⁃ Becoming an Authorized Independent Partner of Umediad Inc. is free but your application is not automatically approved even if you meet all the basic criteria.'
              }
              from={'en'}
            />{' '}
          </div>
          <div className="mt-1">
            <Translation
              input={
                <Translate>
                  ⁃ The authorized independent partner has the task of ensuring
                  that the transaction between the seller and the buyer is done
                  legally.
                </Translate>
              }
              text={
                '⁃ The authorized independent partner has the task of ensuring that the transaction between the seller and the buyer is done legally.'
              }
              from={'en'}
            />{' '}
          </div>
          <div className="mt-1">
            <Translation
              input={
                <Translate>
                  ⁃ The authorized independent partner takes full responsibility
                  for everything it publishes and the impacts that this may
                  have.
                </Translate>
              }
              text={
                '⁃ The authorized independent partner takes full responsibility for everything it publishes and the impacts that this may have.'
              }
              from={'en'}
            />
          </div>
          <h6 className="mt-2">
            <Translation
              input={<Translate>ADVANTAGES</Translate>}
              text={'ADVANTAGES'}
              from={'en'}
            />
          </h6>
          <div className="mt-1">
            <Translation
              input={
                <Translate>
                  ⁃ Local and international visibility in the list of our
                  partners with your information.
                </Translate>
              }
              text={
                '⁃ Local and international visibility in the list of our partners with your information.'
              }
              from={'en'}
            />
          </div>
          <div className="mt-1">
            <Translation
              input={
                <Translate>
                  ⁃ Offer an additional discount on our subscriptions.
                </Translate>
              }
              text={'⁃ Offer an additional discount on our subscriptions.'}
              from={'en'}
            />
          </div>
          <div className="mt-1">
            <Translation
              input={
                <Translate>
                  ⁃ Earn a royalty for each subscriber who chooses to take
                  advantage of your discount.
                </Translate>
              }
              text={
                '⁃ Earn a royalty for each subscriber who chooses to take advantage of your discount.'
              }
              from={'en'}
            />
          </div>
          <div className="mt-1">
            <Translation
              input={
                <Translate>
                  ⁃ Accept or decline requests from all subscribers to manage
                  their ads for a commission.
                </Translate>
              }
              text={
                '⁃ Accept or decline requests from all subscribers to manage their ads for a commission.'
              }
              from={'en'}
            />
          </div>
          <div className="mt-1">
            <Translation
              input={
                <Translate>
                  ⁃ Display up to 999 ads, with 3 different offers each.
                </Translate>
              }
              text={'⁃ Display up to 999 ads, with 3 different offers each.'}
              from={'en'}
            />
          </div>
          <div className="mt-1">
            <Translation
              input={
                <Translate>
                  ⁃ Manage your clients' collaborations efficiently in complete
                  security.
                </Translate>
              }
              text={
                "⁃ Manage your client's collaborations efficiently in complete security."
              }
              from={'en'}
            />
          </div>
          <div className="mt-1">
            <Translation
              input={
                <Translate>
                  ⁃ Sell your audience everywhere, across all media types and
                  domains.
                </Translate>
              }
              text={
                '⁃ Sell your audience everywhere, across all media types and domains.'
              }
              from={'en'}
            />{' '}
          </div>
          <div className="mt-1">
            <Translation
              input={<Translate>⁃ Find more sponsors an</Translate>}
              text={'⁃ Find more sponsors an'}
              from={'en'}
            />
          </div>
          <div className="mt-1">
            <Translation
              input={
                <Translate>
                  ⁃ Buy your local and international audience, via media and
                  influencers.
                </Translate>
              }
              text={
                '⁃ Buy your local and international audience, via media and influencers.'
              }
              from={'en'}
            />
          </div>
          <div className="mt-1">
            <Translation
              input={<Translate>⁃ Launch calls for tenders.</Translate>}
              text={'⁃ Launch calls for tenders.'}
              from={'en'}
            />
          </div>
          <div className="mt-1">
            <Translation
              input={<Translate>⁃ Apply on calls for tenders.</Translate>}
              text={'⁃ Apply on calls for tenders.'}
              from={'en'}
            />
          </div>

          <h6 className="mt-2">
            <Translation
              input={
                <Translate>
                  Who can become an authorised Umediad independent partner?
                </Translate>
              }
              text={'Who can become an authorised Umediad independent partner?'}
              from={'en'}
            />{' '}
          </h6>
          <div className="mt-1">
            <Translation
              input={
                <Translate>
                  • Any adult or company, who have never been commissioned to
                  commit fraud, with an understanding or training that enables
                  them to act as an intermediary in a transaction between a
                  seller and a buyer. [ By way of comparison, the partner's job
                  is similar to that of an estate agent or real estate agency,
                  except that the partner sells the audience, visibility,
                  listening or presence of its clients and not houses.]
                </Translate>
              }
              text={
                "• Any adult or company, who have never been commissioned to commit fraud, with an understanding or training that enables them to act as an intermediary in a transaction between a seller and a buyer. [ By way of comparison, the partner's job is similar to that of an estate agent or real estate agency, except that the partner sells the audience, visibility, listening or presence of its clients and not houses.]"
              }
              from={'en'}
            />
          </div>

          <div className={classes.AboutDescriptionItems}>
            <Translation
              input={
                <Translate>
                  Do you think you can do the job with the information
                  mentioned? Send us your request
                </Translate>
              }
              text={
                'Do you think you can do the job with the information mentioned? Send us your request'
              }
              from={'en'}
            />
            <br />*{' '}
            <Translation
              input={
                <Translate>
                  You must have at least 2 ads to complete within 15 days of
                  your request.
                </Translate>
              }
              text={
                'You must have at least 2 ads to complete within 15 days of your request.'
              }
              from={'en'}
            />
          </div>

          <div className="mt-1">
            <Translation
              input={
                <Translate>
                  ⁃ The platform is optimized and secured with tools and
                  companies among the most reliable on the market.
                </Translate>
              }
              text={
                '⁃ The platform is optimized and secured with tools and companies among the most reliable on the market.'
              }
              from={'en'}
            />
          </div>
          <div className="mt-1">
            <Translation
              input={
                <Translate>
                  ⁃ Our unique intelligent search engine, since (since January
                  28, 2021) opens all the doors to you in this field.
                </Translate>
              }
              text={
                '⁃ Our unique intelligent search engine, since (since January 28,2021) opens all the doors to you in this field.'
              }
              from={'en'}
            />
          </div>
          <div className="mt-1">
            <Translation
              input={
                <Translate>
                  ⁃ Buy your perfect audience, according to your criteria and
                  your budget or publish a free call for tenders to target your
                  customers.
                </Translate>
              }
              text={
                '⁃ Buy your perfect audience, according to your criteria and your budget or publish a free call for tenders to target your customers.'
              }
              from={'en'}
            />
          </div>
          <div className="mt-1">
            <Translation
              input={
                <Translate>
                  ⁃ Sell your audience (visibility, listening, presence ...) at
                  your price. Your ads are available to buyers anywhere during
                  your subscription. Focus on calls for tenders to obtain more
                  contracts.
                </Translate>
              }
              text={
                '⁃ Sell your audience (visibility, listening, presence ...) at your price. Your ads are available to buyers anywhere during your subscription. Focus on calls for tenders to obtain more contracts.'
              }
              from={'en'}
            />
          </div>
          <div className="mt-1">
            <Translation
              input={
                <Translate>
                  ⁃ Partners manage their collaborations, sell their customers'
                  audience, give discounts on our subscriptions, get a
                  commission on the sale of our subscriptions and residual
                  income with their subscribers.
                </Translate>
              }
              text={
                "⁃ Partners manage their collaborations, sell their customers' audience, give discounts on our subscriptions, get a commission on the sale of our subscriptions and residual income with their subscribers."
              }
              from={'en'}
            />
          </div>
          <div className="mt-1">
            <Translation
              input={
                <Translate>
                  ⁃ Welcome to all types of hearings and legal areas. You are
                  responsible for everything that your posts involve.
                </Translate>
              }
              text={
                '⁃ Welcome to all types of hearings and legal areas. You are responsible for everything that your posts involve.'
              }
              from={'en'}
            />
          </div>

          <div className="mt-1">
            <span>
              <Link to="/join">
                <Translation
                  input={<Translate>Register</Translate>}
                  text={'Register'}
                  from={'en'}
                />
              </Link>{' '}
              <Translation
                input={
                  <Translate>now to benefit from our expertise.</Translate>
                }
                text={'now to benefit from our expertise.'}
                from={'en'}
              />
            </span>
          </div>
        </div>
        <Translator />
      </div>
    )
  }
}

export default About
