import React from 'react'
// @ts-ignore
import classes from './TermsPrivacy.module.css'
import {TermsData} from './Data'
import {Translate} from 'react-auto-translate'
import Translator from '../../../../components/Translator'
import Translation from '../../../../shared/Translate'

export default class TermsConditions extends React.Component {
  componentDidMount() {
    window.scroll(0, 0)
  }
  componentDidUpdate() {
    window.scroll(0, 0)
  }

  render() {
    return (
      <div className={classes.TermsPrivacy}>
        <div className={classes.Main}>
          {/* {translation.length === 0 ? (
            <h5>Loading ... </h5>
          ) : (<h5>geting</h5>)} */}
          <div>
            <div className={classes.Header}>
              <h3>
                {/* 0 */}
                <Translation
                  input={<Translate>{TermsData[0]}</Translate>}
                  text={TermsData[0]}
                  from={'en'}
                />
              </h3>
            </div>

            <div className={classes.Divider1}></div>
            <span>
              <Translation
                input={
                  <Translate>Last Updated on: September 2, 2020</Translate>
                }
                text={'Last Updated on: September 22, 2020'}
                from={'en'}
              />
            </span>
            <div className={classes.Divider1}></div>
            <div className={classes.MainSection}>
              <div>
                {/* 1  */}
                <Translation
                  input={<Translate>{TermsData[1]}</Translate>}
                  text={TermsData[1]}
                  from={'en'}
                />

                <div className={classes.Divider2}></div>
                <span>
                  {/* 2 */}
                  <Translation
                    input={<Translate>{TermsData[2]}</Translate>}
                    text={TermsData[2]}
                    from={'en'}
                  />
                  {': '}
                  Jean Kindar Bury
                </span>
                <div className={classes.Divider2}></div>

                <span>
                  {/* 3  */}
                  <Translation
                    input={<Translate>{TermsData[3]}</Translate>}
                    text={TermsData[3]}
                    from={'en'}
                  />
                </span>
              </div>
              <div className={classes.Divider1}></div>
              <div>
                <h6>
                  {/* 4 */}
                  <Translation
                    input={<Translate>{TermsData[4]}</Translate>}
                    text={TermsData[4]}
                    from={'en'}
                  />
                  {/* 1. AGE RESTRICTION */}
                </h6>
                <div className={classes.Divider2}></div>
                <span>
                  {/* 5  */}
                  <Translation
                    input={<Translate>{TermsData[5]}</Translate>}
                    text={TermsData[5]}
                    from={'en'}
                  />
                </span>
              </div>

              <div className={classes.Divider1}></div>
              <div>
                <h6>
                  {/* 6 */}
                  <Translation
                    input={<Translate>{TermsData[6]}</Translate>}
                    text={TermsData[6]}
                    from={'en'}
                  />
                </h6>
                <div className={classes.Divider2}></div>
                <span>
                  {/* 7  */}
                  <Translation
                    input={<Translate>{TermsData[7]}</Translate>}
                    text={TermsData[7]}
                    from={'en'}
                  />
                </span>
              </div>

              <div className={classes.Divider1}></div>
              <div>
                <h6>
                  {/* 8 */}
                  <Translation
                    input={<Translate>{TermsData[8]}</Translate>}
                    text={TermsData[8]}
                    from={'en'}
                  />
                </h6>
                <div className={classes.Divider2}></div>
                <span>
                  {/* 9  */}
                  <Translation
                    input={<Translate>{TermsData[9]}</Translate>}
                    text={TermsData[9]}
                    from={'en'}
                  />
                </span>
              </div>

              <div className={classes.Divider1}></div>
              <div>
                <h6>
                  {/* 10 */}
                  <Translation
                    input={<Translate>{TermsData[10]}</Translate>}
                    text={TermsData[10]}
                    from={'en'}
                  />
                </h6>
                <div className={classes.Divider2}></div>
                <span>
                  {/* 11  */}
                  <Translation
                    input={<Translate>{TermsData[11]}</Translate>}
                    text={TermsData[11]}
                    from={'en'}
                  />
                </span>
              </div>

              <div className={classes.Divider1}></div>
              <div>
                <h6>
                  {/* 12 */}
                  <Translation
                    input={<Translate>{TermsData[12]}</Translate>}
                    text={TermsData[12]}
                    from={'en'}
                  />
                </h6>
                <div className={classes.Divider2}></div>
                <span>
                  {/* 13  */}
                  <Translation
                    input={<Translate>{TermsData[13]}</Translate>}
                    text={TermsData[13]}
                    from={'en'}
                  />
                </span>
                <div className={classes.Divider2}></div>
                <div className={classes.AimsLayout}>
                  <ul>
                    <span>
                      {/* 14  */}
                      <Translation
                        input={<Translate>{TermsData[14]}</Translate>}
                        text={TermsData[14]}
                        from={'en'}
                      />
                    </span>
                    <br />
                    <div className={classes.Divider2}></div>
                    <span>
                      {/* 15 */}
                      <Translation
                        input={<Translate>{TermsData[15]}</Translate>}
                        text={TermsData[15]}
                        from={'en'}
                      />
                    </span>
                    <br />
                    <div className={classes.Divider2}></div>
                    <span>
                      {/* 16  */}
                      <Translation
                        input={<Translate>{TermsData[16]}</Translate>}
                        text={TermsData[16]}
                        from={'en'}
                      />
                    </span>
                    <br />
                  </ul>
                </div>
              </div>

              <div className={classes.Divider1}></div>
              <div>
                <h6>
                  {/* 17 */}
                  <Translation
                    input={<Translate>{TermsData[17]}</Translate>}
                    text={TermsData[17]}
                    from={'en'}
                  />
                </h6>
                <div className={classes.Divider2}></div>
                <span>
                  {/* 18  */}
                  <Translation
                    input={<Translate>{TermsData[18]}</Translate>}
                    text={TermsData[18]}
                    from={'en'}
                  />
                </span>
                <div className={classes.Divider2}></div>
                <span>
                  {/* 19  */}
                  <Translation
                    input={<Translate>{TermsData[19]}</Translate>}
                    text={TermsData[19]}
                    from={'en'}
                  />
                </span>
                <div className={classes.Divider2}></div>
                <span>
                  {/* 20  */}
                  <Translation
                    input={<Translate>{TermsData[20]}</Translate>}
                    text={TermsData[20]}
                    from={'en'}
                  />
                </span>
                <div className={classes.Divider2}></div>
                <span>
                  {/* 21  */}
                  <Translation
                    input={<Translate>{TermsData[21]}</Translate>}
                    text={TermsData[21]}
                    from={'en'}
                  />
                </span>
                <div className={classes.Divider2}></div>
                <span>
                  {/* 22  */}
                  <Translation
                    input={<Translate>{TermsData[22]}</Translate>}
                    text={TermsData[22]}
                    from={'en'}
                  />
                </span>
              </div>

              <div className={classes.Divider1}></div>
              <div>
                <h6>
                  {/* 23 */}
                  <Translation
                    input={<Translate>{TermsData[23]}</Translate>}
                    text={TermsData[23]}
                    from={'en'}
                  />
                </h6>
                <div className={classes.Divider2}></div>
                <span>
                  {/* 24  */}
                  <Translation
                    input={<Translate>{TermsData[24]}</Translate>}
                    text={TermsData[24]}
                    from={'en'}
                  />
                </span>
              </div>

              <div className={classes.Divider1}></div>
              <div>
                <h6>
                  {/* 25 */}
                  <Translation
                    input={<Translate>{TermsData[25]}</Translate>}
                    text={TermsData[25]}
                    from={'en'}
                  />
                </h6>
                <div className={classes.Divider2}></div>
                <span>
                  {/* 26, 27  */}
                  <Translation
                    input={<Translate>{TermsData[26]}</Translate>}
                    text={TermsData[26]}
                    from={'en'}
                  />
                  <Translation
                    input={<Translate>{TermsData[27]}</Translate>}
                    text={TermsData[27]}
                    from={'en'}
                  />
                </span>
                <div className={classes.Divider2}></div>
                <span>
                  {/* 28  */}
                  <Translation
                    input={<Translate>{TermsData[28]}</Translate>}
                    text={TermsData[28]}
                    from={'en'}
                  />
                </span>
                <div className={classes.Divider2}></div>
                <div className={classes.Link}>
                  <p>jeankindarbury@umediad.ca</p>
                </div>
                <div className={classes.Divider2}></div>
                <span>
                  {/* 29  */}
                  <Translation
                    input={<Translate>{TermsData[29]}</Translate>}
                    text={TermsData[29]}
                    from={'en'}
                  />
                </span>
                <div className={classes.Divider2}></div>
                <div className={classes.Link}>
                  <p>rectification@umediad.com</p>
                </div>
              </div>

              <div className={classes.Divider1}></div>
              <div>
                <h6>
                  {/* 30 */}
                  <Translation
                    input={<Translate>{TermsData[30]}</Translate>}
                    text={TermsData[30]}
                    from={'en'}
                  />
                </h6>
                <div className={classes.Divider2}></div>
                <span>
                  {/* 31  */}
                  <Translation
                    input={<Translate>{TermsData[31]}</Translate>}
                    text={TermsData[31]}
                    from={'en'}
                  />
                </span>
              </div>

              <div className={classes.Divider1}></div>
              <div>
                <h6>
                  {/* 32 */}
                  <Translation
                    input={<Translate>{TermsData[32]}</Translate>}
                    text={TermsData[32]}
                    from={'en'}
                  />
                </h6>
                <div className={classes.Divider2}></div>
                <span>
                  {/* 33 */}
                  <Translation
                    input={<Translate>{TermsData[33]}</Translate>}
                    text={TermsData[33]}
                    from={'en'}
                  />{' '}
                  Jean Kindar Bury.
                </span>
                <div className={classes.Divider2}></div>
                <span>
                  {/* 34 */}
                  <Translation
                    input={<Translate>{TermsData[34]}</Translate>}
                    text={TermsData[34]}
                    from={'en'}
                  />
                </span>
                <div className={classes.Divider2}></div>
                <div className={classes.AimsLayout}>
                  <ul>
                    <span>
                      {/* 35 */}
                      a){' '}
                      <Translation
                        input={<Translate>{TermsData[35]}</Translate>}
                        text={TermsData[35]}
                        from={'en'}
                      />
                    </span>
                    <br />
                    <div className={classes.Divider2}></div>
                    <span>
                      {/* 36 */}
                      b){' '}
                      <Translation
                        input={<Translate>{TermsData[36]}</Translate>}
                        text={TermsData[36]}
                        from={'en'}
                      />
                    </span>
                    <br />
                    <div className={classes.Divider2}></div>
                    <span>
                      {/* 37 */}
                      c){' '}
                      <Translation
                        input={<Translate>{TermsData[37]}</Translate>}
                        text={TermsData[37]}
                        from={'en'}
                      />
                    </span>
                    <br />
                    <div className={classes.Divider2}></div>
                    <span>
                      {/* 38 */}
                      d){' '}
                      <Translation
                        input={<Translate>{TermsData[38]}</Translate>}
                        text={TermsData[38]}
                        from={'en'}
                      />
                    </span>
                    <br />
                    <div className={classes.Divider2}></div>
                    <span>
                      {/* 39 */}
                      e){' '}
                      <Translation
                        input={<Translate>{TermsData[39]}</Translate>}
                        text={TermsData[39]}
                        from={'en'}
                      />
                    </span>
                    <br />
                    <div className={classes.Divider2}></div>
                    <span>
                      {/* 40 */}
                      f){' '}
                      <Translation
                        input={<Translate>{TermsData[40]}</Translate>}
                        text={TermsData[40]}
                        from={'en'}
                      />
                    </span>
                    <br />
                    <div className={classes.Divider2}></div>
                    <span>
                      {/* 41 */}
                      g){' '}
                      <Translation
                        input={<Translate>{TermsData[41]}</Translate>}
                        text={TermsData[41]}
                        from={'en'}
                      />
                    </span>
                    <br />
                    <div className={classes.Divider2}></div>
                    <span>
                      {/* 42 */}
                      h){' '}
                      <Translation
                        input={<Translate>{TermsData[42]}</Translate>}
                        text={TermsData[42]}
                        from={'en'}
                      />
                    </span>
                    <br />
                  </ul>
                </div>
              </div>

              <div className={classes.Divider1}></div>
              <div>
                <h6>
                  {/* 43 */}
                  <Translation
                    input={<Translate>{TermsData[43]}</Translate>}
                    text={TermsData[43]}
                    from={'en'}
                  />
                </h6>
                <div className={classes.Divider2}></div>
                <span>
                  {/* 44 */}
                  <Translation
                    input={<Translate>{TermsData[44]}</Translate>}
                    text={TermsData[44]}
                    from={'en'}
                  />
                </span>
                <div className={classes.Divider2}></div>
                <span>
                  {/* 45 */}
                  <Translation
                    input={<Translate>{TermsData[45]}</Translate>}
                    text={TermsData[45]}
                    from={'en'}
                  />
                </span>
                <div className={classes.Divider2}></div>
                <span>
                  {/* 46 */}
                  <Translation
                    input={<Translate>{TermsData[46]}</Translate>}
                    text={TermsData[46]}
                    from={'en'}
                  />
                </span>
                <div className={classes.Divider2}></div>
                <span>
                  {/* 47 */}
                  <Translation
                    input={<Translate>{TermsData[47]}</Translate>}
                    text={TermsData[47]}
                    from={'en'}
                  />
                </span>
                <div className={classes.Divider2}></div>
                <span>
                  {/* 48 */}
                  <Translation
                    input={<Translate>{TermsData[48]}</Translate>}
                    text={TermsData[48]}
                    from={'en'}
                  />
                </span>
              </div>

              <div className={classes.Divider1}></div>
              <div>
                <h6>
                  {/* 49 */}
                  <Translation
                    input={<Translate>{TermsData[49]}</Translate>}
                    text={TermsData[49]}
                    from={'en'}
                  />
                </h6>
                <div className={classes.Divider2}></div>
                <span>
                  {/* 50 */}
                  <Translation
                    input={<Translate>{TermsData[50]}</Translate>}
                    text={TermsData[50]}
                    from={'en'}
                  />
                </span>
              </div>

              <div className={classes.Divider1}></div>
              <div>
                <h6>
                  {/* 51 */}
                  <Translation
                    input={<Translate>{TermsData[51]}</Translate>}
                    text={TermsData[51]}
                    from={'en'}
                  />
                </h6>
                <div className={classes.Divider2}></div>
                <span>
                  {/* 52 */}
                  <Translation
                    input={<Translate>{TermsData[52]}</Translate>}
                    text={TermsData[52]}
                    from={'en'}
                  />
                </span>
                <div className={classes.Divider2}></div>
                <span>
                  {/* 53 */}
                  a){' '}
                  <Translation
                    input={<Translate>{TermsData[53]}</Translate>}
                    text={TermsData[53]}
                    from={'en'}
                  />
                </span>
                <div className={classes.Divider2}></div>
                <span>
                  {/* 54 */}
                  b){' '}
                  <Translation
                    input={<Translate>{TermsData[54]}</Translate>}
                    text={TermsData[54]}
                    from={'en'}
                  />
                </span>
              </div>

              <div className={classes.Divider1}></div>
              <div>
                <h6>
                  {/* 55 */}
                  <Translation
                    input={<Translate>{TermsData[55]}</Translate>}
                    text={TermsData[55]}
                    from={'en'}
                  />
                </h6>
                <div className={classes.Divider2}></div>
                <span>
                  {/* 56 */}
                  <Translation
                    input={<Translate>{TermsData[56]}</Translate>}
                    text={TermsData[56]}
                    from={'en'}
                  />
                </span>
              </div>

              <div className={classes.Divider1}></div>
              <div>
                <h6>
                  {/* 57 */}
                  <Translation
                    input={<Translate>{TermsData[57]}</Translate>}
                    text={TermsData[57]}
                    from={'en'}
                  />
                </h6>
                <div className={classes.Divider2}></div>
                <span>
                  {/* 58 */}
                  <Translation
                    input={<Translate>You defend and indemnify</Translate>}
                    text={'You defend and indemnify'}
                    from={'en'}
                  />{' '}
                  Jean Kindar Bury{' '}
                  <Translation
                    input={<Translate>{TermsData[58]}</Translate>}
                    text={TermsData[58]}
                    from={'en'}
                  />
                </span>
              </div>

              <div className={classes.Divider1}></div>
              <div>
                <h6>
                  {/* 59 */}
                  <Translation
                    input={<Translate>{TermsData[59]}</Translate>}
                    text={TermsData[59]}
                    from={'en'}
                  />
                </h6>
                <div className={classes.Divider2}></div>
                <span>
                  {/* 60 */}
                  <Translation
                    input={<Translate>{TermsData[60]}</Translate>}
                    text={TermsData[60]}
                    from={'en'}
                  />
                </span>
              </div>

              <div className={classes.Divider1}></div>
              <div>
                <h6>
                  {/* 61 */}
                  <Translation
                    input={<Translate>{TermsData[61]}</Translate>}
                    text={TermsData[61]}
                    from={'en'}
                  />
                </h6>
                <div className={classes.Divider2}></div>
                <span>
                  {/* 62 */}
                  <Translation
                    input={<Translate>{TermsData[62]}</Translate>}
                    text={TermsData[62]}
                    from={'en'}
                  />
                </span>
              </div>

              <div className={classes.Divider1}></div>
              <div>
                <h6>
                  {/* 63 */}
                  <Translation
                    input={<Translate>{TermsData[63]}</Translate>}
                    text={TermsData[63]}
                    from={'en'}
                  />
                </h6>
                <div className={classes.Divider2}></div>
                <span>
                  {/* 64 */}
                  <Translation
                    input={<Translate>{TermsData[64]}</Translate>}
                    text={TermsData[64]}
                    from={'en'}
                  />
                </span>
              </div>

              <div className={classes.Divider1}></div>
              <div>
                <h6>
                  {/* 65 */}
                  <Translation
                    input={<Translate>{TermsData[65]}</Translate>}
                    text={TermsData[65]}
                    from={'en'}
                  />
                </h6>
                <div className={classes.Divider2}></div>
                <span>
                  {/* 66 */}
                  <Translation
                    input={<Translate>{TermsData[66]}</Translate>}
                    text={TermsData[66]}
                    from={'en'}
                  />
                </span>
              </div>

              <div className={classes.Divider1}></div>
              <div>
                <h6>
                  {/* 67 */}
                  <Translation
                    input={<Translate>{TermsData[67]}</Translate>}
                    text={TermsData[67]}
                    from={'en'}
                  />
                </h6>
                <div className={classes.Divider2}></div>
                <span>
                  {/* 68 */}
                  <Translation
                    input={<Translate>{TermsData[68]}</Translate>}
                    text={TermsData[68]}
                    from={'en'}
                  />
                </span>
              </div>

              <div className={classes.Divider1}></div>
              <div>
                <h6>
                  {/* 69 */}
                  <Translation
                    input={<Translate>{TermsData[69]}</Translate>}
                    text={TermsData[69]}
                    from={'en'}
                  />
                </h6>
                <div className={classes.Divider2}></div>
                <span>
                  {/* 70 */}
                  <Translation
                    input={<Translate>{TermsData[70]}</Translate>}
                    text={TermsData[70]}
                    from={'en'}
                  />
                </span>
              </div>
              <div className={classes.Divider1}></div>
              <div>
                <h6>
                  {/* 71 */}
                  <Translation
                    input={<Translate>{TermsData[71]}</Translate>}
                    text={TermsData[71]}
                    from={'en'}
                  />
                </h6>
                <div className={classes.Divider2}></div>
                <span>
                  {/* 72 */}
                  <Translation
                    input={<Translate>{TermsData[72]}</Translate>}
                    text={TermsData[72]}
                    from={'en'}
                  />
                  www.umediad.com/privacy-policy.
                </span>
                <div className={classes.Divider2}></div>
                <span>
                  {/* 73 */}
                  <Translation
                    input={<Translate>{TermsData[73]}</Translate>}
                    text={TermsData[73]}
                    from={'en'}
                  />
                </span>
              </div>
              <div className={classes.Divider1}></div>
              <div>
                <h6>
                  {/* 74 */}
                  <Translation
                    input={<Translate>{TermsData[74]}</Translate>}
                    text={TermsData[74]}
                    from={'en'}
                  />
                </h6>
                <div className={classes.Divider2}></div>
                <span>
                  {/* 75 */}
                  <Translation
                    input={<Translate>{TermsData[75]}</Translate>}
                    text={TermsData[75]}
                    from={'en'}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>

        <Translator />
      </div>
    )
  }
}

// export default TermsConditions;
