// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Backdrop_Backdrop__3j6VK {\n    width: 100%;\n    height: 100%;\n    position: fixed;\n    z-index: 100;\n    left: 0;\n    top: 0;\n    background-color: rgba(0, 0, 0, 0.5);\n}\n", ""]);
// Exports
exports.locals = {
	"Backdrop": "Backdrop_Backdrop__3j6VK"
};
module.exports = exports;
