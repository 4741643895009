import React, {Component} from 'react'
import {withTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import axios from '../../../axios'
import * as actions from '../../../store/actions'
import SimpleSnackbar from '../../../components/Snackbar/Snackbar'
import classes from './Dashboard.module.css'
import {
  OrderDesktopView,
  OrderMobileView,
  ChatList,
  ProfileForSeller,
} from './Items'

class SellerDashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      canceled: false,
      billing_info: '',
      error: '',
      isLoading: false,
      isOrderLoading: false,
      latestOrders: [],
      // checkAccount: '',
      accountBalance: 0,
    }
  }

  componentDidMount() {
    if (!this.props.isAuthenticated) {
      this.props.history.push('/login')
    }
    window.scroll(0.0, 0.0)

    this.setState({isLoading: true})
    this.props.getPaymentInfo()
    this.props.getChatUsers()
    this.getActualBalance()
    this.props.checkHeHasPartner()

    if (this.props.location.search) {
      const search = this.props.location.search // could be '?foo=bar'
      // console.log(search, 'search')
      // const params = new URLSearchParams(search)
      const completeUrl = 'http://localhost:3000/' + search
      const params = new URL(completeUrl).searchParams
      let canceled = params.get('canceled')
      let mm = params.get('mm')
      let priceId = params.get('priceId')
      let packageId = params.get('packageId')

      if (params.get('hasPartner')) {
        this.props.uploadPaymentData({
          packageId: packageId,
          priceId: 'free',
          customer: null,
          currency: 'cad',
          payment_status: 'paid',
          setup_intent: null,
          shipping: null,
          subscription: null,
          amount_discount: '0',
          amount_tax: '0',
          customer_email: this.props.user.email,
          sessionId: null,
        })
      } else {
        if (canceled && mm === 'cancel') {
          this.setState({canceled: !this.state.canceled})
          this.setState({isLoading: false})
        } else {
          let session_id = params.get('session_id')

          // this.fetchSuccessData(session_id, priceId, packageId)
          // if( params.get('hasPartner'))
          this.saveToDatabase(session_id, priceId, packageId)
          this.props.getPaymentInfo()
          this.setState({isLoading: false})
          // this.props.history.replace('/seller/dashboard')
        }
      }
      this.props.history.replace('/seller/dashboard')
      setTimeout(() => {
        window.location.reload()
      }, 2000)
    }

    this.setState({isLoading: false})
    this.getLatestOrders(this.props.user.role)
  }

  getLatestOrders = async (role) => {
    this.setState({isOrderLoading: true})
    axios
      .get(`/stripe/getLatestOrders?userRole=${role}`, {
        headers: this.tokenHeadersNormal().headers,
      })
      .then((latestOrders) =>
        this.setState({
          isOrderLoading: false,
          latestOrders: latestOrders.data.orders,
        }),
      )
      .catch((error) => this.setState({error: error.message}))
  }

  tokenHeadersNormal = () => {
    const headersNormal = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    const token = this.props.token
    if (token) {
      headersNormal.headers['x-auth-header'] = token
    }
    return headersNormal
  }

  fetchSuccessData = async (sessionId) => {
    const res = await axios({
      method: 'GET',
      url: '/stripe/checkout-session?sessionId=' + sessionId,
      headers: this.tokenHeadersNormal().headers,
    })
    return res.data.session
  }

  getActualBalance = () => {
    const url = '/stripe/actualBalance'
    axios
      .get(url, {headers: this.tokenHeadersNormal().headers})
      .then((response) => {
        this.setState({
          accountBalance:
            response.data.message === 'ok' && response.data.totalBalance,
        })
      })
      .catch((err) => console.log(err, 'getActualBalance'))
  }

  postCustomerPortal = async () => {
    const currentUrl = window.location.href
    const data = {domainURL: currentUrl.split('/s')[0]}
    const res = await axios({
      method: 'POST',
      data: data,
      url: '/stripe/customerPortal',
      headers: this.tokenHeadersNormal().headers,
    })
    return res.data.session
  }

  customerPortal = async (event) => {
    event.preventDefault()
    this.setState({isLoading: true})
    let cc = ''
    try {
      cc = await this.postCustomerPortal()
      window.location.href = cc.url
      this.setState({isLoading: false})
    } catch (e) {
      this.setState({isLoading: false})
    }
    this.setState({isLoading: false})
  }

  saveToDatabase = async (sessionId, priceId, packageId) => {
    let cc
    try {
      cc = await this.fetchSuccessData(sessionId)
    } catch (e) {
      console.log(e, 'save-to-database')
    }
    if (cc) {
      const {
        customer,
        currency,
        customer_details,
        id,
        payment_status,
        setup_intent,
        subscription,
        total_details,
        shipping,
      } = cc
      const {email} = customer_details
      const {amount_discount, amount_tax} = total_details
      this.props.uploadPaymentData({
        packageId: packageId,
        priceId: priceId,
        customer: customer,
        currency: currency,
        payment_status: payment_status,
        setup_intent: setup_intent,
        shipping: shipping,
        subscription: subscription,
        amount_discount: amount_discount,
        amount_tax: amount_tax,
        customer_email: email,
        sessionId: id,
      })
    }
  }

  renderSingle = (single) => {
    const {t} = this.props
    return (
      <Link to="/subscription-packages" style={{textDecoration: 'none'}}>
        <div className="mt-1 mb-1">
          <div className={classes.hoverChange}>
            <div className="row justify-content-center">
              <div>
                <div className="p-3" style={{color: 'black'}}>
                  <div className="text-primary pb-1">
                    {single.packageId.type ? single.packageId.type : ''}
                  </div>
                  <div className="pb-1">{single.packageId.title}</div>
                  <div className="pb-1">
                    {t('Dashboard:subscriptions.0')}
                    <span className="badge bg-primary ms-2">
                      {single.status}
                    </span>
                  </div>
                  <div className="pb-1">
                    {t('Dashboard:subscriptions.1')}
                    <span className="badge bg-primary ms-2">
                      {single.packageId.validity}{' '}
                      {t('Dashboard:subscriptions.2')}
                    </span>
                  </div>
                  <div className="pb-1">
                    {t('Dashboard:subscriptions.3')}
                    <span className="badge bg-primary ms-2">
                      {single.updatedAt
                        ? single.updatedAt.split('T')[1].split('.')[0]
                        : ''}
                    </span>
                    <span> {t('Dashboard:subscriptions.8')} </span>
                    <span className="badge bg-primary ms-2">
                      {single.updatedAt ? single.updatedAt.split('T')[0] : ''}
                    </span>
                  </div>

                  <div className="pb-1">
                    {t('Dashboard:subscriptions.4')}
                    <span className="badge bg-primary ms-2">
                      {single.updatedAt
                        ? single.updatedAt.split('T')[1].split('.')[0]
                        : ''}
                    </span>
                    <span> {t('Dashboard:subscriptions.8')} </span>
                    {single.updatedAt && (
                      <span className="badge bg-primary ms-2">
                        {parseInt(
                          single.updatedAt.split('T')[0].split('-')[0],
                        ) + parseInt(single.packageId.validity)}
                        {'-' +
                          single.updatedAt.split('T')[0].split('-')[1] +
                          '-' +
                          single.updatedAt.split('T')[0].split('-')[2]}
                      </span>
                    )}
                  </div>
                  <div className="pb-1">
                    {t('Dashboard:subscriptions.5')}
                    {this.props.hasPartner &&
                    single.packageId.type === 'TESTER' ? (
                      <span className="badge bg-primary ms-2">0</span>
                    ) : (
                      <span className="badge bg-primary ms-2">
                        {single.packageId.price ? single.packageId.price : ''}
                      </span>
                    )}
                  </div>

                  <div className="pb-1">
                    {t('Dashboard:subscriptions.6')}
                    <span className="badge bg-primary ms-2">
                      {single.paymentId.payment_status
                        ? single.paymentId.payment_status
                        : ''}
                    </span>
                  </div>

                  <div>
                    <span>{t('Dashboard:subscriptions.7')}</span>
                    <div>
                      <ul style={{listStyleType: 'disc'}}>
                        {single.packageId.privileges.map((gg, index) => {
                          return (
                            <li key={index} className="ps-2 pt-2">
                              {gg}
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    )
  }

  renderSubscribedPackages = () => {
    const paymentData = this.props.payment.data
    const {t} = this.props

    return (
      <div>
        {paymentData.length > 0 ? (
          paymentData.map((single) => {
            return (
              <div key={single._id} className="mt-1 mb-2">
                {this.renderSingle(single)}
              </div>
            )
          })
        ) : (
          <div className="mt-2 mb-2">
            {t('Dashboard:DashboardNavigation.15')}
          </div>
        )}
      </div>
    )
  }

  render() {
    let {canceled, accountBalance} = this.state
    let messageShouldShow = ''
    if (canceled) {
      messageShouldShow =
        'Subscription gets cancelled by clicking Cancel or internet connection. :)'
    }
    const {t} = this.props
    // console.log(this.props.payment, 'props')
    return (
      <div className={classes.Dashboard}>
        <div className={classes.DashboardMain}>
          <div className={classes.PartLeft}>
            <ProfileForSeller
              user={this.props.user}
              accountBalance={accountBalance}
            />
            {!this.props.user.is_subscribed
              ? !this.props.user.is_subscribed && (
                  <div className="mt-3 mb-3">
                    <Link
                      to="/subscription-packages"
                      className="btn btn-sm btn-primary form-control form-control-sm">
                      {/* {t('Dashboard:orders.4')} */}
                    </Link>
                  </div>
                )
              : null}
            <ChatList
              chats={this.props.chat}
              screenWidth={this.props.screenWidth}
            />
          </div>
          <div className={classes.PartRight}>
            <h5>{t('Dashboard:orders.5')}</h5>
            {this.state.latestOrders.length === 0 && (
              <div className="mb-3 mt-2">{t('Dashboard:orders.9')}</div>
            )}
            {/* <div className={classes.OrdersInfo}>
            <div className="container">
              <div className="row justify-content-between align-items-center">
                <div className="col-4 col-sm-6">
                  <div className="d-flex align-items-center">
                    <h6>Active Orders</h6>
                    <h6 className="text-muted ml-1">-2 ($45)</h6>
                  </div>
                </div>
                <div className="col-4 col-sm-6">
                  <select
                    class="form-select"
                    aria-label="Default select example">
                    <option selected>Active order(2)</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
              </div>
            </div>
          </div> */}
            {this.props.screenWidth > 950 ? (
              <OrderDesktopView
                orderManage={'buyer'}
                latestOrders={this.state.latestOrders}
                isLoading={this.state.isLoading}
              />
            ) : (
              <OrderMobileView
                orderManage={'buyer'}
                screenWidth={this.props.screenWidth}
                latestOrders={this.state.latestOrders}
                isLoading={this.state.isLoading}
              />
            )}
            <h5>{t('Dashboard:orders.7')}</h5>
            {canceled && (
              <SimpleSnackbar
                open={true}
                message={messageShouldShow}
                type="error"
              />
            )}
            {/* {isLoading && <SpinnerAbsolute />} */}
            <div>{this.renderSubscribedPackages()}</div>
            <div className="mt-3 mb-3">
              <button
                className="btn btn-primary form-control"
                onClick={(event) => this.customerPortal(event)}>
                {t('Dashboard:orders.6')}
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userAuth.user,
    isAuthenticated: state.userAuth.isAuthenticated,
    token: state.userAuth.token,
    payment: state.payment,
    screenWidth: state.responsiveness.screenWidth,
    chat: state.chat.users,
    hasPartner: state.partner.hasPartner,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getPaymentInfo: () => dispatch(actions.getPaymentInfo()),
    uploadPaymentData: (data) => dispatch(actions.uploadPaymentData(data)),
    getChatUsers: () => dispatch(actions.getChatUsers()),
    checkHeHasPartner: () => dispatch(actions.checkHeHasPartner()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation(['Dashboard'])(SellerDashboard))

// http://localhost:3000/profile_dashboard?session_id=cs_test_a1Pqn4oCkE9lH64nzxr0XO4rpqYQgBCgSlBbU9doRqOmtzelatTn0WuGej&packageId=5f85db942304300c2a9456e4&priceId=price_1INRuJCJ19dG8DzbWiuidz8X
