import React, {useState, useEffect} from 'react'
import {Collapse} from 'reactstrap'
import Translation from '../../../shared/Translate'
import classes from './CollapsiblePanel.module.css'
import {Translate} from 'react-auto-translate'

function CollapsiblePanel({children, ...props}) {
  const {title, index, collapse} = props
  const [isCollapse, setIsCollapse] = useState(collapse)
  // const [icon, setIcon] = useState("fa fa-chevron-down");
  const toggle = () => {
    setIsCollapse(!isCollapse)
    // setIcon(state => {
    //   return state === "fa fa-chevron-down"
    //     ? "fa fa-chevron-right"
    //     : "fa fa-chevron-down";
    // });
  }

  const animate = (collapse) => {
    setIsCollapse(collapse)
    // setIcon(state => {
    //   return state === "fa fa-chevron-down"
    //     ? "fa fa-chevron-right"
    //     : "fa fa-chevron-down";
    // });
  }

  useEffect(() => {
    animate(!collapse)
  }, [collapse])

  return (
    <div className={classes.CollapsiblePanel}>
      <button
        type="button"
        className={classes.CollapsiblePanel__button}
        onClick={() => toggle()}>
        <div className={classes.Question}>
          <Translation
            from={'fr'}
            text={`${index + 1}. ${title}`}
            input={<Translate>{`${index + 1}. ${title}`}</Translate>}
          />
          <span>
            <i
              className={
                isCollapse
                  ? 'fa fa-lg fa-chevron-down'
                  : 'fa fa-lg fa-chevron-right'
              }></i>
          </span>
        </div>
      </button>
      <Collapse className={classes.Text} isOpen={isCollapse}>
        {children}
      </Collapse>
    </div>
  )
}

CollapsiblePanel.defaultProps = {
  children: 'Add node as a child',
  title: 'Collapsible Panel',
  collapse: true,
}

export default CollapsiblePanel
