// import png from './png.png';
// import jpg from './jpg.png';
// import gif from './gif.png';
// import pdf from './pdf.png';
// import zip from './zip.png';
// import doc from './doc.png';
// import mp4 from './mp4.png';
// import xls from './xls.png';
// import xlsx from './xls.png';
// import csv from './csv.png';
import audio from './audio.png';
import video from './video.png';
import document from './document.png';

export { audio, video, document };