import React from 'react'
import classes from './StartSelling.module.css'
import {Divider} from '../../../components/UI/Spacing'
import {Translate} from 'react-auto-translate'
import Translation from '../../../shared/Translate'

export const MediaItems = ({image, heading, desc, color}) => (
  <div
    className={classes.UserReviewsItem}
    style={{borderTop: `4px solid ${color}`}}>
    <div className={classes.UserReviewsItemImage}>
      <img src={image} alt={heading} style={{border: `2px solid ${color}`}} />
    </div>
    <Divider orientation="v" space="15" />
    <h5>
      {/* {heading}{' '} */}
      <Translation
        from={'en'}
        text={heading}
        input={<Translate>{heading}</Translate>}
      />
    </h5>
    <Divider orientation="v" space="1" />
    <div className={classes.Reviews}>
      <span>
        {/* {desc}{' '} */}
        <Translation
          from={'en'}
          text={desc}
          input={<Translate>{desc}</Translate>}
        />
      </span>
    </div>
  </div>
)
