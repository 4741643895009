import React from "react";
import { Avatar } from "./Avatar";
import { getCurrentDate } from '../../../../shared/utility';


const ChatListItems = (props) => {
  const { animationDelay, isOnline, mainUser, currentUser, screenWidth } = props;
  const { name, profile_pic, lastMessage, sender, mood, readMessage, files } = props.data;

  let filesInfo = files && files.length > 0 ? files : [];

  let message = lastMessage && lastMessage.length > 15 ? lastMessage.substr(0, screenWidth < 800 ? 25 : screenWidth > 1400 ? 25 : 20) : lastMessage;

  return <div
    style={{ animationDelay: `0.${ animationDelay }s` }}
    onClick={e => { props.onContactSelect(props.data); props.onOpenOrHide() }}
    className={`chatlist__item ${ currentUser && props.data.id === currentUser.id ? 'active' : ""
      } `}
  >
    <Avatar
      image={
        profile_pic ? profile_pic : "http://placehold.it/80x80"
      }
      isOnline={isOnline}
    />
    <div className="userMeta">
      <p>{name}</p>
      {sender !== mainUser.email ?
        <span className={`${ readMessage ? 'isRead' : 'isNotRead' }`}>{message ? message : filesInfo.length > 0 ? `You received file${ filesInfo.length > 1 ? 's' : '' }` : 'You received an order'}<span className="text-primary fw-bold mx-1">.</span><span className="text-secondary">{getCurrentDate(mood)}</span></span>
        :
        <span className={`${ readMessage ? 'isRead' : 'isNotRead' }`} >{message ? message : filesInfo.length > 0 ? `You sent file${ filesInfo.length > 1 ? 's' : '' }` : 'You created an order'}<span className="text-primary fw-bold mx-1">.</span><span className="text-secondary">{getCurrentDate(mood)}</span></span>
      }
    </div>
  </div>
}

export default ChatListItems;