import React, { Component } from 'react';
import classes from './VerifyEmail.module.css';
import axios from '../../../axios';
import { connect } from 'react-redux';
import SimpleSnackbar from '../../../components/Snackbar/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from 'react-router-dom';

class VerifyEmail extends Component {
    state = {
        loading: false,
        message: '',
        isClicked: false,
        error: false,
        code: '',
        is_verified: false
    }

    handleVerifyClick = () => {
        this.setState({ loading: true, message: '' });
        axios.get('/user/verify-email', { headers: this.tokenHeadersNormal().headers }
        ).then(res => {
            this.setState({ loading: false, message: res.data.message, isClicked: true, });
        }).catch(error => {
            this.setState({ loading: false, message: error.message, error: true });
        });
    }

    onSubmitCode = (e) => {
        e.preventDefault();
        this.setState({ loading: true, message: '', code: '' });

        axios.get(`/user/check-code?verificationCode=${ this.state.code }`, { headers: this.tokenHeadersNormal().headers }
        ).then(res => {
            this.setState({ loading: false, message: res.data.message, is_verified: res.data.is_verified });
        }).catch(error => {
            this.setState({ loading: false, message: error.message, error: true });
        });
    }

    onChangeCode = (event) => this.setState({ code: event.target.value });

    tokenHeadersNormal = () => {
        const headersNormal = {
            headers: {
                'Content-Type': 'application/json',
            },
        }
        const token = this.props.token
        if (token) {
            headersNormal.headers['x-auth-header'] = token;
        }
        return headersNormal;
    };

    render() {
        return <div className={classes.VerifyEmail}>
            {this.state.message && !this.state.error &&
                <SimpleSnackbar open={true} message={this.state.message} type='success' height='40' />}
            {this.state.message && this.state.error &&
                <SimpleSnackbar open={true} message={this.state.message} type='error' height='40' />}

            <div className={classes.VerifyEmailItem}>
                <h6 className='text-primary text-center py-2'>Email Verification</h6>
                <div className='d-flex py-5 justify-content-center align-items-center'>
                    {this.state.loading ? <CircularProgress /> : this.state.isClicked && !this.state.is_verified ? <form className='w-75' onSubmit={this.onSubmitCode}>
                        <div className="form-group my-2">
                            <label htmlFor="code" className='my-2'>Give Pass code</label>
                            <input
                                onChange={this.onChangeCode}
                                type="text"
                                value={this.state.code}
                                className="form-control"
                                id="code" placeholder="Code" />
                        </div>
                        <div className='d-flex py-4 align-items-center'>
                            <button
                                type="submit"
                                onSubmit={this.onSubmitCode}
                                className="btn btn-primary mt-4"
                            >Submit</button>
                            <button
                                onClick={this.handleVerifyClick}
                                type="button"
                                className="btn btn-outline-primary mx-2 mt-4 fw-bold"
                            >Resend</button>
                        </div>
                    </form> : this.state.is_verified ?
                        <Link to='/profile'>
                            <div
                                type="submit"
                                className="btn btn-primary mt-4"
                            >Back to Profile</div>
                        </Link> : <button
                            onClick={this.handleVerifyClick}
                            type="button"
                            className="btn btn-primary mt-3 w-50 fw-bold">
                            Click to verify email
                        </button>}
                </div>
            </div>
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        token: state.userAuth.token,
    }
};

export default connect(mapStateToProps, null)(VerifyEmail);
