import React, { Component } from 'react';
import classes from '../CreateAdd.module.css';
import Button from '../../../../components/UI/Button/Button';
import StepProgress from './StepProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import SimpleSnackbar from '../../../../components/Snackbar/Snackbar';

const LinearProgressWithLabel = (props) =>
    <Box display="flex" alignItems="center">
        <Box width="100%" mr={1}>
            <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box minWidth={35}>
            <Typography variant="body2" color="textSecondary"> {props.value}% </Typography>
        </Box>
    </Box>

export class Step8 extends Component {
    state = { copySuccess: '' };

    copyToClipboard = (event) => {
        this.textArea.select();
        document.execCommand('copy');
        event.target.focus();
        this.setState({ copySuccess: 'Copied!' });
    };

    render() {
        const { t } = this.props;
        if (this.props.shortId) {
            localStorage.removeItem('adsData');
        };

        return <React.Fragment>
            <StepProgress step={this.props.step} />
            {this.props.message && !this.props.error &&
                <SimpleSnackbar open={true} message={this.props.message} type='success' height='40' />}
            {this.props.message && this.props.error &&
                <SimpleSnackbar open='true' message={this.props.message} type='error' height='50' />}
            {this.props.loading ? <LinearProgressWithLabel value={this.props.loading_percentage} /> :
                <React.Fragment>
                    {
                        this.props.loading_percentage ? <LinearProgressWithLabel value={this.props.loading_percentage} /> :
                            <div className={classes.Congratulations}>
                                <p>"" {t('MyAds:CreateAdd.Step7.0')} ""</p>
                                <br />
                                <h6>{t('MyAds:CreateAdd.Step7.1')}</h6>
                                <div>
                                    {
                                        document.queryCommandSupported('copy') &&
                                        <div>
                                            <Button
                                                buttonType='Submit'
                                                clicked={this.copyToClipboard}>{t('MyAds:CreateAdd.Step7.2')}</Button>
                                            <p>{this.state.copySuccess}</p>
                                        </div>
                                    }
                                    <textarea
                                        className='form-control'
                                        readOnly
                                        ref={(textarea) => this.textArea = textarea}
                                        value={this.props.shortId ? `https://www.umediad.com/adInfo?ad_id=${ this.props.shortId}` : null}
                                    />
                                </div>
                            </div>
                    }
                    <div className={classes.Divider}></div>
                    <div className={classes.StepsButton}>
                        <Button
                            buttonType='Submit'
                            clicked={() => this.props.history.goBack()}
                        >{t('MyAds:CreateAdd.Step7.3')}</Button>
                    </div>
                </React.Fragment>
            }
        </React.Fragment>
    }
}

export default Step8;
