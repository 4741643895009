import React from 'react';
import { withTranslation } from 'react-i18next'
import classes from './MyAdds.module.css';
import Button from '../../../components/UI/Button/Button';
import { Add } from '@material-ui/icons';
import { Modal, ConfirmModal } from '../../../components/UI/Modal';
import ViewAdvertise from './ViewAdvertise';
import { Fade } from 'react-reveal';
import * as actions from '../../../store/actions';
import { connect } from 'react-redux';
import { Spinner } from '../../../components/UI/Spinner/Spinner';
import { DataLoadError } from '../../../components/UI/DataLoadError';
import { getDate } from '../../../shared/utility';
import { Link } from 'react-router-dom';
import axios from '../../../axios';
import SimpleSnackbar from '../../../components/Snackbar/Snackbar';
import styles1 from '../../NormalUser/Tender/Tender.module.css';

class MyAdds extends React.Component {
    state = {
        showModal: false,
        showSpinner: true,
        advertiseIndex: null,
        deleteModal: false,
        advertiseId: null,
        error: false,
        message: ''
    }
    
    openModal = (index) => this.setState({ showModal: true, advertiseIndex: index, });
    
    closeModal = () => this.setState({ showModal: false, deleteModal: false });

    componentDidMount() {
        this.props.fetchUserAdvertises();

        setTimeout(() => {
            this.setState({ showSpinner: false });
        }, 20000);
    }

    showDeleteAd = (advertiseId) => {
        this.setState({ deleteModal: true, advertiseId })
    }

    deleteAd = () => {
        this.setState({ deleteModal: false });
        if (this.state.advertiseId) {
            axios.delete(
                `/dashboardAdvertise/deleteAdvertiseAdvertise?advertiseId=${this.state.advertiseId}`,
                { headers: this.tokenHeadersNormal().headers }
            ).then(response => {
                this.setState({ message: response.data.message });
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            }).catch(error => this.setState({ error: true, message: error.response.data.message }));
        }
    }

    tokenHeadersNormal = () => {
        const headersNormal = {
            headers: { 'Content-Type': 'application/json' },
        }
        const token = this.props.token;
        if (token) headersNormal.headers['x-auth-header'] = token;
        return headersNormal;
    }
    
    render() {
        const { t } = this.props;
        let mobileAdvertisesItemsList = <React.Fragment>
            {this.state.showSpinner ?
                <Spinner />
                :
                <DataLoadError text='There are no advertises created yet or an error created.Try again later :)' />}
        </React.Fragment>
        if (this.props.advertisesData) {
            mobileAdvertisesItemsList = <React.Fragment>
                {
                    this.props.advertisesData.map((advertise, index) =>
                        <Fade bottom delay={200 * (index + 1)} key={index}>
                            <div className={classes.TableSingleRowItemColumnForMobile}>
                                <div className={classes.TableSingleRowItemForMobile}>
                                    <div className={classes.TableSingleRowItemForMobileLabel}>
                                        <p>{t('MyAds:MyAdds.columnNames.0')}</p>
                                    </div>
                                    <div className={classes.DividerH}></div>
                                    <div className={classes.TableSingleRowItemForMobileValue}><p>{index + 1}</p></div>
                                </div>
                                <div className={classes.DividerV}></div>
                                <div className={classes.TableSingleRowItemForMobile}>
                                    <div className={classes.TableSingleRowItemForMobileLabel}>
                                        <p>{t('MyAds:MyAdds.columnNames.1')}</p>
                                    </div>
                                    <div className={classes.DividerH}></div>
                                    <div className={classes.TableSingleRowItemForMobileValue}>
                                        <p>{advertise.title}</p>
                                    </div>
                                </div>
                                <div className={classes.DividerV}></div>
                                <div className={classes.TableSingleRowItemForMobile}>
                                    <div className={classes.TableSingleRowItemForMobileLabel}>
                                        <p>{t('MyAds:MyAdds.columnNames.2')}</p>
                                    </div>
                                    <div className={classes.DividerH}></div>
                                    <div className={classes.TableSingleRowItemForMobileValue} dangerouslySetInnerHTML={{ __html: advertise.description.trim().substr(0, 150) + '...' }} />
                                </div>
                                <div className={classes.DividerV}></div>
                                <div className={classes.TableSingleRowItemForMobile}>
                                    <div className={classes.TableSingleRowItemForMobileLabel}>
                                        <p>{t('MyAds:MyAdds.columnNames.3')}</p>
                                    </div>
                                    <div className={classes.DividerH}></div>
                                    <div className={classes.TableSingleRowItemForMobileValue}>
                                        <div className={styles1.MediaItem}>
                                            {advertise.types.map((type, index) => (
                                                <span key={index}>
                                                    {type}
                                                </span>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.DividerV}></div>
                                <div className={classes.TableSingleRowItemForMobile}>
                                    <div className={classes.TableSingleRowItemForMobileLabel}>
                                        <p>{t('MyAds:MyAdds.columnNames.4')}</p>
                                    </div>
                                    <div className={classes.DividerH}></div>
                                    <div className={classes.TableSingleRowItemForMobileValue}>
                                    <div className={styles1.MediaItem}>
                                            {advertise.subtypes.map((subtype, index) => (
                                                <span key={index}>
                                                    {subtype}
                                                </span>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.DividerV}></div>
                                <div className={classes.TableSingleRowItemForMobile}>
                                    <div className={classes.TableSingleRowItemForMobileLabel}>
                                        <p>{t('MyAds:MyAdds.columnNames.5')}</p>
                                    </div>
                                    <div className={classes.DividerH}></div>
                                    <div className={classes.TableSingleRowItemForMobileValue}><p>{advertise.packages.length}</p></div>
                                </div>
                                <div className={classes.DividerV}></div>
                                <div className={classes.TableSingleRowItemForMobile}>
                                    <div className={classes.TableSingleRowItemForMobileLabel}>
                                        <p>{t('MyAds:MyAdds.columnNames.6')}</p>
                                    </div>
                                    <div className={classes.DividerH}></div>
                                    <div className={classes.TableSingleRowItemForMobileValue}>
                                        <button
                                            className='btn btn-warning btn-sm'
                                            onClick={() => this.openModal(index)}>
                                            <i className="fas fa-eye"></i>
                                        </button>
                                        <Link to={`edit-advertise?adId=${advertise._id}`}>
                                            <button
                                                className='btn btn-primary btn-sm'>
                                                <i className="fas fa-pencil-alt"></i>
                                            </button>
                                        </Link>
                                        <button
                                            className='btn btn-danger btn-sm'
                                            onClick={(_) => this.showDeleteAd(advertise._id)}>
                                            <i className="fas fa-trash-alt"></i>
                                        </button>
                                    </div>
                                </div>
                                <div className={classes.DividerV}></div>
                                <div className={classes.TableSingleRowItemForMobile}>
                                    <div className={classes.TableSingleRowItemForMobileLabel}>
                                        <p>{t('MyAds:MyAdds.columnNames.7')}</p>
                                    </div>
                                    <div className={classes.DividerH}></div>
                                    <div className={classes.TableSingleRowItemForMobileValue}><p>{getDate(advertise.createdAt)}</p></div>
                                </div>
                                <div className={classes.DividerV}></div>
                                <div className={classes.TableSingleRowItemForMobile}>
                                    <div className={classes.TableSingleRowItemForMobileLabel}>
                                        <p>{t('MyAds:MyAdds.columnNames.8')}</p>
                                    </div>
                                    <div className={classes.DividerH}></div>
                                    <div className={classes.TableSingleRowItemForMobileValue}><p>{getDate(advertise.createdAt)}</p></div>
                                </div>
                            </div>
                        </Fade>
                    )}
            </React.Fragment>
        }

        return <React.Fragment>
            {this.state.message && !this.state.error &&
                <SimpleSnackbar open={true} message={this.state.message} type='success' />}
            {this.state.message && this.state.error &&
                <SimpleSnackbar open='true' message={this.state.message} type='error' />}
            <React.Fragment>
                <div className={classes.MyAddsItems}>
                    <Link to='/create-new-ad'>
                        <Button buttonType='Common'>
                            <div className={classes.CreateAddButton}>
                                <Add /> {t('MyAds:CreateAdd.title1')}
                            </div>
                        </Button>
                    </Link>
                </div>
                {mobileAdvertisesItemsList}
            </React.Fragment>
            <Modal type='normal' show={this.state.showModal}
                modalClosed={this.closeModal}>
                <ViewAdvertise
                    t={t}
                    advertise={this.props.advertisesData ? this.props.advertisesData[this.state.advertiseIndex] : []}
                    advertiseIndex={this.props.advertisesData ? this.state.advertiseIndex : null}
                    closed={this.closeModal} />
            </Modal>
            <div>
                <ConfirmModal show={this.state.deleteModal}
                    modalClosed={this.closeModal}>
                    <div className={classes.ConfirmWindow}>
                        <h2 className='my-2'>Are you sure?</h2>
                        <span className='my-2'>You want to delete this ad? If you delete the ad,the related resources also would be deleted.</span>
                        <div className='py-4'></div>
                        <div className='d-flex justify-content-center my-2'>
                            <button
                                onClick={this.closeModal}
                                type='button'
                                className='btn btn-primary mx-1 w-50'
                            >No</button>
                            <button
                                onClick={this.deleteAd}
                                type='button'
                                className='btn btn-danger mx-1  w-50'
                            >Yes, Delete the Ad</button>
                        </div>
                    </div>
                </ConfirmModal>
            </div>

        </React.Fragment>
    }
};
const mapStateToProps = (state) => {
    return {
        loading: state.dashboardAdvertise.loading,
        error: state.dashboardAdvertise.error,
        message: state.dashboardAdvertise.message,
        advertisesData: state.dashboardAdvertise.userCreatedAdvertises,
        user: state.userAuth.user,
        token: state.userAuth.token,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchUserAdvertises: () => dispatch(actions.fetchUserAdvertises()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(['translation', 'MyAds'])(MyAdds));
