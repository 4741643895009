// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ChatHome_ChatHome__3ccrF {\n    width: 100%;\n    height: 100%;\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    padding-top: 70px;\n}\n\n.ChatHome_ChatList__1jagw {\n    width: 100%;\n    margin: 15px 20px;\n}\n\n.ChatHome_ChatBody__2fa0r {\n    visibility: hidden;\n    margin: 15px 0px;\n    width: 100%;\n    display: none;\n}\n\n@media only screen and (min-width: 800px) and (max-width: 1200px) {\n    .ChatHome_ChatList__1jagw {\n        width: 350px;\n        margin: 15px 20px;\n    }\n\n    .ChatHome_ChatBody__2fa0r {\n        width: 65%;\n        margin: 15px 15px;\n        visibility: visible;\n        display: block;\n    }\n}\n\n@media only screen and (min-width: 1200px) {\n    .ChatHome_ChatHome__3ccrF {\n        padding-top: 75px;\n    }\n\n    .ChatHome_ChatList__1jagw {\n        width: 25%;\n        margin: 15px 30px;\n    }\n\n    .ChatHome_ChatBody__2fa0r {\n        width: 50%;\n        margin: 15px 0px;\n        visibility: visible;\n        display: block;\n    }\n\n    .ChatHome_ChatProfile__21vXW {\n        width: 25%;\n        margin: 15px 30px;\n    }\n}\n\n@media only screen and (min-width: 1500px) {\n    .ChatHome_ChatHome__3ccrF {\n        width: 1500px;\n        margin: 0 auto;\n    }\n}", ""]);
// Exports
exports.locals = {
	"ChatHome": "ChatHome_ChatHome__3ccrF",
	"ChatList": "ChatHome_ChatList__1jagw",
	"ChatBody": "ChatHome_ChatBody__2fa0r",
	"ChatProfile": "ChatHome_ChatProfile__21vXW"
};
module.exports = exports;
