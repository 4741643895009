import axios from 'axios';

let instance = axios.create({
    //local_baseurl
    // baseURL: 'http://localhost:8888'
    // global_baseurl
    baseURL: 'https://api.umediad.com'
});

export default instance;
