import axios from '../../../axios'
import { fetchError, fetchStart, fetchSuccess } from './Common'
import * as actionTypes from '../ActionTypes'
import { tokenHeadersNormal } from '../HeaderConfig'


export const getChatUsers = () => {
  return (dispatch, getState) => {
    const url = '/chat/allInboxes'
    dispatch(fetchStart());

    axios
      .get(url, {
        headers: tokenHeadersNormal(getState).headers,
      })
      .then((data) => {
        if (data.status === 200) {
          dispatch(fetchSuccess())
          dispatch({
            type: actionTypes.SET_CHAT_USERS,
            payload: {
              users: data.data.data,
            },
          })
        } else {
          dispatch(fetchError('Something went wrong'))
        }
      })
  }
}

export const getAllUnreadMessages = () => {
  return (dispatch, getState) => {
    if (!getState().userAuth.token) return;
    const url = '/chat/unreadMessages';
    dispatch(fetchStart())
    axios
      .get(url, {
        headers: tokenHeadersNormal(getState).headers,
      })
      .then((data) => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({
            type: actionTypes.UNREAD_MESSAGES,
            payload: {
              unread: data.data.allUnread,
            },
          })
        } else {
          dispatch(fetchError('Something went wrong'))
        }
      }).catch(error => console.log(error));
  }
}


export const getConversation = (data, user) => {
  return (dispatch, getState) => {
    const url = '/chat/allMessages'
    dispatch(fetchStart())
    axios
      .post(url, data, { headers: tokenHeadersNormal(getState).headers })
      .then((info) => {
        if (info.status === 200) {
          dispatch(fetchSuccess())
          dispatch({
            type: actionTypes.SET_CONVERSATION_DATA,
            payload: {
              messages: info.data.messages,
              user: user,
            },
          })
        } else {
          dispatch(fetchError('Something went wrong'))
        }
      })
  }
}

export const onUserSelect = (user) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_CURRENT_USER,
      payload: user,
    })
  }
}

export const sendTextMessage = (message, files, user, currentUser) => {
  return (dispatch, getState) => {
    const url = '/chat/singleChat';

    if (user && currentUser) {
      let objectData = {}
      objectData['fromEmail'] = user.email;
      objectData['toEmail'] = currentUser.email;
      objectData['message'] = message.trim();
      objectData['files'] = files;
      dispatch(fetchStart());

      axios
        .post(url, objectData, { headers: tokenHeadersNormal(getState).headers })
        .then((data) => {
          if (data.status === 200) {
            dispatch(fetchSuccess())
            dispatch({
              type: actionTypes.SEND_NEW_CHAT_MESSAGE,
              payload: message,
            })
          } else {
            dispatch(fetchError('Something went wrong'))
          }
        }).catch(e => {
          console.log(e.message);          
        });
    }
  }
}

export const readLastMessage = (data) => {
  return (dispatch, getState) => {
    const url = '/chat/editLastMessage'
    axios
      .post(url, data, { headers: tokenHeadersNormal(getState).headers })
      .then((message) => {
        if (message.status !== 200) {
          dispatch(fetchError('Something went wrong'))
        }
        // else {
        //   dispatch(fetchError('Something went wrong'))
        // }
      })
  }
}

export const sendMediaMessage = (file) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SEND_NEW_MEDIA_MESSAGE,
      payload: file,
    })
  }
}
