import React from 'react';
// import {useSelector} from 'react-redux'
import { withWidth } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CmtList from '../coremat/CmtList';
import ChatUserCell from './ChatUserCell';
// import PerfectScrollbar from 'react-perfect-scrollbar';
import { getChatSidebarHeight } from '../coremat/AppConstants';
import NoRecordFound from './NoRecordFound';
import socket from '../../../../config/socket';

const useStyles = makeStyles((theme) => ({
  chatCellHeader: {
    // backgroundColor: theme.palette.common.dark,
    borderBottom: `1px solid #eee`,
    padding: 16,
    fontSize: 10,
    textTransform: 'uppercase',
    color: theme.palette.text.secondary,
    letterSpacing: 1.5,
  },
  perfectScrollbarRoot: {
    height: (props) => `calc(110vh - ${ props.height }px)`,
    overflow: 'auto',
  },
  perfectScrollbarRootForSmall: {
    height: (props) => `calc(100vh - ${ props.height }px)`,
    overflow: 'auto',
  },
}))

class ChatUserListClass extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      users: [],
    }
  }

  componentDidMount() {
    socket.on('connect', () => {
      var allUsers = [...this.state.users];

      allUsers = allUsers.map((user) => {
        if (user.self) {
          user.connected = true;
          return user;
        } else {
          return user;
        }
      });

      this.setState({ users: allUsers })
    })

    socket.on('disconnect', () => {
      var allUsers = [...this.state.users]
      allUsers = allUsers.map((user) => {
        if (user.self) {
          user.connected = false;
          return user;
        } else {
          return user;
        }
      })
      this.setState({ users: allUsers })
    })

    socket.on('users', (users) => {
      users.forEach((user) => {
        var allusers = [...this.state.users]
        allusers = allusers.map((existingUser) => {
          if (existingUser.userID === user.userID) {
            existingUser.connected = user.connected
            existingUser.messages = user.messages
            return existingUser;
          } else {
            return existingUser;
          }
        });

        this.setState({ users: allusers })
        user.self = user.userID === socket.userID;
        this.setState({ users: [...this.state.users, user] });
      });

      // put the current user first, and sort by username
      var ggUsers = this.state.users.sort((a, b) => {
        if (a.self) return -1
        if (b.self) return 1
        if (a.username < b.username) return -1
        return a.username > b.username ? 1 : 0
      })
      this.setState({ users: ggUsers })
    });

    socket.on('user connected', (user) => {
      var connectusers = [...this.state.users]
      connectusers.map((existingUser) => {
        if (existingUser.userID === user.userID) {
          existingUser.connected = true
          return existingUser
        } else {
          return existingUser
        }
      })
      this.setState({ users: connectusers })
    });

    socket.on('user disconnected', (id) => {
      var ggusers = [...this.state.users]
      ggusers = ggusers.map((user) => {
        if (user.userID === id) {
          user.connected = false
          return user
        } else {
          return user
        }
      })
      this.setState({ users: ggusers })
    });

    socket.on('private message', ({ payload, from }) => {
      var nnUsers = [...this.state.users]
      // console.log(nnUsers);
      nnUsers = nnUsers.map((user) => {
        if (user.userID === from) {
          user.lastMessage = payload.message
          user.readMessage = 'false'
          if (this.props.currentUser) {
            if (user.userID !== this.props.currentUser.email) {
              user.readMessage = false
            }
          } else {
            user.readMessage = false
          }
          return user
        } else {
          return user
        }
      })
      // console.log(nnUsers,'private Mesagae')
      this.setState({ users: nnUsers })
    });
  }

  generateHeaderList = () => {
    const { users } = this.props
    const stateUsers = this.state.users

    // console.log(this.state.users, 'state users')
    // console.log(this.props.users, 'props users')

    if (users.length === 0) {
      return []
    }

    let userList = users;

    // console.log(userList, 'userList');

    if (stateUsers.length > 0 && users.length > 0) {
      for (let i = 0; i < stateUsers.length; i++) {
        for (let j = 0; j < users.length; j++) {
          if (stateUsers[i].userID === users[j].email) {
            if (stateUsers[i].connected === true) {
              users[j].status = 'online'
              if (stateUsers[i].lastMessage) {
                users[j].lastMessage = stateUsers[i].lastMessage
                // users[j].readMessage = stateUsers[i].readMessage
              }
              userList[j] = users[j]
            }
            continue
          }
        }
      }
      return userList
    } else {
      return users
    }
  }

  // const {screenWidth} = useSelector(({responsiveness}) => responsiveness)

  render() {
    const { users, currentUser, onContactSelect, classes, mainUser } = this.props;

    return users.length > 0 ? (
      <div className={classes.perfectScrollbarRootForSmall}>
        <CmtList
          data={this.generateHeaderList()}
          renderRow={(data) => {
            // if (data.header) {
            //   return (
            //     <Box key={data.id} className={classes.chatCellHeader}>
            //       {data.title}
            //     </Box>
            //   )
            // } else {
            return (
              <ChatUserCell
                key={data.id}
                currentUser={currentUser}
                data={data}
                onContactSelect={onContactSelect}
                mainUser={mainUser}
              />
            )
            // }
          }}
        />
      </div>
    ) : (
      <NoRecordFound />
    )
  }
}

const ChatUserList = (props) => {
  const { width } = props
  const classes = useStyles({ height: getChatSidebarHeight(width) })
  return <ChatUserListClass {...props} classes={classes} />
}

export default withWidth()(ChatUserList)

// const ChatUserList = ({users, width, currentUser, onContactSelect}) => {
//   const classes = useStyles({height: getChatSidebarHeight(width)})
//   const generateHeaderList = () => {
//     if (users.length === 0) {
//       return []
//     }
//     let userLsit = []
//     // let isFavourite = true;
//     // userLsit = [{ id: 'header-0', header: true, title: 'Favourite' }];
//     userLsit = []

//     users.map((user) => {
//       // if (isFavourite !== user.favourite) {
//       //   userLsit = userLsit.concat({
//       //     id: 'header-' + userLsit.length,
//       //     header: true,
//       //     title: 'Contacts',
//       //   });
//       //   isFavourite = false;
//       // }
//       userLsit = userLsit.concat(user)
//       return user
//     })
//     // console.log(userLsit,'ddg')
//     return userLsit
//   }

//   // const {screenWidth} = useSelector(({responsiveness}) => responsiveness)

//   return users.length > 0 ? (
//     <div className={classes.perfectScrollbarRootForSmall}>
//       <CmtList
//         data={generateHeaderList()}
//         renderRow={(data) => {
//           if (data.header) {
//             return (
//               <Box key={data.id} className={classes.chatCellHeader}>
//                 {data.title}
//               </Box>
//             )
//           } else {
//             return (
//               <ChatUserCell
//                 key={data.id}
//                 currentUser={currentUser}
//                 data={data}
//                 onContactSelect={onContactSelect}
//               />
//             )
//           }
//         }}
//       />
//     </div>
//   ) : (
//     <NoRecordFound />
//   )
// }

// export default withWidth()(ChatUserList)
