// network availability
export const NETWORK_CONNECTION_AVAILABILITY = 'NETWORK_CONNECTION_AVAILABILITY';

// authorized user
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_MESSAGE_DELETE = 'AUTH_MESSAGE_DELETE';

export const PROFILE_UPDATE_START = 'PROFILE_UPDATE_START';
export const PROFILE_UPDATE_SUCCESS = 'PROFILE_UPDATE_SUCCESS';
export const PROFILE_UPDATE_FAIL = 'PROFILE_UPDATE_FAIL';
export const PROFILE_UPDATE_LP_DISMISS = 'PROFILE_UPDATE_LP_DISMISS';
export const PROFILE_UPDATE_LP = 'PROFILE_UPDATE_LP';
export const PROFILE_FETCH_START = 'PROFILE_FETCH_START';
export const PROFILE_FETCH_FINISHED = 'PROFILE_FETCH_FINISHED';
export const PROFILE_FETCH_ERROR = 'PROFILE_FETCH_ERROR';
export const SUBMIT_SUPPORT_SUCCESS = 'SUBMIT_SUPPORT_SUCCESS';


// dashboard
export const ADVERTISE_ITEMS_FETCH_UPLOAD_START = 'ADVERTISE_ITEMS_FETCH_UPLOAD_START';
export const ADVERTISE_ITEMS_FETCH_UPLOAD_SUCCESS = 'ADVERTISE_ITEMS_FETCH_UPLOAD_SUCCESS';
export const ADVERTISE_SHARE_ID_GET_SUCCESS = 'ADVERTISE_SHARE_ID_GET_SUCCESS';
export const ADVERTISE_USER_ITEMS_FETCH_UPLOAD_SUCCESS = 'ADVERTISE_USER_ITEMS_FETCH_UPLOAD_SUCCESS';
export const ADVERTISE_ITEMS_FETCH_UPLOAD_ERROR = 'ADVERTISE_ITEMS_FETCH_UPLOAD_ERROR';
export const ADVERTISE_ITEMS_FETCH_UPLOAD_LP_DISMISS = 'ADVERTISE_ITEMS_FETCH_UPLOAD_LP_DISMISS';
export const ADVERTISE_ITEMS_FETCH_UPLOAD_LP = 'ADVERTISE_ITEMS_FETCH_UPLOAD_LP';

// normal user
export const ADVERTISES_FETCH_START = 'ADVERTISES_FETCH_START';
export const ADVERTISES_FETCH_SUCCESS = 'ADVERTISES_FETCH_SUCCESS';
export const ADVERTISES_FETCH_ERROR = 'ADVERTISES_FETCH_ERROR';
export const SINGLE_ADVERTISE_FETCH_SUCCESS = 'SINGLE_ADVERTISE_FETCH_SUCCESS';
export const SINGLE_PURCHASE_ADVERTISE_SUCCESS = 'SINGLE_PURCHASE_ADVERTISE_SUCCESS';

// subscription packages
export const SUB_PACKAGES_FETCH_START = 'SUB_PACKAGES_FETCH_START';
export const POST_SUB_PACKAGES_FETCH_SUCCESS = 'POST_SUB_PACKAGES_FETCH_SUCCESS';
export const GET_SUB_PACKAGES_FETCH_SUCCESS = 'GET_SUB_PACKAGES_FETCH_SUCCESS';
export const SELECT_SUB_PACKAGES_SUCCESS = 'SELECT_SUB_PACKAGES_SUCCESS';
export const SUB_PACKAGES_FETCH_FAIL = 'SUB_PACKAGES_FETCH_FAIL';

// handle responsiveness
export const HANDLE_SCREEN_SIZE = 'HANDLE_SCREEN_SIZE';

// payment
export const LOAD_PAYMENT = "LOAD_PAYMENT"
export const UPLOAD_PAYMENT = "UPLOAD_PAYMENT"
export const ERROR_PAYMENT = "ERROR_PAYMENT"

// buy product
export const LOAD_BUY = "LOAD_BUY"
export const UPLOAD_BUY = "UPLOAD_BUY"
export const ERROR_BUY = "ERROR_BUY"

// file upload
export const FILE_UPLOAD_START = 'FILE_UPLOAD_START';
export const FILE_UPLOAD_SUCCESS = 'FILE_UPLOAD_SUCCESS';
export const FILE_UPLOAD_ERROR = 'FILE_UPLOAD_ERROR';
export const FILE_UPLOAD_PERCENTAGE = 'FILE_UPLOAD_PERCENTAGE';

// chat
export const SEND_NEW_CHAT_MESSAGE = "SEND_NEW_CHAT_MESSAGE"
export const SEND_NEW_MEDIA_MESSAGE = "SEND_NEW_MEDIA_MESSAGE"
export const SET_CHAT_USERS = "SET_CHAT_USERS"
export const SET_CONVERSATION_DATA = "SET_CONVERSATION_DATA"
export const SET_CURRENT_USER = "SET_CURRENT_USER"
export const SET_FILTER_DATA = "SET_FILTER_DATA"
export const TOGGLE_SIDEBAR_COLLAPSED = "TOGGLE_SIDEBAR_COLLAPSED"
export const UNREAD_MESSAGES = "UNREAD_MESSAGES"


export const FETCH_ERROR = "FETCH_ERROR"
export const FETCH_START = "FETCH_START"
export const FETCH_SUCCESS = "FETCH_SUCCESS"


// Comment order
export const SUCCESS_FETCH_ORDER_COMMENTS = "SUCCESS_FETCH_ORDER_COMMENTS"
export const START_FETCH_ORDER_COMMENTS = "START_FETCH_ORDER_COMMENTS"
export const ERROR_FETCH_ORDER_COMMENTS = "ERROR_FETCH_ORDER_COMMENTS"
export const ADD_ORDER_COMMENTS = "ADD_ORDER_COMMENTS"

// Tender
export const POST_NEW_TENDER = "POST_NEW_TENDER"
export const START_TENDER = "START_TENDER"
export const TENDERS_FETCH_SUCCESS = "TENDERS_FETCH_SUCCESS"
export const TENDER_FETCH_SUCCESS = "TENDER_FETCH_SUCCESS"
export const TENDER_FAIL = "TENDER_FAIL"
export const TENDER_MODE_CHANGE = "TENDER_MODE_CHANGE"
export const BIDS_FETCH_SUCCESS = "BIDS_FETCH_SUCCESS";

// partner
export const START_PARTNER_REQUEST = "START_PARTNER_REQUEST";
export const PARTNER_FETCH_SUCCESS = "PARTNER_FETCH_SUCCESS";
export const PARTNER_FAIL = "PARTNER_FAIL";
export const REQUEST_FETCH_SUCCESS = "REQUEST_FETCH_SUCCESS";
export const PARTNER_INTERESTED_SUCCESS = "PARTNER_INTERESTED_SUCCESS";
export const SELLER_HAS_PARTNER = "SELLER_HAS_PARTNER";
export const PARTNER_CONFIRM_SUCCESS = "PARTNER_CONFIRM_SUCCESS";
export const ADS_UNDER_PARTNER = "ADD_UNDER_PARTNER";

 