import React from 'react'
import axios from '../../axios'
import {withTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import classes from './Toolbar/Toolbar.module.css'

const SwitchUser = ({token, user, t}) => {
  const history = useHistory()

  const switchUserAxios = async (data) => {
    const res = await axios({
      method: 'POST',
      url: '/user/switch',
      data: data,
      headers: tokenHeadersNormal().headers,
    })
    return res.data
  }
  const tokenHeadersNormal = () => {
    const headersNormal = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    if (token) {
      headersNormal.headers['x-auth-header'] = token
    }
    return headersNormal
  }
  const switchUser = async (data) => {
    let cc = ''
    try {
      cc = await switchUserAxios(data)
      
      // window.location.reload()
      if (user.role === 'buyer') {
          history.replace('/seller/dashboard')
      } else {
          history.replace('/buyer/dashboard')
      }
      if (cc.message === 'Switched successfully!') {
        setTimeout(() => {
          window.location.reload()
        }, 1000)
      }
    } catch (e) {
      console.log(e, 'error in switchUserAxios')
    }
  }

  return (
    <div className="mx-0 text-center">
      {user.role === 'buyer' ? (
        <button
          onClick={(e) => switchUser({okay: 'seller'})}
          className={classes.SwitchButton}>
          {t('Dashboard:DashboardNavigation.12')}
        </button>
      ) : (
        <button
          onClick={(e) => switchUser({okay: 'buyer'})}
          className={classes.SwitchButton}>
          {t('Dashboard:DashboardNavigation.13')}
        </button>
      )}
    </div>
  )
}

export default withTranslation(['translation', 'Dashboard'])(SwitchUser)
