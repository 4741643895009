import * as actionTypes from '../actions/ActionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    screenWidth: Math.max(document.documentElement.clientWidth || 0, window.innerWidth)
};

const onChangeScreenSize = (state, action) => {
    return updateObject(state, {
        screenWidth: action.payload.screenWidth
    })
};

export const HandleResponsivenessReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.HANDLE_SCREEN_SIZE: return onChangeScreenSize(state, action);
        default: return state;
    }
};