import Clothing_Accessories1 from './Clothing & Accessories 150x200.jpg';
import Clothing_Accessories2 from './Clothing & Accessories 200x300.jpg';
import Foodies_Drinks1 from './Foodies & Drinks 150x200.jpg';
import Foodies_Drinks2 from './Foodies & Drinks 200x300.jpg';
import Beauty_wellness1 from './Beauty & wellness 150x200.jpg';
import Beauty_wellness2 from './Beauty & wellness 200x300.jpg';
import Fitness_Health1 from './Fitness & Health150x200.jpg';
import Fitness_Health2 from './Fitness & Health200x300.jpg';
import Sport1 from './Sport 150x200.jpg';
import Sport2 from './Sport 200x300.jpg';
import Music_Video1 from './Music & video 150x200.jpg';
import Music_Video2 from './Music & video 200x 300.jpg';
import Event1 from './Event 150x200.jpg';
import Event2 from './Event 200x300.jpg';
import Business_Job1 from './Business and Job 150x200.jpg';
import Business_Job2 from './Business and Job 200x300.jpg';
import Home_Garden1 from './Home & Garden 150x200.jpg';
import Home_Garden2 from './Home & Garden 200x300.jpg';
import Cinema_Show1 from './Cinema & Show 150x200.jpg';
import Cinema_Show2 from './Cinema & Show 200 x300.jpg';
import All_Engine2 from './All Engine 200x300.jpg';
import All_Engine1 from './All Engine150x200.jpg';
import Lifestyle_Others1 from './Lifestyle & Others 200x300.jpg';
import Lifestyle_Others2 from './Lifestyle & Others150x200.jpg';
import Transport_Delivery1 from './Transport & Delivery 150x200.jpg';
import Transport_Delivery2 from './Transport & Delivery 200x300.jpg';
import Social_Political1 from './Social & Political 150x200.jpg';
import Social_Political2 from './Social & Political 200x300.jpg';
import Game_Techno1 from './Game and technology 150x200.jpg';
import Game_Techno2 from './Game and technology 200x300.jpg';
import Belief_Religion2 from './Beliefs & Religion 200x300.jpg';
import Belief_Religion1 from './Beliefs & Religion150x200.jpg';
import Travel_Adventure1 from './Tavel & Adventure 150x200.jpg';
import Travel_Adventure2 from './Tavel & Adventure 200x300.jpg';
import Adults from './Adults.png';

export { Adults,Travel_Adventure1, Travel_Adventure2, Belief_Religion1, Belief_Religion2, Game_Techno1, Game_Techno2, Social_Political1, Social_Political2, Transport_Delivery1, Transport_Delivery2, Lifestyle_Others1, Lifestyle_Others2, All_Engine1, All_Engine2, Cinema_Show1, Cinema_Show2, Home_Garden1, Home_Garden2, Business_Job1, Business_Job2, Event1, Event2, Sport1, Sport2, Fitness_Health1, Fitness_Health2, Music_Video1, Music_Video2, Beauty_wellness1,Beauty_wellness2,Foodies_Drinks1,Foodies_Drinks2,Clothing_Accessories1,Clothing_Accessories2};
