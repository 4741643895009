import React from 'react';
import { Close } from '@material-ui/icons';
import './CustomOrder.css';

const Header = (props) =>
    <div className='bg-light rounded-top border-bottom'>
        <div className='p-2 p-sm-3  d-flex justify-content-between text-secondary'>
            <div>
                <h6>{props.title}</h6>
            </div>
            <div className='Close mx-1'>
                <Close onClick={props.closeModal} />
            </div>
        </div>
    </div>


export default Header;
