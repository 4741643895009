import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from '../../../axios'
import classes from './Earnings.module.css'
import { withTranslation } from 'react-i18next'
import { CircularProgress } from '@material-ui/core';
import { Line } from '../../../components/UI/Spacing';
import { onReturnOnlyDigit } from '../../../shared/utility';
import { tokenHeaders } from '../../../store/actions/HeaderConfig';
import SimpleSnackbar from '../../../components/Snackbar/Snackbar';

class Earnings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      withdrawableBalance: 0,
      alreadyWithdrawn: 0,
      hasAccount: '',
      isLoading: false,
      sellerPurchases: [],
      partnerPurchases: [],
      totalSellingAmount: 0,
      totalPartnerAmount: 0,
      requestWithdrawal: false,
      amount: '',
      notValid: false,
      accountFullFilled: false,
      isLoadingWithdrawalRequest: false,
      message: '',
      error: false,
    }
  }

  componentDidMount() {
    this.checkCustomerAccount()
    this.getADPurchasesForSeller()
    this.getPurchasesAds();
  }

  getPurchasesAds = () => {
    const url = '/purchaseAdvertise/getPurchasesAds'
    axios
      .get(url, { headers: tokenHeaders(this.props.token).headers })
      .then((response) => {
        this.setState({
          sellerPurchases: response.data.sellerPurchases,
          totalSellingAmount: response.data.totalSellingAmount,
          partnerPurchases: response.data.partnerPurchases,
          totalPartnerAmount: response.data.totalPartnerAmount,
        })
      })
      .catch((err) => console.log(err, 'checkCustomerAccount'))
  }

  getADPurchasesForSeller = () => {
    const url = '/purchaseAdvertise/getADPurchasesForSeller';
    axios
      .get(url, { headers: tokenHeaders(this.props.token).headers })
      .then((response) => {
        this.setState({
          withdrawableBalance: response.data.totalWithDrawableAmount,
          alreadyWithdrawn: response.data.alreadyWithdrawn
        });
      })
      .catch((err) => console.log(err, 'getADPurchasesForSeller'))
  }

  checkCustomerAccount = () => {
    axios
      .get('/payout/checkUserStripeAccount',
        { headers: tokenHeaders(this.props.token).headers })
      .then((response) => {
        this.setState({
          hasAccount: response.data.hasAccount,
          accountFullFilled: response.data.accountFullFilled
        });
      })
      .catch((err) => console.log(err, 'checkCustomerAccount'))
  }

  submitAmount = () => {
    this.setState({ isLoadingWithdrawalRequest: true });

    if (parseFloat(this.state.amount) < 5 || this.state.withdrawableBalance < parseFloat(this.state.amount)) {
      this.setState({
        notValid: true,
        isLoadingWithdrawalRequest: false,
      });

      setTimeout(() => this.setState({ notValid: false }), 2000);
    }
    else {
      const url = '/payout/transferRequest';
      axios
        .post(url, { amount: this.state.amount }, { headers: tokenHeaders(this.props.token).headers })
        .then((response) => {
          this.setState({
            isLoadingWithdrawalRequest: false,
            requestWithdrawal: true,
            message: response.data.message,
          })
        })
        .catch((err) => console.log(err, 'submitAmount'));
    }
  }

  createUserStripeAccount = (event) => {
    event.preventDefault();
    this.setState({ isLoading: true });

    const currentUrl = window.location.href;
    const data = {
      returnUrl: currentUrl,
      refreshUrl: currentUrl,
    }

    axios
      .post('/payout/createOrGoUserStripeAccount',
        data,
        { headers: tokenHeaders(this.props.token).headers })
      .then((response) => {
        this.setState({ isLoading: false });

        window.location.href = response.data.linkResponse.url;
      })
      .catch((err) => console.log(err.message, 'createUserStripeAccount'))
  }

  render() {

    const { t } = this.props;

    const balance = this.state.totalPartnerAmount + this.state.totalSellingAmount - this.state.alreadyWithdrawn;

    return <div className={classes.earningParent}>

      {this.state.message && !this.state.error &&
        <SimpleSnackbar open={true} message={this.state.message} type='success' />}
      {this.state.message && this.state.error &&
        <SimpleSnackbar open='true' message={this.state.message} type='error' />}
      <div className={classes.earningChild}>

        <div className="col-md-11">
          <div className={classes.Balance}>
            {this.state.requestWithdrawal ?
              <React.Fragment>
                <button
                  className='btn btn-sm btn-primary'
                  onClick={_ => this.setState({ requestWithdrawal: false })}>
                  <i className="fas fa-chevron-left"></i>
                </button>
                <div className="row my-3">
                  <div className="form-group col-lg-8 col-12">
                    <input
                      required
                      value={onReturnOnlyDigit(this.state.amount)}
                      onChange={e => this.setState({ amount: e.target.value })}
                      name='amount'
                      type="text"
                      className="form-control form-control-lg"
                      placeholder={t('amount') + '($)'} />
                    <div className='mt-2'>
                      {this.state.notValid &&
                        <span className='badge bg-danger mx-1'>
                          {t('userValidAmount')}
                        </span>}
                    </div>
                    <div className='mt-2'>
                      <span className='text-secondary'>
                        {t('withdrawable')} : <span className='badge bg-primary mx-2'>${this.state.withdrawableBalance}</span>
                        Min : <span className='badge bg-success mx-2'>$5</span>
                        Max : <span className='badge bg-danger mx-2'>$5000</span>
                      </span>
                    </div>
                  </div>
                  <div className='col-lg-4 col-12 mt-4 mt-lg-0'>
                    {this.state.isLoadingWithdrawalRequest ? <CircularProgress /> :
                      <button
                        type="submit"
                        onClick={this.submitAmount}
                        className="btn btn-lg btn-primary w-100"
                      >{t('submit')}</button>}
                  </div>
                </div>
              </React.Fragment>
              : <React.Fragment>
                <div className='row col-12 justify-content-center align-items-center'>
                  <div className="col-lg-8 col-12 mb-3 mb-lg-0">
                    <div>
                      <h5 className='py-2'>{t('totalEarnings')} <h5 className='btn px-2 py-1' style={{ fontSize: '35px', backgroundColor: 'ghostwhite' }}>${this.state.totalPartnerAmount + this.state.totalSellingAmount - this.state.alreadyWithdrawn}</h5></h5>
                    </div>
                  </div>
                  <div className="col-lg-4 col-12 mb-3 mb-lg-0">
                    {this.state.isLoading ? <CircularProgress color='primary' /> : <button
                      type="submit"
                      style={{ backgroundColor: '#06c445', color: 'white' }}
                      onClick={this.createUserStripeAccount}
                      className="btn btn-lg w-100"
                    > {this.state.hasAccount ? t('myWallet') : t('setupPayment')}</button>
                    }
                  </div>
                </div>
                <div className='row col-12 justify-content-center align-items-center mt-2'>
                  <div className="col-lg-8 col-12 mb-3 mb-lg-0">
                    <div>
                      <h5 className='py-1'>{t('totalWithrawable')} <h5 className='btn px-2 py-1' style={{ fontSize: '35px', backgroundColor: 'ghostwhite' }}>${this.state.withdrawableBalance}</h5></h5>
                    </div>
                  </div>
                  <div className="col-lg-4 col-12 mb-3 mb-lg-0">
                    <button
                      title={this.state.accountFullFilled ? t("transferBalance") : t('youCantTransfer')}
                      disabled={!this.state.accountFullFilled || balance <= 0}
                      type="submit"
                      style={{ backgroundColor: 'goldenrod', color: 'white' }}
                      onClick={_ => this.setState({ requestWithdrawal: true })}
                      className="btn btn-lg w-100"
                    >{t('requestWithdrawal')}</button>

                  </div>
                  {this.state.hasAccount && !this.state.accountFullFilled && <div className='mt-2 text-danger'>
                    <span>{t('accountNotFilled')}</span>
                  </div>}
                </div>
              </React.Fragment>}
          </div>
          <Line height={2} backgroundColor='gray' space='4' orientation='h' width='100' />
          <h5 className='mt-5'>{t('allPurchaseAds').toUpperCase()}</h5>
          <table className='table table-hover mt-2'>
            <thead>
              <tr>
                <th scope="col">SL#</th>
                {this.props.screenWidth > 800 &&
                  <th scope="col">{t('adImage')}</th>}
                <th scope="col">{t('title')}</th>
                <th scope="col">{t('status')}</th>
                <th scope="col">{t('amount')}</th>
              </tr>
            </thead>
            <tbody>
              {this.state.sellerPurchases.map((purchase, index) => (
                <tr role='button' onClick={_ => this.props.history.replace(`/adInfo?ad_id=${ purchase.advertiseId.shortId }`)}>
                  <th scope="row">{index + 1}</th>
                  {this.props.screenWidth > 800 && <td className={classes.ListImage}>
                    <img src={purchase.advertiseId.files[0]} alt="img" className="img-thumbnail"></img>
                  </td>}
                  <td>{purchase.advertiseId.title}</td>
                  <td>
                    <span className='badge bg-primary'>{purchase.status}</span>
                  </td>
                  <td>${purchase.paymentId.paidAmountInfo.forSeller}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {this.state.sellerPurchases.length === 0 ? <p>{t('noAdsFound')}</p> :
            <div className="d-flex flex-row-reverse">
              <div className="p-2">
                <p className='btn btn-sm btn-primary'>{t('total')} : ${this.state.totalSellingAmount}</p>
              </div>
              <div className="p-2">
                <p className='text-muted'>{t('everySubscriptionCalculated')}</p>
              </div>
            </div>}
        </div>
      </div>
      <div className="mt-5 row justify-content-center">
        <div className="col-md-11">
          <h5 className='py-2'>{t('allPurchasedAdsAsPartner').toUpperCase()}</h5>
          <table className="table table-hover">
            <thead>
              <tr>
                <th scope="col">SL#</th>
                {this.props.screenWidth > 800 &&
                  <th scope="col">{t('adImage')}</th>}
                <th scope="col">{t('title')}</th>
                <th scope="col">{t('status')}</th>
                <th scope="col">{t('amount')}</th>
              </tr>
            </thead>
            <tbody>
              {this.state.partnerPurchases.map((purchase, index) => (
                <tr role='button' onClick={_ => this.props.history.replace(`/adInfo?ad_id=${ purchase.advertiseId.shortId }`)}>
                  <th scope="row">{index + 1}</th>
                  {this.props.screenWidth > 800 &&
                    <td className={classes.ListImage}>
                      <img src={purchase.advertiseId.files[0]} alt="img" className="img-thumbnail"></img>
                    </td>}
                  <td>{purchase.advertiseId.title}</td>
                  <td>
                    <span className='badge bg-primary'>{purchase.status}</span>
                  </td>
                  <td>${purchase.paymentId.paidAmountInfo.forPartner}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {this.state.partnerPurchases.length === 0 ? <p>{t('noAdsFound')}</p> :
            <div className="d-flex flex-row-reverse">
              <div className="p-2">
                <p className='btn btn-sm btn-primary'>{t('total')} : ${this.state.totalPartnerAmount}</p>
              </div>
              <div className="p-2">
                <p className='text-muted'>{t('everyPriceCalculated')}</p>
              </div>
            </div>}
        </div>
      </div>
    </div>
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userAuth.user,
    isAuthenticated: state.userAuth.isAuthenticated,
    token: state.userAuth.token,
    screenWidth: state.responsiveness.screenWidth,
  }
}

export default connect(mapStateToProps, null)(withTranslation(['translation'])(Earnings))
