// @ts-nocheck
import React from 'react';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import classes from './NavigationItems.module.css';
import { NormalNavigationItem } from './NavigationItem/NavigationItem';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ProfileInfo from './ProfileInfo';
// import { Search } from '../../../../containers/NormalUser/Search';
import SwitchUser from '../../SwitchUser';

class NavigationItems extends React.Component {
  constructor(props) {
    super(props)
    let lang = localStorage.getItem('i18nextLng')
    this.state = {
      selectedValue: lang,
    }
  }

  handleChange = (e) => {
    this.setState({
      selectedValue: e.target.value,
    });

    i18next.changeLanguage(e.target.value);
    localStorage.setItem('translateTo', e.target.value === 'en-US' ? 'en' : 'fr');
    window.location.reload();
  }

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <ul className={classes.NavigationItems}>
          {/* <div className={classes.Search}>
            <Search t={t} />
          </div> */}
          {this.props.isAuthenticated && <React.Fragment>
            <SwitchUser
              user={this.props.user}
              t={t}
              token={this.props.token}
            />
            <Link to="/profile">
              <ProfileInfo user={this.props.user} />
            </Link>
          </React.Fragment>}
          {!this.props.isAuthenticated &&
            <React.Fragment>
              <NormalNavigationItem link="/join">
                {t('appStartNavigation.5')}
              </NormalNavigationItem>
              <div style={{margin:'0px 5px'}} />
              <NormalNavigationItem link="/login">
                {t('appStartNavigation.4')}
              </NormalNavigationItem>
            </React.Fragment>
          }
          <div className='mx-1'></div>
          {this.props.user && this.props.user.role === 'seller' ?
            <NormalNavigationItem exact={true} link="/start-selling">{t('appStartNavigation.0')}</NormalNavigationItem> :
            <NormalNavigationItem exact={true} link="/launch-tender">{t('navigations.0')}</NormalNavigationItem>}
          <div className='mx-2'>
            <select
              className="form-select "
              value={this.state.selectedValue}
              onChange={this.handleChange}
            >
              <option value="en-US">
                {t('appStartNavigation.6')}
              </option>
              <option value="fr">
                {t('appStartNavigation.7')}
              </option>
            </select>
          </div>
        </ul>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userAuth.user,
    token: state.userAuth.token,
    isAuthenticated: state.userAuth.token !== null,
  }
}

export default connect(
  mapStateToProps,
  null,
)(withTranslation()(NavigationItems))
