import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { SimpleSpinner } from '../../../components/UI/Spinner/Spinner';
import classes from './Auth.module.css';
import axios from "../../../axios";

class ForgotPassword extends Component {
    state = {
        password: '',
        reTypePassword: '',
        showPassword: false,
        showRetypePassword: false,
        isLoginError: false,
        loading: false
    }

    componentDidMount() {
        window.scroll(0, 0);
    }

    onSubmitForm = (event) => {
        event.preventDefault();
        if (this.props.location.search) {
            const search = this.props.location.search;
            const params = new URLSearchParams(search);
            if (params.get("email_id") && params.get("token") && params.get("iv")) {

                const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,16}$/;
                if (!regex.test(this.state.password)) {
                    this.setState({ message: 'Password pattern does not match' });
                }
                else if (this.state.password !== this.state.reTypePassword) {
                    this.setState({ message: 'Password does not match' });
                }
                else if (regex.test(this.state.password)) {
                    this.setState({ loading: true });
                    axios.post('/user/passwordReset', {
                        password: this.state.password,
                        email_id: params.get("email_id"),
                        token: params.get("token"),
                        iv: params.get("iv")
                    })
                        .then((response) => {
                            this.setState({ message: response.data.message, password: '', reTypePassword: '' });
                            this.setState({ loading: false });
                        })
                        .catch(error => {
                            this.setState({ message: error.message, loading: false});
                        });
                }
            } else {
                this.setState({ message: 'Please, Provide valid info.' });
            }
        };
        setTimeout(() => {
            this.setState({ message: '' });
        }, 10000);
    }

    render() {
        return <React.Fragment>
            <div className={classes.ForgotMain}>
                <div className={`m-md-5 m-2 p-4 shadow-sm bg-white rounded ${ classes.ForgotForm }`}>
                    {this.state.message && <div className="rounded bg-primary text-white my-2 p-2">{this.state.message}</div>}
                    <h5 className='py-1'>Reset Password</h5>
                    <form onSubmit={this.onSubmitForm}>
                        <div className="form-group">
                            <label className='my-2 form-label'>New Password</label>
                            <div className={classes.PasswordWrapper}>
                                <input
                                    type={this.state.showPassword ? "text" : "password"}
                                    className="form-control"
                                    value={this.state.password}
                                    onChange={e => this.setState({ password: e.target.value })}
                                    required
                                    placeholder="password" />
                                {this.state.showPassword ? <i className="fas fa-eye"
                                    onClick={_ => this.setState({ showPassword: !this.state.showPassword })}>
                                </i>
                                    : <i className="fas fa-eye-slash"
                                        onClick={_ => this.setState({ showPassword: !this.state.showPassword })}
                                    ></i>}
                            </div>
                            <small className="form-text text-muted">Password length min=8, max=16, one(small,capital,number) required.</small>
                        </div>
                        <div className="form-group mt-2">
                            <label className='my-2 form-label'>Retype Password</label>
                            <div className={classes.PasswordWrapper}>
                                <input
                                    onChange={e => this.setState({ reTypePassword: e.target.value })}
                                    type={this.state.showRetypePassword ? "text" : "password"}
                                    value={this.state.reTypePassword}
                                    className="form-control"
                                    placeholder="password" />
                                {this.state.showRetypePassword ? <i className="fas fa-eye"
                                    onClick={_ => this.setState({ showRetypePassword: !this.state.showRetypePassword })}>
                                </i>
                                    : <i className="fas fa-eye-slash"
                                        onClick={_ => this.setState({ showRetypePassword: !this.state.showRetypePassword })}
                                    ></i>}
                            </div>
                        </div>
                        {this.state.loading ? <div className='my-2 d-flex justify-content-center'>
                            <SimpleSpinner />
                        </div> : <input type="submit" value='Submit' className="btn btn-primary w-100 mt-4"></input>}

                    </form>
                </div>
            </div>
        </React.Fragment>
    }
}

const mapStateToProps = (state) => {
    return {
        error: state.userAuth.error,
        message: state.userAuth.message,
        loading: state.userAuth.loading,
        isAuthenticated: state.userAuth.token !== null,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation(['translation', ''])(ForgotPassword))
