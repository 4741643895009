import React from "react"
import { useTranslation } from 'react-i18next';
import classes from "./Footer.module.css";
import img from "../../assets/images/app-image.png"
import { Link } from "react-router-dom"
import Accordion from '../../components/Accordion/Accordion';
import { connect } from 'react-redux';
import { SocialMedia } from './SocialMedia';

const Footer = (props) => {
	const date = new Date();
	const { t } = useTranslation();
	const item1 = <div className={classes.Address}>
		<img src={img} alt="Umediad" />
		<div className={classes.Divider}></div>
		<span>{t('footer.firstSection.1')}{t('footer.firstSection.2')}</span>
		<span>{t('footer.firstSection.3')} Canada</span>
	</div>
	const item2 = <React.Fragment>
		<div className={classes.Divider}></div>
		<Link to="/about-us">
			<span>{t('footer.secondSection.1')}</span>
		</Link>
		<div className={classes.Divider1}></div>
		<Link to="/privacy-policy">
			<span>{t('footer.secondSection.2')}</span>
		</Link>
		<div className={classes.Divider1}></div>
		<Link to="/terms-and-conditions">
			<span>{t('footer.secondSection.3')}</span>
		</Link>
		<div className={classes.Divider1}></div>
		<Link to="">
			<span>{t('footer.secondSection.4')}</span>
		</Link>
	</React.Fragment>
	const item3 = <React.Fragment>
		<div className={classes.Divider}></div>
		<Link to="/contact-us">
			<span>{t('footer.thirdSection.1')}</span>
		</Link>
		<div className={classes.Divider1}></div>
		<Link to="">
			<span> {t('footer.thirdSection.2')}</span>
		</Link>
		<div className={classes.Divider1}></div>
		<Link to="/faq">
			<span> {t('footer.thirdSection.3')}</span>
		</Link>
	</React.Fragment>
	const item4 = <React.Fragment>
		<div className={classes.Divider}></div>
		<Link to="">
			<span>{t('footer.forthSection.1')}</span>
		</Link>
		<div className={classes.Divider1}></div>
		<Link to="">
			<span>{t('footer.forthSection.2')}</span>
		</Link>
		<div className={classes.Divider1}></div>
		<p>Buy or Sell the perfect audience</p>
		<div className={classes.Divider1}></div>
	</React.Fragment>
	return <footer>
		<div className={classes.Footer}>
			{props.screenWidth <= 500 ?
				<React.Fragment>
					<Accordion
						title={t('footer.firstSection.0')}
						content={item1}
					/>
					<Accordion
						title={t('footer.secondSection.0')}
						content={item2}
					/>
					<Accordion
						title={t('footer.thirdSection.0')}
						content={item3}
					/>
					<Accordion
						title={t('footer.forthSection.0')}
						content={item4}
					/>
				</React.Fragment>
				:
				<React.Fragment>
					<div className={classes.ItemMain}>
						<h5>{t('footer.firstSection.0')}</h5>
						{item1}
					</div>
					<div className={classes.ItemMain}>
						<h5>{t('footer.secondSection.0')}</h5>
						{item2}
					</div>
					<div className={classes.ItemMain}>
						<h5>{t('footer.thirdSection.0')}</h5>
						{item3}
					</div>
					<div className={classes.ItemMain}>
						<h5>{t('footer.forthSection.0')}</h5>
						{item4}
					</div>
				</React.Fragment>}
		</div>
		<div className={classes.FooterBottom}>
			{props.screenWidth > 500 ?
				<div className={classes.FooterBottomAppInfo}>
					<img src={img} alt='Umediad' />
					<p>© Umediad Inc. {date.getFullYear()}</p>
				</div> : null}
			<SocialMedia />
			{props.screenWidth <= 500 ?
				<div className={classes.FooterBottomAppInfo}>
					<img src={img} alt='Umediad' />
					<p>© Umediad Inc. {date.getFullYear()}</p>
				</div> : null}
		</div>
	</footer>
}

const mapStateToProps = (state) => {
	return {
		screenWidth: state.responsiveness.screenWidth
	}
}

export default connect(mapStateToProps, null)(Footer)
