// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Spinner_Spinner__BWqNs {\n  display: flex;\n  flex-flow: row;\n  justify-content: center;\n  align-items: center;\n  height: 50vh;\n  width: 95%;\n  margin: 0 20px;\n}\n\n.Spinner_SimpleSpinner__2PAvY {\n  display: flex;\n  flex-flow: row;\n  justify-content: center;\n  align-items: center;\n}\n\n.Spinner_SpinnerAbsolute__1KXey {\n  position: absolute;\n  display: flex;\n  flex-flow: row;\n  justify-content: center;\n  align-items: center;\n  height: 50vh;\n  width: 95%;\n  margin: 0 20px;\n}\n\n.Spinner_container__3dZuq {\n  width: 80px;\n  height: 100px;\n  margin: 100px auto;\n  margin-top: calc(100vh / 2 - 50px);\n}\n\n.Spinner_block__10h3K {\n  position: relative;\n  box-sizing: border-box;\n  float: left;\n  margin: 0 10px 10px 0;\n  width: 12px;\n  height: 12px;\n  border-radius: 3px;\n  background: blueviolet;\n}\n\n.Spinner_block__10h3K:nth-child(4n + 1) {\n  animation: Spinner_wave__16TEH 2s ease 0s infinite;\n}\n\n.Spinner_block__10h3K:nth-child(4n + 2) {\n  animation: Spinner_wave__16TEH 2s ease 0.2s infinite;\n}\n\n.Spinner_block__10h3K:nth-child(4n + 3) {\n  animation: Spinner_wave__16TEH 2s ease 0.4s infinite;\n}\n\n.Spinner_block__10h3K:nth-child(4n + 4) {\n  animation: Spinner_wave__16TEH 2s ease 0.6s infinite;\n  margin-right: 0;\n}\n\n@keyframes Spinner_wave__16TEH {\n  0% {\n    top: 0;\n    opacity: 1;\n  }\n  50% {\n    top: 30px;\n    opacity: 0.2;\n  }\n  100% {\n    top: 0;\n    opacity: 1;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"Spinner": "Spinner_Spinner__BWqNs",
	"SimpleSpinner": "Spinner_SimpleSpinner__2PAvY",
	"SpinnerAbsolute": "Spinner_SpinnerAbsolute__1KXey",
	"container": "Spinner_container__3dZuq",
	"block": "Spinner_block__10h3K",
	"wave": "Spinner_wave__16TEH"
};
module.exports = exports;
