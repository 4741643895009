import React from 'react'
import {withTranslation} from 'react-i18next'
import classes from './Contact.module.css'
import {connect} from 'react-redux'
import * as actions from '../../../../store/actions'
import SimpleSnackbar from '../../../../components/Snackbar/Snackbar'
import {Translate} from 'react-auto-translate'
import Translation from '../../../../shared/Translate'

class Contact extends React.Component {
  state = {
    formData: {
      supportType: 'Technical',
      name: '',
      email: '',
      phone: '',
      umediadAccount: '',
      message: '',
    },
  }
  componentDidMount() {
    window.scroll(0, 0)
  }
  
  onReturnOnlyDigit = (value) =>
    value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1')

  onSubmitContact = (event) => {
    event.preventDefault()
    this.props.onSubmitSupport(this.state.formData)
    setTimeout(
      () => this.props.history.push(`/${this.props.user.role}/dashboard`),
      3000,
    )
  }

  onChangeInput = (event) => {
    const formData = {...this.state.formData}
    if ('phone' === event.target.name)
      formData['phone'] = this.onReturnOnlyDigit(event.target.value)
    else formData[event.target.name] = event.target.value

    this.setState({formData})
  }

  render() {
    const {t} = this.props

    const {email, message, name, phone, umediadAccount} = this.state.formData

    return (
      <div className={classes.Contact}>
        {this.props.supportSuccess && !this.props.error && (
          <SimpleSnackbar
            open={true}
            message={this.props.supportSuccess}
            type="success"
          />
        )}

        <div className={classes.ContactMain}>
          <div className={classes.ContactMainHeader}>
            <h3>
              <Translation
                from={'en'}
                text="Contact Us"
                input={<Translate>Contact Us</Translate>}
              />
            </h3>
          </div>
          <div className={classes.ContactMainForm}>
            <form onSubmit={this.onSubmitContact}>
              <label htmlFor="name">
                <Translation
                  from={'en'}
                  text="Choose Your cause of support"
                  input={<Translate>Choose Your cause of support</Translate>}
                />{' '}
                *
              </label>
              <select
                name="supportType"
                className="form-select"
                onChange={this.onChangeInput}
                required>
                <option value="Technical">{t('contact:2')}</option>
                <option value="Order Issue">{t('contact:3')}</option>
                <option value="Subscriptions">{t('contact:4')}</option>
                <option value="Account Refine">{t('contact:5')}</option>
              </select>
              <label htmlFor="name">
                <Translation
                  from={'en'}
                  text="Your Name"
                  input={<Translate>Your Name</Translate>}
                />
              </label>
              <input
                name="name"
                value={name}
                onChange={this.onChangeInput}
                id="name"
                type="text"
                className="form-control"></input>
              <label htmlFor="email">
                <Translation
                  from={'en'}
                  text="Your Email"
                  input={<Translate>Your Email</Translate>}
                />{' '}
                *
              </label>
              <input
                required
                value={email}
                name="email"
                onChange={this.onChangeInput}
                id="email"
                className="form-control"
                type="email"></input>
              <label htmlFor="phone">
                <Translation
                  from={'en'}
                  text="Phone number"
                  input={<Translate>Phone number</Translate>}
                />{' '}
                *
              </label>
              <input
                name="phone"
                required
                value={phone}
                id="phone"
                onChange={this.onChangeInput}
                className="form-control"></input>
              <label htmlFor="account">
                <Translation
                  from={'en'}
                  text="Umediad Account"
                  input={<Translate>Umediad Account</Translate>}
                />{' '}
                *
              </label>
              <input
                value={umediadAccount}
                name="umediadAccount"
                onChange={this.onChangeInput}
                required
                id="account"
                className="form-control"></input>
              <label htmlFor="message">
                <Translation
                  from={'en'}
                  text="Message"
                  input={<Translate>Message</Translate>}
                />
              </label>
              <textarea
                value={message}
                name="message"
                rows="6"
                onChange={this.onChangeInput}
                id="message"
                className="form-control"></textarea>
              {!this.props.isAuthenticated && (
                <p className="badge mt-2 text-danger">
                  <Translation
                    from={'en'}
                    text="Please login first to create a support"
                    input={
                      <Translate>
                        Please login first to submit support request
                      </Translate>
                    }
                  />
                  .
                </p>
              )}
              <div className="mt-3">
                <button
                  disabled={!this.props.isAuthenticated}
                  type="submit"
                  className="btn btn-primary w-25">
                  {' '}
                  <i className="fa fa-send mx-1"></i>
                  <Translation
                    from={'en'}
                    text="Submit"
                    input={<Translate>Submit</Translate>}
                  />
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.userAuth.isAuthenticated,
    user: state.userAuth.user,
    supportSuccess: state.profile.supportSuccess,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmitSupport: (data) => dispatch(actions.onSubmitSupport(data)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation(['contact'])(Contact))
