import React from 'react';
import classes from './Partner.module.css';


const GetPaid = () => {
    return <div className={classes.Partner}>
        <h3>Get Paid</h3>
    </div>
}

export default GetPaid;