import React from 'react'
import { useTranslation } from 'react-i18next'
// import { goldImages, greenImages, blueImages } from '../../../assets/images/home-slider';
import umediad_cover from '../../../assets/images/umediad_cover.png'
// import { handleResponsiveness } from '../../../assets/images/HandleResponsive';
import styles from './HomeSlide.module.css'
import classes from './HomeSlide.module.css'
import './marquee.css'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Search } from '../../NormalUser/Search'
import Translator from '../../../components/Translator'
import { Translate } from 'react-auto-translate'
import Translation from '../../../shared/Translate'

const HomeSlide = (props) => {
  const { t } = useTranslation()
  const favoriteServices = [
    {
      name: 'Influencer',
      path: '/influencer',
      // type: 'sellerType',
    },
    {
      name: 'Internet',
      path: '/internet',
      lang: 'fr',
    },
    {
      name: 'Social Media',
      path: '/social-media',
    },
    {
      name: 'Radio',
      path: '/radio',
    },
    {
      name: 'Billboard',
      path: '/billboard',
    },
    {
      name: 'Print Media',
      path: '/print-media',
    },

    {
      name: 'Sports',
      path: '/sports',
      lang: 'fr',
    },
    {
      name: 'Event',
      path: '/event',
    },
    {
      name: 'Music',
      path: '/music',
    },
  ]

  console.log(props.searchText)

  return (
    <React.Fragment>
      <div className={styles.AppSlide}>
        {props.screenWidth <= 1124 ? (
          <React.Fragment>
            <div className={classes.mobileSlide}></div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className={classes.Slider}>
              <div className={classes.slide1}>
                <img src={umediad_cover} alt="img" />
              </div>
              {/* <div className={classes.slide2}>
                        <img src={handleResponsiveness(umediad_cover, props.screenWidth)} alt='img' />
                    </div> */}
              <div className={classes.slide2}>
                <img src={umediad_cover} alt="img" />
              </div>
              <div className={classes.slide3}>
                <img src={umediad_cover} alt="img" />
              </div>
            </div>
          </React.Fragment>
        )}
        <div className={classes.TransparentBackground}>
          <div className={classes.Description}>
            <div className={classes.HeaderText}>
              <h2 className="animate__animated animate__lightSpeedInLeft">
                {/* {(t('homeSlide.000'))} */}
                <Translation
                  from={'en'}
                  text="Buy or Sell"
                  input={<Translate>Buy or Sell</Translate>}
                />
              </h2>
              <div className={classes.DividerForMobile}></div>
              <h3
                style={{ color: '#ffffff' }}
                className="animate__animated animate__fadeInRight">
                {/* {t('homeSlide.0')} */}
                <Translation
                  from={'en'}
                  text="The Perfect Ad"
                  input={<Translate>The Perfect Ad</Translate>}
                />
              </h3>
            </div>
            <div className={classes.DividerForMobile}></div>
            <div className={classes.DividerForDesktop}></div>
            <div className={classes.Search}>
              <Search t={t} text ={props.searchText}/>
              <div className={classes.DividerForDesktop}></div>
              <div className={styles.FavoriteService}>
                <h6 className="mt-2">
                  {/* {t('homeSlide.1')} : */}
                  <Translation
                    from={'en'}
                    text="Trending"
                    input={<Translate>Trending: </Translate>}
                  />
                </h6>
                {favoriteServices.map((item, index) => (
                  <p key={index}>
                    <Link
                      to={`/find/advertises${ item.path }?searchType=${ item.type ?? 'media'
                        }`}>
                      <Translation
                        from={item.lang ?? 'en'}
                        text={item.name}
                        input={<Translate>{item.name}</Translate>}
                      />
                    </Link>
                  </p>
                ))}
                <p>
                  <Link to="/find-audience">
                    {/* {t('homeSlide.8')} */}
                    <Translation
                      from={'en'}
                      text="More..."
                      input={<Translate>More...</Translate>}
                    />
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="marquee">
            <big>{t('info.0')}</big>
        </div> */}
      <Translator />
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    screenWidth: state.responsiveness.screenWidth,
  }
}

export default connect(mapStateToProps, null)(HomeSlide)
