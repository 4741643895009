import * as actionTypes from '../actions/ActionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    loading: false,
    error: false,
    commentsInfo: '',
    message: null,
};

const commentFetchStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        error: false,
        message: null,
    });
}

const commentFetchSuccess = (state, action) => {
    return updateObject(state, {
        commentsInfo: action.payload.orderComments,
        error: false,
        loading: false,
        message: null,
    });
};

const newCommentAdd = (state, action) => {
    return updateObject(state, {
        error: false,
        message: action.payload.message,
        loading: false,
    });
};

const commentFetchError = (state, action) => {
    return updateObject(state, {
        error: true,
        message: action.payload.message,
        loading: false
    });
};


export const OrderCommentReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.START_FETCH_ORDER_COMMENTS: return commentFetchStart(state, action);
        case actionTypes.ADD_ORDER_COMMENTS: return newCommentAdd(state, action);
        case actionTypes.SUCCESS_FETCH_ORDER_COMMENTS: return commentFetchSuccess(state, action);
        case actionTypes.ERROR_FETCH_ORDER_COMMENTS: return commentFetchError(state, action);

        default: return state;
    }
};