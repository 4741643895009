export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export const getDate = (date) => {
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const dateObject = new Date(date);
    return monthNames[dateObject.getMonth()].substr(0, 3) + ' ' + dateObject.getDate() + ', ' + dateObject.getFullYear();
};

export const getDateItems = (date) => {
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const dateObject = new Date(date);
    return {
        month: monthNames[dateObject.getMonth()].substr(0, 3).toLowerCase(),
        day: dateObject.getDate(),
        year: dateObject.getFullYear()
    };
};

export const getMonthName = (index) => {
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    return monthNames[index].substr(0, 3).toLowerCase();
};

export const getTime = (date) => {
    const dateObject = new Date(date);
    return dateObject.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
}

export const onReturnOnlyDigit = (value) => value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');

export const getFileType = (file) => {
    let splitArray = file.split('.')
    return file.split('.')[splitArray.length - 1].toLowerCase();
}


export const getCurrentDate = (date) => {
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const dateObject = new Date(date);
    const time = dateObject.getTime();
    const current = new Date().getTime();

    const seconds = Math.floor((current - time) / 1000);
    const minutes = Math.floor(((current - time) / 1000 / 60));
    const hours = Math.floor((current - time) / (1000 * 60 * 60));
    const days = Math.floor(time / (1000 * 60 * 60 * 24));
    if (seconds < 3)
        return 'Just now';
    if (seconds < 60)
        return seconds + ' secs ago';
    if (minutes < 60)
        return minutes + ' mins ago';
    if (hours < 24)
        return hours + ' hours ago';
    if (days < 4)
        return days + ' days ago';

    return monthNames[dateObject.getMonth()].substr(0, 3) + ' ' + dateObject.getDate() + ', ' + dateObject.getFullYear();
};