import React, {Component} from 'react'
import {withTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import axios from '../../../axios'
import * as actions from '../../../store/actions'
import classes from './Dashboard.module.css'
// import { SpinnerAbsolute } from '../../../components/UI/Spinner/Spinner'
import {OrderDesktopView, OrderMobileView, ChatList, ProfileInfo} from './Items'

class SellerDashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      billing_info: '',
      error: '',
      isLoading: false,
      isOrderLoading: false,
      latestOrders: [],
    }
  }

  // static getDerivedStateFromProps(nextProps, prevState) {
  //   if (
  //     JSON.stringify(
  //       nextProps.payment.error !== JSON.stringify(prevState.error),
  //     )
  //   ) {
  //     return {
  //       error: nextProps.payment.error,
  //     }
  //   }
  //   return null
  // }

  componentDidMount() {
    if (!this.props.isAuthenticated) {
      this.props.history.push('/login')
    }
    this.setState({isLoading: true})
    this.props.getChatUsers()
    if (this.props.location.search) {
      const search = this.props.location.search // could be '?foo=bar'

      const completeUrl = 'https://umediad.com' + search
      const params = new URL(completeUrl).searchParams
      // const params = new URLSearchParams(search)
      let canceled = params.get('canceled')
      let mm = params.get('mm')

      if (canceled && mm === 'cancel') {
        this.setState({canceled: !this.state.canceled})
        this.setState({isLoading: false})
      } else {
        let session_id = params.get('session_id')
        let priceId = params.get('priceId')
        let packageId = params.get('packageId')
        this.saveToDatabase(session_id, priceId, packageId)
        this.setState({isLoading: false})
        this.props.history.replace('/seller/dashboard')
      }
      setTimeout(() => {
        window.location.reload()
      }, 3000)
    }
    this.setState({isLoading: false})
    this.getLatestOrders(this.props.user.role)
  }

  getLatestOrders = async (role) => {
    this.setState({isOrderLoading: true})

    axios
      .get(`/stripe/getLatestOrders?userRole=${role}`, {
        headers: this.tokenHeadersNormal().headers,
      })
      .then((latestOrders) =>
        this.setState({
          isOrderLoading: false,
          latestOrders: latestOrders.data.orders,
        }),
      )
      .catch((error) => this.setState({error: error.message}))
  }

  tokenHeadersNormal = () => {
    const headersNormal = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    const token = this.props.token
    if (token) {
      headersNormal.headers['x-auth-header'] = token
    }
    return headersNormal
  }

  fetchSuccessData = async (sessionId) => {
    const res = await axios({
      method: 'GET',
      url: '/stripe/checkout-session?sessionId=' + sessionId,
      headers: this.tokenHeadersNormal().headers,
    })
    return res.data.session
  }

  postCustomerPortal = async () => {
    const res = await axios({
      method: 'POST',
      url: '/stripe/customerPortal',
      headers: this.tokenHeadersNormal().headers,
    })
    return res.data.session
  }

  customerPortal = async (event) => {
    event.preventDefault()
    this.setState({isLoading: true})
    let cc = ''
    try {
      cc = await this.postCustomerPortal()
      window.location.href = cc.url
      this.setState({isLoading: false})
    } catch (e) {
      this.setState({isLoading: false})
    }
    this.setState({isLoading: false})
  }

  saveToDatabase = async (sessionId, priceId, packageId) => {
    let cc
    try {
      cc = await this.fetchSuccessData(sessionId)
    } catch (e) {
      console.log(e, 'save-to-database')
    }
    if (cc) {
      const {
        customer,
        currency,
        customer_details,
        id,
        payment_status,
        setup_intent,
        subscription,
        total_details,
        shipping,
      } = cc
      const {email} = customer_details
      const {amount_discount, amount_tax} = total_details
      this.props.uploadPaymentData({
        packageId: packageId,
        priceId: priceId,
        customer: customer,
        currency: currency,
        payment_status: payment_status,
        setup_intent: setup_intent,
        shipping: shipping,
        subscription: subscription,
        amount_discount: amount_discount,
        amount_tax: amount_tax,
        customer_email: email,
        sessionId: id,
      })
    }
  }

  render() {
    // let { isLoading } = this.state

    const {t} = this.props

    return (
      <div className={classes.Dashboard}>
        <div className={classes.DashboardMain}>
          <div className={classes.PartLeft}>
            <ProfileInfo user={this.props.user} />
            {!this.props.user.is_subscribed
              ? !this.props.user.is_subscribed && (
                  <div className="mt-3 mb-3">
                    <Link
                      to="/subscription-packages"
                      className="btn btn-primary">
                      {t('Dashboard:orders.4')}
                    </Link>
                  </div>
                )
              : null}
            <ChatList
              chats={this.props.chat}
              screenWidth={this.props.screenWidth}
            />
          </div>
          <div className={classes.PartRight}>
            <h5>{t('Dashboard:orders.5')}</h5>
            {this.state.latestOrders.length === 0 && (
              <div className="mb-3 mt-2">{t('Dashboard:orders.9')}</div>
            )}
            {/* <div className={classes.OrdersInfo}>
            <div className="container">
               <div className="row justify-content-between align-items-center">
                <div className="col-4 col-sm-6">
                  <div className="d-flex align-items-center">
                    <h6>Active Orders</h6>
                    <h6 className="text-muted ml-1">-2 ($45)</h6>
                  </div>
                </div>
                <div className="col-4 col-sm-6">
                  <select
                    class="form-select"
                    aria-label="Default select example">
                    <option selected>Active order(2)</option>
                    <option value="1">DEli</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
              </div> 
            </div>
          </div> */}

            {this.props.screenWidth > 950 ? (
              <OrderDesktopView
                orderManage={'seller'}
                latestOrders={this.state.latestOrders}
                isLoading={this.state.isLoading}
              />
            ) : (
              <OrderMobileView
                orderManage={'seller'}
                latestOrders={this.state.latestOrders}
                isLoading={this.state.isLoading}
                screenWidth={this.props.screenWidth}
              />
            )}
            {/* <div className="mt-3 mb-3">
              <button
                className="btn btn-sm btn-primary form-control form-control-sm"
                onClick={(event) => this.customerPortal(event)}>
                {t('Dashboard:orders.6')}
              </button>
            </div> */}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.userAuth.isAuthenticated,
    user: state.userAuth.user,
    token: state.userAuth.token,
    screenWidth: state.responsiveness.screenWidth,
    chat: state.chat.users,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getChatUsers: () => dispatch(actions.getChatUsers()),
    uploadPaymentData: (data) => dispatch(actions.uploadPaymentData(data)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation(['Dashboard'])(SellerDashboard))

// http://localhost:3000/profile_dashboard?session_id=cs_test_a1Pqn4oCkE9lH64nzxr0XO4rpqYQgBCgSlBbU9doRqOmtzelatTn0WuGej&packageId=5f85db942304300c2a9456e4&priceId=price_1INRuJCJ19dG8DzbWiuidz8X
// http://localhost:3000/buyer/dashboard?session_id=cs_test_a12DueBtQENHkpqiOddaNYTyfmU8NcgyDCjmJvu3Cykhf2AWZQzClfrrZj&packageId=5fc400f2b4a4171389d9eaec&priceId=price_1IdiKiCJ19dG8DzbkYuLcoAB
