import React from 'react'
import classes from '../Tender.module.css';
import * as actions from '../../../../store/actions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Spinner } from '../../../../components/UI/Spinner/Spinner';
import { getDate } from '../../../../shared/utility';
import axios from '../../../../axios';

class SellerBids extends React.Component {
    state = {
        message: ''
    }
    componentDidMount() {
        this.props.onGetSellerBids();
        
    }

    tokenHeadersNormal = () => {
        const headersNormal = {
            headers: { 'Content-Type': 'application/json' }
        }
        const token = this.props.token;
        if (token) {
            headersNormal.headers['x-auth-header'] = token;
        }
        return headersNormal;
    }

    createSingleChatroom = async (data) => {
        const res = await axios({
            method: 'POST',
            data: data,
            url: '/chat/create',
            headers: this.tokenHeadersNormal().headers,
        })
        return res.data;
    }

    createChat = async (email) => {
        const toEmail = email;
        const fromEmail = this.props.user.email;
        try {
            await this.createSingleChatroom({ toEmail, fromEmail })
            this.props.history.push('/inbox');
        } catch (e) {
            window.scroll(0.0, 0.0);
            if (e.response.data.message === 'Already exists') {
                this.setState({ message: 'You already created chat with that user.' });
                setTimeout(() => this.props.history.push('/inbox'), 2000)
            } else {
                this.setState({ message: e.response.data.message });
                setTimeout(() => this.setState({ message: '' }), 2000)
            }
        }
    }

    render() {
        let tenders = this.props.loading ? <Spinner /> : '';
        tenders = this.props.bitTenders.map((bitTenders, index) => (
            <div className={classes.PartRightItem} key={index} >
                {bitTenders.tender && <div className={classes.PartRightItems}>
                    <div className='col-12 col-lg-10'>
                        <Link title='Tender title' to={`/tender-description?info_id=${bitTenders.tender._id}`}>
                            <h6>{bitTenders.tender.title}</h6>
                        </Link>
                    </div>
                    <div className='col-lg-2 py-2 py-lg-0 d-flex justify-content-lg-end'>
                        <div>
                            <span className='badge bg-primary mx-1'>{bitTenders.tender.bids}</span>
                            <span>bids</span>
                        </div>
                    </div>
                </div>}
                <div className={classes.BitTenderInfo}>
                    <p>My Bid Info</p>
                    <div className='my-2'>
                        <span>{bitTenders.description}</span>
                    </div>
                    <div className={classes.DomainItem}>
                        <span>Budget</span>
                        <span>{+ bitTenders.budget.budgetMin ? '$' + bitTenders.budget.budgetMin : ''}{bitTenders.budget.budgetMax ? '- $' + bitTenders.budget.budgetMax : ''}</span>
                    </div>
                    <div className='py-1'>
                        <span>in {bitTenders.deliveryDays} days delivery</span>
                    </div>
                    <div className='row py-2'>
                        <span className='text-secondary'>{getDate(bitTenders.createdAt)}</span>
                    </div>
                </div>
                {bitTenders.tender && <div className='col-12 d-flex justify-content-between mt-3'>
                    <div>
                        <button className="btn btn-sm btn-primary" onClick={_ => this.createChat(bitTenders.ownedBy.email)}>Chat with Buyer</button>
                    </div>
                    <div>
                        <div className={classes.MediaItem}>
                            <span>STATUS : {bitTenders.isAccepted ? 'ACCEPTED' : 'PENDING'}</span>
                            <span>{bitTenders.tender.isActive ? 'ACTIVE' : 'CLOSED'}</span>
                        </div>
                    </div>
                </div>}
            </div>
        ));
        return <div className={classes.Tender}>
            <div className={classes.PartRight}>
                <h5 className='mb-3'>Your Bids on Tender</h5>
                {
                    this.state.message && <div className='badge bg-primary my-3 text-light'>
                        {this.state.message}
                    </div>
                }
                {tenders}
                {!this.props.loading && this.props.bitTenders.length === 0 && <p>You do not bid any tender yet.</p>}
            </div>
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        message: state.tender.message,
        loading: state.tender.loading,
        bitTenders: state.tender.bids,
        token: state.userAuth.token,
        user: state.userAuth.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onGetSellerBids: () => dispatch(actions.getSellerBids()),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SellerBids)