import React from 'react';
import { connect } from 'react-redux';
import './CustomOrder.css';
import { Line } from '../../../../components/UI/Spacing';
import SmButton from '../../../../components/UI/Button/SmButton';
import { onReturnOnlyDigit } from '../../../../shared/utility';
import axios from '../../../../axios';
import {
    tokenHeaders
} from '../../../../store/actions/HeaderConfig';
import SimpleSnackbar from '../../../../components/Snackbar/Snackbar';


const CustomOrderForm = (props) => {
    const { adData: { title, _id, files }, goBack } = props;

    const [formData, setFormData] = React.useState({
        scopes: [],
        deliveryDays: '',
        amount: '',
        description: ''
    });
    const [scope, setScope] = React.useState('');
    const [error, showError] = React.useState('');
    const [serverError, showServerError] = React.useState('');
    const [success, showSuccess] = React.useState('');
    const messagesEndRef = React.createRef();
    const [loading, setLoading] = React.useState(false);

    const scrollToBottom = () => messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useEffect(() => scrollToBottom(), [scope]);


    const onChangeInput = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.name === 'description' ? e.target.value : onReturnOnlyDigit(e.target.value)
        });
    }

    const addScope = () => {
        if (scope) {
            formData.scopes.push(scope);
            setScope('');
        }
    }

    const removeScope = (index) => {
        setFormData({
            ...formData,
            scopes: formData.scopes.filter((scope, i) => i !== index)
        });
    }

    const onsubmit = (e) => {
        e.preventDefault();
        const { scopes, deliveryDays, amount, description } = formData;
        if (scopes.length === 0) {
            showError('Please add at least one scope');
            return;
        }
        if (!deliveryDays) {
            showError('Please add delivery time');
            return;
        }
        if (!amount) {
            showError('Please add amount');
            return;
        }
        if (!description) {
            showError('Please add description');
            return;
        }
        const url = '/chat/createCustomOrder';

        const body = {
            scopes,
            deliveryDays,
            amount,
            adInfo: {
                adId: _id,
                title,
                file: files[0]
            },
            description: description.trim(),
            fromEmail: props.user.email,
            toEmail: props.currentUser.email
        }
        setLoading(true);
        axios.post(url, body,
            tokenHeaders(props.token)).then((info) => {
                showSuccess(info.data.message);
                setLoading(false);
                props.addToConversation({
                    files: [],
                    message: '',
                    sentAt: Date.now(),
                    type: 'sent',
                    customOrder: body
                });
                setTimeout(() => {
                    props.closeModal();
                }, 2000)
            }).catch(error => showServerError(error.message));
    }

    if (error) {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        setTimeout(() => {
            showError('');
            scrollToBottom();
        }, 3000);
    }

    const { scopes, deliveryDays, amount, description } = formData;

    return <React.Fragment>
        {serverError &&
            <SimpleSnackbar open={true} message={serverError} type='error' />}
        {success &&
            <SimpleSnackbar open='true' message={success} type='success' />}
        <div className='p-sm-4 p-3 CustomOrderMain'>
            {error && <div className="alert alert-warning alert-dismissible fade show" role="alert">
                <strong>{error}.</strong>
            </div>}
            <h6>{title}</h6>
            <div className='OrderFormHeader my-3 row'>
                <div className='col-4 col-sm-3'>
                    <img src={files[0]} alt='Ad img'></img>
                </div>
                <div className="form-group col-8 col-sm-9">
                    <textarea
                        onChange={onChangeInput}
                        className="form-control"
                        placeholder='Describe your order'
                        value={description}
                        name='description'
                        maxLength={500}
                        rows={props.screenWidth > 800 ? '3' : '2'
                        }>
                    </textarea>
                </div>
            </div>
            <div className='rounded border bg-light my-3 p-3'>
                <div className='row align-items-center'>
                    <label className='text-secondary col-9'>Total Offer Amount *</label>
                    <div className="form-group col-3">
                        <input
                            value={amount}
                            name='amount'
                            type="text"
                            className="form-control"
                            onChange={onChangeInput}
                            placeholder='amount($)'
                        />
                    </div>
                </div>
                <Line height='1' width='100' orientation='h' space='2' />
                <div className='row align-items-center'>
                    <label className='text-secondary col-9'>Delivery WIthin *</label>
                    <div className="form-group col-3">
                        <input value={deliveryDays}
                            type="text"
                            name='deliveryDays'
                            className="form-control"
                            onChange={onChangeInput}
                            placeholder='days'
                        />
                    </div>
                </div>
            </div>
            <div className='my-4'>
                <div>
                    <label className='text-secondary'>Define the Offer Scopes *</label>
                </div>
                <div className='row align-items-center mt-2'>
                    <div className="form-group col-8 col-sm-10">
                        <input
                            type="text"
                            value={scope}
                            onChange={e => setScope(e.target.value)}
                            className="form-control"
                            placeholder='info'
                        />
                    </div>
                    <div className="col-sm-2 col-4">
                        <button className='btn bg-warning w-100' onClick={addScope}>Add</button>
                    </div>
                </div>
            </div>
            {scopes.map((scope, index) => {
                return <div className='d-flex p-2' key={index}>
                    <div className='ScopeIndex col-1'>{index + 1}</div>
                    <div className='mx-2 col-10 text-muted mt-1'>
                        <label>{scope}</label>
                    </div>
                    <div className='ScopeIndex ScopeIndexR col-1'>
                        <i className='fa fa-times' onClick={_ => removeScope(index)}></i>
                    </div>
                </div>
            })}
            <div ref={messagesEndRef} />
        </div>
        <div className='FormBottomMain'>
            <div className='FormBottom'>
                <Line height='2' width='100' orientation='h' space='2' />
                <div className='d-flex justify-content-between mx-3 mx-sm-4'>
                    <SmButton buttonType='AppleDark' clicked={goBack}>
                        <i className='fas fa-chevron-left mx-1'></i>Back</SmButton>
                    <SmButton buttonType='Submit' clicked={onsubmit}>{loading ? 'Submitting...' : 'Submit Offer'} </SmButton>
                </div>
            </div>
        </div>
    </React.Fragment >
}


const mapStateToProps = (state) => {
    return {
        token: state.userAuth.token,
        currentUser: state.chat.currentUser,
        user: state.userAuth.user,
        screenWidth: state.responsiveness.screenWidth,
    }
}


const mapDispatchToProps = (dispatch) => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomOrderForm);
