// @ts-nocheck
import React, { Component } from 'react'
import i18next from 'i18next'
import { withTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import classes from './NavigationItems.module.css'
import NavigationItem from './NavigationItem/NavigationItem'
import { getAllUnreadMessages } from '../../../../store/actions/ActionsFiles/Chat'
import SwitchUser from '../../SwitchUser'

import {
  DashboardOutlined,
  HomeOutlined,
  ContactMailOutlined,
  InfoOutlined,
  AccountCircleOutlined,
  AddBoxOutlined,
  ExitToAppOutlined,
  CategoryOutlined,
  TvOutlined,
  RadioOutlined,
  NetworkCellOutlined,
  PrintOutlined,
  MusicNoteOutlined,
  EventOutlined,
  StyleOutlined,
  SportsOutlined,
  LocalActivityOutlined,
  MessageOutlined,
  ViewListOutlined,
  AllOutOutlined,
  AttachMoneyRounded,
  PictureInPictureAltOutlined,
  SupervisedUserCircleOutlined
} from '@material-ui/icons'

class NavigationItemsForNormal extends Component {
  constructor(props) {
    super(props)
    let lang = localStorage.getItem('i18nextLng')
    this.state = {
      showMenu: false,
      showBrowse: false,
      IconValue1: 'fas fa-angle-down',
      IconValue2: 'fas fa-angle-down',
      IconValue3: 'fas fa-angle-down',
      selectedValue: lang,
      showMenuDashboard: false,
      showPartner: false,
    }
    this.showMenu = this.showMenu.bind(this)
    this.closeMenu = this.closeMenu.bind(this)
    this.showMenuDashboard = this.showMenuDashboard.bind(this)
    this.closeMenuDashboard = this.closeMenuDashboard.bind(this)
    this.showBrowse = this.showBrowse.bind(this)
    this.closeBrowse = this.closeBrowse.bind(this)
    this.showPartner = this.showPartner.bind(this)
    this.closePartner = this.closePartner.bind(this)
  }

  componentDidMount() {
    this.props.allUnreadMessages()
  }

  showMenu(event, showMenu) {
    event.preventDefault()

    this.setState({ showMenu, IconValue1: 'fas fa-angle-up' }, () => {
      document.addEventListener('click', this.closeMenu)
    })
  }

  closeMenu() {
    this.setState({ showMenu: false, IconValue1: 'fas fa-angle-down' }, () => {
      document.removeEventListener('click', this.closeMenu)
    })
  }

  showBrowse(event, showBrowse) {
    event.preventDefault()

    this.setState({ showBrowse, IconValue2: 'fas fa-angle-up' }, () => {
      document.addEventListener('click', this.closeBrowse)
    })
  }

  closeBrowse() {
    this.setState({ showBrowse: false, IconValue2: 'fas fa-angle-down' }, () => {
      document.removeEventListener('click', this.closeBrowse)
    })
  }

  showMenuDashboard(event, showMenuDashboard) {
    event.preventDefault()

    this.setState({ showMenuDashboard, IconValue3: 'fas fa-angle-up' }, () => {
      document.addEventListener('click', this.closeMenuDashboard)
    })
  }

  closeMenuDashboard() {
    this.setState(
      { showMenuDashboard: false, IconValue3: 'fas fa-angle-down' },
      () => {
        document.removeEventListener('click', this.closeMenuDashboard)
      },
    )
  }

  showPartner(event, showPartner) {
    event.preventDefault()
    this.setState({ showPartner }, () => {
      document.addEventListener('click', this.closePartner)
    })
  }

  closePartner() {
    this.setState({ showPartner: false }, () => {
      document.removeEventListener('click', this.closePartner)
    })
  }

  handleChange = (e) => {
    this.setState({
      selectedValue: e.target.value,
    })

    i18next.changeLanguage(e.target.value)
    localStorage.setItem('translateTo', e.target.value === 'en-US' ? 'en' : 'fr');
    window.location.reload();
  }

  render() {
    const { t } = this.props
    const categories = [
      {
        name: t('appStartToolbar.0'),
        path: '/tv',
        icon: <TvOutlined />,
      },
      {
        name: t('appStartToolbar.10'),
        path: '/billboard',
        icon: <PictureInPictureAltOutlined />,
      },
      {
        name: t('appStartToolbar.1'),
        path: '/radio',
        icon: <RadioOutlined />,
      },
      {
        name: t('appStartToolbar.2'),
        path: '/internet',
        icon: <NetworkCellOutlined />,
      },
      {
        name: t('appStartToolbar.3'),
        path: '/sports',
        icon: <SportsOutlined />,
      },
      {
        name: t('appStartToolbar.4'),
        path: '/cinema',
        icon: <TvOutlined />,
      },
      {
        name: t('appStartToolbar.5'),
        path: '/print-media',
        icon: <PrintOutlined />,
      },
      {
        name: t('appStartToolbar.6'),
        path: '/music',
        icon: <MusicNoteOutlined />,
      },
      {
        name: t('appStartToolbar.8'),
        path: '/event',
        icon: <EventOutlined />,
      },
      {
        name: t('appStartToolbar.9'),
        path: '/life-style',
        icon: <StyleOutlined />,
      },
    ]
    return (
      <ul className={classes.NavigationItems}>
        <div className={classes.Divider}></div>
        {!this.props.isAuthenticated ? (
          <React.Fragment>
            <NavigationItem link="/join">
              <div
                className={classes.DropMenuStyle}
                onClick={this.props.closeSidebar}>
                <AddBoxOutlined />
                <span>{t('sideDrawer.4')}</span>
              </div>
            </NavigationItem>
            <NavigationItem link="/login">
              <div
                className={classes.DropMenuStyle}
                onClick={this.props.closeSidebar}>
                <AccountCircleOutlined />
                <span>{t('sideDrawer.3')}</span>
              </div>
            </NavigationItem>
          </React.Fragment>
        ) : (
          <NavigationItem link="/logout">
            <div
              className={classes.DropMenuStyle}
              onClick={this.props.closeSidebar}>
              <ExitToAppOutlined />
              <span>{t('sideDrawer.5')}</span>
            </div>
          </NavigationItem>
        )}
        <NavigationItem link="/" exact={true}>
          <div className={classes.DropMenuStyle}>
            <HomeOutlined />
            <span>{t('sideDrawer.0')}</span>
          </div>
        </NavigationItem>
        <div className={classes.Divider}></div>
        <div className={classes.dropButton}>
          <div
            className={classes.DropMenuStyle}
            onClick={(e) => this.showMenu(e, true, false)}>
            <CategoryOutlined />
            <span>
              {t('appStartToolbar.drawer')}
              <i className={this.state.IconValue1}></i>
            </span>
          </div>
        </div>
        {this.state.showMenu && (
          <div className={classes.dropdown}>
            <div className={classes.dropdownContent}>
              {categories.map((item, index) => (
                <NavigationItem
                  link={`/find/advertises${ item.path }?searchType=media`}>
                  <div className={classes.DropSubMenuStyle} key={index}>
                    {item.icon}
                    <span>{item.name}</span>
                  </div>
                </NavigationItem>
              ))}
            </div>
          </div>
        )}
        <div className={classes.Divider}></div>
        {this.props.isAuthenticated && (
          <React.Fragment>
            <div className={classes.dropButton}>
              <div
                className={classes.DropMenuStyle}
                onClick={(e) => this.showMenuDashboard(e, true)}>
                <DashboardOutlined />
                <span>
                  {/* My Dashboard */}
                  {t('appStartNavigation.1')}
                  <i className={this.state.IconValue3}></i>
                </span>
              </div>
            </div>
            {this.state.showMenuDashboard && (
              <div className={classes.dropdown}>
                <NavigationItem link={`/${ this.props.user.role }/dashboard`}>
                  <div className={classes.DropSubMenuStyle}>
                    <AccountCircleOutlined />
                    <span>{t('Dashboard:DashboardNavigation.1')}</span>
                  </div>
                </NavigationItem>
                {this.props.user.role === 'seller' ?
                  <React.Fragment>
                    <NavigationItem link="/all-audiences">
                      <div className={classes.DropSubMenuStyle}>
                        <AllOutOutlined />
                        <span>
                          {/* My Audiences */}
                          {t('navigations.6')}
                        </span>
                      </div>
                    </NavigationItem>
                    <NavigationItem
                      link={`/my-all-bids`}>
                      <div className={classes.DropSubMenuStyle}>
                        <AttachMoneyRounded />
                        <span>
                          {/* All Bids */}
                          {t('navigations.7')}
                        </span>
                      </div>
                    </NavigationItem>
                  </React.Fragment>
                  :
                  <NavigationItem
                    link={`/my-all-tenders`}>
                    <div className={classes.DropSubMenuStyle}>
                      <AllOutOutlined />
                      <span>
                        {/* All Tenders */}
                        {t('navigations.8')}
                      </span>
                    </div>
                  </NavigationItem>
                }
                <NavigationItem link={`/${ this.props.user.role }/all-orders`}>
                  <div className={classes.DropSubMenuStyle}>
                    <ViewListOutlined />
                    <span>{t('Dashboard:DashboardNavigation.4')}</span>
                  </div>
                </NavigationItem>
                <NavigationItem link="/inbox">
                  <div className={classes.DropSubMenuStyle}>
                    <MessageOutlined />
                    <span>
                      {t('Dashboard:DashboardNavigation.11')}
                      {this.props.unreadmessages !== 0 && (
                        <span
                          style={{
                            background: 'red',
                            borderRadius: '50%',
                            display: 'inline-block',
                            fontSize: 12,
                            padding: '4px',
                            margin: '1px',
                            color: 'white',
                          }}></span>
                      )}
                    </span>
                  </div>
                </NavigationItem>
              </div>
            )}
          </React.Fragment>
        )}
        <React.Fragment>
          <div className={classes.dropButton}>
            <div
              className={classes.DropMenuStyle}
              onClick={(e) => this.showPartner(e, true)}>
              <SupervisedUserCircleOutlined />
              <span style={{ fontWeight: "bold" }}>
                {t("partnership")}
                <i className={!this.state.showPartner ? 'fas fa-angle-down' : 'fas fa-angle-up'}></i>
              </span>
            </div>
          </div>
          {this.state.showPartner && (
            <div className={classes.dropdown}>
              <NavigationItem
                exact={true}
                link='/new-partner-request'>
                <div className={classes.TextMenu}>
                  <span>
                    {t("be_partner")}
                  </span>
                </div>
              </NavigationItem>
              {this.props.isAuthenticated && this.props.user.role === 'seller' &&
                <NavigationItem
                  exact={true}
                  link="/find-partner">
                  <div className={classes.TextMenu}>
                    <span>
                      {t("partner_request")}
                    </span>
                  </div>
                </NavigationItem>}
              {!this.props.isAuthenticated &&
                <NavigationItem exact={true} link="/find-partner">
                  <div className={classes.TextMenu}>
                    <span>
                      {t("partner_request")}
                    </span>
                  </div>
                </NavigationItem>}
              {this.props.isAuthenticated && <React.Fragment>
                <NavigationItem
                  link="/my-partner-request">
                  <div className={classes.TextMenu}>
                    <span>
                      {t("my_partnership")}
                    </span>
                  </div>
                </NavigationItem>
                {/* <NavigationItem
                  link="/get-paid">
                  <div className={classes.TextMenu}>
                    <span><i className='fa fa-angle-right mx-2'></i>Get paid</span>
                  </div>
                </NavigationItem> */}
              </React.Fragment>}
            </div>
          )}
        </React.Fragment>
        <div className={classes.dropButton}>
          <div
            className={classes.DropMenuStyle}
            onClick={(e) => this.showBrowse(e, true)}>
            <LocalActivityOutlined />
            <span>
              {/* Browse Services */}
              {t('navigations.5')}
              <i className={this.state.IconValue2}></i>
            </span>
          </div>
        </div>
        {this.state.showBrowse && (
          <React.Fragment>
            {this.props.user ? (
              <React.Fragment>
                {this.props.user.role === 'buyer' ? (
                  <React.Fragment>
                    <NavigationItem exact={true} link="/find-audience">
                      <div className={classes.TextMenu}>
                        <span>
                          {t('navigations.2')}
                        </span>
                      </div>
                    </NavigationItem>
                    <NavigationItem link="/launch-tender" exact={true}>
                      <div className={classes.TextMenu}>
                        <span>
                          {t('navigations.0')}
                        </span>
                      </div>
                    </NavigationItem>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <NavigationItem link="/start-selling">
                      <div className={classes.TextMenu}>
                        <span>{t('sideDrawer.1')}</span>
                      </div>
                    </NavigationItem>
                    <NavigationItem exact={true} link="/find-tenders">
                      <div className={classes.TextMenu}>
                        <span>
                          {t('navigations.3')}
                        </span>
                      </div>
                    </NavigationItem>
                  </React.Fragment>
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <NavigationItem exact={true} link="/find-audience">
                  <div className={classes.TextMenu}>
                    <span>
                      {t('navigations.2')}
                    </span>
                  </div>
                </NavigationItem>
                <NavigationItem link="/launch-tender" exact={true}>
                  <div className={classes.TextMenu}>
                    <span>
                      {t('navigations.0')}
                    </span>
                  </div>
                </NavigationItem>
                <NavigationItem link="/start-selling">
                  <div className={classes.TextMenu}>
                    <span>
                      {t('sideDrawer.1')}
                    </span>
                  </div>
                </NavigationItem>
                <NavigationItem exact={true} link="/find-tenders">
                  <div className={classes.TextMenu}>
                    <span>
                      {t('navigations.3')}
                    </span>
                  </div>
                </NavigationItem>

              </React.Fragment>
            )}
          </React.Fragment>
        )}
        {this.props.user &&
          <div className='py-2'>
            <SwitchUser user={this.props.user} t={t} token={this.props.token} />
          </div>}
        <div className={classes.Divider}></div>
        {this.props.isAuthenticated && (
          <NavigationItem link="/profile">
            <div className={classes.DropMenuStyle}>
              <AccountCircleOutlined />
              <span>Profile</span>
            </div>
          </NavigationItem>
        )}
        <NavigationItem link="/about-us">
          <div
            className={classes.DropMenuStyle}
            onClick={this.props.closeSidebar}>
            <InfoOutlined />
            <span>{t('sideDrawer.7')}</span>
          </div>
        </NavigationItem>
        <NavigationItem link="/contact-us">
          <div
            className={classes.DropMenuStyle}
            onClick={this.props.closeSidebar}>
            <ContactMailOutlined />
            <span>{t('sideDrawer.6')}</span>
          </div>
        </NavigationItem>
        <div className="mt-3 mx-1">
          <select
            className="form-select w-100"
            value={this.state.selectedValue}
            onChange={this.handleChange}>
            <option value="en-US">{t('appStartNavigation.6')}</option>
            <option value="fr">{t('appStartNavigation.7')}</option>
          </select>
        </div>
      </ul>
    )
  }
}

const NavigationItemsFuncForNormal = (props) => {
  const history = useHistory()
  return <NavigationItemsForNormal {...props} history={history} />
}

const mapStateToProps = (state) => {
  return {
    user: state.userAuth.user,
    token: state.userAuth.token,
    isAuthenticated: state.userAuth.token !== null,
    unreadmessages: state.chat.unreadmessages,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    allUnreadMessages: () => dispatch(getAllUnreadMessages()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(NavigationItemsFuncForNormal))
