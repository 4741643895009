import React, {  Component } from 'react';
import classes from './Customers.module.css'

class Dashboard extends Component {

    render() {
        return (
            <div className={classes.Dashboard}>

                <div >
                 <h2>Customers</h2>
                </div>
            </div>
        );
    }
};

export default Dashboard;
