import * as actionTypes from "../ActionTypes";
import axios from "../../../axios";
import { tokenHeadersNormal } from "../HeaderConfig";

const tenderStart = () => {
    return {
        type: actionTypes.START_TENDER,
    };
};

const tendersSuccess = (tenders, message) => {
    return {
        type: actionTypes.TENDERS_FETCH_SUCCESS,
        payload: { tenders, message }
    };
};

const bidsSuccess = (bids) => {
    return {
        type: actionTypes.BIDS_FETCH_SUCCESS,
        payload: { bids }
    };
};

const tenderSuccess = (tender) => {
    return {
        type: actionTypes.TENDER_FETCH_SUCCESS,
        payload: { tender }
    };
};

const tenderFail = (message) => {
    return {
        type: actionTypes.TENDER_FAIL,
        payload: { message }
    };
};

const tenderModeChange = (message) => {
    return {
        type: actionTypes.TENDER_MODE_CHANGE,
        payload: { message }
    };
}

export const postTender = (tender) => {
    return (dispatch, getState) => {
        dispatch(tenderStart());
        axios
            .post('/tender/new-tender',
                { tender, hasUserSubscription: getState().userAuth.user.is_subscribed },
                { headers: tokenHeadersNormal(getState).headers })
            .then(response => dispatch(tendersSuccess([], response.data.message)))
            .catch(error => {
                if (error.response === undefined) return;
                dispatch(tenderFail(error.response.data.message));
            });
    };
};

export const editTender = (tender, tenderId) => {
    return (dispatch, getState) => {
        dispatch(tenderStart());
        axios
            .post('/tender/edit-tender',
                { tender, tenderId },
                { headers: tokenHeadersNormal(getState).headers })
            .then(response => dispatch(tendersSuccess([], response.data.message)))
            .catch(error => {
                if (error.response === undefined) return;
                dispatch(tenderFail(error.response.data.message));
            });
    };
};

export const onGetTenders = (filters) => {
    return (dispatch) => {
        dispatch(tenderStart());
        axios
            .post('/tender/onGetTenders', filters)
            .then((response) => dispatch(tendersSuccess(response.data.tenders, '')))
            .catch(error => {
                if (error.response === undefined) return;
                dispatch(tenderFail(error.response.data.message));
            });
    };
};

export const getSingleTender = (tenderId) => {
    return (dispatch) => {
        axios
            .get(`/tender/getSingleTender?tenderId=${tenderId}`)
            .then(response => dispatch(tenderSuccess(response.data.tenderData)))
            .catch(error => {
                if (error.response === undefined) return;
                dispatch(tenderFail(error.response.data.message));
            });
    };
};

export const postBidTender = (tenderData, tenderId) => {
    return (dispatch, getState) => {
        dispatch(tenderStart());
        axios
            .post('/tender/postBidTender',
                { tenderData, tenderId },
                { headers: tokenHeadersNormal(getState).headers })
            .then(response => dispatch(tendersSuccess([], response.data.message)))
            .catch(error => {
                if (error.response === undefined) return;
                dispatch(tenderFail(error.response.data.message));
            });
    };
};

export const getBuyerTenders = () => {
    return (dispatch, getState) => {
        dispatch(tenderStart());
        axios
            .get('/tender/getBuyerTenders', { headers: tokenHeadersNormal(getState).headers })
            .then(response => dispatch(tendersSuccess(response.data.tenders, '')))
            .catch(error => {
                if (error.response === undefined) return;
                dispatch(tenderFail(error.response.data.message));
            });
    };
};

export const getBidsTenders = () => {
    return (dispatch, getState) => {
        axios
            .get('/tender/getBidsTenders', { headers: tokenHeadersNormal(getState).headers })
            .then(response => dispatch(bidsSuccess(response.data.bids)))
            .catch(error => {
                if (error.response === undefined) return;
                dispatch(tenderFail(error.response.data.message));
            });
    };
};

export const getSellerBids = () => {
    return (dispatch, getState) => {
        dispatch(tenderStart());
        axios
            .get('/tender/getSellerBids', { headers: tokenHeadersNormal(getState).headers })
            .then(response => dispatch(bidsSuccess(response.data.bids)))
            .catch((error) => {
                if (error.response === undefined) return;
                dispatch(tenderFail(error.response.data.message));
            });
    };
};

export const getAllSellerBids = (tenderId) => {
    return (dispatch) => {
        dispatch(tenderStart());
        axios
            .get(`/tender/getAllSellerBids?tenderId=${tenderId}`)
            .then(response => dispatch(bidsSuccess(response.data.bids)))
            .catch(error => {
                if (error.response === undefined) return;
                dispatch(tenderFail(error.response.data.message));
            });
    };
};

export const onChangeTenderMode = (modeType, tenderId) => {
    return (dispatch, getState) => {
        axios
            .patch(`/tender/changeTenderMode`,
                { modeType, tenderId },
                { headers: tokenHeadersNormal(getState).headers })
            .then(response => dispatch(tenderModeChange(response.data.message)))
            .catch(error => {
                if (error.response === undefined) return;
                dispatch(tenderFail(error.response.data.message));
            });
    };
};

export const deleteTender = (tenderId) => {
    return (dispatch, getState) => {
        axios
            .delete(`/tender/deleteTender?tenderId=${tenderId}`,
                { headers: tokenHeadersNormal(getState).headers })
            .then(response => dispatch(tenderModeChange(response.data.message)))
            .catch(error => {
                if (error.response === undefined) return;
                dispatch(tenderFail(error.response.data.message));
            });
    };
};

export const acceptBid = (tenderId, bidId) => {
    return (dispatch, getState) => {
        axios
            .get(`/tender/acceptBid?tenderId=${tenderId}&bidId=${bidId}`,
                { headers: tokenHeadersNormal(getState).headers })
            .then(response => dispatch(tenderModeChange(response.data.message)))
            .catch(error => {
                if (error.response === undefined) return;
                dispatch(tenderFail(error.response.data.message));
            });
    };
};

