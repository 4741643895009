import React from 'react'
import { withTranslation } from 'react-i18next'
import i18next from 'i18next'
import axios from '../../../../axios'
import classes from './NavigationItems.module.css'
import { NormalNavigationItem } from './NavigationItem/NavigationItem'
import { Link, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { getAllUnreadMessages } from '../../../../store/actions/ActionsFiles/Chat';
import ProfileInfo from './ProfileInfo';
import SwitchUser from '../../SwitchUser';

class NavigationItems extends React.Component {
  constructor(props) {
    super(props)
    let lang = localStorage.getItem('i18nextLng')
    this.state = {
      selectedValue: lang,
    }
  }

  componentDidMount() {
    this.props.allUnreadMessages()
  }

  handleChange = (e) => {
    this.setState({
      selectedValue: e.target.value,
    });

    i18next.changeLanguage(e.target.value);
    localStorage.setItem('translateTo', e.target.value === 'en-US' ? 'en' : 'fr');
    window.location.reload();
  }

  tokenHeadersNormal = () => {
    const headersNormal = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    const token = this.props.token
    if (token) {
      headersNormal.headers['x-auth-header'] = token
    }
    return headersNormal;
  }

  switchUserAxios = async (data) => {
    const res = await axios({
      method: 'POST',
      url: '/user/switch',
      data: data,
      headers: this.tokenHeadersNormal().headers,
    })
    return res.data
  }

  switchUser = async (data) => {
    let cc = ''
    try {
      cc = await this.switchUserAxios(data)
      if (this.props.user.role === 'buyer') {
        this.props.history.replace('/seller/dashboard')
      } else {
        this.props.history.replace('/buyer/dashboard')
      }
      if (cc.message === 'Switched successfully!') {
        setTimeout(() => {
          window.location.reload()
        }, 100)
      }
    } catch (e) {
      console.log(e, 'error in switchUserAxios')
    }
  }

  render() {
    const { t } = this.props

    return <React.Fragment>
      <ul className={classes.NavigationItems}>

        <div className={classes.dropdownForUser}>
          <React.Fragment>
            <span className={[classes.dropdownMenu, classes.dropdownMenuOnScroll].join(' ')}>
              {t("partnership")}<i className='fa fa-caret-down mx-1'></i>
            </span>
              <div className={classes.dropdownContentForUser}>
                <NormalNavigationItem
                  exact={true}
                  link="/new-partner-request">
                  <div className={classes.DropMenuStyle}>
                    <span>
                     {t("be_partner")}
                    </span>
                  </div>
                </NormalNavigationItem>
                {this.props.user.role === 'seller' &&
                  <NormalNavigationItem
                    exact={true}
                    link="/find-partner">
                    <div className={classes.DropMenuStyle}>
                      <span>
                     {t("partner_request")}
                      </span>
                    </div>
                  </NormalNavigationItem>}
                <NormalNavigationItem
                  exact={true}
                  link="/my-partner-request">
                  <div className={classes.DropMenuStyle}>
                    <span>
                     {t("my_partnership")}
                    </span>
                  </div>
                </NormalNavigationItem>
                {/* <NormalNavigationItem
                  exact={true}
                  link="/get-paid">
                  <div className={classes.DropMenuStyle}>
                    <span>
                      <i className='fa fa-angle-right mx-1'></i>Get paid
                    </span>
                  </div>
                </NormalNavigationItem> */}
              </div>
          </React.Fragment>
        </div>

        <div className={classes.dropdownForUser}>
          <NormalNavigationItem
            scrolled={this.props.scrolled}
            link={`/${ this.props.user.role }/dashboard`}>
            {t('Dashboard:DashboardNavigation.1')}<i className='fa fa-caret-down mx-1'></i>
          </NormalNavigationItem>
          <div className={classes.dropdownContentForUser}>
            {this.props.user.role === 'seller' ?
              <React.Fragment>
                <NormalNavigationItem
                  exact={true}
                  link="/all-audiences">
                  {t('navigations.6')}
                </NormalNavigationItem>
                <NormalNavigationItem
                  exact={true}
                  link="/my-all-bids">
                  {t('navigations.7')}
                </NormalNavigationItem>

              </React.Fragment>
              : <React.Fragment>
                <NormalNavigationItem
                  link={`/my-all-tenders`}>
                  {t('navigations.8')}
                </NormalNavigationItem>
              </React.Fragment>}
            <NormalNavigationItem
              link={`/${ this.props.user.role }/all-orders`}>
              {t('navigations.9')}
            </NormalNavigationItem>
            {this.props.user.role === 'seller' && (
              <NormalNavigationItem exact={true} link="/seller/earnings">
                {t('earnings')}
              </NormalNavigationItem>
            )}
            <NormalNavigationItem link="/inbox">
              Messages
              {this.props.unreadmessages !== 0 && (
                <span
                  style={{
                    background: 'red',
                    borderRadius: '50%',
                    display: 'inline-block',
                    fontSize: 12,
                    padding: '4px',
                    margin: '2px',
                    color: 'white',
                  }}></span>
              )}
            </NormalNavigationItem>
          </div>
        </div>
        {this.props.user && this.props.user.role === 'seller' ? (
          <React.Fragment>
            <div className={classes.dropdownForUser}>
              <div className={[classes.dropdownMenu, classes.dropdownMenuOnScroll].join(' ')}>
                <span>
                  {t('navigations.5')}<i className='fa fa-caret-down mx-1'></i>
                </span>
              </div>
              <div className={classes.dropdownContentForUser}>
                <NormalNavigationItem exact={true} link="/find-tenders">
                  {t('navigations.3')}
                </NormalNavigationItem>
                <NormalNavigationItem exact={true} link="/start-selling">
                  {t('navigations.10')}
                </NormalNavigationItem>
              </div>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className={classes.dropdownForUser}>
              <div className={[classes.dropdownMenu, classes.dropdownMenuOnScroll].join(' ')}>
                <span>
                  {t('navigations.5')}<i className='fa fa-caret-down mx-1'></i>
                </span>
              </div>
              <div className={classes.dropdownContentForUser}>
                <NormalNavigationItem exact={true} link="/find-audience">
                {t('navigations.2')}
                </NormalNavigationItem>
                <NormalNavigationItem exact={true} link="/launch-tender">
                  {t('navigations.0')}
                </NormalNavigationItem>
              </div>
            </div>
          </React.Fragment>
        )}

        {this.props.isAuthenticated && (
          <React.Fragment>
            <SwitchUser
              user={this.props.user}
              t={t}
              token={this.props.token}
            />
            <Link to="/profile">
              <ProfileInfo user={this.props.user} />
            </Link>
          </React.Fragment>
        )}

        <div className="mx-2">
          <select
            className="form-select "
            value={this.state.selectedValue}
            onChange={this.handleChange}>
            <option value="en-US">{t('appStartNavigation.6')}</option>
            <option value="fr">{t('appStartNavigation.7')}</option>
          </select>
        </div>
      </ul>
    </React.Fragment>
  }
}

const NavigationItemsFunc = (props) => {
  const history = useHistory()
  return <NavigationItems {...props} history={history} />
}

const mapStateToProps = (state) => {
  return {
    user: state.userAuth.user,
    isAuthenticated: state.userAuth.token !== null,
    token: state.userAuth.token,
    unreadmessages: state.chat.unreadmessages,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    allUnreadMessages: () => dispatch(getAllUnreadMessages()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation(['translation', 'Dashboard'])(NavigationItemsFunc))
