import React from 'react';
import styles from './ImageSlider.module.css';
import { Link } from 'react-router-dom';
import Translation from '../../../shared/Translate';
import {Translate} from 'react-auto-translate'

const SliderItem = ({ image, text, link }) => {
    return <Link to={`/find/advertises/${link.toLowerCase()}?searchType=domain`}>
        <div className={styles.AppSlide}>
            <img src={image} alt={text} />
            <div className={styles.CenteredItem}>
                <h6>
              <Translation text={text} input={<Translate>{text}</Translate>} /></h6>
            </div>
        </div>
    </Link>
};

export default SliderItem;