import React from 'react'
import classes from './components/Profile.module.css';
import { connect } from 'react-redux';
import { Line } from '../../../components/UI/Spacing'
import '../../../components/Tabs/Tabs.css'
import * as actions from '../../../store/actions';
import { Spinner } from '../../../components/UI/Spinner/Spinner';
import { getDate } from '../../../shared/utility'
import { RowItem, Label, LabelValue, ProfileRowItem } from './components/Items'
// import {requestFirebaseNotificationPermission} from '../Chat/firebaseinit'
// import axios from '../../../axios';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';


class ProfileInfo extends React.Component {
  componentDidMount() {
    window.scroll(0.0, 0.0);
    this.props.onGetProfile();
    this.props.fetchUserAdvertises();
    // this.saveBrowserTokenToDb()
  }

  // tokenHeadersNormal = () => {
  //   const headersNormal = {
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //   }
  //   const token = this.props.token
  //   if (token) {
  //     headersNormal.headers['x-auth-header'] = token
  //   }
  //   return headersNormal
  // }

  // saveBrowserTokenToDb = () => {
  //   const user = this.props.user
  //   requestFirebaseNotificationPermission()
  //     .then((firebaseToken) => {
  //       const url = '/chat/saveBrowserToken'
  //       axios
  //         .post(
  //           url,
  //           {token: firebaseToken, email: user.email},
  //           {headers: this.tokenHeadersNormal().headers},
  //         )
  //         .then((_) => {})
  //         .catch((_) => {
  //           console.log('ALREADY EXISTS')
  //         })
  //     })
  //     .catch((err) => {
  //       console.log('CHAT-E', err)
  //       return err
  //     })
  // }


  specificItemsCount = (itemsArray) => {
    const allItems = []
    const counts = {}
    itemsArray.forEach(
      (element) => (counts[element] = (counts[element] || 0) + 1),
    )
    for (let item in counts) {
      const _item = {}
      _item.name = item
      _item.value = counts[item]
      allItems.push(_item)
    }
    return allItems;
  }

  render() {
    const { t } = this.props;
    const workingDomains = [];
    const workingMedia = [];

    if (this.props.advertisesData) {
      this.props.advertisesData.filter((advertise) => {
        workingMedia.push([...advertise.types]);
        advertise.domains.forEach(data => workingDomains.push(data.domain));

        return advertise.is_published === true;
      })
    };

    let profileData = <Spinner />
    if (this.props.profile) {
      const {
        gender,
        birth,
        description,
        country,
        cover_photo,
        contact_number,
        street,
        state,
        city,
        zip_code,
        user,
      } = this.props.profile

      const { full_name, username, profile_image, createdAt, role, accountType, referral_code } = user

      const number = contact_number
        ? contact_number.split('$', 2)[0] + contact_number.split('$', 2)[1]
        : ''
      profileData = (
        <React.Fragment>
          <div className={classes.CoverPhoto}>
            <img src={cover_photo} alt="cover img" />
            <div className={classes.Status}>
              <small>
                <i className="fas fa-circle mx-1"></i>{t('online')}
              </small>
            </div>
            <div className={classes.UserImage}>
              <img src={profile_image} alt="profile" />
              <small className={classes.Level}>{t('level')} 1</small>
            </div>
          </div>
          <div
            className={classes.PartLeftItem}
            style={{
              borderTopLeftRadius: '0px',
              borderTopRightRadius: '0px',
              borderTop: 'none',
            }}>
            <h5 className="fw-bold">{username}</h5>
            <div className='row'>
              <div className="col-lg-6 col-12">
                <button
                  onClick={() => this.props.history.push('./edit-profile')}
                  type="button"
                  className="btn btn-primary mt-3 w-100 fw-bold">
                  {t('editProfile')}
                </button>
              </div>
              <div className="col-lg-6 col-12">
                <a
                  className="btn btn-outline-primary mt-3 w-100 fw-bold"
                  href={`/profile/${ role }/${ username }`}
                  target="_blank"
                  rel="noopener noreferrer">
                  {t('previewProfile')}
                </a>
              </div>
            </div>
            {!user.is_verified && <div className='my-3'>
              <span>{t('emailNotVerified')}
                <Link to='/account/verify-email' className='mx-2'>{t('verifyEmail')}</Link></span>
            </div>}
            <Line orientation="h" space="3" height="1" width="100" />
            <ProfileRowItem
              icon="fas fa-user-tag"
              label={t('roleAs')}
              value={role.charAt(0).toUpperCase() + role.slice(1)}
            />
            <ProfileRowItem
              icon="fas fa-user-cog"
              label={t('accType')}
              value={accountType}
            />

            <ProfileRowItem
              icon="fas fa-map-marker-alt"
              label={t('country')}
              value={country}
            />
            <ProfileRowItem
              icon="fas fa-user"
              label={t('memberSince')}
              value={getDate(createdAt)}
            />
            <ProfileRowItem
              icon="far fa-clock"
              label={t('avgResponse')}
              value="2H"
            />
            <ProfileRowItem
              icon="fas fa-paper-plane"
              label={t('lastDelivary')}
              value={"1h " + t('ago')}
            />
            <div className='my-1'>
              <span className='text-secondary fw-lighter'><i className='fas fa-code-branch mx-2'></i>{t('referral')}</span>
            </div>
            <div className='m-2'>
              <div className='text-primary rounded bg-light my-1 p-2'><span>
                {"https://umediad.com/join?code=" + referral_code}</span></div>
            </div>
            <Line orientation="h" space="2" height="1" width="100" />
            <div className="row justify-content-between align-items-center">
              <div className="col-5">
                <span className="text-secondary fw-lighter ">
                  <i className="fas fa-calendar-check mx-2"></i>{t('availability')}
                </span>
              </div>
              <div className="col-6">
                <button type="button" className="btn btn-outline-primary w-100">
                  {t('setAvailability')}
                </button>
              </div>
            </div>
          </div>
          <div className={classes.PartLeftItem}>
            <Label label={t('workingMedia')} />
            <LabelValue
              value={this.specificItemsCount(workingMedia).map(
                (media, index) => (
                  <span key={index} className="badge bg-primary mx-1">
                    {media.name}({media.value})
                  </span>
                ),
              )}
            />
            <Label label={t('checkedDomain')} />
            <LabelValue
              value={this.specificItemsCount(workingDomains).map(
                (domain, index) => (
                  <span key={index} className="badge bg-success fw-light mx-1">
                    {domain.name}({domain.value})
                  </span>
                ),
              )}
            />
            <Label label={t('totalAudience')} />
          </div>
          <div className={classes.PartLeftItem}>
            <Label label={t('companyName')} />
            <LabelValue value={full_name} />
            <Label label={t('descripton')} />
            <LabelValue value={description} />
            <Line orientation="h" space="2" height="1" width="100" />
            <Label label={t('languages')} />
            <div className="col-12 my-2">
              <div className="row justify-content-center align-items-center my-2">
                <small className="text-secondary">
                  {t('english')} - <small className="text-danger mx-1">{t('fluent')}</small>
                </small>
              </div>
            </div>
            <Line orientation="h" space="2" height="1" width="100" />
            <Label label={t('address')} />
            <RowItem label={t('state')} value={state} />
            <RowItem label={t('city')} value={city} />
            <RowItem label={t('street')} value={street} />
            <RowItem label={t('zipCode')} value={zip_code} />
            <Line orientation="h" space="3" height="1" width="100" />
            <Label label={t('contactNo')} />
            <LabelValue value={number ? number : '-'} />
            <Label label={t('gender')} />
            <LabelValue value={gender ? gender : '-'} />
            <Label label={t('dateOfBirth')} />
            <LabelValue value={birth ? getDate(birth) : '-'} />
          </div>
        </React.Fragment>
      )
    }
    return (
      <div className={classes.Profile}>
        <div className={classes.PublicProfile}>{profileData}</div>
      </div >
    )
  }
}

const mapStateToProps = (state) => {
  return {
    buy: state.buy,
    user: state.userAuth.user,
    token: state.userAuth.token,
    profile: state.profile.profile,
    advertisesData: state.dashboardAdvertise.userCreatedAdvertises,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onGetProfile: () => dispatch(actions.getProfile()),
    fetchUserAdvertises: () => dispatch(actions.fetchUserAdvertises())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(['translation'])(ProfileInfo))

