import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import axios from '../../../axios'
import * as actions from '../../../store/actions'
import SimpleSnackbar from '../../../components/Snackbar/Snackbar'
import { SpinnerAbsolute } from '../../../components/UI/Spinner/Spinner'
import classes from './MyOrders.module.css'
// import Button from '../../../components/UI/Button/Button'
// import Aux from '../../../hoc/Aux/Aux'
// import {Fade} from 'react-reveal'
// import OrdersInfo from './OrdersInfo'

class MyOrders extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      canceled: false,
      //   billing_info: '',
      error: '',
      isLoading: false,
    }
  }

  componentDidMount() {
    this.setState({ isLoading: true })
    this.props.getBuyInfo()
    if (this.props.location.search) {
      const search = this.props.location.search
      const params = new URLSearchParams(search)
      let canceled = params.get('canceled')
      let mm = params.get('mm')
      if (canceled && mm === 'cancel') {
        this.setState({ canceled: !this.state.canceled })
        this.setState({ isLoading: false })
      } else {
        let session_id = params.get('session_id')
        let advertiseId = params.get('advertiseId')
        let type = params.get('type')
        let sellerId = params.get('sellerId')

        // let adType = params.get('adType')
        // let adSubtype = params.get('adSubtype')
        // let adTitle = params.get('adTitle')
        // let advertiseObj = {}
        // advertiseObj['type'] = adType
        // advertiseObj['subtype'] = adSubtype
        // advertiseObj['title'] = adTitle
        // let advertiseInfo = JSON.stringify(advertiseObj)

        this.saveToDatabase(
          session_id,
          advertiseId,
          type,
          sellerId,
          // advertiseInfo,
        )
        this.setState({ isLoading: false })
      }
    }
    this.setState({ isLoading: false })
  }

  tokenHeadersNormal = () => {
    const headersNormal = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    const token = this.props.token
    if (token) {
      headersNormal.headers['x-auth-header'] = token
    }
    return headersNormal
  }

  fetchSuccessData = async (sessionId) => {
    const res = await axios({
      method: 'GET',
      url: '/stripe/payment-session?sessionId=' + sessionId,
      headers: this.tokenHeadersNormal().headers,
    })
    return res.data.session
  }

  saveToDatabase = async (
    sessionId,
    advertiseId,
    type,
    sellerId,
    // advertiseInfo,
  ) => {
    let cc
    try {
      cc = await this.fetchSuccessData(sessionId)
    } catch (e) {
      console.log(e, 'save-to-database')
    }
    if (cc) {
      // setup_intent is "package_type" here
      const {
        customer,
        currency,
        customer_details,
        id,
        payment_status,
        // subscription,
        total_details,
        shipping,
        amount_subtotal,
      } = cc
      const { email } = customer_details
      const { amount_discount, amount_tax } = total_details
      this.props.uploadBuyData({
        packageId: advertiseId,
        priceId: amount_subtotal / 100,
        customer: customer,
        currency: currency,
        payment_status: payment_status,
        setup_intent: type,
        shipping: shipping,
        subscription: 'subscription',
        amount_discount: amount_discount,
        amount_tax: amount_tax,
        customer_email: email,
        sessionId: id,
        sellerId: sellerId,
        // advertiseInfo: advertiseInfo,
      })
    }
  }

  renderOrders = () => {
    const paymentData = this.props.buy.data
    if (paymentData.length > 0) {
      return paymentData.map((single) => {
        return (
          <div key={single._id} className="mt-1 mb-2">
            <Link
              to={{
                pathname: `/advertise/description/${ single.advertiseId._id }`,
              }}
              style={{ textDecoration: 'none' }}>
              <div className="mt-1 mb-1">
                <div className={classes.hoverChange}>
                  <div className="row justify-content-center">
                    <div className="d-flex">
                      <div className="p-3" style={{ color: 'black' }}>
                        <div className="text-primary">
                          <span>
                            {single.advertiseId.type
                              ? single.advertiseId.type
                              : ''}
                          </span>
                          <span> | </span>
                          <span>
                            {single.advertiseId.subtype
                              ? single.advertiseId.subtype
                              : ''}
                          </span>
                        </div>
                        <div>
                          <span>Title</span>
                          <span className="badge bg-secondary ms-2">
                            {single.advertiseId.title
                              ? single.advertiseId.title
                              : ''}
                          </span>
                        </div>
                        <div>
                          <span>Package</span>
                          <span className="badge bg-primary ms-2">
                            {single.paymentId.setup_intent
                              ? single.paymentId.setup_intent
                              : ''}
                          </span>
                        </div>
                        <div>
                          <span>Price</span>
                          <span className="badge bg-primary ms-2">
                            $
                            {single.paymentId.priceId
                              ? single.paymentId.priceId
                              : ''}
                          </span>
                        </div>
                        <div>
                          <span>Payment Status</span>
                          <span className="badge bg-primary ms-2">
                            {single.paymentId.payment_status
                              ? single.paymentId.payment_status
                              : ''}
                          </span>
                        </div>
                        <div>
                          Status
                          <span className="badge bg-primary ms-2">
                            {single.status ? single.status : ''}
                          </span>
                        </div>

                        <div>
                          Ordered at
                          <span className="badge bg-primary ms-2">
                            {single.updatedAt
                              ? single.updatedAt.split('T')[1].split('.')[0]
                              : ''}
                          </span>
                          <span> of</span>
                          <span className="badge bg-primary ms-2">
                            {single.updatedAt
                              ? single.updatedAt.split('T')[0]
                              : ''}
                          </span>
                        </div>

                        {single.seller.full_name && (
                          <div>
                            <div>
                              Seller
                              <span className="badge bg-secondary ms-2">
                                {single.seller.full_name
                                  ? single.seller.full_name
                                  : ''}
                              </span>
                              <span className="badge bg-secondary ms-2">
                                {single.seller.username
                                  ? single.seller.username
                                  : ''}
                              </span>
                            </div>

                            <button className="mt-2 btn btn-sm btn-primary">
                              Chat with seller
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        )
      })
    } else
      return <div className="mt-2 mb-2">You haven't brought to any ads.</div>
  }

  render() {
    let { canceled, isLoading } = this.state
    let messageShouldShow = ''
    if (canceled) {
      messageShouldShow =
        'Subscription gets cancelled by clicking Cancel or internet connection. :)'
    }

    const { innerWidth: width } = window;

    return (
      <div className="container">
        <div className={width > 720 ? 'col m-5' : 'col m-3'}>
          <div className={classes.Dashboard}> </div>
          {canceled && (
            <SimpleSnackbar
              open={true}
              message={messageShouldShow}
              type="error"
              height="70"
            />
          )}
          {isLoading && <SpinnerAbsolute />}
          <div className="mt-3 mb-3">
            <h5>ORDERS</h5>
          </div>
          <div>{this.renderOrders()}</div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.userAuth.isAuthenticated,
    token: state.userAuth.token,
    buy: state.buy,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getBuyInfo: () => dispatch(actions.getBuyInfo()),
    uploadBuyData: (data) => dispatch(actions.uploadBuyData(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyOrders)
