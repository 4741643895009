import React from 'react'
import CollapsiblePanel from '../../../../components/UI/CollapsiblePanel'

// @ts-ignore
import classes from './FAQPage.module.css'
import {Translate} from 'react-auto-translate'
import Translation from '../../../../shared/Translate'
import Translator from '../../../../components/Translator'

export default class FAQPage extends React.Component {
  componentDidMount() {
    window.scroll(0.0, 0.0)
  }
  componentDidUpdate() {
    window.scroll(0.0, 0.0)
  }
  render() {
    const faqData = [
      {
        question: 'C’est quoi Umediad?',
        answer: {
          paragraphs: [
            {
              value:
                'Umediad est une plateforme de marketing (site web and application mobile) pour vendre et acheter des annonces.',
            },
            {
              value: 'À propos :',
              link: 'https://www.umediad.com/about-us',
            },
          ],
        },
      },
      {
        question: 'Qui peut acheter les annonces?',
        answer: {
          paragraphs: [
            {
              value:
                'Tous utilisateurs majeurs qui sont inscrits gratuitement sur umediad et qui disposent d’un mode de paiement en ligne.',
            },
          ],
        },
      },
      {
        question: 'Qui peut vendre ses annonces?',
        answer: {
          paragraphs: [
            {
              value:
                'Généralement des médias, influenceurs et partenaires ou tous utilisateurs majeurs qui désirent mettre en vente, une visibilité, une présence, une audience dont ils ont légalement le doit de vendre. En s’inscrivant sur Umediad, L’utilisateur reconnaît être le seul visé de tout ce que son affichage à causé, cause ou peut entraîner et qu’il est de sa responsabilité de s’assurer de la légalité de tout ce qu’il fait sur umediad.',
              break: true,
            },
            {
              value:
                ' Cliquez sur le lien pour vous inscrire avec votre courriel:',
              link: 'https://www.umediad.com/join',
              break: true,
            },
          ],
        },
      },
      {
        question: 'Qui sont les Partenaires et comment devenir partenaire? ',
        answer: {
          paragraphs: [
            {
              value:
                'Un partenaire indépendant autorisé comme son nom l’indique est une tierce (Agence d’influence and marketing, média affichage audiovisuel and imprimé, agence de mannequins, Gérant d’artistes et d’athlètes. Agence de communication, vendeur et agent marketing) qui  est autorisé par Umediad à vendre les abonnements en échanges de commissions et à percevoir un revenu résiduel sur les clients qui ont utilisé leurs rabais. Pour plus de détails lisez les thermes et conditions dans le menu, partenaire, devenir partenaire ou cliquez sur le lien suivant:',

              link: 'https://www.umediad.com/new-partner-request',
            },
          ],
        },
      },
      {
        question: 'Quel type d’annonce peut-on acheter ou vendre sur Umediad? ',
        answer: {
          paragraphs: [
            {
              value:
                'Visibilité, présence, audience en provenance des médias, influenceurs ou autres secteurs de plusieurs pays dans le monde. Certaines expectations peuvent s’appliquer sans préavis ni explications. ',
            },
          ],
        },
      },
      {
        question: 'Combien coûte les services chez umediad? ',
        answer: {
          paragraphs: [
            {
              value:
                'L’inscription pour les acheteurs est gratuit. Les vendeurs qui choisissent un partenaire peuvent obtenir un compte d’essai gratuit pour une durée limitée. D’autres options payantes sont disponibles pour les vendeurs qui souhaitent obtenir plusieurs publications. Devenir partenaire est gratuit mais limité. Il y a des frais de services lors de chaque transaction et dépendant de l’abonnement choisi, les acheteurs conservent entre 80% et 90% du prix qu’ils demandent. ',
            },
            {
              value: 'Pour plus de détails consulter le site web.',
            },
          ],
        },
      },
      {
        question: 'Quel sont les services offerts par Umediad? ',
        answer: {
          paragraphs: [
            {
              items: [
                'Placer votre annonce dans un média audio ou visuel, dans un sport, un événement, un livre, une Musique, un podcast, un vidéo, une page web ou réseau social, sur des vêtements, dans une entreprise.',
                'Acheter la présence ou le temps d’une célébrité.',
                'Placer un appel d’offres pour trouver l’annonce parfaite pour votre intérêt. ',
                'Obtenir une visibilité sur la plateforme d’UMEDIAD. ',
                'Avoir accès aux médias et influenceurs de partout, notre base de données s’agrandit avec les médias et influenceurs qui se rajoutent de jour en jour. ',
                'Publier une annonce pour vendre votre audience, la visibilité de votre entreprise ou celle de votre page de réseau social. ',
                'Vendre votre disponibilité pour une annonce ou un événement. ',
                'Devenir un Partenaire indépendant autorisé.',
                'Vendre la visibilité des autres médias influenceurs, Artistes, athlètes, modèles, en tant que partenaire indépendant autorisé. ',
                'Miser sur les appels d’offres disponibles. ',
                'Offrir un rabais sur nos abonnements en échange de commissions en tant que partenaire indépendant autorisé.',
              ],
            },
          ],
        },
      },
      {
        question:
          'Comment gagner de l’argent sur Umediad et combien d’argent je peux gagner? ',
        answer: {
          paragraphs: [
            {
              value:
                'Les avantages d’utiliser UMEDIAD pour gagner de l’argent sont nombreuses :',
              items: [
                'Travailler à votre rythme.',
                'Pas de plafond salarial.',
                'Travailler dans plusieurs pays.',
                'Revenu récurrent. ',
                'Des nouveaux clients de partout.',
              ],
            },
            {
              value:
                'Voici quelque exemples qui sont directement liés aux services.',
            },
            {value: 'Acheter l’annonce parfaite:'},
            {
              value:
                'Cibler votre audience selon votre budget, ou la présence d’une célébrité dans vos affaires augmente non seulement votre chiffre d’affaires mais établit votre notoriété en créant un lien de confiance plus durable avec vos clients.',
              break: true,
            },
            {
              value: 'Vendre l’annonce parfaite:',
            },
            {
              value:
                'Un utilisateur peut afficher un service  à partir de 10$ jusqu’à 250´000$ par paiement, son revenu dépend de l’intérêt que les acheteurs ont pour son service et le prix de la concurrence. ',
            },
            {
              value:
                'Dans le cas d’une annonce (Influenceur, humoriste, page social media populaire, un événement, une bannière, un journal, un artiste, un mannequin un podcast, show, visibilité à l’intérieur d’une entreprise etc.) vendue à seulement 1250$. 1 client par jour durant 5 jours par semaine et 2 jours de congés vous rapporte 6250$ par semaine. En prenant 4 semaines de vacances par année votre chiffre d’affaires annuel serait de 300’000$ en plus de ce que vous gagner déjà au paravant dans vos affaires. En soustrayant la commission d’UMEDIAD de 15% si vous avez un abonnement SOLO, et les frais de services pour le système de paiement Stripe qui ne dépassent pas 5%. Vous gagnez un revenu annuel de 240’000$ imposable selon votre pays.',
            },
            {
              value: 'NB: les taxes sont en suspens.',
              break: true,
            },
            {
              value: 'L’EXEMPLE DE PARTENAIRE INDÉPENDANT AUTORISÉ :',
            },
            {
              value:
                'Dans le cas d’un partenaire ( Agence de mannequins ou d’influenceurs, un regroupement de médias, un média possédant plusieurs type d’emplacement publicitaire radio TV, panneaux, un vendeur qui désire rencontrer les medias pour vendre leurs publicités, une agence de communication ou événementielle, Gérant d’artiste, d’athlètes etc.) possédant 250 emplacements ou candidats. Tout comme dans l’exemple précédent, ces candidats qui peuvent avoir un chiffre d’affaires de 300’000$  par année. ',
            },
            {
              value:
                'Le partenaire obtient une compensation de 22.5$ sur l’abonnement (AFFAIRES) de chaque candidat lui permettant de couvrir le temps pour aider son candidat à créer son compte. Un revenu résiduel de 5% sur chacune des collaborations de tous les candidats est versé au PARTENAIRE, ce pourcentage provient de la commission prélevée par UMEDIAD. Le partenaire reçoit 5625$ sur l’abonnement de ses 250 Candidats, soit 250x22.5$ + son revenu résiduel, soit 300’000 (chiffre d’affaires annuel potentiel par candidat) X 250 (Le nombre de ses candidats) X 5% de commissions = 3’750’000$ en Revenu Annuel pour le Partenaire.',
              break: true,
            },
            {
              value:
                'NB: Les chiffres dans les exemples varient d’un utilisateur à un autre et peuvent être moindre ou plus pour d’autres utilisateurs. L’essentiel est qu’un partenaire reçoit 15% de commission quand un abonné qui le choisi comme partenaire s’abonne à Umediad, et continue de percevoir entre 4% à 5% de revenus résiduel sur chaque collaboration publicitaire de ses abonnés. ',
              break: true,
            },
          ],
        },
      },
      {
        question: 'Comment je reçois l’argent l’argent gagner ?',
        answer: {
          paragraphs: [
            {
              value:
                'Tous les informations de paiements sur la plateforme umediad sont gérer par STRIPE, un  leader mondial dans le système de paiement en ligne qui se trouve en California USA. Umediad ne conserve aucun mode de paiement et vous recevrez votre facture à chaque transaction. ',
            },
            {
              value:
                'Pour tous les transactions effectuées durant un mois, vous recevrez directement dans votre compte l’argent de ces collaborations le 15eme du mois suivant. ',
            },
            {
              value:
                'Umediad se réserve une période de temps entre la transaction et le paiement pour vous protéger en cas de litige. Par exemple : Pour tous les transactions effectuées durant le mois (du 1er Janvier, 00h00mn au 31 Janvier 23h59) Umediad verse automatiquement votre rétribution le 10eme jour du mois suivant (10 Février) et les délais de transaction sont de 5 jours ouvrables. Vous recevrez votre paiement entre le 15eme et le 17ème jours de chaque mois, pour tous les collaborations effectuées le mois précédent. ',
            },
            {
              value:
                'De plus les Partenaires ont acces sur umediad à la liste des personnes qui les choisissent comme partenaire, leurs publications, leurs collaborations, et le montant de leurs commissions sur chacune de ses transactions.',
            },
            {
              value:
                'Selon l’abonnement choisi, le vendeur conserve entre 80% et 90% du prix d’affichage, reçoivent une facture, le montant de sa rétribution pour chaque collaboration et reçoivent directement l’argent dans son compte en banque entre le 15eme et le 17 jours du mois pour tous les collaborations effectuées le mois précédent.',
              break: true,
            },
            {
              value:
                'L’enregistrement de votre compte STRIPE se fait directement sur umediad en créant votre profile, vous aurez besoins d’une adresse courriel, d’un numéro de téléphone, de votre numéro de compte et vos informations afin que STRIPE puisse autoriser votre compte. ',
              break: true,
            },
            {
              value:
                'Pour connaître d’autres avantages offerts par les abonnements d’ Umediad consulter le lien suivant :',
              link: 'https://www.umediad.com/subscription-packages',
            },
          ],
        },
      },
    ]
    var collapse = true
    return (
      <div className={classes.FAQPage}>
        <h2>
          <Translation
            from={'en'}
            text={'Frequently Asked Questions'}
            input={<Translate>{'Frequently Asked Questions'}</Translate>}
          />
          ?
        </h2>
        {faqData.map((item, index) => {
          return (
            <div className={classes.FAQItem}>
              <CollapsiblePanel
                title={item.question}
                index={index}
                collapse={index === 0 ? false : collapse}>
                {item.answer.paragraphs.map((paragraph, index) => {
                  return (
                    <p key={index}>
                      {paragraph.value && (
                        <Translation
                          from={'fr'}
                          text={paragraph.value}
                          input={<Translate>{paragraph.value}</Translate>}
                        />
                      )}{' '}
                      {paragraph.link && (
                        <a
                          href={paragraph.link}
                          target="_blank"
                          rel="noopener noreferrer">
                          {paragraph.link}
                        </a>
                      )}{' '}
                      {paragraph.items && (
                        <ul>
                          {paragraph.items.map((item, index) => {
                            return (
                              <li
                                key={index}
                                style={{
                                  listStyleType: 'disc',
                                  marginLeft: '40px',
                                  fontSize: '1em',
                                }}>
                                <Translation
                                  from={'fr'}
                                  text={item}
                                  input={<Translate>{item}</Translate>}
                                />
                              </li>
                            )
                          })}
                        </ul>
                      )}
                      {paragraph.break && (
                        <p>
                          <br />
                        </p>
                      )}
                    </p>
                  )
                })}
              </CollapsiblePanel>
            </div>
          )
        })}
        <div className={classes.bottomInfo}>
          <p>
            {
              <Translation
                from={'fr'}
                text="Lisez notre politique de confidentialité et nos conditions d’utilisation au bas de la pages d’accueil"
                input={
                  <Translate>
                    {
                      '{Lisez notre politique de confidentialité et nos conditions d’utilisation au bas de la pages d’accueil}'
                    }
                  </Translate>
                }
              />
            }
          </p>
          <br />
          <h3>UMEDIAD</h3>
          <small>
            <Translation
              from={'fr'}
              text="Meilleure plateforme pour gérer vos collaborations publicitaires. "
              input={
                <Translate>
                  Meilleure plateforme pour gérer vos collaborations
                  publicitaires.
                </Translate>
              }
            />
          </small>
          <p>Umediad.com</p>
        </div>
        <Translator />
      </div>
    )
  }
}
