import React from 'react'
import {withTranslation} from 'react-i18next'
import {loadStripe} from '@stripe/stripe-js'
import {Button} from '@material-ui/core'
import axios from '../../../axios'
import classes from './Packages.module.css'
import Checkbox from '@material-ui/core/Checkbox'
// import FormControlLabel from '@material-ui/core/FormControlLabel'
import {Link} from 'react-router-dom'
import * as actions from '../../../store/actions'
import {connect} from 'react-redux'
import {Spinner} from '../../../components/UI/Spinner/Spinner'
import SimpleSnackbar from '../../../components/Snackbar/Snackbar'
import {stripe_key} from '../../../config/stripe'
import {SimpleSpinner} from '../../../components/UI/Spinner/Spinner'
import {Translate} from 'react-auto-translate'
import Translation from '../../../shared/Translate'
import Translator from '../../../components/Translator'

const stripe = loadStripe(stripe_key)

class Packages extends React.Component {
  state = {
    isFlag: '',
    isLoading: false,
    error: '',
    terms: false,
    checkBox: false,
    checked: false,
    packageId: '',
    type: '',
  }

  componentDidMount() {
    this.props.getPaymentInfo()
    this.props.checkHeHasPartner()
  }

  tokenHeadersNormal = () => {
    const headersNormal = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    const token = this.props.token
    if (token) {
      headersNormal.headers['x-auth-header'] = token
    }
    return headersNormal
  }

  createCheckoutSession = async (normalPriceId, packageId) => {
    const currentUrl = window.location.href

    const res = await axios({
      method: 'POST',
      url: '/stripe/create-checkout-session',
      data: {
        priceId: normalPriceId,
        packageId: packageId,
        domainURL: currentUrl.split('/s')[0],
        hasPartner: this.props.hasPartner,
      },
      headers: this.tokenHeadersNormal().headers,
    })
    return res.data
  }

  normalPay = async (priceId, packageId) => {
    if (this.props.hasPartner && this.state.type === 'TESTER') {
      this.props.history.push(
        `/seller/dashboard?priceId=${priceId}&packageId=${packageId}&hasPartner=${true}`,
      )
      return
    } else {
      let session
      try {
        session = await this.createCheckoutSession(priceId, packageId)
      } catch (e) {
        console.log(e)
      }
      const stp = await stripe
      let result

      if (session) {
        result = await stp.redirectToCheckout({
          sessionId: session.sessionId,
        })
      } else {
        this.setState({
          error: 'Something is wrong! Please contact to Umediad Admin!',
        })
        return
      }
      if (result.error) {
        console.log(result.error)
      }
    }
  }

  handleTermsChecker = (event, packageId, type) => {
    const {screenWidth, history} = this.props

    if (!this.props.isAuthenticated) {
      this.setState({
        error: 'Please log in first',
        isLoading: false,
      })
      history.push('/login')

      return
    }
    if (!this.props.user.isProfileOk) {
      this.setState({
        error: 'Your profile is not completed, please go to your profile',
        isLoading: false,
      })
      if (screenWidth < 768) {
        window.scrollTo(0, screenWidth)
      }
      window.scrollTo(0, 250)
      return
    }

    let priceId

    // live
    if (type === 'TESTER') {
      priceId = 'price_1J5UdjBmU6GYJ7qkyphoZaNq'
    } else if (type === 'SOLO') {
      priceId = 'price_1J5UgFBmU6GYJ7qkzVXTqrwz'
    } else if (type === 'BUSINESS') {
      priceId = 'price_1J5UjuBmU6GYJ7qkAUckbUyK'
    } else if (type === 'BUSINESS PREMIUM') {
      priceId = 'price_1J5Ul2BmU6GYJ7qknWjKxazR'
    }

    const {ids} = this.props
    let flag = 0
    if (ids.length > 0) {
      for (let i = 0; i < ids.length; i++) {
        if (ids[i]) {
          if (priceId === ids[i]) {
            flag = 1
            break
          }
        }
      }
    }

    if (flag === 1) {
      this.setState({
        isFlag: type,
        isLoading: false,
      })
      return
    }
    //live
    this.setState({packageId: packageId, type: type, terms: true})

    window.scrollTo(0, window.screen.height * 10)
  }

  handleCheckChange = (event) => {
    this.setState({checked: event.target.checked})
  }

  handleAccept = () => {
    const {packageId, type} = this.state
    this.handlePackageId(packageId, type)
    setTimeout(() => {
      this.setState({
        packageId: '',
        type: '',
        terms: false,
        checked: false,
      })
    }, 2000)
  }

  handlePackageId = (packageId, type) => {
    this.setState({isLoading: true})
    let priceId
    if (type === 'TESTER') {
      priceId = 'price_1J5UdjBmU6GYJ7qkyphoZaNq'
    } else if (type === 'SOLO') {
      priceId = 'price_1J5UgFBmU6GYJ7qkzVXTqrwz'
    } else if (type === 'BUSINESS') {
      priceId = 'price_1J5UjuBmU6GYJ7qkAUckbUyK'
    } else if (type === 'BUSINESS PREMIUM') {
      priceId = 'price_1J5Ul2BmU6GYJ7qknWjKxazR'
    }

    if (this.props.isAuthenticated) {
      if (!this.state.error) {
        this.normalPay(priceId, packageId)
      }
    } else {
      this.props.history.push('/join')
    }
  }

  renderSinglePackage = (data, color) => {
    var first = [classes.pricing_wrapper]
    first.push(classes.clearfix)

    // const {t} = this.props
    return (
      <div className={classes.pricing_table}>
        <h3 style={{background: color}} className={classes.pricing_title}>
          <Translation
            from={'en'}
            text={data.name}
            input={<Translate>{data.name}</Translate>}
          />
          {this.props.hasPartner && data.type !== 'TESTER' && (
            <sub style={{fontSize: 12}}>
              {/* 15% {t('StartSelling:off')} */}
              <Translation
                from={'en'}
                text={'15% Off'}
                input={<Translate>15% Off</Translate>}
              />
            </sub>
          )}
        </h3>
        {!this.props.hasPartner && (
          <div
            style={{
              padding: '10px',
              backgroundColor: '#C4AF9A',
              color: 'black',
              fontSize: 14,
            }}>
            <span>
              {data.type === 'TESTER' ? (
                <Translation
                  from={'en'}
                  text={'To get this package free, '}
                  input={<Translate>To get this package free, </Translate>}
                />
              ) : (
                <Translation
                  from={'en'}
                  text={'To get 15% discount, '}
                  input={<Translate>To get 15% discount, </Translate>}
                />
              )}{' '}
              <Link
                style={{textDecoration: 'none', lineHeight: '1.3em'}}
                to={'/find-partner'}>
                {/* {t('translation:choose_partner')} */}{' '}
                <Translation
                  from={'en'}
                  text={'Choose Partner'}
                  input={<Translate>Choose Partner</Translate>}
                />
              </Link>
            </span>
          </div>
        )}

        <div className={classes.price}>
          {data.price}
          <sup>
            {' '}
            {this.props.hasPartner && data.type === 'TESTER' ? (
              <Translation
                from={'en'}
                text={'for'}
                input={<Translate>for</Translate>}
              />
            ) : (
              'CAD / '
            )}{' '}
            <Translation
              text={data.validity}
              input={<Translate>{data.validity}</Translate>}
            />
          </sup>
        </div>
        <ul className={classes.table_list}>
          {/* <li >{data.title}</li> */}
          {data.privileges.map((privilege, index) => (
            <li key={index}>
              <Translation
                text={privilege}
                input={<Translate>{privilege}</Translate>}
              />
            </li>
          ))}
        </ul>
        <div className={classes.StartedButton}>
          <Button
            variant="contained"
            color="primary"
            onClick={(event) =>
              this.handleTermsChecker(event, data._id, data.type)
            }>
            {/* {t('StartSelling:packages.others.1')} */}
            <Translation
              from={'en'}
              text={'Get Started'}
              input={<Translate>Get Started</Translate>}
            />
          </Button>
        </div>
      </div>
    )
  }

  renderBenefits = (t) => {
    // const {t} = this.props
    const benefits = [
      t('StartSelling:benefits.0'),
      t('StartSelling:benefits.1'),
      t('StartSelling:benefits.2'),
      t('StartSelling:benefits.3'),
      t('StartSelling:benefits.4'),
      t('StartSelling:benefits.5'),
      t('StartSelling:benefits.6'),
    ]
    return (
      <div className="d-flex justify-content-center">
        <div className={classes.benefitDiv}>
          <h3 className={classes.specialFont} style={{textAlign: 'center'}}>
            <Translation
              from={'en'}
              text="Benefits of subscriptions"
              input={<Translate>Benefits of subscriptions</Translate>}
            />
          </h3>
          {benefits.map((single, index) => {
            return (
              <div key={index}>
                <div className={classes.specialFont}>
                  <div>
                    {' '}
                    <span className={classes.SpecificationItemIndex}>
                      {index + 1}
                    </span>
                  </div>
                  <div className="mx-2">
                    <Translation
                      text={single}
                      input={<Translate>{single}</Translate>}
                    />
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  render() {
    const {t} = this.props
    const {isFlag, isLoading, error, checked, terms, type} = this.state

    var packages = [
      {
        _id: '60d1c6af77297e3bde96a056',
        validity: t('StartSelling:packages.list.commonpriv.2'),
        privileges: [
          t('StartSelling:packages.list.tester.3'),
          t('StartSelling:packages.list.commonpriv.0'),
          t('StartSelling:packages.list.tester.1'),
          t('StartSelling:packages.list.tester.2'),
        ],
        type: 'TESTER',
        name: 'TESTER',
        title: t('StartSelling:packages.list.tester.3'),
        price: this.props.hasPartner
          ? t('StartSelling:packages.list.tester.4')
          : '$3.49',
      },
      {
        _id: '60d1c7af77297e3bde96a057',
        validity: t('StartSelling:packages.list.commonpriv.5'),
        privileges: [
          t('StartSelling:packages.list.solo.2'),
          t('StartSelling:packages.list.commonpriv.0'),
          t('StartSelling:packages.list.solo.1'),
          t('StartSelling:packages.list.commonpriv.4'),
        ],
        type: 'SOLO',
        name: 'SOLO',
        title: t('StartSelling:packages.list.solo.2'),
        price: this.props.hasPartner ? '$25.5' : '$30',
      },
      {
        _id: '60d1c8c777297e3bde96a058',
        validity: t('StartSelling:packages.list.commonpriv.3'),
        privileges: [
          t('StartSelling:packages.list.business.2'),
          t('StartSelling:packages.list.commonpriv.1'),
          t('StartSelling:packages.list.business.1'),
          t('StartSelling:packages.list.commonpriv.4'),
        ],
        type: 'BUSINESS',
        name: 'BUSINESS',
        title: t('StartSelling:packages.list.business.2'),
        price: this.props.hasPartner ? '$127.5' : '$150',
      },
      {
        _id: '60d1cc4777297e3bde96a059',
        validity: t('StartSelling:packages.list.commonpriv.3'),
        privileges: [
          t('StartSelling:packages.list.premium.2'),
          t('StartSelling:packages.list.commonpriv.1'),
          t('StartSelling:packages.list.premium.1'),
          t('StartSelling:packages.list.commonpriv.4'),
        ],
        type: 'BUSINESS PREMIUM',
        name: 'PREMIUM',
        title: t('StartSelling:packages.list.premium.2'),
        price: this.props.hasPartner ? '$510' : '$600',
      },
    ]
    const colors = ['#e95846', 'blueviolet', '#42BBC9', 'black', '#06798D']

    return (
      <React.Fragment>
        <div className={classes.Package}>
          {this.props.message && !this.props.error ? (
            <SimpleSnackbar
              open={true}
              message={this.props.message}
              type="success"
            />
          ) : null}
          {this.props.message && this.props.error ? (
            <SimpleSnackbar
              open="true"
              message={this.props.message}
              type="error"
            />
          ) : null}

          <div style={{paddingTop: '65px'}} />
          <div className="pb-4">{this.renderBenefits(t)}</div>

          <h3
            className="pb-2"
            style={{
              textAlign: 'center',
            }}>
            <Translation
              from={'en'}
              text="Choose your preferred subscription package"
              input={
                <Translate>
                  Choose your preferred subscription package
                </Translate>
              }
            />
          </h3>

          {isFlag && (
            <div className="m-3 p-2 d-flex justify-content-center align-items-center">
              <div className="bg-danger rounded-3 text-white p-2">
                <Translation
                  from={'en'}
                  text={`You have already subscribed for ${isFlag} !`}
                  input={
                    <Translate>
                      You have already subscribed for {isFlag} !
                    </Translate>
                  }
                />
              </div>
            </div>
          )}

          {error && (
            <div className="m-3 p-2 d-flex justify-content-center align-items-center">
              <div className="bg-danger rounded-3 text-white p-2">{error}</div>
            </div>
          )}
          {error && <SimpleSnackbar open="true" message={error} type="error" />}

          {/* <div className={classes.BoxRow}> */}
          <div className={classes.PackageItem}>
            {packages ? (
              packages.map((packageData, index) => (
                <div key={index}>
                  {this.renderSinglePackage(packageData, colors[index])}
                </div>
              ))
            ) : (
              <Spinner />
            )}
          </div>
        </div>

        {terms && (
          <div className="m-5 rounded-3 p-2" style={{background: '#eee'}}>
            <div className="d-flex">
              <div>
                <Checkbox
                  checked={checked}
                  onChange={this.handleCheckChange}
                  color="primary"
                  // inputProps={{'aria-label': 'secondary checkbox'}}
                />
              </div>
              <div className="pt-2" style={{lineHeight: '1.6'}}>
                <span>
                  {/* {t('StartSelling:packages.message.0')} */}
                  <Translation
                    from={'en'}
                    text="I have selected "
                    input={<Translate>I have selected</Translate>}
                  />{' '}
                </span>
                <span className="text-primary" style={{fontWeight: 600}}>
                  {' '}
                  <Translation
                    from={'en'}
                    text={type}
                    input={<Translate>{type}</Translate>}
                  />{' '}
                </span>
                <span>
                  {' '}
                  {/* {t('StartSelling:packages.message.1')} */}
                  <Translation
                    from={'en'}
                    text=" package to get started. "
                    input={<Translate> package to get started. </Translate>}
                  />
                </span>
                <span>
                  {' '}
                  <Translation
                    from={'en'}
                    text="I have read, I understand and I accept all the "
                    input={
                      <Translate>
                        I have read, I understand and I accept all the{' '}
                      </Translate>
                    }
                  />
                </span>
                <span>
                  {' '}
                  <Link to="/terms-and-conditions">
                    <Translation
                      from={'en'}
                      text="Terms and Conditions"
                      input={<Translate>Terms and Conditions</Translate>}
                    />
                  </Link>
                </span>
                <span>
                  {' '}
                  <Translation
                    from={'en'}
                    text=" as well as all the "
                    input={<Translate> as well as all the </Translate>}
                  />
                </span>
                <span>
                  {' '}
                  <Link to="/privacy-policy">
                    <Translation
                      from={'en'}
                      text="Privacy Policies"
                      input={<Translate>Privacy Policies</Translate>}
                    />
                  </Link>
                </span>
                <span>
                  {' '}
                  <Translation
                    from={'en'}
                    text="of Umediad"
                    input={<Translate>of Umediad</Translate>}
                  />
                </span>
              </div>
            </div>

            <div className="m-3 d-flex justify-content-center">
              {checked ? (
                isLoading ? (
                  <SimpleSpinner />
                ) : (
                  <button
                    className="btn btn-primary"
                    onClick={this.handleAccept}>
                    {' '}
                    <Translation
                      from={'en'}
                      text="CONTINUE"
                      input={<Translate>CONTINUE</Translate>}
                    />
                  </button>
                )
              ) : (
                <button className="btn btn-secondary" disabled>
                  <span>
                    {' '}
                    <Translation
                      from={'en'}
                      text="ACCEPT AND CONTINUE"
                      input={<Translate>ACCEPT AND CONTINUE</Translate>}
                    />
                  </span>
                </button>
              )}
            </div>
          </div>
        )}
        <Translator />
      </React.Fragment>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    error: state.subscriptionPackages.error,
    loading: state.subscriptionPackages.loading,
    // packages: state.subscriptionPackages.packages,
    message: state.subscriptionPackages.message,
    isAuthenticated: state.userAuth.isAuthenticated,
    user: state.userAuth.user,
    token: state.userAuth.token,
    ids: state.payment.ids,
    screenWidth: state.responsiveness.screenWidth,
    hasPartner: state.partner.hasPartner,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    // getSubscriptionPackages: () => dispatch(actions.getSubscriptionPackages()),
    getPaymentInfo: () => dispatch(actions.getPaymentInfo()),
    checkHeHasPartner: () => dispatch(actions.checkHeHasPartner()),
    selectSubscriptionPackage: (body) =>
      dispatch(actions.selectSubscriptionPackage(body)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation(['StartSelling', 'translation'])(Packages))
