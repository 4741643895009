import * as actionTypes from '../actions/ActionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    tenders: [],
    bids: [],
    tender: null,
    loading: false,
    message: null,
    error: false,
    modeChangeMessage: null
};

const tenderStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        error: false,
        message: null,
    });
}

const tendersSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: false,
        tenders: action.payload.tenders,
        message: action.payload.message,
    });
}

const bidsSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: false,
        bids: action.payload.bids,
    });
}

const tenderSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: false,
        tender: action.payload.tender
    });
}

const tenderFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: true,
        message: action.payload.message,
    });
}

const tenderModeChange = (state, action) => {
    return updateObject(state, {
        modeChangeMessage: action.payload.message,
    });
}

export const TenderReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.START_TENDER: return tenderStart(state, action);
        case actionTypes.TENDERS_FETCH_SUCCESS: return tendersSuccess(state, action);
        case actionTypes.BIDS_FETCH_SUCCESS: return bidsSuccess(state, action);
        case actionTypes.TENDER_FETCH_SUCCESS: return tenderSuccess(state, action);
        case actionTypes.TENDER_FAIL: return tenderFail(state, action);
        case actionTypes.TENDER_MODE_CHANGE: return tenderModeChange(state, action);

        default: return state;
    }
};