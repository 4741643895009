import React from 'react'
import classes from '../Settings.module.css'
import { connect } from 'react-redux'
import SettingsNavigation from '../SettingsNavigation'
import { Line } from '../../../../components/UI/Spacing'
import SimpleSnackbar from '../../../../components/Snackbar/Snackbar'
import axios from '../../../../axios'
import { withTranslation } from 'react-i18next'

class Notifications extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ads: false,
      balanceUpdate: false,
      chatMessages: false,
      newsletter: false,
      purchase: false,
      subscription: false,
      updated: '',
    }
  }
  componentDidMount() {
    this.getAllNotificationOptions()
  }
  tokenHeadersNormal = () => {
    const headersNormal = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    const token = this.props.token
    if (token) {
      headersNormal.headers['x-auth-header'] = token
    }
    return headersNormal
  }

  getAllNotificationOptions = () => {
    const url = '/user/getNotificationSettings'
    axios
      .get(url, { headers: this.tokenHeadersNormal().headers })
      .then((response) => {
        const {
          ads,
          balanceUpdate,
          chatMessages,
          newsletter,
          purchase,
          subscription,
        } = response.data.data
        this.setState({
          ads: ads,
          balanceUpdate: balanceUpdate,
          chatMessages: chatMessages,
          newsletter: newsletter,
          purchase: purchase,
          subscription: subscription,
        })
      })
      .catch((err) => console.log(err, 'checkCustomerAccount'))
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.checked, updated: '' })
  }

  renderOptions = (name, checked, abled, target) => {
    return (
      <div className="row justify-content-between align-items-center py-2">
        <div className="col-8">
          <small className="text-secondary fw-lighter">{name}</small>
        </div>
        <div className="col-4">
          <div className="form-check">
            {abled ? (
              <input
                className="form-check-input"
                checked={checked}
                type="checkbox"
                disabled
              />
            ) : (
              <input
                className="form-check-input"
                checked={checked}
                name={target}
                type="checkbox"
                onChange={this.handleChange}
              />
            )}
          </div>
        </div>
      </div>
    )
  }

  editNotificationSettings = async (data) => {
    const res = await axios({
      method: 'POST',
      data: data,
      url: '/user/changeNotification',
      headers: this.tokenHeadersNormal().headers,
    })
    return res.data
  }

  submitResult = async () => {
    const {
      ads,
      balanceUpdate,
      chatMessages,
      newsletter,
      purchase,
      subscription,
    } = this.state
    const data = {
      ads,
      balanceUpdate,
      chatMessages,
      newsletter,
      purchase,
      subscription,
    }
    var res
    try {
      res = await this.editNotificationSettings(data)
      if (res) {
        this.setState({ updated: res.message })
      }
    } catch (e) {
      console.log(e, 'submit_result')
    }
  }

  render() {
    const {
      ads,
      balanceUpdate,
      chatMessages,
      newsletter,
      purchase,
      subscription,
      updated,
    } = this.state
    // console.log(updated, 'message')
    const { t } = this.props;
    return (
      <div className={classes.Settings}>
        {updated && updated === 'updated' && (
          <SimpleSnackbar
            open={true}
            message="Notifications setttings has been changed, and will affect soon!"
            type="success"
          />
        )}
        <div className={classes.PartLeft}>
          <SettingsNavigation user={this.props.user} />
        </div>
        <div className={classes.PartRight}>
          <div className="row justify-content-between align-items-center py-2">
            <div className="col-lg-4 col-md-4 col-sm-12">
              <small className="text-dark fw-bold ">{t('notifications.title').toUpperCase()}</small>
            </div>
            <div className="col-lg-8 col-md-8 col-sm-12 mt-2">
              <small
                className="text-muted"
                style={{ fontSize: '14px', lineHeight: '1.5' }}>
                {t('notifications.message')}
              </small>
            </div>
          </div>
          <div className="row justify-content-between align-items-center py-2">
            <div className="col-8">
              <small className="text-secondary fw-bold">{t('notifications.type')}</small>
            </div>
            <div className="col-4">
              <small className="text-secondary fw-bold ">{t('notifications.emailServices')}</small>
            </div>
          </div>

          {this.renderOptions(
            t('notifications.types.0'),
            subscription,
            'disabled',
            'subscription',
          )}
          {this.renderOptions(
            t('notifications.types.1'),
            purchase,
            'disabled',
            'purchase',
          )}
          {this.renderOptions(
            t('notifications.types.2'),
            balanceUpdate,
            '',
            'balanceUpdate',
          )}
          {this.renderOptions(t('notifications.types.3'), ads, '', 'ads')}
          {this.renderOptions(t('notifications.types.4'), chatMessages, '', 'chatMessages')}
          {this.renderOptions(t('notifications.types.5'), newsletter, '', 'newsletter')}

          <Line orientation="h" space="4" height="1" width="100" backgroundColor={undefined} />
          <div className="col-12 py-3 mt-3">
            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="btn btn-primary"
                onClick={this.submitResult}>
                {t('notifications.saveChanges')}
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userAuth.user,
    token: state.userAuth.token,
  }
}

export default connect(mapStateToProps, null)(withTranslation(['UserProfile'])(Notifications))
