import React from 'react'
import {useTranslation} from 'react-i18next'
import AppleSignin from 'react-apple-signin-auth'
import Button from '../../../components/UI/Button/Button'
import classes from './Auth.module.css'

const MyAppleSigninButton = (props) => {
	const { t } = useTranslation(['translation', 'SigninUp'])
	return <AppleSignin
		authOptions={{
			clientId: "app.umediad.com",
			scope: "name email",
			redirectURI: props.url,
			//   redirectURI: 'http://fb540b0dee03.ngrok.io/user/apple/register',
			state: "",
			nonce: "nonce",
			// usePopup: true,
			// usePopup: ${authOptions.usePopup}
		}}
		uiType="dark"
		render={(props) => {
			return <Button buttonType="AppleDark">
				<div {...props}>
					<div className={classes.SocialButton}>
						<i className="fab fa-apple" aria-hidden="true"></i>
						<div className={classes.SocialText}>
							{/* {props.children.props.children[1]} */}
							{t('SigninUp:common.2')}
						</div>
					</div>
				</div>
			</Button>
		}}
	/>
}

export default MyAppleSigninButton

/* {props.children.props.children[1]} */
// buttonType="AppleDark"
