import React from 'react'
import classes from './ServicesDetails.module.css'
import {Link} from 'react-router-dom'
import {Translate} from 'react-auto-translate'
import Translation from '../../../shared/Translate'

const ServiceDetailsItem = ({image, name, path}) => {
  return (
    <div className={classes.ServicesDetailsMainItem}>
      <Link to={`/find/advertises${path}?searchType=media`}>
        <div className={classes.ServicesDetailsItem}>
          <div>
            {' '}
            <img src={image} alt={name} />{' '}
          </div>
          <div className={classes.Divider}></div>
          <div className={classes.HoverDiv}></div>
          <div className={classes.Divider}></div>
          <div>
            <big>
              <Translation text={name} input={<Translate>{name}</Translate>} />
            </big>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default ServiceDetailsItem
