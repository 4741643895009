import React, { Component } from 'react';
import classes from './Tender.module.css';
import { Multiselect } from 'multiselect-react-dropdown';
import { withTranslation } from 'react-i18next';
import * as actions from '../../../store/actions';
import { connect } from 'react-redux';
import SimpleSnackbar from '../../../components/Snackbar/Snackbar';
import { SimpleSpinner } from '../../../components/UI/Spinner/Spinner';
class LaunchTender extends Component {
    constructor(props) {
        super(props)
        const { t } = this.props;
        this.state = {
            businessDomain: [
                { id: 0, domain: t('translation:domainSlider.list.0') },
                { id: 1, domain: t('translation:domainSlider.list.1') },
                { id: 2, domain: t('translation:domainSlider.list.2') },
                { id: 3, domain: t('translation:domainSlider.list.3') },
                { id: 4, domain: t('translation:domainSlider.list.4') },
                { id: 5, domain: t('translation:domainSlider.list.5') },
                { id: 6, domain: t('translation:domainSlider.list.6') },
                { id: 7, domain: t('translation:domainSlider.list.7') },
                { id: 8, domain: t('translation:domainSlider.list.8') },
                { id: 9, domain: t('translation:domainSlider.list.9') },
                { id: 10, domain: t('translation:domainSlider.list.10') },
                { id: 11, domain: t('translation:domainSlider.list.11') },
                { id: 12, domain: t('translation:domainSlider.list.12') },
                { id: 13, domain: t('translation:domainSlider.list.13') },
                { id: 14, domain: t('translation:domainSlider.list.14') },
                { id: 15, domain: t('translation:domainSlider.list.15') },
                { id: 16, domain: t('translation:domainSlider.list.16') },
                { id: 17, domain: t('translation:domainSlider.list.17') },
                { id: 18, domain: t('translation:domainSlider.list.18') },
            ],
            selectedTypeIndex: null,
            tenderData: {
                title: '',
                description: '',
                sellerType: '',
                location: {
                    city: '',
                    state: '',
                    country: ''
                },
                budget: {
                    budgetMin: '',
                    budgetMax: '',
                    fixed: ''
                },
                deliveryDays: '',
                language: '',
                media: [],
                subMedia: [],
                domains: [],
                serviceSpecifications: '',
                files: []
            },
            message: '',
            tenderId: null,
            mediaItems: [],
            select: null
        }
    }

    onReturnOnlyDigit = value => value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');

    componentDidMount() {
        window.scrollTo(0.0, 0.0);
        this.props.onFetchTypeAndSubTypes();
        if (this.props.location.search) {
            const search = this.props.location.search;
            const params = new URLSearchParams(search);
            if (params.get("info_id")) {
                this.setState({ tenderId: params.get("info_id") });
                this.props.getSingleTender(params.get("info_id"));
            }
        }
    }

    onInputChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        const tenderData = { ...this.state.tenderData };

        if (name === 'media') {
            if (tenderData.media.findIndex(media => value === media) === -1) {
                tenderData.media.push(value);
            }
            this.setState({
                selectedTypeIndex: this.props.advertiseData.findIndex(data => value === data.type)
            });
        } else if (name === 'subMedia') {
            if (tenderData.subMedia.findIndex(subMedia => value === subMedia) === -1) {
                tenderData.subMedia.push(value);
            }
        } else if (name === 'city' || name === 'state' || name === 'country')
            tenderData['location'][name] = value;
        else if (name === 'budgetMin' || name === 'budgetMax' || name === 'fixed')
            tenderData['budget'][name] = this.onReturnOnlyDigit(value);
        else if (name === 'deliveryDays')
            tenderData['deliveryDays'] = this.onReturnOnlyDigit(value);
        else
            tenderData[name] = value;

        this.setState({ tenderData, select: true });
    }

    onSelectAdvertiseDomain = (selectedList, selectedItem) => {
        const tenderData = { ...this.state.tenderData };
        tenderData.domains.push(selectedItem);
        this.setState({ tenderData });
    }

    onRemoveAdvertiseDomain = (selectedList, removedItem) => {
        const tenderData = { ...this.state.tenderData };
        tenderData.domains = selectedList;
        this.setState({ tenderData });
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        const tenderData = { ...this.state.tenderData };
        if (this.props.singleTender !== nextProps.singleTender) {
            const {
                title,
                description,
                sellerType,
                location,
                budget,
                deliveryDays,
                language,
                media,
                subMedia,
                domains,
                serviceSpecifications,
                files
            } = nextProps.singleTender.tender;

            tenderData.title = title;
            tenderData.description = description;
            tenderData.sellerType = sellerType;
            tenderData.location = location;
            tenderData.budget = budget;
            tenderData.deliveryDays = deliveryDays;
            tenderData.language = language;
            tenderData.media = media;
            tenderData.subMedia = subMedia;
            tenderData.domains = domains;
            tenderData.serviceSpecifications = serviceSpecifications;
            tenderData.files = files;
            this.setState({ tenderData });
        }
        if (this.props.advertiseData !== nextProps.advertiseData) {
            this.setState({ mediaItems: nextProps.advertiseData });
        }
    }

    onSubmitNewTender = (event) => {
        event.preventDefault();

        if (!this.props.isAuthenticated) {
            this.props.history.push('/login');
        } else {
            if (this.props.user.role === 'buyer') {
                this.props.postNewTender(this.state.tenderData);
                setTimeout(() => this.props.history.push('/my-all-tenders'), 2000);
            } else {
                this.setState({ message: 'Please, Log in as buyer to create tender' });
                setTimeout(() => this.setState({ message: '' }), 3000);
            }
        }
    }

    onEditTender = (event) => {
        event.preventDefault();

        this.props.editTender(this.state.tenderData, this.state.tenderId);
        setTimeout(() => this.props.history.push('/my-all-tenders'), 2000);
    }

    onRemoveMedia = (media) => {
        const tenderData = { ...this.state.tenderData };
        tenderData.media = tenderData.media.filter(_media => media !== _media);
        this.setState({ tenderData, select: false, selectedTypeIndex: null });
    }

    onRemoveSubMedia = (subMedia) => {
        const tenderData = { ...this.state.tenderData };
        tenderData.subMedia = tenderData.subMedia.filter(_subMedia => subMedia !== _subMedia);
        this.setState({ tenderData });
    }

    render() {
        const { t } = this.props;
        const { title, deliveryDays, domains, description, budget, language, location, media, subMedia, serviceSpecifications } = this.state.tenderData;
        const sellerTypes = ['Event-City-Social movement', 'Company', 'Media', 'Influencer-Team-Entrepreneur'];

        return <div className={classes.Tender}>
            {this.props.message && !this.props.error &&
                <SimpleSnackbar open={true} message={this.props.message} type='success' />}
            {this.props.message && this.props.error &&
                <SimpleSnackbar open='true' message={this.props.message} type='error' />}
            <div className={classes.PublicTender}>
                <h5 className='text-center'>{this.state.tenderId ?
                    t('Tender:launchTender.1') : t('Tender:launchTender.0')}</h5>
                <div className='my-3'>
                    <div className="form-group col-12 mb-3">
                        <label className='form-label'>Tender Title</label>
                        <input
                            required
                            value={title}
                            onChange={this.onInputChange}
                            name='title'
                            type="text"
                            className="form-control"
                            placeholder='title' />
                    </div>
                    <div className="form-group col-12 mb-3">
                        <label className='form-label'>Tender Description</label>
                        <textarea
                            required
                            value={description}
                            onChange={this.onInputChange}
                            name='description'
                            type="text"
                            rows='4'
                            className="form-control"
                            placeholder='description' />
                    </div>
                    <div className="row justify-content-between mb-3">
                        <div className="form-group col">
                            <label className='form-label'>Select Sellers Type</label>
                            <select className="form-select" name='sellerType' onChange={this.onInputChange}>
                                <option disabled selected>Select</option>
                                {sellerTypes.map((_sellerType, index) => (
                                    <option key={index} value={_sellerType}>{_sellerType}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className='row justify-content-between mb-3'>
                        <div className="form-group col">
                            <label className='form-label'>Media</label>
                            <select className="form-select"
                                name='media' ate
                                onChange={this.onInputChange}>
                                <option disabled selected={this.state.select === false || this.state.select === null}>Select</option>
                                {this.props.advertiseData.map((data, index) => (
                                    <option key={index} value={data.type}>{data.type}</option>
                                ))}
                            </select>
                            <div className={classes.MediaItem}>
                                {media.map((media, index) => (
                                    <span key={index}>{media} <i onClick={_ => this.onRemoveMedia(media)} className="fas fa-times mx-1"></i></span>
                                ))}
                            </div>
                        </div>
                        <div className="form-group col">
                            <label className='form-label'>Sub Media</label>
                            <select className="form-select"
                                required
                                name='subMedia'
                                onChange={this.onInputChange}>
                                {this.state.selectedTypeIndex && <React.Fragment>
                                    <option disabled selected>Select SubMedia for {this.state.mediaItems[this.state.selectedTypeIndex].type}</option>
                                    {this.state.mediaItems[this.state.selectedTypeIndex].sub_type.map((sub_type, index) => (
                                        <option key={index} value={sub_type}>{sub_type}</option>
                                    ))}
                                </React.Fragment>}
                            </select>
                            <div className={classes.MediaItem}>
                                {subMedia.map((subMedia, index) => (
                                    <span key={index}>{subMedia} <i onClick={_ => this.onRemoveSubMedia(subMedia)} className="fas fa-times mx-1"></i></span>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mb-3">
                        <label className='form-label'>Select Domains</label>
                        <Multiselect
                            placeholder='Select'
                            showArrow
                            showCheckbox
                            closeIcon="cancel"
                            options={this.state.businessDomain}
                            selectedValues={domains}
                            onSelect={this.onSelectAdvertiseDomain}
                            onRemove={this.onRemoveAdvertiseDomain}
                            displayValue="domain"
                        />
                    </div>
                    <label className='form-label'>Location</label>
                    <div className='row justify-content-between mb-3'>
                        <div className="form-group col">
                            <input
                                name='city'
                                value={location.city}
                                onChange={this.onInputChange}
                                type="text"
                                className="form-control"
                                placeholder="city" />
                        </div>
                        <div className="form-group col">
                            <input
                                value={location.state}
                                onChange={this.onInputChange}
                                name='state'
                                type="text"
                                className="form-control"
                                placeholder="state" />
                        </div>
                    </div>
                    <div className='row justify-content-between mb-3'>
                        <div className="form-group col-6">
                            <input
                                name='country'
                                value={location.country}
                                onChange={this.onInputChange}
                                type="text"
                                className="form-control"
                                placeholder="country" />
                        </div>
                    </div>
                    <div className='row justify-content-between mb-3'>
                        <div className="form-group col">
                            <label className='form-label'>Delivery Days</label>
                            <input
                                required
                                value={deliveryDays}
                                onChange={this.onInputChange}
                                name='deliveryDays'
                                type="text"
                                className="form-control"
                                placeholder='days' />
                        </div>
                        <div className="form-group col">
                            <label className='form-label'>Seller Language</label>
                            <input
                                name='language'
                                value={language}
                                onChange={this.onInputChange}
                                type="text"
                                className="form-control"
                                placeholder="language" />
                        </div>
                    </div>
                    <label className='form-label'>Budget</label>
                    <div className='row justify-content-between mb-3'>
                        <div className="form-group col-4">
                            <input
                                required
                                name='budgetMin'
                                value={budget.budgetMin}
                                onChange={this.onInputChange}
                                type="text"
                                className="form-control"
                                placeholder="min($)" />
                        </div>
                        <div className="form-group col-4">
                            <input
                                required
                                value={budget.budgetMax}
                                onChange={this.onInputChange}
                                name='budgetMax'
                                type="text"
                                className="form-control"
                                placeholder="max($)" />
                        </div>
                        <div className="form-group col-4">
                            <input
                                required
                                value={budget.fixed}
                                onChange={this.onInputChange}
                                name='fixed'
                                type="text"
                                className="form-control"
                                placeholder="fixed($)" />
                        </div>
                    </div>
                    <label className='form-label'>Budget Specifications</label>
                    <div className='col-12 mb-3'>
                        <textarea
                            rows='2'
                            required
                            value={serviceSpecifications}
                            onChange={this.onInputChange}
                            name='serviceSpecifications'
                            type="text"
                            className="form-control"
                            placeholder="specifications" />
                    </div>
                    {/* <div className="mb-3">
                        <label className='form-label'>Resources files</label>
                        <div className={classes.FileInput}>
                            <input
                                className="form-control"
                                type='file'
                                accept='.png,.jpg,.jpeg,.mp4'
                                multiple
                                // onChange={(event) => handlePreferencesFiles(event, 'images')}
                                id='image' />
                            <label htmlFor='image'>
                                <i className="fa fa-paperclip" aria-hidden="true"></i>
                            </label>
                        </div>
                    </div> */}
                    <div>
                        {this.state.message && <p className='badge bg-primary'>{this.state.message}</p>}
                    </div>
                    {this.props.loading ? <SimpleSpinner /> : <button
                        type="submit"
                        onClick={this.state.tenderId ? this.onEditTender : this.onSubmitNewTender}
                        className="btn btn-primary my-2 fw-bold">{this.state.tenderId ? 'Edit' : 'Submit'} Tender</button>}
                </div>
            </div>
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.userAuth.isAuthenticated,
        user: state.userAuth.user,
        advertiseData: state.dashboardAdvertise.advertiseData,
        message: state.tender.message,
        error: state.tender.error,
        loading: state.tender.loading,
        singleTender: state.tender.tender,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getSingleTender: (tenderId) => dispatch(actions.getSingleTender(tenderId)),
        onFetchTypeAndSubTypes: () => dispatch(actions.fetchTypeAndSubtypes()),
        postNewTender: (tender) => dispatch(actions.postTender(tender)),
        editTender: (tender, tenderId) => dispatch(actions.editTender(tender, tenderId))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation(['Tender'])(LaunchTender));
