import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import classes from '../Dashboard.module.css'
import { Spinner } from '../../../../components/UI/Spinner/Spinner';
import { getDate } from '../../../../shared/utility';

export const OrderDesktopView = ({ latestOrders, isLoading, orderManage }) => {
    const { t } = useTranslation(['Dashboard'])
    return isLoading ? <Spinner /> : latestOrders.map((order, index) => (
        <Link
            to={{
                pathname: `/manage-order/${ order._id }/${ order.advertiseId._id }/${ order
                    .status }`,
            }}
            style={{ textDecoration: 'none' }}>
            <div className={classes.OrdersInfo} key={index}>
                <div className='container'>
                    <div className="row justify-content-between align-items-center">
                        <div className='col-5'>
                            <div className='d-flex align-items-center text-dark'>
                                <img src={order.advertiseId.files[0]} className='img-fluid rounded' alt='Order' />
                                <div className={classes.Avatar}>
                                    {order[orderManage] && order[orderManage].profile_image ?
                                        <img src={order[orderManage] && order[orderManage].profile_image} alt='Profile' /> :order[orderManage] && order[orderManage].username.charAt(0).toUpperCase()}
                                </div>
                                <h6>{order[orderManage] && order[orderManage].username}</h6>
                            </div>
                        </div>
                        <div className='col-1'>
                            <div className={classes.TextInfo}>
                                <small className='text-muted'>{t('Dashboard:orders.0')}</small>
                                <small className='mt-2'>${order.paymentId.priceId}</small>
                            </div>
                        </div>
                        <div className='col-2'>
                            <div className={classes.TextInfo}>
                                <small className='text-muted'>{t('Dashboard:orders.1')}</small>
                                <small className='text-primary mt-2'>{!order.deliveryDate ? 'Not confirmed' : getDate(order.deliveryDate)}</small>
                            </div>
                        </div>
                        <div className='col-2'>
                            <div className={classes.TextInfo}>
                                <small className='text-muted mb-2'>{t('Dashboard:orders.2')}</small>
                                <div className={classes.IsActive}>{order.status}</div>
                            </div>
                        </div>
                        <div className='col-1'>
                            <small className='text-primary'><Link to={{
                                pathname: `/manage-order/${ order._id }/${ order.advertiseId._id }/${ order.status }`,
                            }}>{t('Dashboard:orders.3')}</Link> </small>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    ))
}

