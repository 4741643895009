import React from 'react';
import classes from './MyAdds.module.css';
import styles from './CreateAdd.module.css';
import { Close } from '@material-ui/icons';
import styles1 from '../../NormalUser/Tender/Tender.module.css';

const backgrounds = ['#4dabf7', 'rgb(170, 235, 73)', 'tomato'];

const ViewAdvertise = (props) => {
    const { t } = props;
    return <div className={classes.ViewAdvertise}>
        <div className={classes.ViewAdvertiseHeader}>
            <div className={`d-flex m-2`}>
                <h5>{t('MyAds:MyAdds.title')}</h5>
            </div>
            <div className='d-flex m-2 justify-content-end align-items-center' onClick={props.closed}>
                <Close style={{ color: 'darkgreen', cursor: 'pointer' }} />
            </div>
        </div>
        <div className={classes.DividerV}></div>
        <div className={classes.ViewAdvertiseMain}>
            <div className={classes.ViewAdvertiseMainRow}>
                <div className={classes.TableSingleRowItemForMobile}>
                    <div className={classes.TableSingleRowItemForMobileLabel}>
                        <p>{t('MyAds:MyAdds.columnNames.0')}</p>
                    </div>
                    <div className={classes.DividerH}></div>
                    <div className={classes.TableSingleRowItemForMobileValue}><p>{props.advertiseIndex + 1}</p></div>
                </div>
                <div className={classes.DividerV}></div>
                <div className={classes.TableSingleRowItemForMobile}>
                    <div className={classes.TableSingleRowItemForMobileLabel}>
                        <p>{t('MyAds:MyAdds.columnNames.1')}</p>
                    </div>
                    <div className={classes.DividerH}></div>
                    <div className={classes.TableSingleRowItemForMobileValue}>
                        <p>{props.advertise.title}</p>
                    </div>
                </div>
                <div className={classes.DividerV}></div>
                <div className={classes.TableSingleRowItemForMobile}>
                    <div className={classes.TableSingleRowItemForMobileLabel}>
                        <p>{t('MyAds:MyAdds.columnNames.2')}</p>
                    </div>
                    <div className={classes.DividerH}></div>
                    <div className={classes.TableSingleRowItemForMobileValue} dangerouslySetInnerHTML={{ __html: props.advertise.description }} />
                </div>
                <div className={classes.DividerV}></div>
                <div className={classes.TableSingleRowItemForMobile}>
                    <div className={classes.TableSingleRowItemForMobileLabel}>
                        <p>{t('MyAds:MyAdds.columnNames.9')}</p>
                    </div>
                    <div className={classes.DividerH}></div>
                    <div className={classes.TableSingleRowItemForMobileValue}>
                        <div className={styles1.MediaItem}>
                            {props.advertise.types.map((type, index) => (
                                <span key={index}>
                                    {type}
                                </span>
                            ))}
                        </div>
                    </div>
                </div>
                <div className={classes.DividerV}></div>
                <div className={classes.TableSingleRowItemForMobile}>
                    <div className={classes.TableSingleRowItemForMobileLabel}>
                        <p>{t('MyAds:MyAdds.columnNames.10')}</p>
                    </div>
                    <div className={classes.DividerH}></div>
                    <div className={classes.TableSingleRowItemForMobileValue}>
                        <div className={styles1.MediaItem}>
                            {props.advertise.subtypes.map((subtype, index) => (
                                <span key={index}>
                                    {subtype}
                                </span>
                            ))}
                        </div>
                    </div>
                </div>
                <div className={classes.DividerV}></div>
                <div className={classes.TableSingleRowItemForMobile}>
                    <div className={classes.TableSingleRowItemForMobileLabel}>
                        <p>{t('MyAds:MyAdds.columnNames.5')}</p>
                    </div>
                    <div className={classes.DividerH}></div>
                    <div className={classes.TableSingleRowItemForMobileValue}><p>{props.advertise.packages.length}</p></div>
                </div>
            </div>
            {props.advertise.packages.map((packageData, index) => (
                <div className={classes.ViewAdvertiseMainRow}>
                    <h6 style={{ backgroundColor: backgrounds[index].toString() }}>{packageData.type}</h6>
                    <div className={classes.TableSingleRowItemForMobile}>
                        <div className={classes.TableSingleRowItemForMobileLabel}>
                            <p>{t('MyAds:MyAdds.columnNames.1')}</p>
                        </div>
                        <div className={classes.DividerH}></div>
                        <div className={classes.TableSingleRowItemForMobileValue}><p>{packageData.name}</p></div>
                    </div>
                    <div className={classes.DividerV}></div>
                    <div className={classes.TableSingleRowItemForMobile}>
                        <div className={classes.TableSingleRowItemForMobileLabel}>
                            <p>{t('MyAds:MyAdds.columnNames.2')}</p>
                        </div>
                        <div className={classes.DividerH}></div>
                        <div className={classes.TableSingleRowItemForMobileValue}>
                            <p>{packageData.description}</p>
                        </div>
                    </div>
                    <div className={classes.DividerV}></div>
                    <div className={classes.TableSingleRowItemForMobile}>
                        <div className={classes.TableSingleRowItemForMobileLabel}>
                            <p>{t('MyAds:MyAdds.columnNames.11')}</p>
                        </div>
                        <div className={classes.DividerH}></div>
                        <div>
                            {packageData.tasks.map((task, index) => (
                                <div className={styles.PackageTaskItem} key={index}>
                                    <div>
                                        <div className={styles.PackageTaskItemIndex}><small>{index + 1}</small></div>
                                    </div>
                                    <div className={styles.PackageTaskItemValue}>
                                        <small>{task}</small>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={classes.DividerV}></div>
                    <div className={classes.TableSingleRowItemForMobile}>
                        <div className={classes.TableSingleRowItemForMobileLabel}>
                            <p>{t('MyAds:MyAdds.columnNames.13')}</p>
                        </div>
                        <div className={classes.DividerH}></div>
                        <div className={classes.TableSingleRowItemForMobileValue}>
                            <p>{packageData.delivery_time} days</p>
                        </div>
                    </div>
                    <div className={classes.DividerV}></div>
                    <div className={classes.TableSingleRowItemForMobile}>
                        <div className={classes.TableSingleRowItemForMobileLabel}>
                            <p>{t('MyAds:MyAdds.columnNames.14')}</p>
                        </div>
                        <div className={classes.DividerH}></div>
                        <div className={classes.TableSingleRowItemForMobileValue}>
                            <p>${packageData.price}</p>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    </div>
};

export default ViewAdvertise;