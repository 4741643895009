import React from 'react'
import {Badge, Box} from '@material-ui/core'
import CmtAvatar from '../coremat/CmtAvatar'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx';
import { getCurrentDate } from '../../../../shared/utility';

const useStyles = makeStyles((theme) => ({
  chatCellItem: {
    padding: 16,
    borderBottom: `1px solid #eee`,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover, &.active': {
      backgroundColor: '#eee',
      '& $titleRoot': {
        color: theme.palette.primary.main,
      },
    },
  },
  chatCellInfo: {
    width: 'calc(100% - 40px)',
    paddingLeft: 16,
  },
  titleRoot: {
    position: 'relative',
  },
  chatDesRoot: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: 12,
    color: theme.palette.text.secondary,
    paddingRight: 10,
    width: 'calc(100% - 24px)',
  },
  chatDesRootUnRead: {
    fontSize: 14,
    color: 'black',
    paddingRight: 10,
    width: 'calc(100% - 24px)',
    h6: {
      fontWeight: 800,
    },
  },
  navCount: {
    // backgroundColor: theme.palette.info.main,
    color: 'red',
    background: 'red',
    padding: 2,
    borderRadius: '50%',
    marginLeft: 'auto',
    width: 10,
    height: 10,
    textAlign: 'center',
    lineHeight: '19px',
  },
  chatAvatarRoot: {
    position: 'relative',
  },
  statusDot: {
    position: 'relative',
    '& .MuiBadge-badge': {
      width: 10,
      height: 10,
      borderRadius: '50%',
      border: `solid 1px ${ theme.palette.common.white }`,
      position: 'absolute',
      right: 4,
      top: 6,
      zIndex: 1,
    },
  },
  badgeOnline: {
    backgroundColor: '#8DCD03',
  },
  badgeAway: {
    backgroundColor: '#FF8C00',
  },
  badgeOffline: {
    backgroundColor: '#C1C1C1',
  },
}));

const ChatUserCell = ({data, currentUser, onContactSelect, mainUser}) => {
  const classes = useStyles()
 
  const getBadgeStatusClass = () => {
    if (data.status === 'online') {
      return classes.badgeOnline
    }

    if (data.status === 'away') {
      return classes.badgeAway
    }

    return classes.badgeOffline
  }
  // console.log(data,'CHATUSERCELL')
  return (
    <Box
      className={clsx(classes.chatCellItem, {
        active: currentUser && currentUser.id === data.id,
      })}
      onClick={() => onContactSelect(data)}>
      <Box className={classes.chatAvatarRoot}>
        <Badge
          classes={{root: classes.statusDot, badge: getBadgeStatusClass()}}
          variant="dot">
          <CmtAvatar size={40} src={data.profile_pic} alt={data.name} />
        </Badge>
      </Box>
      <Box className={classes.chatCellInfo}>
        <Box display="flex" alignItems="center">
          <Typography
            component="div"
            variant="subtitle2"
            className={classes.titleRoot}>
            {data.name.length > 13 ? data.name.substring(0, 12) + '...' : data.name}
          </Typography>
          <Box color="text.secondary" fontSize={12} ml="auto">
            {getCurrentDate(data.mood)}
          </Box>
        </Box>

        <Box display="flex" alignItems="center">
          {!data.readMessage && data.sender !== mainUser.email ? (
            <Typography variant="h6" className={classes.chatDesRootUnRead}>
              {data.messageType === 'file' ? 'You received files.' : data.lastMessage}
            </Typography>
          ) : (
            <Typography className={classes.chatDesRoot}>
                {data.messageType === 'file' ? 'You sent files.' : data.lastMessage}
            </Typography>
          )}

          {!data.readMessage && data.sender !== mainUser.email && (
            <Box component="span" className={classes.navCount}></Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default ChatUserCell;
