// @ts-nocheck
import React from 'react'
import classes from './Partner.module.css'
import styles from '../Tender/Tender.module.css'
import { allDomains } from '../../../shared/utils'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import * as actions from '../../../store/actions'
import { withTranslation } from 'react-i18next'
import axios from '../../../axios'
import ShadowImage from '../../../assets/images/cv.png'
import { Spinner } from '../../../components/UI/Spinner/Spinner'
import { Fade } from 'react-reveal'
import { Translate } from 'react-auto-translate'
import Translation from '../../../shared/Translate'
import Translator from '../../../components/Translator'

const colors = [
  '#0d6efd',
  'goldenrod',
  'chocolate',
  'green',
  'rgb(103, 103, 124)',
];

const returnColorIndex = () => Math.floor(Math.random() * colors.length);

class PartnerRequests extends React.Component {
  state = {
    formData: {
      _type: null,
      _subType: null,
      _domains: [],
      _country: null,
      _state: null,
      _city: null,
      _language: null,
    },
    // types: [],
    // subTypes: [],
    // domains: [],
    country: [],
    state: [],
    city: [],
    language: [],
    whichSubItemFocused: {
      is_country: false,
      is_domain: false,
      is_state: false,
      is_city: false,
      is_language: false,
    },
    isSubmitted: false,
  }

  componentDidMount() {
    this.props.onGetPartnerRequests(this.state.formData)
    this.getPartnerSubItems()
    window.scrollTo(0.0, 0.0)
    document.addEventListener('mousedown', this.onHandleClickOutsideCountry)
    document.addEventListener('mousedown', this.onHandleClickOutsideState)
    document.addEventListener('mousedown', this.onHandleClickOutsideCity)
    document.addEventListener('mousedown', this.onHandleClickOutsideDomains)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.onHandleClickOutsideCountry)
    document.removeEventListener('mousedown', this.onHandleClickOutsideState)
    document.removeEventListener('mousedown', this.onHandleClickOutsideCity)
    document.removeEventListener('mousedown', this.onHandleClickOutsideDomains)
  }

  is_country = React.createRef()
  is_state = React.createRef()
  is_city = React.createRef()
  is_domain = React.createRef()

  onHandleClickOutsideDomains = (event) => {
    const boxes = { ...this.state.whichSubItemFocused }
    boxes['is_domain'] = false

    if (this.is_domain && !this.is_domain.current.contains(event.target))
      this.setState({ whichSubItemFocused: boxes })
  }

  onHandleClickOutsideCountry = (event) => {
    const boxes = { ...this.state.whichSubItemFocused }
    boxes['is_country'] = false
    if (this.is_country && !this.is_country.current.contains(event.target))
      this.setState({ whichSubItemFocused: boxes })
  }

  onHandleClickOutsideState = (event) => {
    const boxes = { ...this.state.whichSubItemFocused }
    boxes['is_state'] = false
    if (this.is_state && !this.is_state.current.contains(event.target))
      this.setState({ whichSubItemFocused: boxes })
  }

  onHandleClickOutsideCity = (event) => {
    const boxes = { ...this.state.whichSubItemFocused }
    boxes['is_city'] = false
    if (this.is_city && !this.is_city.current.contains(event.target))
      this.setState({ whichSubItemFocused: boxes })
  }

  getPartnerSubItems = () => {
    axios
      .get('/partner/getPartnerSubItems')
      .then((response) => {
        response.data.domains.forEach((domain) => {
          const index = allDomains.findIndex(
            (_domain) => _domain.name === domain.name,
          )
          if (index !== -1) allDomains[index].value = domain.value
        })

        this.setState({
          types: response.data.types,
          subTypes: response.data.subTypes,
          domains: allDomains,
          country: response.data.countries,
          state: response.data.states,
          city: response.data.cities,
          languages: response.data.languages,
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  filterServices = (searchQuery, services) => {
    if (!searchQuery) return services
    return services.filter((service) =>
      service.name.toLowerCase().includes(searchQuery.toLowerCase()),
    )
  }

  onInputChange = (e) => {
    e.preventDefault()
    const formData = { ...this.state.formData }
    formData[e.target.name] = e.target.value
    this.setState({ formData })
  }

  setValue = (e, name, value) => {
    e.preventDefault()

    const formData = { ...this.state.formData }
    formData[name] = value
    this.setState({ formData })
    this.setUnFocusedAll()
  }

  setWhichSubItemFocused = (e) => {
    const whichSubItemFocused = { ...this.state.whichSubItemFocused }
    whichSubItemFocused['is' + e.target.name] = true

    for (const key in whichSubItemFocused)
      if (key !== 'is' + e.target.name) whichSubItemFocused[key] = false

    this.setState({ whichSubItemFocused })
  }

  setUnFocusedAll = () => {
    const whichSubItemFocused = { ...this.state.whichSubItemFocused }
    for (const key in whichSubItemFocused) {
      whichSubItemFocused[key] = false
    }
    this.setState({ whichSubItemFocused })
  }

  onAddDomainChange = (value) => {
    const formData = { ...this.state.formData }
    if (formData._domains.findIndex((domain) => domain === value) === -1)
      formData._domains.push(value)
    this.setState({ formData })
  }

  onRemoveDomain = (domain) => {
    const formData = { ...this.state.formData }
    const index = formData._domains.findIndex((item) => item === domain)
    if (index !== -1) formData._domains.splice(index, 1)
    this.setState({ formData })
  }

  onSubmitFilter = (e) => {
    e.preventDefault()
    const { _type, _subType, _domains, _country, _state, _city, _language } =
      this.state.formData

    if (
      _type !== null ||
      _subType !== null ||
      _domains.length !== 0 ||
      _country !== null ||
      _state !== null ||
      _city !== null ||
      _language !== null
    ) {
      this.setState({ isSubmitted: true })
      this.props.onGetPartnerRequests(this.state.formData)
    }
  }

  onOpenDomains = () => {
    const whichSubItemFocused = { ...this.state.whichSubItemFocused }
    whichSubItemFocused['is_domain'] = !whichSubItemFocused.is_domain

    this.setState({ whichSubItemFocused })
  }

  isSelectedDomain = (domain) => {
    const { _domains } = this.state.formData
    return _domains.findIndex((item) => item === domain) !== -1
  }

  render() {
    const {
      // _types,
      // _subType,
      _domains,
      _country,
      _state,
      _city,
      // _language,
    } = this.state.formData

    const {
      is_country,
      is_state,
      is_city,
      is_domain,
      // is_language,
    } = this.state.whichSubItemFocused
    const {
      // types,
      // subType,
      domains,
      country,
      state,
      city,
      // language,
    } = this.state

    const filteredCountry = this.filterServices(_country, country)
    const filteredState = this.filterServices(_state, state)
    const filteredCity = this.filterServices(_city, city)

    const { t } = this.props

    return (
      <div className={classes.Partner}>
        <div className={classes.PartnerTop} style={{ background: `#0d6efd` }}>
          <div className="row col-12 justify-content-center">
            <div
              className="col-lg-3 col-12 mb-2 mb-lg-0 relative"
              ref={this.is_domain}>
              <div
                className={classes.SearchFilterItem}
                onClick={this.onOpenDomains}>
                {_domains.length !== 0 ? (
                  <p className="btn btn-sm btn-warning">
                    <Translation
                      from={'en'}
                      text={_domains.length}
                      input={<Translate>{_domains.length}</Translate>}
                    />{' '}
                    <Translation
                      from={'en'}
                      text="Selected"
                      input={<Translate>Selected</Translate>}
                    />
                  </p>
                ) : (
                  <p className="text-secondary">
                    <Translation
                      from={'en'}
                      text="Select Domain"
                      input={<Translate>Select Domain</Translate>}
                    />
                  </p>
                )}
                <i
                  className={`fas fa-chevron-up text-success ms-2 ${ is_domain && classes.SearchFilterIconRotate
                    } ${ classes.SearchFilterIcon }`}></i>
              </div>
              <div className={styles.MediaItem}>
                {_domains.map((domain, index) => (
                  <span key={index}>
                    <Translation
                      text={domain}
                      input={<Translate>{domain}</Translate>}
                    />
                    <i
                      role="button"
                      onClick={(_) => this.onRemoveDomain(domain)}
                      className="fas fa-times mx-1"></i>
                  </span>
                ))}
              </div>
              {is_domain && (
                <div
                  className={classes.SearchResults}
                  style={{ width: '300px', marginTop: '7px' }}>
                  {domains
                    ? domains.map((domain) => (
                      <p
                        role="button"
                        key={domain.name}
                        onClick={(_) => this.onAddDomainChange(domain.name)}>
                        {' '}
                        {this.isSelectedDomain(domain.name) && (
                          <i className="fas fa-check text-warning"></i>
                        )}
                        <Translation
                          text={domain.name}
                          input={<Translate>{domain.name}</Translate>}
                        />
                        <strong>({domain.value})</strong>
                      </p>
                    ))
                    : null}
                </div>
              )}
            </div>
            <div
              className="col-lg-2 col-12 mb-2 mb-lg-0 relative"
              ref={this.is_country}>
              <input
                name="_country"
                onChange={this.onInputChange}
                type="text"
                onFocus={this.setWhichSubItemFocused}
                value={_country}
                className="form-control form-control-lg"
                placeholder={t('country')}
              />
              {is_country && (
                <div className={classes.SearchResults}>
                  {filteredCountry.map((service) => (
                    <p
                      role="button"
                      key={service.name}
                      onClick={(e) =>
                        this.setValue(e, '_country', service.name)
                      }>
                      <Translation
                        text={service.name}
                        input={<Translate>{service.name}</Translate>}
                      />{' '}
                      <strong>({service.value})</strong>
                    </p>
                  ))}
                  {!filteredCountry.length && (
                    <span className="mx-2">
                      <Translation
                        from={'en'}
                        text="No country found"
                        input={<Translate>No country found</Translate>}
                      />
                    </span>
                  )}
                </div>
              )}
            </div>
            <div
              className="col-lg-2 col-12 mb-2 mb-lg-0 relative"
              ref={this.is_state}>
              <input
                onChange={this.onInputChange}
                name="_state"
                value={_state}
                onFocus={this.setWhichSubItemFocused}
                type="text"
                className="form-control form-control-lg "
                placeholder={t('state')}
              />
              {is_state && (
                <div className={classes.SearchResults}>
                  {filteredState.map((service) => (
                    <p
                      role="button"
                      key={service.name}
                      onClick={(e) => this.setValue(e, '_state', service.name)}>
                      <Translation
                        text={service.name}
                        input={<Translate>{service.name}</Translate>}
                      />{' '}
                      <strong>({service.value})</strong>
                    </p>
                  ))}
                  {!filteredState.length && (
                    <span className="mx-2">
                      {' '}
                      <Translation
                        from={'en'}
                        text="No state found"
                        input={<Translate>No state found</Translate>}
                      />
                    </span>
                  )}
                </div>
              )}
            </div>
            <div
              className="col-lg-2 col-12 mb-3 mb-lg-0 relative"
              ref={this.is_city}>
              <input
                onChange={this.onInputChange}
                name="_city"
                type="text"
                onFocus={this.setWhichSubItemFocused}
                value={_city}
                className="form-control form-control-lg"
                placeholder={t('city')}
              />
              {is_city && (
                <div className={classes.SearchResults}>
                  {filteredCity.map((service) => (
                    <p
                      role="button"
                      key={service.name}
                      onClick={(e) => this.setValue(e, '_city', service.name)}>
                      <Translation
                        text={service.name}
                        input={<Translate>{service.name}</Translate>}
                      />{' '}
                      <strong>({service.value})</strong>
                    </p>
                  ))}
                  {!filteredCity.length && (
                    <span className="mx-2">
                      <Translation
                        from={'en'}
                        text="No city found"
                        input={<Translate>No city found</Translate>}
                      />
                    </span>
                  )}
                </div>
              )}
            </div>
            <div className="col-lg-2 col-12">
              <button
                type="submit"
                onClick={this.onSubmitFilter}
                className="btn btn-lg btn-warning w-100">
                <Translation
                  from={'en'}
                  text="Find"
                  input={<Translate>Find</Translate>}
                />
              </button>
            </div>
          </div>
        </div>
        <div className={classes.PartnerBottom}>
          <h6 className="mx-4 my-2">
            <Translation
              from={'en'}
              text="Total partner found"
              input={<Translate>Total partner found</Translate>}
            />
            :{' '}
            <h6 className="btn btn-primary fw-bold">
              {this.props.requests.length}
            </h6>{' '}
          </h6>
          <div className={classes.RequestType}>
            <h3 className="text-center mb-3">
              <Translation
                from={'en'}
                text="Select Partner"
                input={<Translate>Select Partner</Translate>}
              />
            </h3>
          </div>
          <div className={classes.Requests}>
            {this.props.requests.map((request, index) => {
              const color = returnColorIndex();
              return (
                <Fade bottom delay={200 * (index + 1)}>
                  <div
                    key={index}
                    className={classes.Request}
                    style={{ borderTop: `3px solid ${ colors[color] }` }}>
                    <div className={classes.RequestTop}>
                      <img
                        src={request.user && request.user.profile_image ? request.user.profile_image
                            : ShadowImage
                        }
                        alt="Partner"
                      />
                    </div>
                    <div className="py-3 text-muted">
                      <p>{request.name}</p>
                    </div>
                    <div className={classes.RequestType}>
                      <big>
                        <Translation
                          text={
                            request.media.length > 0
                              ? request.media[0] + ' ...'
                              : ''
                          }
                          input={
                            <Translate>
                              {request.media.length > 0
                                ? request.media[0] + ' ...'
                                : ''}
                            </Translate>
                          }
                        />
                      </big>
                    </div>
                    <div className={classes.RequestLocation}>
                      <strong>
                        <i className="fas fa-map-marker-alt mx-1"></i>
                        <Translation
                          text={
                            request.location.city +
                            ', ' +
                            request.location.country
                          }
                          input={
                            <Translate>
                              {request.location.city +
                                ', ' +
                                request.location.country}
                            </Translate>
                          }
                        />
                      </strong>
                    </div>
                    <div className="mt-4 mb-1 col-12 d-flex justify-content-center">
                      <Link
                        to={`/partner-request-description?info_id=${ request._id }&c=${ colors[color] }`}>
                        <button
                          type="submit"
                          style={{ background: colors[color] }}
                          className="btn btn-lg text-light">
                          {request.files ? (
                            <Translation
                              from={'en'}
                              text="Ping Up"
                              input={<Translate>Ping Up</Translate>}
                            />
                          ) : (
                            <Translation
                              from={'en'}
                              text="Explore"
                              input={<Translate>Explore</Translate>}
                            />
                          )}
                        </button>
                      </Link>
                    </div>
                  </div>
                </Fade>
              )
            })}
            {this.props.requests.length === 0 && !this.props.loading && (
              <div className="py-2 w-100 d-flex justify-content-center">
                <div className={classes.RequestLocation}>
                  <li>
                    {' '}
                    <Translation
                      from={'en'}
                      text="No Partner found"
                      input={<Translate>No Partner found</Translate>}
                    />
                  </li>
                </div>
              </div>
            )}
            {this.props.loading && <Spinner />}
          </div>
        </div>
        <Translator />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    requests: state.partner.partnersRequests,
    message: state.partner.message,
    error: state.partner.error,
    loading: state.partner.loading,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onGetPartnerRequests: (filters) =>
      dispatch(actions.onGetPartnerRequests(filters)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation(['Partner'])(PartnerRequests))
