import * as actionTypes from '../actions/ActionTypes'

const initialState = {
  data: [],
  ids: [],
  error: [],
}

export const BuyReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPLOAD_BUY: {
      let dd = [...state.data]
      dd.unshift(action.payload.uploadedData)
      return {
        ...state,
        data: dd,
        error: [],
      }
    }
    case actionTypes.LOAD_BUY: {
      return {
        ...state,
        data: action.payload.loadedData,
        ids: action.payload.ids,
        error: [],
      }
    }
    case actionTypes.ERROR_BUY: {
      return {
        ...state,
        error: action.payload.error,
      }
    }
    default:
      return state
  }
}
