import React from 'react'
import classes from './Tender.module.css';
import styles from '../Advertises/SingleAdvertise/SingleAdvertise.module.css';
import { Link } from 'react-router-dom';
import { Line } from '../../../components/UI/Spacing';
import { flag } from 'country-emoji';
import * as actions from '../../../store/actions';
import { connect } from 'react-redux';
import { Spinner, SimpleSpinner } from '../../../components/UI/Spinner/Spinner';
import { withTranslation } from 'react-i18next';
import { getDate } from '../../../shared/utility';
import SimpleSnackbar from '../../../components/Snackbar/Snackbar';

// import { flag, code, name, countries } from 'country-emoji';
class SingleTender extends React.Component {
    state = {
        tenderData: {
            _description: '',
            budget: {
                _budgetMin: '',
                _budgetMax: ''
            },
            _deliveryDays: '',
        },
        message: '',
        tenderId: null
    }

    componentDidMount() {
        window.scrollTo(0.0, 0.0);
        if (this.props.location.search) {
            const search = this.props.location.search;
            const params = new URLSearchParams(search);
            if (params.get("info_id")) {
                this.setState({ tenderId: params.get("info_id") });
                this.props.getSingleTender(params.get("info_id"));
                this.props.getAllSellerBids(params.get("info_id"));
            }
        }
    }

    onReturnOnlyDigit = value => value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');

    onChangeInputBid = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        const tenderData = { ...this.state.tenderData };

        if (name === '_budgetMin')
            tenderData['budget'][name] = this.onReturnOnlyDigit(value);
        else if (name === '_budgetMax')
            tenderData['budget'][name] = this.onReturnOnlyDigit(value);
        else if (name === '_deliveryDays')
            tenderData[name] = this.onReturnOnlyDigit(value);
        else
            tenderData[name] = value;

        this.setState({ tenderData });
    }

    onSubmitBidTender = (event) => {
        event.preventDefault();

        if (!this.props.isAuthenticated) {
            this.setState({ message: 'Please, Log in as seller to bid a tender and must you have subscription.' });
            setTimeout(() => {
                this.props.history.push('/login');
                this.setState({ message: '' })
            }, 3000);
        } else {
            if (this.props.user.role === 'seller' && this.props.user.is_subscribed) {
                this.props.postBidTender(this.state.tenderData, this.state.tenderId);
                setTimeout(() => this.props.history.push('/my-all-bids'), 2000);
            } else {
                this.setState({ message: 'Please, Log in as seller to bid a tender and must you have subscription.' });
                setTimeout(() => this.setState({ message: '' }), 3000);
            }
        }
    }

    render() {
        const { _deliveryDays, _description, budget: { _budgetMin, _budgetMax } } = this.state.tenderData;

        let tenderHeader = '';
        let tenderDescription = <Spinner />;
        let tenderProfile = <Spinner />;
        if (this.props.singleTender) {
            const {
                tender: {
                    title,
                    description,
                    media,
                    subMedia,
                    domains,
                    deliveryDays,
                    isActive,
                    serviceSpecifications,
                    budget: { budgetMin, budgetMax, fixed }
                },
                profile: {
                    user: {
                        full_name, profile_image, username, createdAt
                    }
                }
            } = this.props.singleTender;

            tenderHeader = <div className={classes.TenderTitleMain}>
                <div className='d-flex justify-content-end'>
                    <span className={classes.isOpen}>{isActive ? 'OPEN' : 'CLOSED'}</span>
                </div>
                <div className={classes.TenderTitle}>
                    <div className='mx-lg-3 col-lg-7 col-12'>
                        <h3>{title}</h3>
                    </div>
                    <div className='mx-lg-3 col-lg-4 col-12 mt-4'>
                        <h4>Budget $({budgetMin} - {budgetMax})/ {fixed} CAD , {deliveryDays} days delivery</h4>
                    </div>
                </div>
            </div>

            tenderDescription = <React.Fragment>
                <div className={classes.PartRightItem}>
                    {this.props.isAuthenticated &&
                        <div className='pb-3'>
                            <p>{description}</p>
                        </div>}
                    <div className={classes.MediaItem}>
                        <span>Media :</span>
                        {media.map((media, index) => (
                            <span key={index}>{media}</span>
                        ))}
                    </div>
                    <div className={classes.MediaItem}>
                        <span>SubMedia :</span>
                        {subMedia.map((subMedia, index) => (
                            <span key={index}>{subMedia}</span>
                        ))}
                    </div>
                    <div className={classes.DomainItem}>
                        <span>Domains :</span>
                        {domains.map((tenderData, index) => (
                            <span key={index}>{tenderData.domain}</span>
                        ))}
                    </div>
                    <Line space='3' orientation='h' width='100' height='1' />
                    <div className='py-1'>
                        <h6>Specification</h6>
                        <p>{serviceSpecifications}</p>
                    </div>
                </div>
                <form onSubmit={this.onSubmitBidTender} className={classes.PartRightItem}>
                    <fieldset disabled={!isActive}>
                        <h5>Offer to work on this tender now!</h5>
                        <Line space='1' orientation='h' width='100' height='1' />
                        <label className='form-label mt-2'>Your Offer Budget</label>
                        <div className='row justify-content-between'>
                            <div className="col">
                                <input
                                    required
                                    value={_budgetMin}
                                    name='_budgetMin'
                                    onChange={this.onChangeInputBid}
                                    type="text"
                                    className="form-control"
                                    placeholder='min(cad)' />
                            </div>
                            <div className="col">
                                <input
                                    required
                                    value={_budgetMax}
                                    name='_budgetMax'
                                    onChange={this.onChangeInputBid}
                                    type="text"
                                    className="form-control"
                                    placeholder='max(cad)' />
                            </div>
                        </div>
                        <label className='form-label mt-3'>Delivery Days</label>
                        <div className='row justify-content-between'>
                            <div className="col-lg-6 col-12">
                                <input
                                    maxLength='3'
                                    value={_deliveryDays}
                                    required
                                    onChange={this.onChangeInputBid}
                                    name='_deliveryDays'
                                    type="text"
                                    className="form-control"
                                    placeholder='days' />
                            </div>
                        </div>
                        <label className='form-label mt-3'>Description</label>
                        <textarea
                            required
                            value={_description}
                            onChange={this.onChangeInputBid}
                            name='_description'
                            type="text"
                            rows='4'
                            className="form-control"
                            placeholder='description' />
                        <div className='mt-3'>
                            {this.state.message && <p className='btn btn-sm bg-primary text-light'>{this.state.message}</p>}
                        </div>
                        {this.props.loading ? <SimpleSpinner /> : isActive && <div className='py-2'>
                            <button
                                type='submit'
                                className='btn btn-primary'
                            >Bid on this Tender</button>
                        </div>}
                    </fieldset>
                </form>
                {this.props.isAuthenticated && this.props.user.is_subscribed &&
                    <div className={classes.PartRightItem}>
                        <h5>{this.props.bidsTenders.length} sellers are {isActive ? 'biding' : 'bided'} on this tender.</h5>
                        <Line space='1' orientation='h' width='100' height='1' />
                        <div className={classes.SellerBids}>
                            {this.props.bidsTenders.map((bid, index) => (
                                <React.Fragment key={index}>
                                    <div className='row py-3'>
                                        <div className='col-lg-8 col-12'>
                                            <div className='d-flex'>
                                                <div className='mx-3'>
                                                    <img src={bid.user && bid.user.profile_image} alt='user img' />
                                                </div>
                                                <div className={`${classes.CreatorInfoItem} m-2`}>
                                                    <Link
                                                        to={`/profile/seller/${bid.user && bid.user.username}`}
                                                        target='_blank'>
                                                        <h5>{bid.user && bid.user.full_name}</h5>
                                                    </Link>
                                                    <p>{bid.description}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-12 mt-3'>
                                            <h5>$({bid.budget.budgetMin} - {bid.budget.budgetMax}) CAD <span><p>in {bid.deliveryDays} days</p></span></h5>
                                        </div>
                                    </div>
                                    <div className='row py-2'>
                                        <span className='text-secondary'>{getDate(bid.createdAt)}</span>
                                    </div>
                                    {this.props.bidsTenders.length - 1 > index && <Line space='2' orientation='h' width='100' height='1' />}
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                }
            </React.Fragment>
            tenderProfile = <React.Fragment>
                <div className={classes.PartLeftItem}>
                    <h6>About the Buyer</h6>
                    <Line space='3' orientation='h' width='100' height='1' />
                    <div className='d-flex align-items-center'>
                        <div>
                            <img src={profile_image} alt="company profile" />
                        </div>
                        <div className={classes.CreatorInfoItem}>
                            <Link
                                to={`/profile/buyer/${username}`}
                                target='_blank'>
                                <h6>{full_name}</h6>
                            </Link>
                            {/* <div className='my-1 text-primary'>
                                <span className='badge bg-primary mx-1'>5.0</span>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <span>(10 reviews)</span>
                            </div> */}
                        </div>
                    </div>
                    <div className='py-2'></div>
                    <div className={styles.ExtraInfo}>
                        <div className={styles.ExtraInfoItem}>
                            <p>From</p>
                            <h6>{flag(this.props.singleTender.profile.country)} {this.props.singleTender.profile.country}</h6>
                        </div>
                        <div className={styles.ExtraInfoItem}>
                            <p>Member Since</p>
                            <h6>{getDate(createdAt)}</h6>
                        </div>
                    </div>
                    <Line space='3' orientation='h' width='100' height='1' />
                    <div>
                        <span>{this.props.singleTender.profile.description}</span>
                    </div>
                    <div className='pt-3'>
                        <button className='btn btn-sm w-100 btn-primary'>Contact</button>
                    </div>
                </div>
                <div className={classes.PartLeftItem}>
                    <h6>Others tenders from this Buyer</h6>
                    <Line space='2' orientation='h' width='100' height='1' />
                    <span><i className="fas fa-caret-right mx-1 text-primary"></i>I want to publish an ad</span>
                </div>
                <div className={classes.PartLeftItem}>
                    <h6>Similar Tenders</h6>
                    <Line space='2' orientation='h' width='100' height='1' />
                    <span><i className="fas fa-caret-right mx-1 text-primary"></i>I want to publish an ad</span>
                </div>
            </React.Fragment>
        }
        return <React.Fragment>
            {this.props.message && !this.props.error &&
                <SimpleSnackbar open={true} message={this.props.message} type='success' />}
            {this.props.message && this.props.error &&
                <SimpleSnackbar open='true' message={this.props.message} type='error' />}
            {tenderHeader}
            <div className={`${classes.Tender} mt-3`}>
                <div className={`${classes.PartRight} ml-0 mt-0`}>
                    {tenderDescription}
                </div>
                {this.props.isAuthenticated && this.props.user.is_subscribed &&
                    <div className={`${classes.PartLeft} mx-4`}>
                        {tenderProfile}
                    </div>
                }
            </div>
        </React.Fragment>
    }
}

const mapStateToProps = (state) => {
    return {
        advertiseData: state.dashboardAdvertise.advertiseData,
        isAuthenticated: state.userAuth.isAuthenticated,
        user: state.userAuth.user,
        message: state.tender.message,
        loading: state.tender.loading,
        singleTender: state.tender.tender,
        bidsTenders: state.tender.bids,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getSingleTender: (tenderId) => dispatch(actions.getSingleTender(tenderId)),
        postBidTender: (tenderData, tenderId) => dispatch(actions.postBidTender(tenderData, tenderId)),
        getAllSellerBids: (tenderId) => dispatch(actions.getAllSellerBids(tenderId))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation(['translation', 'MyAds'])(SingleTender))
