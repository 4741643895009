import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Close } from '@material-ui/icons';
import { Modal } from '../../../../components/UI/Modal';
import classes from './Profile.module.css';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

export const CoverPhotoModal = ({ showModal, closeModal, handleCoverFile }) => {
    const [upImg, setUpImg] = useState(null);
    const [image, setImage] = useState(null);
    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);
    const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: 16 / 9 });
    const [completedCrop, setCompletedCrop] = useState(null);

    const onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setImage(e.target.files[0])
            const reader = new FileReader();
            reader.addEventListener('load', () => setUpImg(reader.result));
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const onLoad = useCallback((img) => {
        imgRef.current = img;
    }, []);

    useEffect(() => {
        if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
            return;
        }

        const image = imgRef.current;
        const canvas = previewCanvasRef.current;
        const crop = completedCrop;

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');
        const pixelRatio = window.devicePixelRatio;

        canvas.width = crop.width * pixelRatio;
        canvas.height = crop.height * pixelRatio;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );
    }, [completedCrop]);

    const setCroppedImage = (canvas, crop) => {
        if (crop || canvas) {
            canvas.toBlob(blob => handleCoverFile(blob, 'cover_photo'),
                'image/png', 1
            );
        }
    }

    const setWithoutCropped = () => handleCoverFile(image, 'cover_photo');
    
    const resetFile = (e) => {
        setUpImg(null);
        setImage(null);
        setCompletedCrop(null);
    }

    return <Modal show={showModal} type='lightbox'>
        <div className={`d-flex m-2 justify-content-end align-items-center pointer ${classes.Close}`} onClick={(e) => { resetFile(e); closeModal(e) }}>
            <Close style={{ color: 'darkgreen' }} />
        </div>
        <div className={classes.AddCover}>
            <div className={classes.AddCoverPhoto}>
                <ReactCrop
                    src={upImg}
                    onImageLoaded={onLoad}
                    crop={crop}
                    onChange={(c) => setCrop(c)}
                    onComplete={(c) => setCompletedCrop(c)}
                />
                <canvas
                    ref={previewCanvasRef}
                    style={{
                        margin: '5px 0px',
                        width: Math.round(completedCrop?.width ?? 0),
                        height: Math.round(completedCrop?.height ?? 0)
                    }}
                />
                {upImg ?
                    <div className={classes.Upload}>
                        <button
                            disabled={!completedCrop?.width || !completedCrop?.height}
                            onClick={(e) => setCroppedImage(previewCanvasRef.current, completedCrop)}
                            className="btn btn-outline-primary m-2">
                            <i className="fas fa-crop mx-1"></i>Choose cropped image
                        </button>
                        <button
                            onClick={setWithoutCropped}
                            className="btn btn-primary m-2">
                            <i className="fas fa-upload mx-1"></i>Choose without cropped
                        </button>
                    </div> :
                    <div className={classes.Upload}>
                        <input
                            type='file'
                            accept='.png,.jpg,.jpeg'
                            onChange={onSelectFile}
                            id='cover_photo'
                        />
                        <div>
                            <label htmlFor='cover_photo' className="btn btn-primary fw-bold">
                                <i className="fas fa-image mx-1"></i>Choose image
                            </label>
                        </div>
                    </div>}
            </div>
        </div>
    </Modal>
}