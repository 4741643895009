import React from "react";
import ChatBody from "./Body";
import ChatList from "./List";
import ChatProfile from "./Profile";
import classes from './ChatHome.module.css';
import axios from '../../../axios';
import { connect } from 'react-redux';
import {
  getChatUsers,
  onUserSelect,
  readLastMessage
} from '../../../store/actions/ActionsFiles/Chat';

import {
  tokenHeaders
} from '../../../store/actions/HeaderConfig';

function ChatHome(props) {
  const [show, setShow] = React.useState(false);
  const [profileInfo, setProfileInfo] = React.useState('');
  const [latestMessage, updateLatestMessage] = React.useState(null);
  const [conversations, setConversations] = React.useState([]);

  let users = props.users;
  React.useEffect(() => {
    if (props.currentUser) {
      axios
        .get(
          `/chat/getAboutProfile?userEmail=${ props.currentUser.email }`,
          tokenHeaders(props.token))
        .then((info) => setProfileInfo(info.data.profile));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentUser, props.user])

  const onContactSelect = (contact) => {
    props.onUserSelect(contact);
    updateLatestMessage(null);

    if (contact.sender !== props.user.email) {
      users = users.map((user) => {
        if (user.email === contact.sender) {
          user.readMessage = true;
          return user;
        } else {
          return user;
        }
      });

      let obj = {};

      obj['fromEmail'] = contact.sender;
      obj['toEmail'] = contact.receiver;
      obj['message'] = contact.lastMessage;
      obj['messageType'] = contact.messageType;

      props.readLastMessage(obj);
    }
  }

  React.useEffect(() => {
    props.getChatUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user.email]);

  return <div className={classes.ChatHome}>
    <ChatList
      mainUser={props.user}
      currentUser={props.currentUser}
      users={users.sort(function (x, y) {
        return y.favourite - x.favourite
      })}
      onContactSelect={onContactSelect}
      onOpenOrHide={_ => setShow(true)}
      show={show}
      screenWidth={props.screenWidth}
      latestMessage={latestMessage}
    />
    <ChatBody
      showBody={_ => setShow(false)}
      show={show}
      screenWidth={props.screenWidth}
      profileInfo={profileInfo}
      updateLatestMessage={latestMessage => updateLatestMessage(latestMessage)}
      setConversations={conversations => setConversations(conversations)}
    />
    {props.screenWidth > 1200 && profileInfo && <ChatProfile
      screenWidth={props.screenWidth}
      profileInfo={profileInfo}
      conversations={conversations}
    />}
  </div>
}

const mapStateToProps = (state) => {
  return {
    user: state.userAuth.user,
    token: state.userAuth.token,
    screenWidth: state.responsiveness.screenWidth,
    currentUser: state.chat.currentUser,
    users: state.chat.users
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onUserSelect: (contact) => dispatch(onUserSelect(contact)),
    readLastMessage: (obj) => dispatch(readLastMessage(obj)),
    getChatUsers: () => dispatch(getChatUsers()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatHome);


